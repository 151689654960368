<h2 class="content-block">Belegdetails</h2>

<div class="content-block" *ngIf="viewFinished">
  <div class="dx-card responsive-paddings">
    <p style="font-size: 16px">
      <b>{{ typeAndNumber }}</b>
    </p>

    <div class="card-detail">
      <app-address-card *ngIf="address" [address]="address"></app-address-card>
    </div>

    <div>
      <p style="font-size: 16px"><b>Positionen:</b></p>
      <app-bss-list
        [id]="'supportingDocPositions'"
        [priceRight]="priceRight"
        [inputDataSource]="receiptPosition"
        [searchExpressions]="searchExpressions"
        [emptyInputDataMessage]="'Es sind keine Positionen vorhanden'"
      >
      </app-bss-list>

      <div *ngIf="priceRight">
        <div class="flexContainer">
          <div class="flexContainerItem">Netto:</div>
          <div class="flexContainerItem" style="text-align: right">{{ netSum }} €</div>
        </div>
        <div class="flexContainer">
          <div class="flexContainerItem">Umsatzsteuer:</div>
          <div class="flexContainerItem" style="text-align: right; text-decoration: underline">{{ tax }} €</div>
        </div>
        <div class="flexContainer">
          <div class="flexContainerItem">Gesamt:</div>
          <div
            class="flexContainerItem"
            style="text-align: right; text-decoration: underline; text-decoration-style: double"
          >
            {{ grossSum }} €
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

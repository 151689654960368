import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { GlobalSettings, countryCodes } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { HWAddress, HWFile, HWObjectAddress, Zusatzbezeichnungen } from 'apps/handwerkPWA/src/app/entities';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import {
  ConnectionDialogues,
  ConnectionService,
} from 'apps/handwerkPWA/src/app/services/globalServices/connection.service';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import { MediaService } from 'apps/handwerkPWA/src/app/services/globalServices/media.service';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { FeatureNames } from 'libs/shared/src/lib/entities/models/user/FeatureCheck';
import { ContactHelper } from 'libs/shared/src/lib/helper/contactHelper';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { TimeHelper } from 'libs/shared/src/lib/helper/timeHelper';
import { AuthorizationService } from 'libs/shared/src/lib/services/authorization.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';
import { ObjectaddressService } from '../../../../services/dataServices/objectAddress.service';

@Component({
  selector: 'app-object-address-edit',
  templateUrl: './object-address-edit.component.html',
  styleUrls: ['./object-address-edit.component.scss'],
})
export class ObjectAddressEditComponent implements OnInit, OnDestroy {
  objectAddress: HWObjectAddress;
  customers: HWAddress[];
  showAdditions = false;
  objectAdditionalDesignations: string[];
  selectedFile: HWFile;
  infoIconAdditions: boolean;
  countries = countryCodes;
  customerPath: string;
  userInfo: UserInfo;
  dataChanged: boolean;
  subscription: Subscription;
  dataSavedSubscription: Subscription;
  currentFilesAmount: string;
  allowedToEdit = false;
  isNewAddress = true;
  callPhone = ContactHelper.callPhone;

  constructor(
    private globalSettingService: HWGlobalSettingService,
    private objectAddressService: ObjectaddressService,
    private addressService: AddressService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private authorizationService: AuthorizationService,
    private rightsService: RightsService,
    private connectionService: ConnectionService,
    private mediaService: MediaService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userInfo = await this.globalSettingService.getUserInfo();
    this.allowedToEdit = this.rightsService.getCurrentRight().employeeRights.addressRights.editObjectAddresses;

    this.subscription = this.routingService.save.subscribe(() => {
      void this.save();
    });

    await this.loadObjectAddress();

    this.dataSavedSubscription = this.routingService.dataChanged.subscribe(dataChanged => {
      this.dataChanged = dataChanged;
    });
    this.customers = await this.addressService.getAllBy('ADRTYP', 'K');
    this.customerPath = this.createCustomerPath(this.customers);
    const additionalDesignations: Zusatzbezeichnungen = await this.globalSettingService.getEntity(
      GlobalSettings.AdditionalDesignations
    );
    if (additionalDesignations) this.objectAdditionalDesignations = additionalDesignations.ObjektZusatzBezeichnung;
    this.setUnsetAdditionsIcon();
    const newVersion = this.authorizationService.current.getValue().featureCheck(FeatureNames.mediaTable2).available;
    const files = newVersion
      ? await this.mediaService.getAllBy('Baseuuid', this.objectAddress.getUuid())
      : this.objectAddress.Files;
    this.currentFilesAmount = GlobalHelper.calculateDataAmount(files);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.dataSavedSubscription?.unsubscribe();
  }

  setUnsetAdditionsIcon(): void {
    this.infoIconAdditions = false;
    const tmpAdditionsArray = this.objectAddress.Zusatz.filter(additional =>
      GlobalHelper.isNullOrUndefinedOrEmptyString(additional)
    );
    if (tmpAdditionsArray.length < 5) this.infoIconAdditions = true;
  }

  selectedACountry(countryCode: string): void {
    this.objectAddress.LAND = countryCode;
  }

  isNewObjectAddress(): boolean {
    const lfdNr = this.objectAddress.LfdNr;
    if (lfdNr.length > 10) return true;
    return false;
  }

  async save(): Promise<void> {
    if (!this.objectAddress.Name || this.objectAddress.Name === '') {
      this.dialogService.closeLoadingDialog();
      await this.dialogService.openConfirmDialog(
        'Fehlende Daten!',
        'Um diesen Datensatz zu sichern muss ein Name angegeben werden.',
        'ok',
        null,
        true
      );
      return;
    }

    void this.dialogService.openLoadingDialog('Speichern', 'Speichere Datensatz.');
    if (this.isNewObjectAddress()) {
      const isOnline = await this.connectionService.checkOnline(ConnectionDialogues.CreateData);
      if (!isOnline) return;
      const customer = await this.addressService.findOneBy('KU_NR', this.userInfo.monteur);
      const employee = customer.NAME;
      const datum = TimeHelper.dateToDatabaseDate(new Date());
      this.objectAddress.BEMERKUNG =
        '# Mobil angelegt am ' + datum + ' durch ' + employee + ' # ' + this.objectAddress.BEMERKUNG;
    }
    this.objectAddress.Such = this.objectAddress.Name.toUpperCase();
    if (!this.objectAddress.Kunde) {
      const customerZuObject = this.customers.find(kunde => kunde.KU_NR === this.objectAddress.KuNr);
      this.objectAddress.addCustomer(customerZuObject);
    }
    await this.objectAddressService.sendAddressToWebservice(this.objectAddress);
    this.dialogService.closeLoadingDialog();
    this.routingService.dataChanged.next(false);
    await this.routingService.navigateTo('adresse/' + this.objectAddress.Kunde.Guid + '/objektadressen');
  }

  openMaps(item: HWObjectAddress): void {
    const ort = item.ORT;
    const street = item.STRASSE;
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(ort)) {
      window.open('https://www.google.com/maps/search/?api=1&query=' + street + '%2C+' + ort, '_blank');
    }
  }
  async newImages(): Promise<void> {
    await this.routingService.navigateTo(`medien/ObjectAddress/${this.objectAddress.Guid}/upload`);
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  private createCustomerPath(customers: HWAddress[]): string {
    const customerGuid = customers.find(customer => customer.KU_NR === this.objectAddress.KuNr)?.Guid;
    return `/adresse/${customerGuid}/edit`;
  }

  private async loadObjectAddress(): Promise<void> {
    if (this.routingService.getCurrentRoute().includes('neu')) {
      this.objectAddress = await this.createNewObjectAddress();
      return;
    }
    this.isNewAddress = false;
    const addressGuid = this.routingService.getRouteParam('guid');
    this.objectAddress = await this.objectAddressService.findOneBy('Guid', addressGuid);
  }

  private async createNewObjectAddress(): Promise<HWObjectAddress> {
    const customerNumber = this.routingService.getRouteParam('kundennummer');
    const customer = await this.addressService.findOneBy('KU_NR', customerNumber);

    const newObjectAddress = new HWObjectAddress(null, customerNumber);
    newObjectAddress.addCustomer(customer);

    return newObjectAddress;
  }
}

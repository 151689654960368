<app-bss-search-popup
  [buttonText]="roomEntityType + ' wählen'"
  [buttonStyle]="'bss-button block-button primary-button fullWidth'"
  [headline]="'Wählen Sie einen ' + roomEntityType + '-namen aus'"
  [inputDataSource]="roomNames"
  [customDisplayExpressions]="['Bezeichng']"
  (selectedValue)="roomBookPosition.Bezeich = $event.Bezeichng"
>
</app-bss-search-popup>

<br />
<app-bss-textbox [labelText]="roomEntityType + ' - Bezeichnung'" [(value)]="roomBookPosition.Bezeich"></app-bss-textbox>
<br />
<app-bss-textbox *ngIf="floor" [labelText]="'Stockwerk'" [(value)]="floor.Bezeich" [disabled]="true"></app-bss-textbox>
<br />
<app-bss-textbox *ngIf="apartment" [labelText]="'Gehört zu'" [(value)]="apartment.Bezeich" [disabled]="true">
</app-bss-textbox>
<br />

<button (click)="save(roomBookPosition)" class="bss-button button-block primary-button fullWidth">Speichern</button>

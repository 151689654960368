export class Allocation {
  allocation_ipv4: string;
  allocation_portv4: number;
  allocation_ipv6: string;
  allocation_portv6: number;
  uuid: string;
  errorMessage: string;
  statusCode: number;
  hostname: string;
  turnProxyUrl: string;

  constructor(data: Allocation) {
    Object.assign(this, data);
    this.turnProxyUrl = `https://${this.hostname}:3000/`;
  }
}

<div class="dx-card responsive-paddings">
  <div class="dx-fieldset">
    <div class="fieldbox">
      <app-bss-textbox [labelText]="'Bezeichnung'" [(value)]="manuellePosition.Bezeichnung"></app-bss-textbox>

      <app-bss-textbox [labelText]="'Mengen Einheit'" [(value)]="manuellePosition.MengenEinheit"></app-bss-textbox>

      <app-bss-number-popup
        #mengenInput
        class="customInputStyle"
        [ignoreLabelStyles]="true"
        [labelText]="'Menge ändern'"
        [title]="'Menge ändern'"
        [title]="'Einzelpreis'"
        [mengenTyp]="manuellePosition.MengenEinheit"
        [description]="'Fahrtweg in Km'"
        (outputValue)="manuellePosition.Menge = $event"
        [inputValue]="manuellePosition.Menge"
        [minusAllowed]="false"
        [DecimalPlaces]="3"
        [DecimalPlacesInputEnabled]="true"
        [ValidateInputDecimals]="true"
      >
      </app-bss-number-popup>

      <app-bss-number-popup
        *ngIf="manuellePosition.type != 'Textposition'"
        class="customInputStyle"
        [ignoreLabelStyles]="true"
        [labelText]="'Einzelpreis'"
        [title]="'Einzelpreis'"
        [mengenTyp]="'€'"
        [description]="'Einzelpreis pro Km'"
        (outputValue)="manuellePosition.PriceVK = $event"
        [inputValue]="manuellePosition.PriceVK"
        [minusAllowed]="false"
        [DecimalPlaces]="2"
        [DecimalPlacesInputEnabled]="true"
        [ValidateInputDecimals]="true"
      >
      </app-bss-number-popup>
    </div>

    <button (click)="createCustom(manuellePosition)" class="bss-button primary-button">Speichern</button>
  </div>
</div>

<div class="card aufmass-card" *ngIf="measurement">
  <div class="flexContainer">
    <div class="flexContainerItem" (click)="goToMeasurementDetail()">
      <div class="flexContainer">
        <div class="flexContainerItem">
          <p>Suchbegriff: {{ measurement.Such }}</p>
          <b>{{ measurement.AufName }} {{ measurement?.AufmId }} </b><br />
          <p *ngIf="measurement.Betreff">Betreff: {{ measurement.Betreff }}</p>
          <p>Kunde: {{ customer?.NAME }} {{ customer?.KU_NR }}</p>
          <p *ngIf="project">Projekt: {{ project.Nummer + project.Betreff }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

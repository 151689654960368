<div (click)="showPopupClicked(disabled)" [ngClass]="buttonStyle" [ngStyle]="{ opacity: disabled ? '0.3' : '1' }">
  <div *ngIf="!icon">{{ buttonText }}</div>
  <dx-button *ngIf="icon" [icon]="icon"></dx-button>
</div>

<dx-popup
  [width]="'100%'"
  [maxWidth]="'500px'"
  [title]="headline"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="showPopup"
>
  <div *dxTemplate="let data of 'content'">
    <dx-list
      [searchEnabled]="true"
      [dataSource]="inputDataSource"
      [searchExpr]="customSearchExpression"
      class="popup-card-list"
    >
      <div *dxTemplate="let item of 'item'; let i = index">
        <div class="no-margin-p" (click)="onItemClick(item)">
          <div style="display: grid" *ngIf="dataSourceIconPath">
            <b style="margin: auto" *ngIf="item[customDisplayExpressions[0]]">{{
              item[customDisplayExpressions[0]]
            }}</b>
            <img style="margin: auto" [src]="item[dataSourceIconPath]" width="35" height="35" />
          </div>
          <b *ngIf="!dataSourceIconPath && item[customDisplayExpressions[0]]"
            >{{ firstLineLabel }}{{ item[customDisplayExpressions[0]] }}</b
          >
          <p *ngFor="let expression of customDisplayExpressions | slice: 1">{{ item[expression] }}</p>
        </div>
      </div>
    </dx-list>
  </div>
</dx-popup>

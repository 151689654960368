import { Injectable } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { HWAddress, HWOffeneposten } from 'apps/handwerkPWA/src/app/entities';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { SyncObject } from '../../entities/models/SyncObject';
import { ConnectionDialogues, ConnectionService } from '../globalServices/connection.service';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class OffenepostenService implements DataService {
  serviceName = 'OffenepostenService';
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private dialogService: DialogService,
    private baseService: BaseService,
    private connectionService: ConnectionService,
    private rightsService: RightsService,
  ) {}
  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    await this.pushToWebService();
    await this.getFromWebService(userInfo, silent);
  }

  // currently no way to create or edit open
  async pushToWebService(): Promise<void> {}

  async getOffenePostenByKundennummerByChoice(userInfo: UserInfo, kundenNummer: string): Promise<HWOffeneposten[]> {
    const currentOffenePosten = await this.getOffenePostenByKundennummerFromIDB(kundenNummer);

    const isOnline = await this.connectionService.checkOnline(ConnectionDialogues.GetData);
    if (!isOnline) return currentOffenePosten;

    const livePosten = await this.getOffenePostenByKuNrFromWebservice(userInfo, kundenNummer);
    return livePosten;
  }

  async getOffenePostenByKundennummerFromIDB(kundenNummer: string): Promise<HWOffeneposten[]> {
    return await this.baseService.getAllBy(HWOffeneposten, 'Kundennummer', kundenNummer);
  }

  /**@description Holt die jeweiligen offenen Posten.
   *  Schreibt sie in die IDB, löscht zuvor die jeweilige Tabelle in die geschrieben wird */
  async getFromWebService(userInfo: UserInfo, silent: boolean): Promise<void> {
    const right = this.rightsService.getCurrentRight();
    if (!right.employeeRights.addressRights.showOpenPositions) return;
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle offene Posten...');
    const targetUrl = `allOps/mandant/${userInfo.mandant}/username/${userInfo.monteur}/sStart/0/sCount/0`;
    const openItemData = await this.restService.returnData<HWOffeneposten[]>(targetUrl);
    if (GlobalHelper.isNullOrUndefinedOrEmptyArray(openItemData)) {
      return;
    }
    const openItem = openItemData.map(data => new HWOffeneposten(data));
    await this.updateOpenItemInIdb(openItem);
  }

  async getOffenePostenByKuNrFromWebservice(userInfo: UserInfo, KuNr: string): Promise<HWOffeneposten[]> {
    void this.dialogService.openLoadingDialog('Synchronisation', '...hole offene Posten...');
    const targetUrl = `ops/mandant/${userInfo.mandant}/KU_NR/${KuNr}/username/${userInfo.monteur}`;
    const allPostenData = await this.restService.returnData<HWOffeneposten[]>(targetUrl);
    if (GlobalHelper.isNullOrUndefinedOrEmptyArray(allPostenData)) {
      this.dialogService.closeLoadingDialog();
      return null;
    }
    const offenePosten = allPostenData.map(data => new HWOffeneposten(data));
    await this.updateOpenItemInIdb(offenePosten);
    this.dialogService.closeLoadingDialog();
    return offenePosten;
  }

  getRequiredObjects(): SyncObject[] {
    return [HWAddress];
  }

  /*******************************************************************************
   *
   * PRIVATE FUNCTIONS
   *
   ******************************************************************************** */

  private async updateOpenItemInIdb(offenePosten: HWOffeneposten[]): Promise<void> {
    const kundenNummer = offenePosten[0].Kundennummer;
    await this.controllerService.deleteData('HWOffeneposten', 'Kundennummer', kundenNummer);
    await this.controllerService.setData('HWOffeneposten', offenePosten);
  }
}

import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { Right } from './Right';

export class Role {
  roleName = 'Undefinierte Rolle';
  right: Right;

  constructor(right: Right, roleName?: string) {
    this.right = right;
    if (GlobalHelper.isNullOrUndefined(roleName)) {
      return;
    }
    this.roleName = roleName;
  }
}

<div class="content-block bottom-padding">
  <div class="form-block">
    <h2>Neue Messung</h2>

    <div class="dx-card responsive-paddings messungen">
      <div class="messungenBox">
        <div style="flex: 1">Beschr.</div>
        <div style="flex: 1">Sollwert</div>
        <div style="flex: 2" class="messungenBox">
          <div style="flex: 2">Istwert</div>
          <div style="flex: 0.8">Einheit</div>
        </div>
      </div>

      <div style="padding-bottom: 10px">
        <div *ngFor="let sollwert of sollwertEintraege; let i = index">
          <app-bss-measuring-field
            (keyboardOpen)="openKeyboard = $event"
            [fieldIndex]="i"
            [noSollwert]="false"
            *ngIf="messwerteintraege"
            [label]="sollwert.Beschreibung"
            [sollwert]="sollwert.Messwert"
            [(measurement)]="messwerteintraege[i].Messwert"
            [unit]="sollwert.Einheit"
          >
          </app-bss-measuring-field>
        </div>
      </div>

      <app-bss-toggle-textarea
        *ngIf="sollwersatz"
        [buttonText]="'Beschreibung Sollwert'"
        [(value)]="sollwersatz.Beschreibung"
        [disabled]="true"
      ></app-bss-toggle-textarea>
      <app-bss-toggle-textarea
        *ngIf="messung"
        [buttonText]="'Bemerkung Istwert'"
        [(value)]="messung.Beschreibung"
      ></app-bss-toggle-textarea>
    </div>
  </div>
</div>

<app-bss-speed-dial
  *ngIf="!openKeyboard"
  id="saveButton"
  icon="check"
  class="add-button"
  label="speichern"
  [index]="1"
  (Click)="addMessung()"
></app-bss-speed-dial>

<div class="content-block bottom-padding" *ngIf="roomBookEntry && roomBookEntry.Raufmid">
  <div class="form-block">
    <h2>Maßkette</h2>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <app-bss-plain-text-box [labelText]="'Aufmaß'" [value]="measurement.AufName + ' ' + measurement?.AufmId">
          </app-bss-plain-text-box>

          <app-bss-plain-text-box [labelText]="'Raum'" [value]="room.Bezeich"> </app-bss-plain-text-box>
        </div>

        <app-bss-suggestion-field
          [headline]="'Wählen Sie eine Flächenbezeichnung'"
          [dataTypeName]="'Flächenbezeichnung'"
          [(value)]="roomBookEntry.Bezeich"
          [placeholder]="'Flächenbezeichnung'"
          [inputDataSource]="roomNames"
          [customDisplayExpression]="'Bezeichng'"
          [searchExpressions]="searchExpressions"
        >
        </app-bss-suggestion-field
        ><br />

        <app-aufmass-formel
          [aufmass]="measurement"
          (generatedFormula)="roomBookEntry.useFormula($event)"
        ></app-aufmass-formel
        ><br />

        <app-bss-switchbox [labelText]="'Nicht berechnen'" [(value)]="roomBookEntry.NotCalc"> </app-bss-switchbox><br />

        <app-bss-switchbox [labelText]="'Abzug'" [(value)]="roomBookEntry.IsAbzug"> </app-bss-switchbox><br />

        <app-bss-select-box
          *ngIf="roomBookEntry.IsAbzug"
          [labelText]="'Abzug von'"
          [placeholder]="'Bezugsfläche auswählen'"
          [displayExpr]="'Bezeich'"
          [items]="bezugsFlaechen"
          (selectedValue)="selectedBezugsArea($event)"
        >
        </app-bss-select-box
        ><br />

        <button class="bss-button deleteButton" (click)="deleteEntry()">Löschen</button>
      </div>
    </div>
  </div>
</div>

<app-bss-speed-dial id="saveButton" icon="check" class="add-button" label="speichern" [index]="1" (Click)="save()">
</app-bss-speed-dial>

<div class="content-block bottom-padding" *ngIf="loaded">
  <div class="form-block">
    <h2>{{ headline }}</h2>

    <div class="dx-card responsive-paddings">
      <div class="fieldbox">
        <app-bss-textbox [labelText]="'Name'" [placeholder]="'Name'" [(value)]="measurement.AufName"> </app-bss-textbox>

        <app-bss-select-box
          *ngIf="!isEdit"
          [items]="typeOptions"
          [displayExpr]="'displayName'"
          [value]="selectedType"
          (selectedValue)="changeType($event)"
        ></app-bss-select-box>

        <app-bss-textbox [labelText]="'Suchbegriff'" [placeholder]="'Suchbegriff'" [(value)]="measurement.Such">
        </app-bss-textbox>

        <app-bss-textbox [labelText]="'Betreff'" [placeholder]="'Betreff'" [(value)]="measurement.Betreff">
        </app-bss-textbox>
      </div>

      <div class="flexContainer project-or-address-box">
        <div class="flexContainerItem">
          <app-bss-search-popup
            [buttonText]="'Projekt wählen'"
            [buttonStyle]="'bss-button primary-button'"
            [headline]="'Wählen Sie ein Projekt aus'"
            [inputDataSource]="projects"
            [customDisplayExpressions]="['Nummer', 'Betreff', 'KdSuch']"
            [firstLineLabel]="'Projekt '"
            (selectedValue)="selectedOne($event)"
          >
          </app-bss-search-popup>
        </div>
        <div class="or-box">oder</div>
        <div class="flexContainerItem">
          <app-bss-search-popup
            [buttonText]="'Kunde wählen'"
            [buttonStyle]="'bss-button primary-button'"
            [headline]="'Wählen Sie einen Kunden aus'"
            [inputDataSource]="customers"
            [customDisplayExpressions]="['NAME', 'STRASSE', 'PLZ', 'ORT']"
            [additionalSearchExpression]="['KU_NR']"
            (selectedValue)="selectedOne($event)"
          >
          </app-bss-search-popup>
        </div>
      </div>

      <br />

      <div *ngIf="selectedProject">
        <b>Projekt:</b><br />
        <app-project-card [project]="selectedProject"></app-project-card>
        <br />
      </div>

      <div *ngIf="selectedAddress">
        <b>Kunde:</b><br />
        <app-address-card
          [noClickEvents]="true"
          [address]="selectedAddress"
          [withContactBar]="false"
        ></app-address-card>
        <br />
      </div>

      <div *ngIf="!isEdit && measurement.Type === measurementTypes.roomMeasurement">
        <app-bss-select-box
          [showClearButton]="true"
          [labelText]="'Raumvorlage'"
          [items]="roomBooks"
          [placeholder]="'Raumvorlage wählen'"
          (selectedValue)="selectedOne($event, true)"
          [displayExpr]="'Bezeich'"
        >
        </app-bss-select-box>

        <br />
        <div>oder</div>
        <br />

        <app-bss-textbox
          [labelText]="'Raumvorlage'"
          [placeholder]="'Freie Raumvorlage'"
          [(value)]="measurement.RBezeich"
        >
        </app-bss-textbox>

        <br />
      </div>

      <app-bss-textarea [labelText]="'Bemerkungen'" [(value)]="measurement.Beschr"></app-bss-textarea>
    </div>
  </div>
</div>

<app-bss-speed-dial *ngIf="!isEdit" icon="check" class="add-button" label="erstellen" [index]="1" (Click)="createNew()">
</app-bss-speed-dial>

<app-bss-speed-dial
  id="saveButton"
  *ngIf="isEdit"
  icon="check"
  class="add-button"
  label="speichern"
  [index]="1"
  (Click)="saveEdit()"
>
</app-bss-speed-dial>

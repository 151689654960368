<div class="slider-buttons">
  <div class="button-navigation">
    <dx-button [icon]="'chevronprev'" class="icon-button" (click)="previousView()"></dx-button>
  </div>
  <div class="buttons-slider">
    <div class="button-container">
      <dx-button *ngFor="let site of multiViewItems; let i = index" (click)="setView(i)" class="slider-btn"></dx-button>
    </div>
  </div>
  <div class="button-navigation">
    <dx-button [icon]="'chevronright'" class="icon-button" (click)="nextView()"></dx-button>
  </div>
</div>
<dx-multi-view
  [animationEnabled]="false"
  [loop]="true"
  [dataSource]="multiViewItems"
  itemTemplate="item"
  #multiView
  (onSelectionChanged)="selectionChanged(multiView.selectedIndex)"
>
  <div *dxTemplate="let multiViewSite of 'item'">
    <div class="multiview-title">
      <span (click)="goToPage(multiViewSite.path)">{{ multiViewSite.title }}</span>
    </div>

    <app-bss-list
      [id]="multiViewSite.cardName"
      [inputDataSource]="multiViewSite.dataArray"
      [emptyInputDataMessage]="multiViewSite.emptyInputDataMessage"
      [searchExpressions]="multiViewSite.searchExpression"
    ></app-bss-list>
  </div>
</dx-multi-view>

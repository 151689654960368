import { Component, OnInit } from '@angular/core';
import { HWAddress, HWObjectAddress } from 'apps/handwerkPWA/src/app/entities';
import { AddressTypeHelper } from 'apps/handwerkPWA/src/app/helper/entities/HWAddress/addressTypeHelper';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { ObjectaddressService } from 'apps/handwerkPWA/src/app/services/dataServices/objectAddress.service';
import { EmployeeRights } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { AddressLongTypes } from '../../../config/Konstanten';
import { addressSearch } from '../../../config/search-constants';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';
import { SyncObjectService } from '../../../services/globalServices/syncObject.service';

@Component({
  selector: 'app-addresses-index',
  templateUrl: './addresses-index.component.html',
  styleUrls: ['./addresses-index.component.scss'],
})
export class AddressesIndexComponent implements OnInit {
  addressTypes = ['Alle', 'Kunden'];
  allAddresses: (HWAddress | HWObjectAddress)[];
  filteredAddresses: (HWAddress | HWObjectAddress)[];
  adressType = this.addressTypes[0];
  contactInformationArray = ['TEL', 'FUNK_PRIV', 'GLOBEMAIL', 'Anschrift', 'WWW'];
  searchExpressions = addressSearch;
  employeeRights: EmployeeRights;

  constructor(
    private globalSettingService: HWGlobalSettingService,
    private addressService: AddressService,
    private objectAddressService: ObjectaddressService,
    private routingService: RoutingService,
    private rightsService: RightsService,
    private syncObjectService: SyncObjectService,
  ) {}

  async ngOnInit(): Promise<void> {
    const hwAddresses = await this.addressService.getAllDisplayable();
    this.employeeRights = this.rightsService.getCurrentRight().employeeRights;
    this.addAddressCategories();
    this.allAddresses = hwAddresses.filter(address => address.LeaveDate == null || address.LeaveDate >= new Date());
    this.allAddresses = this.allAddresses.concat(await this.objectAddressService.getAll());
    this.filteredAddresses = this.allAddresses;
    const lastAdressType = this.globalSettingService.lastAddressSelectionType;
    if (lastAdressType) {
      this.adressType = AddressTypeHelper.getAddressesFullType(lastAdressType);
      this.filterAddressesByType(lastAdressType);
    }
    this.syncObjectService.currentSyncObjects.next([HWAddress, HWObjectAddress]);
  }

  addAddressCategories(): void {
    const addressRights = this.rightsService.getCurrentRight().employeeRights.addressRights;
    if (addressRights.showEmployees) this.addressTypes.push('Mitarbeiter');
    if (addressRights.showSuppliers) this.addressTypes.push('Lieferanten');
    if (addressRights.showFreeAddresses) this.addressTypes.push('Freie Adressen');
    if (addressRights.showObjectAddresses) this.addressTypes.push('Objektadressen');
  }
  /**@description Wenn die AdressenSelection geändert wurde */
  filterChanged(adressType: AddressLongTypes): void {
    const shortType = AddressTypeHelper.getShortTypeFromType(adressType);
    this.globalSettingService.lastAddressSelectionType = shortType;
    if (GlobalHelper.isNullOrUndefined(shortType)) {
      this.filteredAddresses = this.allAddresses;
      return;
    }
    this.filterAddressesByType(shortType);
  }

  async newAddress(): Promise<void> {
    await this.routingService.navigateTo('adresse/neu');
  }

  private filterAddressesByType(filterType: string): void {
    this.filteredAddresses = this.allAddresses.filter(address => address.ADRTYP === filterType);
  }
}

import { UserInfo } from 'libs/shared/src/lib/entities';
import { HWAnlage, Messwerttabelle } from '../../entities';

export class MaintenanceHelper {
  /**@description Fügt der Messung metaDaten hinzu und entfernt überflüssige Daten */
  static transformMessungForHandwerkDb(
    anlage: HWAnlage,
    messung: Messwerttabelle,
    userInfo: UserInfo
  ): Messwerttabelle {
    const sendMessung = new Messwerttabelle(anlage.Anlagendaten, messung);
    sendMessung.mandant = userInfo.mandant;
    sendMessung.monteur = userInfo.monteur;
    const messwerte: string[] = [];
    for (const eintrag of messung.MesswertTabellenEintraege) {
      const messwert = eintrag.Messwert;
      messwerte.push(messwert);
    }
    sendMessung.messwerte = messwerte;
    delete sendMessung.MesswertTabellenEintraege;
    delete sendMessung.notTransferred;
    return sendMessung;
  }

  static getUntransferredMessungen(anlagen: HWAnlage[]): { Anlage: HWAnlage; Messungen: Messwerttabelle[] }[] {
    const anlageAndMessung: { Anlage: HWAnlage; Messungen: Messwerttabelle[] }[] = [];
    for (const anlage of anlagen) {
      const messwertSatz = anlage.Messwertsatz;
      if (!messwertSatz) {
        continue;
      }
      const userMessungen = messwertSatz.Istwerttabellen;
      const untransferredMessungen = userMessungen.filter(messung => messung.notTransferred === true);
      if (untransferredMessungen.length === 0) {
        continue;
      }
      anlageAndMessung.push({ Anlage: anlage, Messungen: untransferredMessungen });
    }

    return anlageAndMessung;
  }
}

import { Component, Input } from '@angular/core';
import { Setting } from 'libs/shared/src/lib/entities';
import { DialogService } from 'libs/shared/src/lib/services/dialog.service';
import { MailService } from '../../services/mail.service';

@Component({
  selector: 'app-mailtest',
  templateUrl: './mailtest.component.html',
  styleUrls: ['./mailtest.component.scss'],
})
export class MailtestComponent {
  @Input() settings: Setting;
  recipient = '';

  constructor(private dialogService: DialogService, private mailService: MailService) {}

  async sendTestMail(): Promise<void> {
    void this.dialogService.openLoadingDialog('E-Mail Test', '...Teste den Versand der E-Mail...'); // Kein Close Loadingdialog - der Mailservice macht die nächste Nachricht
    await this.mailService.sendTestMail(this.settings, this.recipient);
  }
}

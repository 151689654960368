import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { UserInfo } from 'libs/shared/src/lib/entities/models/user/UserInfo';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';
import { MeasurementTypes } from '../../../config/Konstanten';
import { Aufmass, HWAddress, Projekt, RoomTemplate } from '../../../entities';
import { ActivityTrackerService } from '../../../services/dataServices/activityTracker.service';
import { AddressService } from '../../../services/dataServices/address.service';
import { MeasurementService } from '../../../services/dataServices/measurement.service';
import { ProjectService } from '../../../services/dataServices/project.service';
import { RoomTemplateService } from '../../../services/dataServices/roomTemplate.service';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';

@Component({
  selector: 'app-measurement-create-update',
  templateUrl: './measurement-create-update.component.html',
  styleUrls: ['./measurement-create-update.component.scss'],
})
export class AufmassNewComponent implements OnInit, OnDestroy {
  headline = 'Neues Aufmaß erstellen';
  userInfo: UserInfo;
  projects: Projekt[];
  roomBooks: RoomTemplate[];
  roomTemplates: RoomTemplate[];
  customers: HWAddress[];
  isEdit = false;
  measurement: Aufmass;
  selectedAddress: HWAddress;
  selectedProject: Projekt;
  loaded = false;
  saveSubscription: Subscription;
  typeOptions: { displayName: string; value: MeasurementTypes }[];
  selectedType: { displayName: string; value: MeasurementTypes };
  measurementTypes = MeasurementTypes;

  constructor(
    private routingService: RoutingService,
    private measurementService: MeasurementService,
    private roomTemplateService: RoomTemplateService,
    private globalSettingService: HWGlobalSettingService,
    private dialogService: DialogService,
    private projectService: ProjectService,
    private addressService: AddressService,
    private activityTrackerService: ActivityTrackerService,
  ) {}
  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    await this.loadData();
    const uuidFromEdit = this.routingService.getRouteParam('uuid');
    this.isEdit = !GlobalHelper.isNullOrUndefined(uuidFromEdit);
    if (this.isEdit) await this.loadDataFromMeasurement(uuidFromEdit);
    else this.createNewMeasurement();

    var hideMeasurementType =
      this.globalSettingService.isInProdEnvironment() || this.globalSettingService.isInQsEnvironment();
    if (hideMeasurementType) {
      this.typeOptions = [{ displayName: 'Raumaufmaß', value: MeasurementTypes.roomMeasurement }];
    } else {
      this.typeOptions = [
        { displayName: 'Raumaufmaß', value: MeasurementTypes.roomMeasurement },
        { displayName: 'Freies Aufmaß', value: MeasurementTypes.freeMeasurement },
      ];
    }

    this.selectedType = this.typeOptions.find(option => option.value === this.measurement.Type);

    this.loaded = true;

    await this.preselectCustomer();
  }

  createNewMeasurement(): void {
    const newAufmass = new Aufmass(null);
    const type = this.routingService.getRouteParam('type') as MeasurementTypes;
    newAufmass.createNew(type);
    this.measurement = newAufmass;
  }

  changeType(selectedType: { displayName: string; value: MeasurementTypes }): void {
    this.measurement.setTypeVariables(selectedType.value);
  }

  selectedOne(entity: Projekt | RoomTemplate | HWAddress, wasRVorlage = false): void {
    if (entity instanceof Projekt) {
      this.selectedProject = entity;
      if (!this.measurement.Such) this.measurement.Such = entity.KdSuch;
      this.measurement.ProjName = entity.Name;
      this.measurement.Betreff = entity.Betreff;
      const projectCustomer = this.customers.find(customer => customer.KU_NR === entity.Kunde);
      this.selectedOne(projectCustomer);
    }
    if (entity instanceof HWAddress) {
      this.selectedAddress = entity;
      if (!this.measurement.Such) this.measurement.Such = entity.SUCH;
      this.measurement.Kunde = entity.KU_NR;
      if (this.selectedAddress.KU_NR !== this.selectedProject?.Kunde)
        // Project no longer fits the customer => discard
        this.selectedProject = undefined;
    }
    if (entity instanceof RoomTemplate) {
      this.measurement.RVorlage = entity.Haustyp;
      this.measurement.RBezeich = entity.Bezeich;
    }
    if (wasRVorlage && entity === null) {
      this.measurement.RVorlage = 0;
      this.measurement.RBezeich = '';
    }
  }

  async createNew(): Promise<void> {
    const measurement = this.measurement;
    if (!measurement.ProjName && !measurement.Kunde) {
      await this.dialogService.openErrorMessage(
        'Fehlende Eingabe',
        'Es muss entweder ein Projekt oder eine Kunde ausgewählt sein.',
      );
      return;
    }
    if (measurement.Type === MeasurementTypes.roomMeasurement) {
      const success = await this.hasRoomBookOrTemplate(measurement);
      if (!success) return;
    }
    const saved = await this.measurementService.saveAufmass(measurement, this.userInfo, false);
    if (saved) void this.routingService.navigateTo(`/aufmass/${measurement.Uuid}/raumbuch`);
    else this.routingService.routeBack(true);
  }

  async hasRoomBookOrTemplate(measurement: Aufmass): Promise<boolean> {
    if (!measurement.RBezeich) {
      await this.dialogService.openErrorMessage(
        'Fehlendes Raumbuch',
        'Sie müssen ein Raumbuch wählen oder einen Namen für ein freies Raumbuch eingeben.',
      );
      return true;
    }
    if (measurement.RVorlage !== 0) {
      measurement.addRoomBookPositionsFromRoomBookTemplate(measurement.RVorlage, this.roomTemplates);
      return true;
    }
    return false;
  }
  async saveEdit(): Promise<void> {
    await this.activityTrackerService.countUpAutoSyncs();
    this.routingService.dataChanged.next(false);
    await this.measurementService.saveAufmass(this.measurement, this.userInfo, false);
    void this.routingService.navigateTo(`/aufmass/${this.measurement.Uuid}/raumbuch`);
  }

  /**@description If you navigated here via the customer, select this customer */
  private async preselectCustomer(): Promise<void> {
    const customerUuid = this.routingService.getRouteParam('kundeUuid');
    if (!customerUuid) return;
    const customer = await this.addressService.findOneBy('Guid', customerUuid);
    this.selectedOne(customer);
  }

  /**@description Loads measurement data and fills the fields for editing */
  private async loadDataFromMeasurement(uuid: string): Promise<void> {
    this.headline = 'Aufmaß bearbeiten';
    this.measurement = await this.measurementService.findOneBy('Uuid', uuid);
    this.selectedAddress = this.customers.find(customer => customer.KU_NR === this.measurement.Kunde);
    this.selectedProject = this.measurement.ProjName
      ? this.projects.find(project => project.Name === this.measurement.ProjName)
      : null;
    this.saveSubscription = this.routingService.save.subscribe(() => void this.saveEdit());
  }
  private async loadData(): Promise<void> {
    this.userInfo = await this.globalSettingService.getUserInfo();
    this.projects = await this.projectService.getAll();
    this.customers = await this.addressService.getAllBy('ADRTYP', 'K');
    this.roomTemplates = await this.roomTemplateService.getAll();
    this.roomBooks = await this.roomTemplateService.getAllRoomBookTemplates();
  }
}

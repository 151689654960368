import { Component, OnInit } from '@angular/core';
import { HWAddress, HWRepairOrder, HWTermin, ServiceAuftrag } from 'apps/handwerkPWA/src/app/entities';
import { AppointmentService } from 'apps/handwerkPWA/src/app/services/dataServices/appointment.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { appointmentSearch } from '../../../config/search-constants';
import { AppointmentHelper } from '../../../helper/services/terminHelper';
import { AddressService } from '../../../services/dataServices/address.service';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';

@Component({
  selector: 'app-appointments-index',
  templateUrl: './appointments-index.component.html',
  styleUrls: ['./appointments-index.component.scss'],
})
export class AppointmentsIndexComponent implements OnInit {
  appointmentsForCustomer: (HWTermin | HWRepairOrder | ServiceAuftrag)[];
  selectedAppointment: HWTermin;
  searchExpressions = appointmentSearch;
  address: HWAddress;

  constructor(
    private globalSettingService: HWGlobalSettingService,
    private appointmentService: AppointmentService,
    private rightsService: RightsService,
    private addressService: AddressService,
    private routingService: RoutingService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAppointments();
  }

  async navigateTo(path: string): Promise<void> {
    const dateInMs = new Date().getTime();
    await this.routingService.navigateTo(`${path}/${dateInMs}/${this.address.KU_NR}`);
  }

  private async loadAppointments(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const addressGuid = this.routingService.getRouteParam('guid');
    this.address = await this.addressService.findOneBy('Guid', addressGuid);
    const rights = this.rightsService.getCurrentRight();
    const baseAppointmentsForCustomer = await this.appointmentService.getAllAppointmentsFromIDB(
      userInfo,
      this.address.KU_NR
    );
    this.appointmentsForCustomer = AppointmentHelper.appointmentsToMoreSpecificDatasets(
      baseAppointmentsForCustomer,
      rights.employeeRights
    );
  }
}

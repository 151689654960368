import * as uuid from 'uuid';
import { BaseInformationTracker } from '../repository/BaseInformationTracker';
import { ModuleCalls } from './ModuleCallsDTO';

export class ActivityTrackingEntryDTO {
  uuid: string;
  installation_ID: string;
  device_ID: string;
  installationType: string;
  usedPWALicense: string;
  licenseCount: number;
  usedLicenseSlots: number;
  mandant: string;
  monteur: string;
  operatingsystem: string;
  usedBrowser: string;
  moduleCalls: ModuleCalls[];
  automaticSyncCount: number;
  manualSyncCount: number;
  dateTime: Date;

  constructor(
    baseInformation: BaseInformationTracker,
    moduleCalls: ModuleCalls[],
    callsAutomaticSync: number,
    callsManualSync: number
  ) {
    this.installation_ID = baseInformation.installation_ID;
    this.device_ID = baseInformation.device_ID;
    this.installationType = baseInformation.installationType;
    this.usedPWALicense = baseInformation.usedPWALicense;
    this.licenseCount = baseInformation.licenseCount;
    this.usedLicenseSlots = baseInformation.usedLicenseSlots;
    this.mandant = baseInformation.mandant;
    this.monteur = baseInformation.monteur;
    this.operatingsystem = baseInformation.operatingsystem;
    this.usedBrowser = baseInformation.usedBrowser;
    this.dateTime = baseInformation.dateTime;
    this.uuid = uuid.v4();
    this.moduleCalls = moduleCalls;
    this.automaticSyncCount = callsAutomaticSync;
    this.manualSyncCount = callsManualSync;
  }
}

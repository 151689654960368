import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DxSelectBoxComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { ValueChangedEvent } from 'devextreme/ui/select_box';
import { RoutingService } from '../../../services/routing.service';
@Component({
  selector: 'app-bss-select-box',
  templateUrl: './bss-select-box.component.html',
  styleUrls: ['./bss-select-box.component.scss'],
})
export class BssSelectBoxComponent<Type, Key extends keyof Type> implements AfterViewInit, OnChanges {
  @Input() labelText: string;
  @Input() items: Type[];
  @Input() value: Type;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() acceptCustomValue = false;
  @Input() showClearButton = false;
  @Input() displayExpr: Key | (() => string);
  @Input() searchMode: string;
  @Input() searchExpr: Key;
  @Input() minSearchLength: number;
  @Input() showDataBeforeSearch = true;

  @Output() selectedValue = new EventEmitter<Type>();
  @ViewChild(DxSelectBoxComponent) selectBox: DxSelectBoxComponent;

  selectBoxData: DataSource;
  displayExpressionValue: string | (() => string);

  constructor(private routingService: RoutingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const dataSourceChanged = changes.items;
    if (dataSourceChanged) {
      if (this.displayExpr) {
        this.displayExpressionValue =
          typeof this.displayExpr === 'function' ? this.displayExpr : this.displayExpr.toString();
      }
      this.createSelectBoxData();
      return;
    }
  }

  ngAfterViewInit(): void {
    if (this.searchMode && this.selectBox) {
      this.selectBox.searchMode = this.searchMode;
    }
  }

  createSelectBoxData(): void {
    this.selectBoxData = new DataSource({
      store: new ArrayStore({
        data: this.items,
      }),
      pageSize: 10,
      paginate: true,
    });
  }

  valueChanged(event: ValueChangedEvent): void {
    const value = event.value as Type;
    this.routingService.dataChanged.next(true);
    this.selectedValue.emit(value);
  }
}

import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import * as uuid from 'uuid';
import { BuildingElement } from '../models/aufmass/BuildingElement';
import { MeasurementRoute } from '../models/aufmass/MeasurementRoute';
import { RoomBookPosition } from '../models/aufmass/RoomBookPosition';

export class RoomBook {
  KlassenName: string;
  Uuid: string = null;
  Aufmid: string = null;
  /** Assigns a room book entry to a room */
  Rpos_Id: number = null;
  /** Consecutive number, starting at 1, for all elements that are not a deduction - If the position is a deduction, the LineId shows which actual element the deduction refers to. */
  Lineid: number = null;
  /** Consecutive number starting with 1 - individual for each surface. Ex: Wall1 has windowA, windowB,
   * wall2 has door - then the deductionIds would be : windowA = 1, windowB = 2, door = 1 (because other face)
   */
  Abzugid: number = null;
  /** Sequential number starting at 1 for each room book entry of a room, first entry 1, second 2,...*/
  Raufmid: number = null;
  Bezeich: string = null;
  Zresult: number = null;
  IsAbzug: boolean = null;
  NotCalc: boolean = null;
  Aufmass: string = null;
  /** Consecutive number, starting at 1, over ALL entries of ALL rooms of ALL measurements (Autoinc) */
  Id: number = null;
  transferred: boolean = null;
  raumbPosUuid: string = null;

  // PWA Only
  isFloor = false;
  isCeiling = false;
  private AutoKey: string;

  constructor(roomBook: RoomBook, roomBookPosition?: RoomBookPosition) {
    this.Uuid = uuid.v4();
    GlobalHelper.assignIfPropertyExists(this, roomBook);

    // Formal, so that the logic of the baseService continues to work - on new creation the roomBPos MUST be specified.
    if (roomBookPosition) this.raumbPosUuid = roomBookPosition.Uuid;
  }

  static toString(): string {
    return 'RoomBook';
  }

  /**@description Helper function to give modified identifiers for building elements */
  getBezeich(): string {
    if (this.IsAbzug) return '>' + this.Bezeich;
    return this.Bezeich;
  }

  fillFromMeasurement(
    currentEntries: RoomBook[],
    room: RoomBookPosition,
    measurement: MeasurementRoute | BuildingElement,
    height: number,
    formula?: string,
  ): void {
    this.Uuid = measurement.uuid;

    this.Aufmid = room.AufmId;
    this.Rpos_Id = room.Rpos_ID;
    this.Bezeich = measurement.labelLong;
    const isBuildingElement = measurement instanceof BuildingElement;
    let belongsToRouteWithLineId: number;
    if (measurement instanceof BuildingElement) {
      let deductionFrom: RoomBook;
      if (measurement.deductionFromUuid === 'boden') deductionFrom = currentEntries.find(entry => entry.isFloor);
      if (measurement.deductionFromUuid === 'decke') deductionFrom = currentEntries.find(entry => entry.isCeiling);
      if (!(measurement.deductionFromUuid === 'decke' || measurement.deductionFromUuid === 'boden'))
        deductionFrom = currentEntries.find(entry => entry.Uuid === measurement.deductionFromUuid);
      belongsToRouteWithLineId = deductionFrom.Lineid;
    }
    this.IsAbzug = isBuildingElement;
    this.NotCalc = false;
    this.Raufmid = GlobalHelper.getNextNumber(
      currentEntries.map(entry => entry.Raufmid),
      1,
    );
    this.Lineid = !isBuildingElement
      ? GlobalHelper.getNextNumber(
          currentEntries.map(entry => entry.Lineid),
          1,
        )
      : belongsToRouteWithLineId;
    this.Abzugid = !isBuildingElement ? 0 : this.getNextDeductionId(currentEntries, belongsToRouteWithLineId);
    this.isFloor = measurement.isFloor;
    this.isCeiling = measurement.isCeiling;
    if (!GlobalHelper.isNullOrUndefined(formula)) this.useFormula(formula);
    else this.calculateFromMeasurement(height, measurement);
    currentEntries.push(this);
  }
  useFormula(formula: string): void {
    if (formula === '') formula = '0';
    this.Aufmass = formula?.replaceAll('.', ',');
    this.Zresult = eval(formula?.replaceAll(',', '.').replaceAll('[', '').replaceAll(']', '')) as number;
  }

  createNewFromScratch(currentEntries: RoomBook[], raum: RoomBookPosition): void {
    this.Uuid = uuid.v4();
    this.Aufmid = raum.AufmId;
    this.Rpos_Id = raum.Rpos_ID;
    this.NotCalc = false;
    this.Raufmid = GlobalHelper.getNextNumber(
      currentEntries.map(entry => entry.Raufmid),
      1,
    );
    this.IsAbzug = false;
  }

  /**@description At the end of creating a new, free measurement (not from drawing) */
  fillLineIdDeductionId(currentEntries: RoomBook[], deductionRelation?: RoomBook): void {
    this.Lineid = !this.IsAbzug
      ? GlobalHelper.getNextNumber(
          currentEntries.map(entry => entry.Lineid),
          1,
        )
      : deductionRelation.Lineid;
    this.Abzugid = !this.IsAbzug ? 0 : this.getNextDeductionId(currentEntries, deductionRelation.Lineid);
  }

  private calculateFromMeasurement(roomHeight: number, measurement: MeasurementRoute | BuildingElement): void {
    const roomHeightString = roomHeight.toString().replace('.', ',');
    const length = measurement.length;
    const lengthString = length.toString().replace('.', ',');
    const measurementString = `${lengthString} * ${roomHeightString}`;
    this.Aufmass = measurementString?.replaceAll('.', ',');
    this.Zresult = roomHeight * length;
  }

  private getNextDeductionId(currentEntries: RoomBook[], belongsToRouteWithLineId: number): number {
    const allOtherBuildingElements = currentEntries.filter(
      entry => entry.Lineid === belongsToRouteWithLineId && entry.IsAbzug,
    );
    const currentDeductionIds = allOtherBuildingElements.flatMap(buildingElement => buildingElement.Abzugid);
    return GlobalHelper.getNextNumber(currentDeductionIds, 1);
  }
}

import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalSettings, topService } from 'apps/handwerkPWA/src/app/config/Konstanten';
import {
  ConnectionDialogues,
  ConnectionService,
} from 'apps/handwerkPWA/src/app/services/globalServices/connection.service';
import { ControllerService } from 'apps/handwerkPWA/src/app/services/globalServices/controller.service';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import { StateService } from 'apps/handwerkPWA/src/app/services/globalServices/state.service';
import CryptoES from 'crypto-es';
import { Allocation, UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import moment from 'moment';
import { Observable, tap } from 'rxjs';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  brokerNotReachable = false;

  constructor(
    private httpClient: HttpClient,
    private dialogService: DialogService,
    private globalSettingService: HWGlobalSettingService,
    private connectionService: ConnectionService,
    private controllerService: ControllerService,
    private stateService: StateService
  ) {}

  /**
   * @description         Holt eine gültige allocation vom broker
   * @param userInfo:        userInfo in der die uuid des handwerks steht
   * @returns             UserInfo die die currentallocation enthält
   */
  public async updateAllocationInUserInfo(userInfo: UserInfo): Promise<UserInfo> {
    const passkey = 'bssblue';
    const brokerkey = 'bssblue';
    const completeBrokerURL = `https://broker1.bssservices.de/broker/rest/request_allocation?uuid=${userInfo.uuid}&pass_key=${passkey}&broker_key=${brokerkey}`;
    const allocationData = await this.httpGetWithURL<Allocation>(completeBrokerURL);
    if (!allocationData) {
      await this.dialogService.openWaitingConfirmDialog(
        'Verbingunsproblem',
        'Die Vermittlung zwischen Ihrem Mobilgerät und Ihrem Handwerkssystem ist gerade nicht möglich. Bitte versuchen Sie es in einigen Minuten erneut.',
        'Ok'
      );
      this.brokerNotReachable = true;
    } else {
      this.brokerNotReachable = false;
    }
    const allocation = new Allocation(allocationData);
    userInfo.currentAllocation = allocation;
    const updateUserInfoInIdb = userInfo?.user !== 'guiUser';
    if (updateUserInfoInIdb) await this.globalSettingService.setEntity(userInfo, GlobalSettings.UserInfo);
    return userInfo;
  }

  /**@description Funktion um Daten aus dem Webservice zu holen und direkt zu übergeben */
  public async returnData<Type>(url: string, data?: any, silent?: boolean, useCleanUrl?: boolean): Promise<Type> {
    try {
      const serviceUrl = useCleanUrl ? url : topService + url;
      return await this.sendGetDataToServer<Type>(serviceUrl, data, silent, useCleanUrl);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**@description Gibt, falls vorhanden, den Last Modified header einer Url als Date zurück */
  public async httpLastModified(targetUrl: string): Promise<Date> {
    const errorDate = new Date('1701-01-31T01:01:01');
    if (await this.connectionService.checkOnline()) {
      return errorDate;
    }
    let response: HttpResponse<null>;
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    });
    try {
      response = await new Promise((resolve, reject) => {
        this.httpClient
          .head<any>(targetUrl, {
            headers: httpHeaders,
            observe: 'response',
            reportProgress: true,
          })
          .subscribe(
            event => {
              resolve(event);
            },
            error => {
              reject(error);
            }
          );
      });
    } catch (err) {
      return errorDate; // fallback mit definitiv älterem datum
    }
    const lastModified = response.headers.get('last-modified');
    const lastModifiedDate = moment(lastModified, 'DD.MM.YYYY HH:mm:ss', 'de')?.toDate();
    return lastModifiedDate;
  }

  public newHttpPost<Type>(targetUrl: string, data: Type): Observable<Type> {
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    });
    return this.httpClient
      .post<Type>(targetUrl, data, {
        headers: httpHeaders,
      })
      .pipe(
        tap({
          error: (error: unknown) => {
            console.error(error);
          },
        })
      );
  }
  /**@description Erstellt je nach code eine Fehlermeldung */
  private async createHttpErrorMessage(
    url: string,
    responseFromServerRetry: HttpResponse<null> | HttpErrorResponse,
    silent: boolean
  ): Promise<void> {
    if (this.brokerNotReachable) return;
    const httpStatusCode = responseFromServerRetry.status;
    if (silent && httpStatusCode !== 401) return;
    url = url?.replace('/TopService.svc/', '');
    if (url?.includes('Email')) return;
    if (httpStatusCode === 401) {
      // fall mobilKennwort falsch
      await this.dialogService.openWaitingConfirmDialog(
        'Authentifizierungsproblem!',
        'Das Kennwort des Mitarbeiters ist entweder falsch oder der Mitarbeiter ist durch ein Austrittsdatum gesperrt.',
        'OK'
      );

      await this.controllerService.upgradeIndexedDB();
      // Damit in jedem Fall zurückGeroutet werden kann
      localStorage.clear();
      this.stateService.loggedIn.next(false);
      location.reload();
      return;
    }
    const failedTurnAnswer = httpStatusCode === 418;
    if (url?.includes('checklogin') || failedTurnAnswer) {
      await this.dialogService.openWaitingConfirmDialog(
        'Verbindungsproblem!',
        'Prüfen Sie Ihre Internetverbindung und stellen sicher, dass der Webservice korrekt ausgeführt wird.',
        'OK'
      );
      return;
    }
    if (url?.includes('OfflinePositions') && failedTurnAnswer) {
      await this.dialogService.openWaitingConfirmDialog(
        'Synchronisierungsproblem!',
        'Die Synchronisation der Offline-Positionen kann nicht durchgeführt werden, weil es ein Problem' +
          ' mit der Verbindung gibt oder die Menge der Positionen zu groß ist.',
        'OK'
      );
      return;
    }
    await this.dialogService.openWaitingConfirmDialog(
      `HTTP-Error: ${httpStatusCode}`,
      `Beim Aufruf von ${url} trat ein Fehler auf.`,
      'OK'
    );
  }

  private async startDataExchange(
    url: string,
    data: any,
    useCleanUrl?: boolean
  ): Promise<HttpResponse<null> | HttpErrorResponse> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const password = userInfo.pin; // vor version 3.0.2.12 fragten die 3 endpunkten das handwerkspasswort statt dem des monteur ab
    const allocation = userInfo.currentAllocation;
    const authorizationUrl = `https://${allocation.allocation_ipv4}:${allocation.allocation_portv4}${url}`;
    const authorizationHeader = CryptoES.HmacMD5(authorizationUrl, password);
    const authorizationBase64 = authorizationHeader.toString(CryptoES.enc.Base64).slice(0, -2);
    const targetUrl = (useCleanUrl ? '' : allocation.turnProxyUrl) + url;
    let response: HttpResponse<null>;
    const httpHeaders = new HttpHeaders({
      AppAuthorization: authorizationBase64,
      RequestOrigin: 'handwerksPWA',
      port: allocation.allocation_portv4?.toString(),
      Username: userInfo.monteur, // Username und Monteur sind scheinbar wirklich beides die Monteurpnr - zumindest geht so überall die authentication
      Mandant: userInfo.mandant,
      DeviceToken: userInfo.Device.Devtoken,
      Monteur: userInfo.monteur,
      'Content-Type': 'application/json',
    });
    try {
      response = await new Promise((resolve, reject) => {
        this.httpClient
          .post<any>(targetUrl, data, {
            headers: httpHeaders,
            observe: 'events',
            reportProgress: true,
          })
          .subscribe(
            httpEvent => {
              if (httpEvent.type === HttpEventType.Response) {
                resolve(httpEvent);
              }
            },
            error => {
              reject(error);
            }
          );
      });
    } catch (err) {
      return new HttpErrorResponse(err);
    }
    return response;
  }

  private async httpGetWithURL<Type>(url: string): Promise<Type> {
    let result: Type;
    try {
      result = await this.httpClient.get<Type>(url).toPromise();
    } catch (e) {
      console.error('HTTP-Fehler: \n\r' + JSON.stringify(e));
    }
    return result;
  }

  /**@description Sendet Daten zum Server und erhält passende Antwort je nach Endpunkt und gesendeten Daten */
  private async sendGetDataToServer<Type>(
    url: string,
    dataToSend: any,
    silent: boolean,
    useCleanUrl?: boolean
  ): Promise<Type> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const online = await this.connectionService.checkOnline(ConnectionDialogues.PushData, silent);
    if (!online) return null;

    const requestInitData = JSON.stringify(dataToSend);
    const response = (await this.startDataExchange(url, requestInitData, useCleanUrl)) as HttpResponse<null>;
    // Erfolg
    if (response.status === 200) return GlobalHelper.isNullOrUndefined(response.body) ? null : response.body;
    // Allokation neu anfragen und erneut versuchen
    if (response.status === 418 || response.status === 0) await this.updateAllocationInUserInfo(userInfo);
    const retryResponse = (await this.startDataExchange(url, requestInitData, useCleanUrl)) as HttpResponse<null>;
    if (retryResponse.status !== 200) await this.createHttpErrorMessage(url, retryResponse, silent);
    return GlobalHelper.isNullOrUndefined(retryResponse.body) ? null : retryResponse.body;
  }
}

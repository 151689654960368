<div class="content-block bottom-padding">
  <h2>Auftragspositionen</h2>

  <app-bss-list
    *ngIf="sortedItems?.length"
    [id]="'documentPosition'"
    [priceRight]="showPrices"
    [inputDataSource]="sortedItems"
    [searchExpressions]="searchExpressions"
    [secondaryDataSource]="order"
    [userInfo]="userInfo"
    [dragAndDrop]="true"
    (itemDragged)="reorderList($event)"
    [emptyInputDataMessage]="'Es sind keine Auftragspositionen vorhanden'"
    (updatedDataSource)="listChanged()"
  ></app-bss-list>
  <br />
  <div
    [style]="
      (canEdit | async) ? 'background-color: #fff' : 'background-color: lightgray !important; color:gray !important;'
    "
  >
    <app-bss-button-link
      [title]="'Auftrag abschließen'"
      [path]="'auftragsabschluss/' + orderType + '/' + orderId"
    ></app-bss-button-link>
  </div>
</div>
<app-bss-speed-dial
  icon="add"
  label="Position hinzufügen"
  [index]="1"
  [disabled]="!(canEdit | async)"
  (Click)="navigateTo('auftragpositionen/' + orderType + '/' + orderId + '/hinzufuegen')"
  (ClickDisabled)="navigateTo('auftragpositionen/' + orderType + '/' + orderId + '/hinzufuegen')"
  [disabledCssClasses]="[speedDialCssClasses.graySpeedDial]"
></app-bss-speed-dial>

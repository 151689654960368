import { BuildingElementTemplate } from './BuildingElementTemplate';
import { CoordinatePoint } from './CoordinatePoint';

export type InputMode =
  | 'textInput'
  | 'buildingElement'
  | 'drawPoints'
  | 'none'
  | 'wallGrid'
  | 'buildingElementRoom'
  | 'buildingElementWall';

export class MeasurementDrawStackElement {
  event: CoordinatePoint;
  gridEnabled: boolean;
  currentMode: InputMode;
  currentBuildingElement?: BuildingElementTemplate;
  text?: string;

  constructor(
    event: CoordinatePoint,
    gridEnabled: boolean,
    currentMode: InputMode,
    currentBuildingElement?: BuildingElementTemplate,
    text?: string
  ) {
    this.event = event;
    this.gridEnabled = gridEnabled;
    this.currentMode = currentMode;
    this.currentBuildingElement = currentBuildingElement;
    this.text = text;
  }
}

import { AfterViewInit, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Base64Pdf } from '../../entities';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements AfterViewInit {
  /** The person signing the order example: Herr Leiber */
  @Input() defaultSigningPerson: string;
  @Input() showPopup = false;
  @Output() unterschriftBase64 = new EventEmitter<Base64Pdf>();
  @Output() stopEvent = new EventEmitter<boolean>();
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private paint: boolean;

  private clickX: number[] = [];
  private clickY: number[] = [];
  private clickDrag: boolean[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.resizeCanvas();
  }

  @HostListener('document:mousedown', ['$event'])
  pressMouseEventHandler(event: MouseEvent): void {
    this.pressEventHandler(event);
  }

  @HostListener('document:touchstart', ['$event'])
  pressTouchEventHandler(event: TouchEvent): void {
    this.pressEventHandler(event);
  }

  @HostListener('document:mousemove', ['$event'])
  mouseDragEventHandler(event: MouseEvent): void {
    this.dragEventHandler(event);
  }

  @HostListener('document:touchmove', ['$event'])
  touchDragEventHandler(event: TouchEvent): void {
    this.dragEventHandler(event);
  }

  @HostListener('document:mouseup', ['$event'])
  mouseReleaseEventHandler(): void {
    this.releaseEventHandler();
  }

  @HostListener('document:touchend', ['$event'])
  touchReleaseEventHandler(): void {
    this.releaseEventHandler();
  }

  @HostListener('document:mouseout', ['$event'])
  cancelMouseEventHandler(): void {
    this.cancelEventHandler();
  }

  @HostListener('document:touchcancel', ['$event'])
  cancelTouchEventHandler(): void {
    this.cancelEventHandler();
  }

  ngAfterViewInit(): void {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    canvas.height = window.innerHeight - 100;
    canvas.width = window.innerWidth - 48;
    const context = canvas.getContext('2d');
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.strokeStyle = 'black';
    context.lineWidth = 1;
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);

    this.canvas = canvas;
    this.context = context;
    this.redraw();
  }
  public resizeCanvas(): void {
    this.clearCanvas();
    this.canvas.height = window.innerHeight - 100;
    this.canvas.width = window.innerWidth - 48;
    this.context.lineCap = 'round';
    this.context.lineJoin = 'round';
    this.context.strokeStyle = 'black';
    this.context.lineWidth = 1;
    this.context.fillStyle = 'white';
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }
  public clearCanvas(): void {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.clickX = [];
    this.clickY = [];
    this.clickDrag = [];
  }
  createUnterschrift(auftragsPerson: string): void {
    const canvas = this.canvas;
    const context = this.context;
    const isLandscape = canvas.width > canvas.height;
    this.putUnterschriftPersonIntoCanvas(canvas, context, auftragsPerson, isLandscape);
    const unterschriftBase64 = canvas.toDataURL('image/jpeg');
    const base64PdfItem = new Base64Pdf();
    base64PdfItem.data = unterschriftBase64;
    base64PdfItem.landScape = isLandscape;
    this.unterschriftBase64.emit(base64PdfItem);
    this.stopEvent.emit(true);
  }

  closePopup(): void {
    this.stopEvent.emit(true);
  }

  private redraw(): void {
    const clickX = this.clickX;
    const context = this.context;
    const clickDrag = this.clickDrag;
    const clickY = this.clickY;
    for (let i = 0; i < clickX.length; ++i) {
      context.beginPath();
      if (clickDrag[i] && i) {
        context.moveTo(clickX[i - 1], clickY[i - 1]);
      } else {
        context.moveTo(clickX[i] - 1, clickY[i]);
      }

      context.lineTo(clickX[i], clickY[i]);
      context.stroke();
    }
    context.closePath();
  }

  private addClick(x: number, y: number, dragging: boolean): void {
    this.clickX.push(x);
    this.clickY.push(y);
    this.clickDrag.push(dragging);
  }

  private releaseEventHandler = (): void => {
    this.paint = false;
    this.redraw();
  };

  private cancelEventHandler = (): void => {
    this.paint = false;
  };

  private getMouseXAndY(e: MouseEvent | TouchEvent): { mouseX: number; mouseY: number } {
    let mouseX: number;
    let mouseY: number;
    if (e instanceof TouchEvent) {
      mouseX = e.changedTouches[0].pageX;
      mouseY = e.changedTouches[0].pageY;
    } else {
      mouseX = e.pageX;
      mouseY = e.pageY;
    }
    return { mouseX, mouseY };
  }

  private pressEventHandler(e: MouseEvent | TouchEvent): void {
    let { mouseX, mouseY } = this.getMouseXAndY(e);
    mouseX -= this.canvas.offsetLeft;
    mouseY -= this.canvas.offsetTop;

    this.paint = true;
    this.addClick(mouseX, mouseY, false);
    this.redraw();
  }

  private dragEventHandler(e: MouseEvent | TouchEvent): void {
    let { mouseX, mouseY } = this.getMouseXAndY(e);
    mouseX -= this.canvas.offsetLeft;
    mouseY -= this.canvas.offsetTop;

    if (this.paint) {
      this.addClick(mouseX, mouseY, true);
      this.redraw();
    }

    e.preventDefault();
  }

  private putUnterschriftPersonIntoCanvas(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    auftragsPerson: string,
    isLandscape: boolean
  ): void {
    const canvasHeight = canvas.height;
    const canvasWidth = canvas.width;
    const unterschriftNamePixelSize = isLandscape ? canvasHeight / 7 : canvasWidth / 9;
    context.font = `${unterschriftNamePixelSize}px Arial`;
    const nameSplit = auftragsPerson ? auftragsPerson.split(' ').reverse() : '';
    context.fillStyle = 'black';
    if (!isLandscape) {
      let index = 1;
      for (const word of nameSplit) {
        context.fillText(word, 1, canvasHeight - unterschriftNamePixelSize * index, canvasWidth);
        index = index + 1;
      }
    } else context.fillText(auftragsPerson, 1, canvasHeight - unterschriftNamePixelSize, canvasWidth);

    context.scale(3, 1);
  }
}

<h2 class="content-block">Termine</h2>

<div class="content-block bottom-padding">
  <app-bss-list
    [id]="'CustomerAppointment'"
    [inputDataSource]="appointmentsForCustomer"
    [searchExpressions]="searchExpressions"
    [emptyInputDataMessage]="'Es sind keine Termine vorhanden'"
  ></app-bss-list>
</div>

<app-bss-speed-dial icon="add" [index]="1" label="Neuer Termin" (Click)="navigateTo('termin/neu')"></app-bss-speed-dial>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import {
  Dokumentposition,
  HWRepairOrder,
  HWRepairOrderItem,
  PositionsArt,
  ServiceAuftrag,
  Textposition,
} from 'apps/handwerkPWA/src/app/entities';
import { BaseAuftrag, BaseDocumentPosition } from 'apps/handwerkPWA/src/app/interfaces';
import { ServiceOrderService } from 'apps/handwerkPWA/src/app/services/dataServices/serviceOrder.service';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-order-item-card',
  templateUrl: './order-item-card.component.html',
  styleUrls: ['./order-item-card.component.scss'],
})
export class OrderItemCardComponent implements OnInit {
  @Input() order: BaseAuftrag;
  @Input() position: HWRepairOrderItem | Textposition | Dokumentposition;
  @Input() showPrices: boolean;
  @Input() userInfo: UserInfo;
  @Input() deleteAble = true;
  @Output() updatedDataSource = new EventEmitter<BaseDocumentPosition[]>();
  einzelpreis: string;
  gesamtPreis: string;
  mengeOrZeit: string;
  numberValue: string;
  positionsHeadline: string;
  showPrintStuecklistenChoice = false;
  printStueckliste = true;

  constructor(
    private routingService: RoutingService,
    private dialogService: DialogService,
    private serviceAuftragService: ServiceOrderService
  ) {}

  ngOnInit(): void {
    if (GlobalHelper.isNullOrUndefined(this.userInfo))
      throw new Error('Zur Verwendung der DocumentPositionComponent muss die UserInfo Injiziert werden');

    this.position = this.order.findOrderItem(this.position);
    this.positionsHeadline = this.getPositionHeadline(this.position);
    const longType = this.position.getLongtype();
    if (this.position.Nummer !== '{\\rtf1\\ansi\\deff0\\') this.numberValue = this.getNumberAndDescription(longType);
    this.mengeOrZeit = this.position.getDisplayMengeString();
    if (this.showPrices) this.getPriceInformation(this.order.getKunde().priceGroup);
    if (this.order instanceof ServiceAuftrag) this.handleStuecklistenOptions(this.order, this.position);
    this.deleteAble = this.deleteAble && this.order.isEditable(this.userInfo);
  }
  async gotoPosition(): Promise<void> {
    if (this.position.isUnterposition()) {
      return;
    }

    if (this.order instanceof ServiceAuftrag) {
      await this.routingService.navigateTo(
        'auftragpositionen/wartungsauftrag/' + this.order.UUID + '/' + this.position.getUniqueIdentifier()
      );
    } else if (this.order instanceof HWRepairOrder) {
      await this.routingService.navigateTo(
        'auftragpositionen/reparaturauftrag/' + this.order.Guid + '/' + this.position.getUniqueIdentifier()
      );
    }
  }

  /**
   * @description Sobald der Nutzer auf die Mülltonne klickt, wird das jeweilige Element herausgelöscht
   */
  async deletePosition(order: BaseAuftrag, position: BaseDocumentPosition): Promise<void> {
    if (!position) return;
    const response = await this.dialogService.openConfirmDialog(
      'Position löschen',
      position.getBezeichnung(),
      'Löschen',
      'Abbrechen',
      true
    );
    if (!response) return;
    order.deleteItemFromPositionen(position);
    const newDisplayPositions = order.getDisplayPositions();
    this.routingService.dataChanged.next(true);
    this.updatedDataSource.emit(newDisplayPositions);
  }

  async changeStueckListenPrint(order: BaseAuftrag, position: BaseDocumentPosition): Promise<void> {
    if (!(order instanceof ServiceAuftrag)) return;
    const orderPosition = order.findOrderItem(position);
    order.toggleStueckListenPrint(orderPosition);
    order = await this.serviceAuftragService.sendServiceOrderToWebService(order, false);
    this.printStueckliste = position.getPrintPartList();
    this.serviceAuftragService.stuecklistenChange.next(order as ServiceAuftrag);
  }

  /**@description Baut die Headline-Zeile einer Karte zusammen, bei einer Unterposition lässt man Positionsnummern weg */
  private getPositionHeadline(position: BaseDocumentPosition): string {
    const isUnterposition = position.isUnterposition();
    const displayPosNr = isUnterposition ? '' : this.position.getPosNr() + ' ';
    return displayPosNr + position.getBezeichnung();
  }

  /**@description Holt die Anzeigepreise */
  private getPriceInformation(priceGroup: string): void {
    this.einzelpreis = this.position.getEinzelpreisDisplayLine(priceGroup);
    this.gesamtPreis = this.position.getGesamtpreisDisplayLine(priceGroup);
  }

  private getNumberAndDescription(longType: PositionsArt): string {
    const numberDisplay = this.position.Nummer ? this.position.Nummer : 'ohne Nummer';
    return longType + '-Nummer: ' + numberDisplay;
  }

  /**@description Prüft ob die Möglichkeit zur Einstellung der stücklisten gegeben ist (nur bei serviceauftrag) und guckt dann nach dem jeweiligen wert */
  private handleStuecklistenOptions(order: BaseAuftrag, positionsData: BaseDocumentPosition): void {
    const position = order.findOrderItem(positionsData);
    const isLeistung = position.getLongtype() === 'Leistung';
    if (!isLeistung) return;
    this.printStueckliste = position.getPrintPartList();
    this.showPrintStuecklistenChoice = true;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuickButtonIcons } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-bss-textbox',
  templateUrl: './bss-textbox.component.html',
  styleUrls: ['./bss-textbox.component.scss'],
})
export class BssTextboxComponent {
  @Input() labelText;
  @Input() buttonIcon: QuickButtonIcons;
  @Input() value: string;
  @Input() maxLength = null;
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() placeholder = '';
  @Input() mode = 'text';
  @Input() buttonEnabled = true;
  @Input() rtlEnabled = false;
  @Input() width;
  @Output() valueChange = new EventEmitter<string>();
  @Output() buttonClick = new EventEmitter<void>();
  @Output() focusOut = new EventEmitter<string>();

  constructor(private routingService: RoutingService) {}

  valueChanged(value: string): void {
    this.routingService.dataChanged.next(true);
    this.valueChange.emit(value);
  }

  buttonClicked(): void {
    this.buttonClick.emit();
  }

  focusWentOut(value: string): void {
    this.focusOut.emit(value);
  }
}

import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { HWMonteur, Steuer } from '..';
import { BaseAuftrag, BaseDocumentPosition } from '../../interfaces';

export class PrintPosition {
  posNr: string;
  menge: string;
  einheit: string;
  bezeichnung: string;
  epreis: string;
  gpreis: string;
  belongsToPosnr?: string;

  constructor(item: BaseDocumentPosition, showPrice: boolean, priceGroup: string) {
    if (!item) return;
    // Mobile Textvorlage
    if (item.getLongtype() == 'Textposition' && item.Menge == 1) this.bezeichnung = item.getLangtext();
    else this.bezeichnung = item.getBezeichnung();
    if (item.getLongtype() === 'Textposition') return;
    this.posNr = item.getPosNr();
    this.menge = GlobalHelper.convertToRoundedDisplayString(item.getMengeOrStunden());
    this.einheit = item.getMengenEinheit();
    if (!showPrice) return;
    this.epreis = GlobalHelper.convertToRoundedDisplayString(item.getEinzelDisplayprice(priceGroup)) + ' €';
    this.gpreis = GlobalHelper.convertToRoundedDisplayString(item.getGesamtDisplayPrice(priceGroup)) + ' €';
  }

  static createSumBlockAsPrintPositions(
    employee: HWMonteur,
    order: BaseAuftrag,
    items: BaseDocumentPosition[],
    priceGroup: string,
  ): PrintPosition[] {
    const tax = employee.Steuern;
    const itemSums = order.calculateItemSums(tax);
    const netSum = itemSums.NettoSum;
    const taxedSum = itemSums.TaxedSum;
    const completeSum = itemSums.CompleteSum;
    const taxLine = Steuer.getSteuerBezeichnungOfUsedSteuern(tax, items);
    const emptyLine = new PrintPosition(null, false, priceGroup);
    const netSumPrintPos = new PrintPosition(null, false, priceGroup);
    netSumPrintPos.bezeichnung = 'Nettosumme';
    netSumPrintPos.gpreis = netSum + ' €';
    const taxSum = new PrintPosition(null, false, priceGroup);
    taxSum.bezeichnung = taxLine;
    taxSum.gpreis = taxedSum + ' €';
    const totalSum = new PrintPosition(null, false, priceGroup);
    totalSum.bezeichnung = 'Gesamtsumme';
    totalSum.gpreis = completeSum + ' €';
    return [emptyLine, netSumPrintPos, taxSum, totalSum];
  }

  static addSubPositionLines(
    printItems: PrintPosition[],
    items: BaseDocumentPosition[],
    order: BaseAuftrag,
    priceGroup: string,
  ): PrintPosition[] {
    const subPositionLines: PrintPosition[] = [];
    for (const item of printItems) {
      const orderItem = items.find(searchItem => searchItem.getPosNr() === item.posNr);
      if (
        orderItem &&
        orderItem.getPrintPartList() === true &&
        order.getSubPositionsOfServices(orderItem)?.length > 0
      ) {
        const subPositionLine = new PrintPosition(null, false, priceGroup);
        subPositionLine.bezeichnung = `Darin enthalten je ${item.einheit}:`;
        subPositionLine.belongsToPosnr = orderItem.getPosNr();
        subPositionLines.push(subPositionLine);
      }
    }
    for (const subPosLine of subPositionLines) {
      const itemIndex = printItems.findIndex(printItem => printItem.posNr === subPosLine.belongsToPosnr);
      printItems.splice(itemIndex + 1, 0, subPosLine);
    }
    return printItems;
  }
}

import { FeatureNames } from 'libs/shared/src/lib/entities/models/user/FeatureCheck';

export class PageSetting {
  constructor(
    public path: string,
    public hasRight = true,
    public featureName: FeatureNames,
    public licenseNeeded: boolean
  ) {}
}

<div class="card serviceauftrag-card">
  <div class="flexContainer" *ngIf="project">
    <div class="flexContainerItem">
      <div class="flexContainer">
        <div class="flexContainerItem">
          <b>Projekt {{ project.Betreff }}</b
          ><br />
          <p>Nummer: {{ project.Nummer }}</p>
          <p>Kunde: {{ project.KdSuch }} {{ project.Kunde }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

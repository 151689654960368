import { Injectable } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { FeatureNames } from 'libs/shared/src/lib/entities/models/user/FeatureCheck';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { AuthorizationService } from 'libs/shared/src/lib/services/authorization.service';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { SyncObject } from '../../entities/models/SyncObject';
import { Medien } from '../../entities/repository/Medien';
import { BaseService } from '../dataServices/base.service';
import { DataService } from '../dataServices/data.service';
import { ControllerService } from './controller.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService implements DataService {
  serviceName = 'MediaService';
  constructor(
    private restService: RestService,
    private controllerService: ControllerService,
    private baseService: BaseService,
    private dialogService: DialogService,
    private authorizationService: AuthorizationService,
  ) {}

  async getAllBy(selector: string, value: string): Promise<Medien[]> {
    const media = await this.baseService.getAllBy(Medien, selector, value);
    return media;
  }

  async getMediaDataUrlFromWebService(file: Medien): Promise<string> {
    void this.dialogService.openLoadingDialog('Hole Daten', 'Hole Datei vom Server...');
    const returnValue = await this.restService.returnData<string | Medien>('getMediumData', file);
    this.dialogService.closeLoadingDialog();
    if (returnValue instanceof Medien) await this.insertOrUpdate(returnValue);
    const dataWithSignature = Medien.addMedienSignature(file.Datatype, (returnValue as Medien).Data);
    return dataWithSignature;
  }

  /**@description Converts all images to jpg like in the new HW "Medien" view */
  convertImages(files: Medien[]): void {
    for (const file of files) {
      if (!file.Data?.startsWith('data:image') || file.Data.startsWith('data:image/jpeg')) continue;

      const image = new Image();
      image.src = file.Data;

      const canvas = document.createElement('canvas');
      canvas.height = image.height;
      canvas.width = image.width;

      const twoDimensionalCanvas = canvas.getContext('2d');
      twoDimensionalCanvas.drawImage(image, 0, 0, image.width, image.height);

      file.Data = canvas.toDataURL('image/jpeg');
      file.PreviewPictureData = canvas.toDataURL('image/jpeg');
      const splitName = GlobalHelper.splitFilenameAndExtension(file.getOriginalName());
      file.setOriginalName(splitName.name + '.jpg');
    }
  }

  async sendMedia(newMedia: Medien[]): Promise<Medien[]> {
    const filteredMedia = newMedia.filter(doc => doc.Send === false && doc.Data);
    let silent = false;
    const results = [];
    for (const media of filteredMedia) {
      results.push(this.transferMedia(media, silent));
      silent = true;
    }
    await Promise.all(results);
    return filteredMedia;
  }

  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    await this.pushToWebService();
    const hasNewDatabase = this.authorizationService.current.value.featureCheck(FeatureNames.mediaTable2).available;
    if (hasNewDatabase) await this.getFromWebService(userInfo, silent);
  }

  async getFromWebService(userInfo: UserInfo, silent = false): Promise<void> {
    await this.pushToWebService();
    if (!silent) void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Medienkopfdaten...');
    const targetUrl = 'getMedienWithoutData';
    const mediaData: Medien[] = await this.restService.returnData<Medien[]>(targetUrl, null, silent);
    const receivedMedia = mediaData.map(data => new Medien(data));
    await this.overrideLocal(receivedMedia);
  }

  async pushToWebService(): Promise<void> {
    const unpushedMedia = await this.getUnpushedMedia();
    await this.sendMedia(unpushedMedia);
  }

  getRequiredObjects(): SyncObject[] {
    return [];
  }

  private async getAll(): Promise<Medien[]> {
    const media = await this.baseService.getAll(Medien);
    return media;
  }

  private async overrideLocal(media: Medien[]): Promise<void> {
    await this.controllerService.clearStore('Medien');
    await this.controllerService.setData('Medien', media);
  }

  private async transferMedia(file: Medien, silent: boolean): Promise<void> {
    const targetUrl = 'addMedium';
    const sendFile = new Medien(file);

    delete sendFile.PreviewPictureData;
    sendFile.Data = GlobalHelper.base64To64Raw(sendFile.Data); // Web service in C# does not like additional info in base64

    const success = await this.restService.returnData<boolean>(targetUrl, sendFile, silent);
    file.Send = success === true;
    await this.insertOrUpdate(file);
  }

  private async insertOrUpdate(media: Medien): Promise<void> {
    await this.controllerService.deleteData('Medien', 'UUID', media.UUID);
    await this.controllerService.setData('Medien', [media]);
  }

  private async getUnpushedMedia(): Promise<Medien[]> {
    const allMedien = await this.getAll();
    const unpushed = allMedien.filter(medium => medium.Send === false);
    return unpushed;
  }
}

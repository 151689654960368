<h2 class="content-block">Objektadressen</h2>

<div class="content-block bottom-padding">
  <app-bss-list
    [id]="'adresses'"
    [inputDataSource]="objectAddresses"
    [searchExpressions]="searchExpressions"
    [emptyInputDataMessage]="'Es sind keine Objektadressen vorhanden'"
  ></app-bss-list>
</div>

<app-bss-speed-dial
  *ngIf="canEditObjAddresses"
  icon="add"
  class="add-button"
  label="Neue Objektadresse"
  [index]="3"
  (Click)="navigateTo('objektadresse/neu/' + this.address.KU_NR)"
></app-bss-speed-dial>

import { Injectable } from '@angular/core';
import { RoomTemplate } from '../../entities/repository/RoomTemplate';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class RoomTemplateService {
  constructor(private baseService: BaseService, private controllerService: ControllerService) {}

  async getAll(): Promise<RoomTemplate[]> {
    return await this.baseService.getAll(RoomTemplate);
  }

  async getAllBy(selector: string, value: string): Promise<RoomTemplate[]> {
    return await this.baseService.getAllBy(RoomTemplate, selector, value);
  }

  async findOneBy(selector: string, value: string): Promise<RoomTemplate> {
    return await this.baseService.findOneBy(RoomTemplate, selector, value);
  }

  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(RoomTemplate, selector, value);
  }

  async overrideLocal(roomBooks: RoomTemplate[]): Promise<void> {
    await this.controllerService.clearStore('RoomTemplate');
    await this.controllerService.setData('RoomTemplate', roomBooks);
  }

  async getAllRoomBookTemplates(): Promise<RoomTemplate[]> {
    const allTemplates = await this.getAll();
    return allTemplates?.filter(template => template.isRoomBookTemplate());
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import Integer from '@zxing/library/esm/core/util/Integer';
import { HWAddress, HWTermin } from 'apps/handwerkPWA/src/app/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { TimeHelper } from 'libs/shared/src/lib/helper/timeHelper';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppointmentType, DxDateType, RepairOrderStates, calendarSyncedMonths } from '../../../config/Konstanten';
import { AppointmentEventType } from '../../../entities/repository/HWTermin';
import { AddressService } from '../../../services/dataServices/address.service';
import { AppointmentService } from '../../../services/dataServices/appointment.service';
import { RepairOrderService } from '../../../services/dataServices/repairOrder.service';
import { ServiceOrderService } from '../../../services/dataServices/serviceOrder.service';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';

@Component({
  selector: 'app-appointments-edit',
  templateUrl: './appointments-edit.component.html',
  styleUrls: ['./appointments-edit.component.scss'],
})
export class AppointmentEditComponent implements OnInit, OnDestroy {
  searchTimeoutOption = 200;
  appointment: HWTermin;
  appointmentPerson: HWAddress[];
  selectedPerson: HWAddress;
  allowEdit: boolean;
  pickerType = 'rollers';
  contactInformationArray = ['TEL', 'FUNK_PRIV', 'GLOBEMAIL', 'Anschrift', 'WWW'];
  subscription: Subscription;
  blocked = false;
  navigationButtonTitle: string;
  navigationButtonLink: string;
  hasReference: boolean;
  referenceActive: boolean;
  task: boolean;
  appointmentText: string;
  dataChanged: BehaviorSubject<boolean>;
  mindate = TimeHelper.getDate(TimeHelper.addMonths(new Date(), -calendarSyncedMonths));
  newAppointment: boolean;
  editMainRecurring: boolean;
  editType: DxDateType;

  constructor(
    private appointmentService: AppointmentService,
    private globalSettingService: HWGlobalSettingService,
    private addressService: AddressService,
    private dialogService: DialogService,
    private rightsService: RightsService,
    private routingService: RoutingService,
    private repairOrderService: RepairOrderService,
    private serviceOrderService: ServiceOrderService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAppointment();
    this.dataChanged = this.routingService.dataChanged;

    this.subscription = this.routingService.save.subscribe(() => {
      void this.save();
    });

    const appointmentRights = this.rightsService.getCurrentRight().employeeRights.appointmentRights;
    this.allowEdit = appointmentRights.allowEdit;
    const allowCreate = appointmentRights.allowCreation;
    if (!allowCreate && GlobalHelper.isNullOrUndefined(this.appointment.id)) {
      this.dialogService.openInformDialog(
        'Fehler',
        'Sie verfügen nicht über das nötige Recht um neue Termine anzulegen.',
        'Ok',
      );
      this.routingService.routeBack();
    }
    await this.setPreferredPerson();

    if (this.appointment.bss_type === AppointmentType.RepairOrder) {
      this.hasReference = true;
      const repairOrder = await this.repairOrderService.getOrderByOrderNumber(this.appointment.Referenz);
      if (repairOrder && repairOrder.Status !== RepairOrderStates.Completed) {
        this.referenceActive = true;
        this.navigationButtonTitle = 'Zum Auftrag';
        this.navigationButtonLink = '/reparaturauftrag/' + repairOrder.Guid + '/edit';
      }
    }
    if (this.appointment.bss_type === AppointmentType.MaintenanceAppointment) {
      this.hasReference = true;
      const serviceOrder = await this.serviceOrderService.findOneBy(
        'Dokumentid',
        this.appointment.DokIdName.substring(1),
      );
      if (serviceOrder) {
        this.referenceActive = true;
        this.navigationButtonTitle = 'zum Wartungstermin';
        this.navigationButtonLink = '/wartungsauftrag/' + serviceOrder.getUuid() + '/edit';
      }
    }
    this.task = this.appointment.bss_type === AppointmentType.Task;
    this.appointmentText = this.appointment.getAppointmentText();
  }

  async navigateToReference(): Promise<void> {
    await this.routingService.navigateTo(this.navigationButtonLink);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  /**@description If one of the dates is changed. */
  dateChanged(date: Date, startDate: boolean): void {
    const dbAppointment = TimeHelper.dateToDatabaseDate(date, true);

    if (startDate) {
      this.appointment.start = dbAppointment;
      this.appointment.startDate = date;
      const endDate = new Date(date);
      const newEndDateAsNumber = endDate.setHours(date.getHours() + 1);
      const newEndDate = new Date(newEndDateAsNumber);
      const dbEndDate = TimeHelper.dateToDatabaseDate(newEndDate, true);
      this.appointment.finish = dbEndDate;
      this.appointment.endDate = newEndDate;
      return;
    }
    if (!startDate) {
      this.appointment.endDate = date;
      this.appointment.finish = dbAppointment;
    }
  }

  checkIfAppointmentIsInPast(): boolean {
    const now = new Date();
    now.setMinutes(now.getMinutes() - 5); // 5 minute tolerance
    return this.appointment.startDate < now && this.appointment.endDate < now;
  }

  canShowAppointment(): boolean {
    const today = new Date();
    const daysTolerance = 1;
    let minTime = TimeHelper.addMonths(today, -calendarSyncedMonths);
    minTime = TimeHelper.addDays(minTime, -daysTolerance);
    let maxTime = TimeHelper.addMonths(today, calendarSyncedMonths);
    maxTime = TimeHelper.addDays(maxTime, daysTolerance);
    return !(this.appointment.startDate < minTime || this.appointment.startDate > maxTime);
  }

  /**@descriptionSaves appointment in IDB and web service */
  async save(): Promise<void> {
    if (this.blocked) return;
    this.blocked = true;
    if (!GlobalHelper.isNullOrUndefined(this.selectedPerson)) {
      const customerNumber = this.selectedPerson.KU_NR;
      this.appointment.adr = customerNumber;
    }
    if (this.appointment.startDate > this.appointment.endDate) {
      await this.dialogService.openConfirmDialog(
        'Fehler',
        'Der Termin kann nicht enden, bevor er begonnen hat. Bitte korrigieren Sie Ihre Eingabe.',
        'Ok',
        null,
        true,
      );
      this.blocked = false;
      return;
    }
    const canShowAppointment = this.canShowAppointment();
    if (!canShowAppointment) {
      const confirmation = await this.dialogService.openConfirmDialog(
        'Warnung!',
        `Die eingegebenen Termindaten liegen weiter als ${calendarSyncedMonths} Monate von heute entfernt und werden deshalb nicht vollständig im Kalender angezeigt.`,
        'Trotzdem speichern',
        'Abbrechen',
      );
      if (!confirmation) {
        this.blocked = false;
        return;
      }
    }

    const inPast = this.checkIfAppointmentIsInPast();
    if (inPast) {
      const confirmation = await this.dialogService.openConfirmDialog(
        'Warnung!',
        'Die eingegebenen Termindaten liegen in der Vergangenheit!',
        'Trotzdem speichern',
        'Abbrechen',
        false,
      );
      if (!confirmation) {
        this.blocked = false;
        return;
      }
    }
    if (this.appointment.isRecurring()) {
      if (!this.editMainRecurring) {
        this.appointment.parentID = parseInt(this.appointment.id, 10);
        this.appointment.eventtype = AppointmentEventType.Change;
        this.newAppointment = true;
      } else {
        const timeDifference = this.appointment.endDate.getTime() - this.appointment.startDate.getTime();
        this.appointment.endDate = new Date(this.appointment.startDate.getTime() + timeDifference);
      }
    }
    const userInfo = await this.globalSettingService.getUserInfo();
    if (this.newAppointment) {
      this.appointment.id = null;
      this.appointment = await this.appointmentService.addAppointment(userInfo, this.appointment);
    } else this.appointment = await this.appointmentService.updateAppointment(this.appointment);
    if (this.editMainRecurring) {
      await this.appointmentService.synchronize(userInfo, true);
    }

    this.routingService.dataChanged.next(false);
    this.blocked = false;
    this.routingService.routeBack();
  }

  selectCustomer(customer: HWAddress): void {
    this.selectedPerson = customer;
    this.appointment.kundenName = customer.NAME;
    this.dataChanged.next(true);
  }

  /**@description Tries to get a reference Customer and Employee */
  private async setPreferredPerson(): Promise<void> {
    const allAddresses = await this.addressService.getAllDisplayable();
    this.appointmentPerson = allAddresses.filter(
      address => address.ADRTYP === 'K' || address.ADRTYP === 'M' || address.ADRTYP === 'F' || address.ADRTYP === 'L',
    );
    this.appointmentPerson = this.appointmentPerson.filter(
      address => address.LeaveDate == null || address.LeaveDate >= new Date(),
    );
    if (!GlobalHelper.isNullOrUndefined(this.appointment)) {
      const customerNumber = this.appointment.adr;
      this.selectedPerson = this.appointmentPerson.find(address => address.KU_NR === customerNumber);
    }
  }

  private async loadAppointment(): Promise<void> {
    const currentRoute = this.routingService.getCurrentRoute();
    if (currentRoute.startsWith('/termin/neu')) {
      this.createNewAppointment();
    } else {
      await this.getAppointmentById();
    }
  }

  private createNewAppointment(): void {
    const dateParam = this.routingService.getRouteParam('date');
    const dateInMs = dateParam ? parseInt(dateParam, 10) : new Date().getTime();
    const date = new Date(dateInMs);
    const customerNumber = this.routingService.getRouteParam('kundennummer');
    this.appointment = new HWTermin(null, date, customerNumber);
    this.dateChanged(date, true);
    this.newAppointment = true;
    this.editType = DxDateType.dateTime;
  }

  private async getAppointmentById(): Promise<void> {
    const autoKey = Integer.parseInt(this.routingService.getRouteParam('id'), 10);
    const userInfo = await this.globalSettingService.getUserInfo();
    const repairOrders = await this.repairOrderService.getAllRepairOrdersFromIDB();
    const allAppointment = await this.appointmentService.getAllAppointmentsFromIDB(
      userInfo,
      null,
      true,
      null,
      repairOrders,
      false,
    );
    const clickedAppointment = allAppointment.find(appointmentFilter => appointmentFilter.AutoKey === autoKey);
    if (
      clickedAppointment.eventtype === AppointmentEventType.Recurring ||
      clickedAppointment.eventtype === AppointmentEventType.Change
    ) {
      this.editMainRecurring = await this.dialogService.openConfirmDialog(
        'Warnung!',
        'Der ausgewählte Termin ist ein Serientermin!',
        'Ganze Serie öffnen',
        'Nur diesen Termin öffnen',
        false,
      );
      // Gets the main recurring appointment
      if (this.editMainRecurring) {
        this.editType = DxDateType.time;
        this.appointment = allAppointment.find(
          appointmentFilter =>
            appointmentFilter.id ===
              (clickedAppointment?.parentID ? '' + clickedAppointment.parentID : clickedAppointment.id) &&
            appointmentFilter.recurrenceIndex === -1,
        );
        return;
      }
    }
    this.editType = DxDateType.dateTime;
    this.appointment = clickedAppointment;
    return;
  }
}

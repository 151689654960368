import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';

export class Anschrift {
  Strasse: string;
  Postleitzahl: string;
  Ort: string;
  /**Fallback falls die Anschrift nicht im korrekten Format vom handwerk übertragen wird */
  FallbackSearchString: string;
  FallbackDisplayString: string;

  constructor(street: string, postleitZahl: string, ort: string) {
    this.Strasse = street;
    this.Postleitzahl = postleitZahl;
    this.Ort = ort;
  }

  public arbeitsortToAnschrift(arbeitsort: string): void {
    if (GlobalHelper.isNullOrUndefined(arbeitsort)) {
      this.FallbackSearchString = '';
      return;
    }
    const arbeitsortSplit = arbeitsort.split(',');
    if (arbeitsortSplit.length !== 2) {
      this.getArbeitsortFallback(arbeitsort);
      return;
    }
    const plzOrt = arbeitsortSplit[1].trim();
    const plzOrtSplit = plzOrt.split(' ');
    if (plzOrtSplit.length < 2) {
      this.getArbeitsortFallback(arbeitsort);
      return;
    }
    this.Strasse = arbeitsortSplit[0];
    this.Postleitzahl = plzOrtSplit[0];
    this.Ort = plzOrtSplit[1];
  }

  private getArbeitsortFallback(arbeitsort: string): void {
    this.FallbackDisplayString = arbeitsort;
    const fallbackSplit = arbeitsort.split(' ');
    let fallBackSearchString = '';
    for (const word of fallbackSplit) {
      fallBackSearchString = fallBackSearchString + '+' + word;
    }
    const length = fallBackSearchString.length;
    this.FallbackSearchString = fallBackSearchString.substring(1, length - 1);
    return;
  }
}

<div *ngIf="currentMode === 'drawPoints' && !routeClosed" class="info-message">Wand platzieren</div>
<div *ngIf="currentMode === 'textInput'" class="info-message">Text platzieren</div>
<div *ngIf="currentBuildingElement && !currentBuildingElement.inRoom" class="info-message">in Wand positionieren</div>
<div *ngIf="currentBuildingElement && currentBuildingElement.inRoom" class="info-message">in Fläche positionieren</div>

<div class="control-buttons">
  <div class="control-buttons-raw">
    <div class="control-button" (click)="askForNew()">Neu</div>
    <div class="control-button" (click)="toggleGrid()">Raster</div>
    <div
      class="control-button"
      (click)="rebuildDrawingFromStack()"
      [ngClass]="{ disabled: drawStackElements.length === 0 }"
    >
      Rückgängig
    </div>
    <div
      class="control-button"
      (click)="createMeasurementConstruct()"
      [ngClass]="{ disabled: !routeClosed, finish: routeClosed }"
    >
      Messen
    </div>
  </div>
  <div class="control-buttons-raw">
    <div class="control-button" (click)="setCurrentMode('drawPoints')" [ngClass]="{ disabled: routeClosed }">Wände</div>
    <div class="control-button">
      <app-bss-search-popup
        [disabled]="!routeClosed"
        [buttonText]="'Bauelement'"
        [headline]="'Wählen Sie ein Bauelement'"
        [inputDataSource]="buildingElements"
        [customDisplayExpressions]="['name']"
        [dataSourceIconPath]="'iconPath'"
        (selectedValue)="setCurrentMode('buildingElement', $event)"
      >
      </app-bss-search-popup>
    </div>
    <div class="control-button">
      <app-bss-search-popup
        [disabled]="routeClosed || currentMode === 'drawPoints'"
        [buttonText]="'Formen'"
        [headline]="'Wählen Sie eine Form'"
        [inputDataSource]="shapes"
        [dataSourceIconPath]="'iconPath'"
        (selectedValue)="drawFromShape($event)"
      >
      </app-bss-search-popup>
    </div>
    <div class="control-button" (click)="setCurrentMode('textInput')">Text</div>
  </div>
</div>

<canvas
  #bssCanvas
  id="bssCanvas"
  (click)="clickOnCanvas($event, gridEnabled, currentMode, currentBuildingElement)"
  class="fullScreen"
>
</canvas>

<div class="textinput" id="textinput">
  <input class="textInputField" type="text" name="textinput" placeholder="Texteingabe" [(ngModel)]="textInput" /><br />
  <button class="bss-button primary-button" style="width: 50px; padding: 6px 10px" (click)="addTextToCanvas(textInput)">
    OK
  </button>
</div>

import { Component, OnInit } from '@angular/core';
import { NachrichtenService } from 'apps/handwerkPWA/src/app/services/dataServices/nachrichten.service';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { messageSearch } from '../../../config/search-constants';
import { HWNachricht } from '../../../entities/repository/HWNachricht';
import { MessageHelper } from '../../../helper/services/messageHelper';
import { SyncObjectService } from '../../../services/globalServices/syncObject.service';

@Component({
  selector: 'app-messages-index',
  templateUrl: './messages-index.component.html',
  styleUrls: ['./messages-index.component.scss'],
})
export class MessagesIndexComponent implements OnInit {
  searchExpressions = messageSearch;
  userInfo: UserInfo;

  messages = [
    {
      messagetype: 'Eingehende Nachrichten',
      icon: 'arrowdown',
      items: [],
      list_id: 'nachrichten',
    },
    {
      messagetype: 'Ausgehende Nachrichten',
      icon: 'arrowup',
      items: [],
      list_id: 'nachrichten',
    },
    {
      messagetype: 'Ausgehende Mails',
      icon: 'email',
      items: [],
      list_id: 'nachrichtenEmail',
    },
  ];

  constructor(
    private nachrichtenService: NachrichtenService,
    private globalSettingService: HWGlobalSettingService,
    private routingService: RoutingService,
    private syncObjectService: SyncObjectService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.userInfo = await this.globalSettingService.getUserInfo();
    await this.nachrichtenService.getFromWebService(this.userInfo, true);
    const messages = await this.nachrichtenService.getAll();
    this.messages[2].items = await this.nachrichtenService.getAllEmailsSend();
    const sortedNachrichten = MessageHelper.sortBySender(messages);
    this.messages[0].items = sortedNachrichten.sendFromHW;
    this.messages[1].items = sortedNachrichten.sendFromPWA;
    this.syncObjectService.currentSyncObjects.next([HWNachricht]);
  }

  async newMessage(): Promise<void> {
    await this.routingService.navigateTo('nachricht/neu');
  }
}

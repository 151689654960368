import { Component, Input } from '@angular/core';
import { HWContactPerson } from 'apps/handwerkPWA/src/app/entities/repository/HWContactPerson';

@Component({
  selector: 'app-contact-person-card',
  templateUrl: './contact-person-card.component.html',
  styleUrls: ['./contact-person-card.component.scss'],
})
export class ContactPersonCardComponent {
  @Input() contactPerson: HWContactPerson;
  showContactBar = false;

  toggleContactBar(): void {
    this.showContactBar = !this.showContactBar;
  }
}

import { RoomBookPositionLevels } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { BuildingElement, CoordinatePoint, RoomBook, RoomTemplate } from 'apps/handwerkPWA/src/app/entities';
import { RoomBookPosition } from 'apps/handwerkPWA/src/app/entities/models/aufmass/RoomBookPosition';
import { ValueNamePair } from 'apps/handwerkPWA/src/app/entities/models/aufmass/ValueNamePair';
import { GlobalHelper } from './globalHelper';

export type RoomEntityName = 'Etage' | 'Raum oder Wohnung' | 'Raum';

const dimensionChainIcon = 'assets/icons/Check.PNG';

const noDimensionChainIcon = 'assets/icons/existiertNicht.png';

export class MeasurementHelper {
  /**@param rVorlage Heißt rVorlage im Aufmass, aber Haustyp in der RoomTemplate Entity */
  static getRoomBookTemplate(template: RoomTemplate[], rVorlage: number): RoomTemplate {
    return template.find(sTemplate => sTemplate.Haustyp === rVorlage);
  }

  static getRooms(roomBookPosition: RoomBookPosition[]): RoomBookPosition[] {
    const position = roomBookPosition.slice();
    return position?.filter(sRoomBookPosition => sRoomBookPosition.isRoom());
  }

  static getApartments(roomBookPosition: RoomBookPosition[]): RoomBookPosition[] {
    const position = roomBookPosition.slice();
    return position?.filter(sRoomBookPosition => sRoomBookPosition.isApartment());
  }

  static getFloors(roomBookPositions: RoomBookPosition[]): RoomBookPosition[] {
    const position = roomBookPositions.slice();
    return position?.filter(roomBookPosition => roomBookPosition.isFloor());
  }

  /**@description assigns the nodes of all original Positions to the parentId */
  static assignParentIds(roomBookPositions: RoomBookPosition[], treeNodes: RoomBookPosition[]): void {
    const treeNodesPlain: RoomBookPosition[] = [];
    const floors = treeNodes.slice();
    const apartments = floors.flatMap(floor => floor.items as RoomBookPosition[]);
    const rooms = apartments.flatMap(floor => floor.items as RoomBookPosition[]);
    treeNodesPlain.push(...floors);
    treeNodesPlain.push(...apartments);
    treeNodesPlain.push(...rooms);
    for (const roomBookPosition of roomBookPositions) {
      const positionInTreeNodes = treeNodesPlain.find(node => node.Uuid === roomBookPosition.Uuid);
      roomBookPosition.parentId = positionInTreeNodes?.parentId;
    }
  }

  /**
   * @description Fetches all floors, apartments and rooms - then assigns the rooms to the apartments based on the apartment ID and floor ID.
   * Then assigns the apartments to the floor based on the floor id
   */
  static buildTreeNodes(roomBookPositions: RoomBookPosition[]): RoomBookPosition[] {
    for (const position of roomBookPositions) {
      position.selected = false;
      position.expanded = false;
      position.level = RoomBookPositionLevels.room;
      position.icon = position.hasDimensionChain ? dimensionChainIcon : noDimensionChainIcon;
    }
    const floors = this.getFloors(roomBookPositions);
    const apartments = this.getApartments(roomBookPositions);
    const rooms = this.getRooms(roomBookPositions);

    for (const apartment of apartments) {
      apartment.level = RoomBookPositionLevels.apartment;
      const roomsOfApartment = rooms
        .filter(room => room.Wng_ID === apartment.Wng_ID && room.Stw_ID === apartment.Stw_ID)
        ?.sort(this.compareRoomEntity);
      apartment.icon = this.getCheckIcon(apartment, roomsOfApartment);
      this.assignParentId(roomsOfApartment, apartment);
      apartment.items.push(...roomsOfApartment);
    }

    for (const floor of floors) {
      floor.level = RoomBookPositionLevels.floor;
      const apartmentsOfFloor = apartments
        .filter(apartment => apartment.Stw_ID === floor.Stw_ID)
        ?.sort(this.compareRoomEntity);
      floor.icon = this.getCheckIcon(floor, apartmentsOfFloor);
      this.assignParentId(apartmentsOfFloor, floor);
      floor.items.push(...apartmentsOfFloor);
    }

    return floors.sort(this.compareRoomEntity);
  }

  /**@description Looks if a CoordinatePoint is close to another one and interprets it, otherwise the pressed point is returned */
  static getExistingPointIfClose(
    point: CoordinatePoint,
    measurementCoordinatePoints: CoordinatePoint[],
    tolerance: number = 20
  ): CoordinatePoint {
    const { xCoordinate, yCoordinate } = point;
    const isClose = (p: CoordinatePoint): boolean =>
      Math.abs(p.xCoordinate - xCoordinate) <= tolerance && Math.abs(p.yCoordinate - yCoordinate) <= tolerance;
    const existingPoint = measurementCoordinatePoints.find(isClose);
    return existingPoint ?? point;
  }

  /**@description Calculates gross (sum of all areas) and Net (sum of all areas - deducted areas)*/
  static calculateGrossAndNetSums(entries: RoomBook[]): {
    gross: number;
    net: number;
    difference: number;
    grossWalls: number;
    netWalls: number;
    differenceWalls: number;
  } {
    const calcEntries = entries.filter(entry => !entry.NotCalc);
    const wallAreas = calcEntries
      .filter(entry => entry.IsAbzug === false && !(entry.isFloor || entry.isCeiling) && !entry.NotCalc)
      .flatMap(bookEntry => bookEntry.Zresult || 0);
    const wallAreasSums = wallAreas.reduce((a, b) => a + b, 0);
    const wallAreaDeduction = calcEntries
      .filter(entry => entry.IsAbzug === true && !(entry.isFloor || entry.isCeiling))
      .flatMap(bookEntry => bookEntry.Zresult || 0);
    const wallAreaDeductionSums = wallAreaDeduction.reduce((a, b) => a + b, 0);
    const grossAreas = calcEntries
      .filter(entry => entry.IsAbzug === false)
      .flatMap(bookEntry => bookEntry.Zresult || 0);
    const grossSums = grossAreas.reduce((a, b) => a + b, 0);
    const deductionAreas = calcEntries
      .filter(entry => entry.IsAbzug === true)
      .flatMap(bookEntry => bookEntry.Zresult || 0);
    const deductionSums = deductionAreas.reduce((a, b) => a + b, 0);
    return {
      gross: grossSums,
      net: grossSums - deductionSums,
      difference: deductionSums,
      grossWalls: wallAreasSums,
      netWalls: wallAreasSums - wallAreaDeductionSums,
      differenceWalls: wallAreaDeductionSums,
    };
  }

  static createFormulaFromAlternatingFormula(element: BuildingElement, alternatingFormula: string): string {
    const valueNames = GlobalHelper.getAllElementsBetween(alternatingFormula, '[', ']');
    const valueNamePairs = valueNames.map(name => new ValueNamePair(name));
    for (const pair of valueNamePairs) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      pair.value = element[pair.name];
    }
    let result = alternatingFormula;
    for (const pair of valueNamePairs) result = result.replaceAll(pair.name, pair.value.toString());
    return result;
  }

  static convertFormulaToValueNamePairs(formula: string): ValueNamePair[] {
    const valueNames = GlobalHelper.getAllElementsBetween(formula, '[', ']');
    const valueNamePairs = valueNames.map(name => new ValueNamePair(name));
    return valueNamePairs;
  }

  static isInTolerance(number1: number, number2: number, tolerance: number): boolean {
    const difference = Math.abs(number1 - number2);
    const inTolerance = difference < tolerance;
    return inTolerance;
  }

  static isBetween(numberToCheck: number, number1: number, number2: number): boolean {
    const between1 = number1 < numberToCheck && numberToCheck < number2;
    const between2 = number2 < numberToCheck && numberToCheck < number1;
    return between1 || between2;
  }

  /**@description Checks if a number is between two values, if it is not, the closer end is returned */
  static getEdgeIfOver(checkNumber: number, edge1: number, edge2: number): number {
    const bottomEdge = Math.min(edge1, edge2);
    const topEdge = Math.max(edge1, edge2);
    if (bottomEdge < checkNumber && checkNumber < topEdge) return checkNumber;
    if (checkNumber > topEdge) return topEdge;
    if (checkNumber < bottomEdge) return bottomEdge;
    return null;
  }

  static getMeasurementPictureBoundaries(
    measurementCoordinatePoints: CoordinatePoint[],
    padding: number
  ): { boundaryX: number; width: number; boundaryY: number; height: number } {
    const xCoordinates = measurementCoordinatePoints.map(point => point.xCoordinate);
    const yCoordinates = measurementCoordinatePoints.map(point => point.yCoordinate);
    const xMin = Math.min(...xCoordinates);
    const xMax = Math.max(...xCoordinates);
    const xDistance = xMax - xMin;
    const yMin = Math.min(...yCoordinates);
    const yMax = Math.max(...yCoordinates);
    const yDistance = yMax - yMin;
    return {
      boundaryX: xMin - padding,
      width: xDistance + 2 * padding,
      boundaryY: yMin - padding,
      height: yDistance + 2 * padding,
    };
  }

  static async cutoutOfCanvas(
    url: string,
    inputXStart: number,
    inputYStart: number,
    inputXWidth: number,
    inputXHeight: number
  ): Promise<string> {
    return new Promise(resolve => {
      const inputImage = new Image();
      inputImage.onload = (): void => {
        const outputImage = document.createElement('canvas');
        outputImage.width = inputXWidth;
        outputImage.height = inputXHeight;
        const context = outputImage.getContext('2d');
        context.drawImage(
          inputImage,
          inputXStart,
          inputYStart,
          inputXWidth,
          inputXHeight,
          0,
          0,
          inputXWidth,
          inputXHeight
        );
        resolve(outputImage.toDataURL('image/jpeg'));
      };
      inputImage.src = url;
    });
  }

  /**@description Comparison function for the positions, sorted by number */
  private static compareRoomEntity(a: RoomBookPosition, b: RoomBookPosition): number {
    let aNumber = a.Raumb_ID;
    if (a.isApartment()) aNumber = a.Wng_ID;
    if (a.isFloor()) aNumber = a.Stw_ID;

    let bNumber = b.Raumb_ID;
    if (b.isApartment()) bNumber = b.Wng_ID;
    if (b.isFloor()) bNumber = b.Stw_ID;
    return GlobalHelper.compareFunction(aNumber, bNumber);
  }

  /**
   * @description
   *  Looks which icon is set and recursively for the sub-positions and so sets the own icon (deliberately not)
   * look if the node has a dimension chain, otherwise further levels will not be reached */
  private static getCheckIcon(position: RoomBookPosition, subPosition: RoomBookPosition[]): string {
    if (position.icon === dimensionChainIcon) return dimensionChainIcon;
    else if (subPosition.length > 0 && subPosition.every(pos => pos.icon === dimensionChainIcon))
      return dimensionChainIcon;
    return noDimensionChainIcon;
  }

  /**@description assigns the nodes to the parentId */
  private static assignParentId(roomBookPosition: RoomBookPosition[], parent: RoomBookPosition): void {
    for (const position of roomBookPosition) position.parentId = parent.Uuid;
  }
}

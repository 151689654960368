export const imagesPaths: string[] = [
  'assets/icons/abgebrochen.png',
  'assets/icons/abgelehnt.png',
  'assets/icons/admin.png',
  'assets/icons/Adressen.png',
  'assets/icons/AktBelege.png',
  'assets/icons/alt.png',
  'assets/icons/angenommen.png',
  'assets/icons/ansprech.png',
  'assets/icons/aufmpos.png',
  'assets/icons/auftrag.png',
  'assets/icons/back.svg',
  'assets/icons/beendet.png',
  'assets/icons/begonnen.png',
  'assets/icons/calendar.png',
  'assets/icons/calendarEdit.png',
  'assets/icons/camera_switch.svg',
  'assets/icons/cancel.png',
  'assets/icons/cancel.svg',
  'assets/icons/cancelRed.png',
  'assets/icons/cart.png',
  'assets/icons/credit_cards.png',
  'assets/icons/delete.png',
  'assets/icons/delivery.png',
  'assets/icons/dreieck.svg',
  'assets/icons/dreieckOben.svg',
  'assets/icons/dreieckRechts.svg',
  'assets/icons/dropdown.png',
  'assets/icons/edit.png',
  'assets/icons/email.png',
  'assets/icons/email_add.png',
  'assets/icons/email_send.png',
  'assets/icons/empty.png',
  'assets/icons/erledigt.png',
  'assets/icons/export.png',
  'assets/icons/folderAufm.png',
  'assets/icons/Freiesaufm.png',
  'assets/icons/Freiesaufm_done.png',
  'assets/icons/help.png',
  'assets/icons/house.png',
  'assets/icons/ic_add.png',
  'assets/icons/ic_addGreen.png',
  'assets/icons/ic_arrow_back.png',
  'assets/icons/ic_arrow_forward.png',
  'assets/icons/ic_build.png',
  'assets/icons/ic_content_copy.png',
  'assets/icons/ic_content_paste.png',
  'assets/icons/ic_delete.png',
  'assets/icons/ic_deleteGrey.png',
  'assets/icons/ic_deleteRed.png',
  'assets/icons/ic_edit.png',
  'assets/icons/ic_event.png',
  'assets/icons/ic_extension.png',
  'assets/icons/ic_file_download.png',
  'assets/icons/ic_info.png',
  'assets/icons/ic_mail.png',
  'assets/icons/ic_menu.png',
  'assets/icons/ic_next.png',
  'assets/icons/ic_people.png',
  'assets/icons/ic_person.png',
  'assets/icons/ic_refresh.png',
  'assets/icons/ic_save.png',
  'assets/icons/ic_send.png',
  'assets/icons/ic_settings.png',
  'assets/icons/ic_shopping_cart.png',
  'assets/icons/ic_today.png',
  'assets/icons/import.png',
  'assets/icons/info.png',
  'assets/icons/invoice.png',
  'assets/icons/Kontakte.png',
  'assets/icons/Kunden.png',
  'assets/icons/leer.png',
  'assets/icons/letter.svg',
  'assets/icons/Lieferanten.png',
  'assets/icons/master.png',
  'assets/icons/menu.svg',
  'assets/icons/my_blueapp_App_logo_152.png',
  'assets/icons/my_blueapp_App_logo_192.png',
  'assets/icons/my_blueapp_App_logo_384.png',
  'assets/icons/my_blueapp_App_logo_512.png',
  'assets/icons/my_blueapp_App_logo_72.png',
  'assets/icons/neu.png',
  'assets/icons/new.png',
  'assets/icons/note.png',
  'assets/icons/objektadr.png',
  'assets/icons/offen.png',
  'assets/icons/ok.png',
  'assets/icons/order.png',
  'assets/icons/order2.png',
  'assets/icons/paste.png',
  'assets/icons/pay.png',
  'assets/icons/pdf.svg',
  'assets/icons/PhoneIA.png',
  'assets/icons/PhoneIC.png',
  'assets/icons/PhoneOA.png',
  'assets/icons/PhoneOC.png',
  'assets/icons/preferences.png',
  'assets/icons/price.png',
  'assets/icons/Raumaufm.png',
  'assets/icons/Raumaufm_done.png',
  'assets/icons/raumbuch.png',
  'assets/icons/save.png',
  'assets/icons/Spaltenaufm.png',
  'assets/icons/Spaltenaufm_done.png',
  'assets/icons/statistic.png',
  'assets/icons/sync.svg',
  'assets/icons/updown.png',
  'assets/icons/user.png',
  'assets/icons/Zusatz.png',
];

import { Component, OnInit } from '@angular/core';
import { serviceOrderSearch } from 'apps/handwerkPWA/src/app/config/search-constants';
import { ServiceAuftrag } from 'apps/handwerkPWA/src/app/entities';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { AppointmentService } from 'apps/handwerkPWA/src/app/services/dataServices/appointment.service';
import { MaintenanceSystemService } from 'apps/handwerkPWA/src/app/services/dataServices/maintenanceSystem.service';
import { ServiceOrderService } from 'apps/handwerkPWA/src/app/services/dataServices/serviceOrder.service';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import { SyncObjectService } from 'apps/handwerkPWA/src/app/services/globalServices/syncObject.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-service-orders-index',
  templateUrl: './service-orders-index.component.html',
  styleUrls: ['./service-orders-index.component.scss'],
})
export class ServiceOrdersIndexComponent implements OnInit {
  serviceAuftraege: BehaviorSubject<ServiceAuftrag[]> = new BehaviorSubject<ServiceAuftrag[]>(null);
  searchExpressions = serviceOrderSearch;

  constructor(
    private serviceOrderService: ServiceOrderService,
    private maintenanceAndService: MaintenanceSystemService,
    private addressService: AddressService,
    private appointmentService: AppointmentService,
    private globalsettingService: HWGlobalSettingService,
    private syncObjectService: SyncObjectService,
  ) {}

  async ngOnInit(): Promise<void> {
    const monteure = await this.addressService.getAllBy('ADRTYP', 'M');
    const allAnlagen = await this.maintenanceAndService.getAnlagenFromIDB();
    const userInfo = await this.globalsettingService.getUserInfo();
    const termine = await this.appointmentService.getAllAppointmentsFromIDB(userInfo);
    this.serviceAuftraege.next(
      await this.serviceOrderService.getAllServiceOrdersWithAllDetailsFromIDB(monteure, allAnlagen, termine),
    );
    this.syncObjectService.currentSyncObjects.next([ServiceAuftrag]);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-bss-textarea',
  templateUrl: './bss-textarea.component.html',
  styleUrls: ['./bss-textarea.component.scss'],
})
export class BssTextareaComponent {
  @Input() labelText: string;
  @Input() value: string;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() maxLength = null;
  @Input() minHeight = 100;
  @Input() maxHeight = 150;
  @Output() valueChange = new EventEmitter<string>();

  constructor(private routingService: RoutingService) {}

  valueChanged(value: string): void {
    this.routingService.dataChanged.next(true);
    this.valueChange.emit(value);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { HWNachricht } from 'apps/handwerkPWA/src/app/entities';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { HWMessageType } from '../../../entities/repository/HWNachricht';
import { NachrichtenService } from '../../../services/dataServices/nachrichten.service';

@Component({
  selector: 'app-message-card',
  templateUrl: './message-card.component.html',
  styleUrls: ['./message-card.component.scss'],
})
export class MessageCardComponent implements OnInit {
  @Input() message: HWNachricht;
  @Output() deletedMessage = new EventEmitter<HWNachricht>();
  @Input() userInfo: UserInfo;
  deleteDisabled: boolean;
  HWMessageType = HWMessageType;

  constructor(private nachrichtenService: NachrichtenService, private dialogService: DialogService) {}

  ngOnInit(): void {
    this.deleteDisabled = !this.message.Gelesen;
    if (GlobalHelper.isNullOrUndefined(this.userInfo))
      throw new Error('Zur Verwendung der NachrichtenCard muss die UserInfo injiziert werden');
  }

  /**@description Öffnet die Nachricht und setzt gelesen auf true, wenn noch nicht vorhanden. Ist die Nachricht geöffnet gewesen, "schließt" man sie wieder */
  async reactOnNachrichtClick(userInfo: UserInfo, message: HWNachricht): Promise<void> {
    message.offenInView = !message.offenInView;
    if (message.Gelesen === false && message.TYP === HWMessageType.Outgoing) {
      message.Gelesen = true;
      this.deleteDisabled = false;
      await this.nachrichtenService.save(message);
      await this.nachrichtenService.sendHandwerkGelesen(userInfo, [message]);
    }
  }

  async deleteNachricht(message: HWNachricht): Promise<void> {
    if (this.deleteDisabled) return;
    const confirmed = await this.dialogService.openConfirmDialog(
      'Nachricht löschen',
      'Möchten Sie diese Nachricht wirklich löschen?',
      'Löschen',
      'Behalten',
      false
    );
    if (confirmed) {
      await this.nachrichtenService.destroy('ID', message.ID);
      this.deletedMessage.emit(message);
    }
  }
}

import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import deMessages from 'apps/handwerkPWA/src/assets/de.json';
import version from 'deployment/version.json';
import config from 'devextreme/core/config';
import { loadMessages, locale } from 'devextreme/localization';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { ScreenService } from 'libs/shared/src/lib/services/screen.service';
import { timer } from 'rxjs';
import { GlobalSettings } from './config/Konstanten';
import { imagesPaths } from './config/assets';
import { LoginService } from './pages/login/login.service';
import { ActivityTrackerService } from './services/dataServices/activityTracker.service';
import { BackgroundService } from './services/globalServices/background.service';
import { ControllerService } from './services/globalServices/controller.service';
import { HWGlobalSettingService } from './services/globalServices/hwGlobalSetting.service';
import { LocalstorageService } from './services/globalServices/localstorage.service';
import { UpdatepwaService } from './services/globalServices/updatepwa.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  version = version;
  title = 'handwerkPWA';

  constructor(
    private controllerService: ControllerService,
    private globalSettingService: HWGlobalSettingService,
    private updatePwaService: UpdatepwaService,
    private routingService: RoutingService,
    private screen: ScreenService,
    private backgroundService: BackgroundService,
    private localStorageService: LocalstorageService,
    private activityTrackerService: ActivityTrackerService,
    private loginService: LoginService,
  ) {
    // Für die Lokalisierung(deutsche Namen und Datumsbezeichnungen)
    loadMessages(deMessages);
    locale('de-DE');
  }

  @HostBinding('class') get getClass(): string {
    return Object.keys(this.screen.sizes)
      .filter(cl => this.screen.sizes[cl])
      .join(' ');
  }

  /**@description Guckt nach dem Browserback, verwendet dann anschließend den eigenen Service (Browser back unterdrückt durch BackButtonDisableModule) */
  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    const source = timer(1); // ansonsten wird scheinbar nicht geroutet
    source.subscribe(() => {
      this.routingService.routeBack();
    });
  }

  /**@description Bevor die PWA neu geladen wird, bspw. bei einem Refresh */
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(): void {
    this.localStorageService.keepSessionData();
  }

  async ngOnInit(): Promise<void> {
    this.updatePwaService.storeInstallEvent();
    this.localStorageService.readSessionData();
    GlobalHelper.saveToLocalStorage('productName', 'handwerkPWA');
    void this.updatePwaService.triggerInstall();
    await this.controllerService.createStores();
    await this.backgroundService.restartServiceOnPageRefresh();
    this.preloadImages(imagesPaths);
    await this.updatePwaService.startPeriodicCheck();
    this.configDevExtreme();

    const appVersion: string = await this.globalSettingService.getEntity(GlobalSettings.AppVersion);
    if (appVersion !== this.version.version) {
      if (
        (await this.globalSettingService.getEntity(GlobalSettings.Rights)) ||
        (await this.globalSettingService.getEntity(GlobalSettings.LoggedIn))
      ) {
        await this.loginService.logOut(true);
      }
      await this.doUpdateLogic();
    }
    let savingTime = await this.globalSettingService.getEntity<Date>(GlobalSettings.ActivityTrackerSavingTime);
    if (!savingTime) savingTime = this.activityTrackerService.getSavingTime();
    await this.globalSettingService.setEntity(savingTime, GlobalSettings.ActivityTrackerSavingTime);
    await this.activityTrackerService.pushUnpushedData();
  }

  async doUpdateLogic(): Promise<void> {
    const userInfo = await this.updatePwaService.afterUpdateInitiated();
    userInfo.updateDonePwa = true;
    await this.globalSettingService.setEntity(userInfo, GlobalSettings.UserInfo);
    await this.routingService.navigateTo('/Login');
  }

  private preloadImages(images: string[]): void {
    for (const image of images) {
      const img = new Image();
      img.src = image;
    }
  }

  private configDevExtreme(): void {
    config({
      floatingActionButtonConfig: { shading: true },
    });
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HWAddress, HWObjectAddress } from 'apps/handwerkPWA/src/app/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss'],
})
export class AddressCardComponent implements OnInit, OnChanges {
  @Input() address: HWAddress | HWObjectAddress;
  @Input() noClickEvents = false;
  @Input() withContactBar = true;
  showContactBar = false;
  telephoneNumber: string;
  mobileNumber: string;
  eMailAddress: string;
  searchExpression: string;
  addressName: string;
  website: string;

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {
    this.setCustomerValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.address) this.setCustomerValues();
  }

  setCustomerValues(): void {
    this.telephoneNumber = this.address instanceof HWAddress ? this.address.TEL : this.address.Telefon;
    this.mobileNumber = this.address instanceof HWAddress ? this.address.FUNK_PRIV : null;
    this.eMailAddress = this.address instanceof HWAddress ? this.address.GLOBEMAIL : null;
    this.website = this.address instanceof HWAddress ? this.address.WWW : null;
    this.searchExpression = this.address instanceof HWAddress ? this.address.SUCH : this.address.Such;
    this.addressName = this.address instanceof HWAddress ? this.address.NAME : this.address.Name;
  }

  async goToAddressDetail(kunde: HWAddress | HWObjectAddress, noClickEvents: boolean): Promise<void> {
    if (noClickEvents) {
      return;
    }

    if (kunde instanceof HWObjectAddress) {
      await this.routingService.navigateTo('objektadresse/' + kunde.Guid + '/edit');
    } else {
      await this.routingService.navigateTo('adresse/' + kunde.Guid + '/edit');
    }
  }

  toggleContactBar(): void {
    this.showContactBar = !this.showContactBar;
  }
}

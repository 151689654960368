import { Injectable } from '@angular/core';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { Aufmass, RoomBook } from '../../entities';
import { Room } from '../../entities/models/aufmass/Room';
import { RoomBookPosition } from '../../entities/models/aufmass/RoomBookPosition';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class RoomBookService {
  constructor(
    private baseService: BaseService,
    private controllerService: ControllerService,
    private restService: RestService
  ) {}

  getAll(measurement: Aufmass): RoomBook[] {
    return measurement.RAUMBUCH;
  }

  public async findMeasurementBy(value: string, selector: string): Promise<Aufmass> {
    return await this.baseService.findOneBy(Aufmass, selector, value);
  }

  public async getAllBy(selector: string, value: string): Promise<RoomBook[]> {
    const measurement = await this.findMeasurementBy(value, selector);
    return measurement.RAUMBUCH;
  }

  async getAllRoomBooksByPosition(room: RoomBookPosition, measurement?: Aufmass): Promise<RoomBook[]> {
    let allRoomBooksForRoom: RoomBook[] = [];
    if (!measurement) {
      const allForAufmass = await this.getAllBy('AufmId', room.AufmId);
      allRoomBooksForRoom = allForAufmass?.filter(roomBook => roomBook.Rpos_Id === room.Rpos_ID);
    } else {
      allRoomBooksForRoom = measurement.getRoomBook()?.filter(roomBook => roomBook.Rpos_Id === room.Rpos_ID);
    }
    const allForRoomSorted = this.sortAllForRoom(allRoomBooksForRoom);
    return allForRoomSorted;
  }

  async findOneBy(selector: string, value: string): Promise<RoomBook> {
    return await this.baseService.findOneBy(RoomBook, selector, value);
  }

  findOneRoomBookBy(measurement: Aufmass, selector: string, value: unknown): RoomBook {
    return measurement.RAUMBUCH.find(roomBook => roomBook[selector] === value);
  }

  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(RoomBook, selector, value);
  }

  /**@description Creates a floor plan - overwrites all measurements made so far */
  async saveFloorPlan(measurement: Aufmass, room: Room, silent = false): Promise<void> {
    const success = await this.pushToWebService(room, silent);
    for (const entry of room.RaumbuchEntries) entry.transferred = success;
    await this.overrideLocalRoom(room, measurement);
  }

  async saveSingleArea(
    entry: RoomBook,
    roomBookPos: RoomBookPosition,
    measurement: Aufmass,
    silent = false
  ): Promise<Aufmass> {
    const mockRoom = new Room([entry], null, roomBookPos);
    const success = await this.pushToWebService(mockRoom, silent);
    entry.transferred = success;
    await this.updateLocalArea(entry, measurement);
    return measurement;
  }

  /**@description Deletes a surface and recursively all deductions from this Area */
  async deleteSingleArea(roomBookEntry: RoomBook, measurement: Aufmass, silent = false): Promise<void> {
    const targetUrl = 'deleteRaumbuchEntry';
    await this.restService.returnData(targetUrl, roomBookEntry, silent);
    await this.updateLocalArea(roomBookEntry, measurement, true);
  }

  async addHasDimensionChain(roomEntity: RoomBookPosition): Promise<void> {
    const allPositions = await this.getAllRoomBooksByPosition(roomEntity);
    const hasDimensionChain = allPositions.length !== 0;
    roomEntity.hasDimensionChain = hasDimensionChain;
  }

  async syncAllUntransferred(measurement: Aufmass): Promise<void> {
    const all = this.getAll(measurement);
    const untransferred = all.filter(entry => entry.transferred === false);
    for (const entry of untransferred) {
      const mockRoomBookPosition = new RoomBookPosition(null);
      mockRoomBookPosition.Uuid = entry.raumbPosUuid;
      await this.saveSingleArea(entry, mockRoomBookPosition, measurement, true);
    }
  }

  private sortByRoomMeasurementId(a: RoomBook, b: RoomBook): 1 | -1 | 0 {
    const aId = a.Raufmid;
    const bId = b.Raufmid;
    if (aId > bId) return 1;
    if (aId < bId) return -1;
    return 0;
  }

  private sortAllForRoom(allForRoom: RoomBook[]): RoomBook[] {
    const allSorted: RoomBook[] = [];
    const notDeductedAreas: RoomBook[] = [];
    if (allForRoom) {
      const all = allForRoom.slice();
      for (const one of all) {
        if (!one.IsAbzug) notDeductedAreas.push(one);
      }
      notDeductedAreas.sort(this.sortByRoomMeasurementId);
      for (const area of notDeductedAreas) {
        const deductionsForArea = all.filter(entity => entity.IsAbzug && entity.Lineid === area.Lineid);
        allSorted.push(area);
        allSorted.push(...deductionsForArea);
      }
    }
    return allSorted;
  }

  private async pushToWebService(room: Room, silent: boolean): Promise<boolean> {
    const targetUrl = 'saveRaumbuchEntries';
    // Zur Sicherheit, dass wirklich alle eckigen Klammern rausgefiltert werden,
    // da das Handwerk die Mengen sonst nicht berechnen kann
    const entryArray: RoomBook[] = [];
    if (room.RaumbuchEntries)
      for (const entry of room.RaumbuchEntries) {
        const tmpEntry = entry;
        tmpEntry.Aufmass = entry.Aufmass.replaceAll('[', '').replaceAll(']', '');
        entryArray.push(tmpEntry);
      }
    room.RaumbuchEntries = entryArray;
    //
    const successData = await this.restService.returnData<boolean>(targetUrl, room, silent);
    return GlobalHelper.isNullOrUndefined(successData) ? false : successData;
  }

  private async overrideLocalRoom(room: Room, measurement: Aufmass): Promise<void> {
    const entries = room.RaumbuchEntries.map(position => new RoomBook(position));
    const first = entries[0];
    await this.controllerService.deleteData('Aufmass', 'AufmId', first.Aufmid);
    measurement.RAUMBUCH = measurement.RAUMBUCH.concat(entries);
    await this.controllerService.setData('Aufmass', [measurement]);
  }

  private async updateLocalArea(area: RoomBook, measurement: Aufmass, justDelete = false): Promise<void> {
    const roomBookEntries = measurement.RAUMBUCH.find(roomBook => roomBook.Uuid === area.Uuid);

    if (roomBookEntries) {
      await this.controllerService.deleteData('Aufmass', 'Uuid', measurement.Uuid);
      measurement.RAUMBUCH.splice(measurement.RAUMBUCH.indexOf(roomBookEntries), 1);

      if (!justDelete) {
        measurement.RAUMBUCH.push(area);
      }
    } else {
      await this.controllerService.deleteData('Aufmass', 'Uuid', measurement.Uuid);
      measurement.RAUMBUCH.push(area);
    }
    await this.controllerService.setData('Aufmass', [measurement]);
  }
}

import { Injectable } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { BelegInfo, Belegpositionen, HWAddress, HWBeleg } from 'apps/handwerkPWA/src/app/entities';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { SyncObject } from '../../entities/models/SyncObject';
import { ConnectionDialogues, ConnectionService } from '../globalServices/connection.service';
import { ControllerService } from '../globalServices/controller.service';
import { HWGlobalSettingService } from '../globalServices/hwGlobalSetting.service';
import { AddressService } from './address.service';
import { BaseService } from './base.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class BelegService implements DataService {
  serviceName = 'BelegService';
  constructor(
    private controllerService: ControllerService,
    private restService: RestService,
    private addressService: AddressService,
    private dialogService: DialogService,
    private baseService: BaseService,
    private globalSettingService: HWGlobalSettingService,
    private connectionService: ConnectionService,
    private rightsService: RightsService,
  ) {}

  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    await this.pushToWebService();
    await this.getFromWebService(userInfo, silent);
  }

  // currently no editing create functionality and therefore no need to push them
  async pushToWebService(): Promise<void> {}

  /**
   * @description Holt alle Belege aus der IDB mit bestimmten Suchkriterien
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async getAllBy(selector: string, value: string): Promise<HWBeleg[]> {
    return await this.baseService.getAllBy(HWBeleg, selector, value);
  }

  /**
   * @description Holt alle Belege nach Kundennummer aus der IDB oder wenn Internetverbindung besteht aus Webservice
   * @param kundenNummer der Adresse für die die Belege geholt werden sollen
   * */
  async getAllByKundennummer(kundenNummer: string): Promise<HWBeleg[]> {
    const localBelege = await this.getAllBy('kunde', kundenNummer);
    const isOnline = await this.connectionService.checkOnline(ConnectionDialogues.GetData);
    if (isOnline) return await this.getBelegeByKundennummerFromWebservice(kundenNummer);

    return localBelege;
  }

  /**
   * @description Holt ein Beleg aus der IDB mit bestimmten Parametern
   * @param selector ist die Attribute die durchgesucht werden soll
   * @param value ist der Inhalt, nachdem gesucht werden soll
   * */
  async findOneBy(selector: string, value: string): Promise<HWBeleg> {
    return await this.baseService.findOneBy(HWBeleg, selector, value);
  }

  /**
   * @description Holt alle Belegposition für einen Belge aus der IDB oder wenn Internetverbindung besteht aus Webservice
   */
  async getBelegPositionen(beleg: HWBeleg): Promise<Belegpositionen[]> {
    const localBelegPositionen = beleg.Belegpositionen;

    const isOnline = await this.connectionService.checkOnline(ConnectionDialogues.GetData);
    if (isOnline) return await this.getAllBelegPositionenFromWebservice([beleg]);

    return localBelegPositionen;
  }

  /**@description Holt alle Belege die geholt werden können - 0 als Kundennummer holt alle */
  async getFromWebService(userInfo: UserInfo, silent = false): Promise<void> {
    const right = this.rightsService.getCurrentRight();
    if (!right.employeeRights.receiptRights.enableModule) return;
    if (!silent) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...hole alle Belege...');
    }
    const allBelegeData = await this.restService.returnData<HWBeleg[]>(
      'belege/mandant/' + userInfo.mandant + '/KU_NR/' + '0' + '/username/' + userInfo.monteur,
    );
    let allBelege: HWBeleg[] = [];
    if (GlobalHelper.isNullOrUndefined(allBelegeData)) {
      return;
    }
    allBelege = await this.rawDataArrayToObjectsArray(allBelegeData);
    await this.writeBelegeToIDB(allBelege, true);
  }

  async getBelegeByKundennummerFromWebservice(kundennummer: string): Promise<HWBeleg[]> {
    const userInfo = await this.globalSettingService.getUserInfo();
    void this.dialogService.openLoadingDialog('Synchronisation', '...hole Belege...');
    const allBelegeData = await this.restService.returnData<HWBeleg[]>(
      'belege/mandant/' + userInfo.mandant + '/KU_NR/' + kundennummer + '/username/' + userInfo.monteur + '',
    );
    let allBelege: HWBeleg[] = [];
    if (GlobalHelper.isNullOrUndefined(allBelegeData)) {
      this.dialogService.closeLoadingDialog();
      return allBelege;
    }
    allBelege = await this.rawDataArrayToObjectsArray(allBelegeData);
    const allBelegpositionen = await this.getAllBelegPositionenFromWebservice(allBelege);
    const belegeWithBelegpositionen = this.addBelegpositonenToBelege(allBelege, allBelegpositionen);
    await this.updateBelegInIdb(belegeWithBelegpositionen);
    this.dialogService.closeLoadingDialog();
    return allBelege;
  }

  getRequiredObjects(): SyncObject[] {
    return [HWAddress];
  }

  /**@description Fügt den Belgen ihre Positionen hinzu */
  private addBelegpositonenToBelege(allBelege: HWBeleg[], allBelegpositionen: Belegpositionen[]): HWBeleg[] {
    for (const position of allBelegpositionen) {
      const nummer = position.Belegnummer;
      for (const beleg of allBelege) {
        const belegNummer = beleg.name;
        if (belegNummer === nummer) {
          beleg.addBelegposition(position);
        }
      }
    }
    return allBelege;
  }

  /**@description Schreibt die Belege in die IDB */
  private async writeBelegeToIDB(belege: HWBeleg[], clear: boolean): Promise<void> {
    if (clear) {
      await this.controllerService.clearStore('HWBeleg');
    }
    await this.controllerService.setData('HWBeleg', belege);
  }

  /**@description Holt alle Belegpositionen die geholt werden können */
  private async getAllBelegPositionenFromWebservice(requestedReceipts: HWBeleg[]): Promise<Belegpositionen[]> {
    const receiptInfo = requestedReceipts.map(receipt => new BelegInfo(receipt.name, receipt.jahr));

    const receiptPositions = await this.restService.returnData<Belegpositionen[]>('allBelegpositionen', receiptInfo);
    if (GlobalHelper.isNullOrUndefined(receiptPositions)) {
      return [];
    }
    return receiptPositions
      .filter(position => !GlobalHelper.isNullOrUndefined(position))
      .map(position => new Belegpositionen(position));
  }

  private async updateBelegInIdb(belege: HWBeleg[]): Promise<void> {
    if (GlobalHelper.isNullOrUndefinedOrEmptyArray(belege)) {
      return;
    }
    const kundenNummer = belege[0].kunde;
    await this.controllerService.deleteData('HWBeleg', 'kunde', kundenNummer);
    await this.controllerService.setData('HWBeleg', belege);
  }

  private async rawDataArrayToObjectsArray(rawDataArray: HWBeleg[]): Promise<HWBeleg[]> {
    const belege: HWBeleg[] = [];
    const allAddresses = await this.addressService.getAll();

    for (const belegData of rawDataArray) {
      const beleg = new HWBeleg(belegData);
      const kunde = allAddresses.find(filterKunde => filterKunde.KU_NR === beleg.kunde);
      if (kunde) {
        beleg.kundenname = kunde.NAME;
      }
      belege.push(beleg);
    }

    return belege;
  }
}

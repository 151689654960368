<h2 class="content-block">W&S Anlagen</h2>

<div class="content-block">
  <app-bss-list
    [id]="'anlagen'"
    [inputDataSource]="anlagen"
    [searchExpressions]="searchExpressions"
    [emptyInputDataMessage]="'Es sind keine W&S Anlagen vorhanden'"
  ></app-bss-list>
</div>

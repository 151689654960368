import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-bss-measuring-field',
  templateUrl: './bss-measuring-field.component.html',
  styleUrls: ['./bss-measuring-field.component.scss'],
})
export class BssMeasuringFieldComponent implements OnInit {
  @Input() minusAllowed = true;
  @Input() measurement: string;
  @Input() label: string;
  @Input() unit: string;
  @Input() fieldIndex: number;
  @Input() sollwert: string;
  @Input() disabled = false;
  @Input() noSollwert = true;
  @Input() title = 'Messwert eintragen';
  @Input() placeholder: string;
  @Input() rtlEnabled = false;
  @Input() beginningEmpty = false;
  @Input() id = 'measureField';
  @Input() useDxTemplate = false;
  @Input() keyboardPosition: string;
  @Input() jumpToNext = true;
  /** Input of a distance measuring device via Bluetooth */
  @Input() distanceMeterMode = false;
  @Output() measurementChange = new EventEmitter<string>();
  @Output() measurementValueChange = new EventEmitter<string>();
  @Output() keyboardOpen = new EventEmitter<boolean>();
  measuringFieldClass = 'measuringField';

  constructor(private routingService: RoutingService) {}

  measurementFunc = (): number => parseFloat(this.measurement?.replace(',', '.'));

  ngOnInit(): void {
    if (GlobalHelper.isNullOrUndefined(this.measurement) && !this.beginningEmpty) {
      this.measuringFieldClass = 'measuringFieldDisplayonly';
    }
  }

  getDecimalPlacesLength(): number {
    return this.measurement?.replace(',', '.')?.split('.')[1]?.length
      ? this.measurement?.replace(',', '.')?.split('.')[1]?.length
      : 2;
  }

  valueChanged(measurement: string | number): void {
    measurement = measurement.toString();
    this.measurement = measurement;
    this.routingService.dataChanged.next(true);
    this.clickNextMeasuringField(this.fieldIndex, this.jumpToNext);
    this.measurementChange.emit(measurement);
    this.measurementValueChange.emit(measurement);
  }

  /**@description Jumps to the next measuring field */
  clickNextMeasuringField(currentFieldIndex: number, jumpToNext: boolean): void {
    const nextFieldId = this.id + (currentFieldIndex + 1);
    const nextField = document.getElementById(nextFieldId);
    if (GlobalHelper.isNullOrUndefined(nextField) || !jumpToNext) return;
    const inputField = nextField.firstElementChild as HTMLElement;
    inputField.click();
  }
}

import { AddressRights } from 'libs/shared/src/lib/entities';
export class AddressRightHelper {
  static hasAddressRight(addressRights: AddressRights, addressType: string, rightType: string): boolean {
    switch (addressType) {
      case 'K': {
        return rightType === 'edit' ? addressRights.editCustomers : addressRights.showCustomers;
      }
      case 'M': {
        return rightType === 'edit' ? addressRights.editEmployees : addressRights.showEmployees;
      }
      case 'F': {
        return rightType === 'edit' ? addressRights.editFreeAddresses : addressRights.showFreeAddresses;
      }
      case 'L': {
        return rightType === 'edit' ? addressRights.editSuppliers : addressRights.showSuppliers;
      }
      default: {
        return false;
      }
    }
  }
}

import * as uuid from 'uuid';

export class Textvorlage {
  Nummer: string;
  SuchText: string;
  Text: string;

  constructor(data: object) {
    this.Nummer = uuid.v4();
    Object.assign(this, data);
  }
}

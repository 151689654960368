<div class="content-block bottom-padding" *ngIf="objectAddress">
  <div class="form-block">
    <h2>Objektadresse</h2>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <app-bss-plain-text-box [labelText]="'Kunde'" [value]="objectAddress.Kunde.NAME"></app-bss-plain-text-box>
        </div>
        <div class="fieldbox">
          <app-bss-textbox
            [labelText]="'Standort 01'"
            [(value)]="objectAddress.STANDORT1"
            [disabled]="!allowedToEdit"
          ></app-bss-textbox>
          <app-bss-textbox
            [labelText]="'Standort 02'"
            [(value)]="objectAddress.STANDORT2"
            [disabled]="!allowedToEdit"
          ></app-bss-textbox>
          <app-bss-textbox
            [labelText]="'Name'"
            [(value)]="objectAddress.Name"
            [disabled]="!allowedToEdit"
          ></app-bss-textbox>
          <app-bss-textbox
            [labelText]="'Straße'"
            [buttonIcon]="'map'"
            [(value)]="objectAddress.STRASSE"
            [buttonEnabled]="objectAddress?.STRASSE !== '' && objectAddress?.ORT !== ''"
            (buttonClick)="openMaps(objectAddress)"
            [disabled]="!allowedToEdit"
          ></app-bss-textbox>
          <app-bss-textbox
            [labelText]="'Postleitzahl'"
            [(value)]="objectAddress.PLZ"
            [maxLength]="5"
            [disabled]="!allowedToEdit"
          ></app-bss-textbox>
          <app-bss-textbox
            [labelText]="'Ort'"
            [(value)]="objectAddress.ORT"
            [disabled]="!allowedToEdit"
          ></app-bss-textbox>
          <app-bss-select-box
            [labelText]="'Land'"
            [items]="countries"
            [value]="objectAddress.LAND"
            (selectedValue)="selectedACountry($event)"
            [disabled]="!allowedToEdit"
          ></app-bss-select-box>
          <app-bss-textbox
            [labelText]="'Telefon'"
            [(value)]="objectAddress.Telefon"
            [buttonEnabled]="objectAddress?.Telefon != ''"
            (buttonClick)="callPhone(objectAddress.Telefon)"
            [buttonIcon]="'tel'"
            [disabled]="!allowedToEdit"
          ></app-bss-textbox>
        </div>
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">Weitere Informationen</div>

          <app-bss-toggle-textarea
            [buttonText]="'Bemerkungen'"
            [(value)]="objectAddress.BEMERKUNG"
            [disabled]="!allowedToEdit"
          ></app-bss-toggle-textarea>

          <app-bss-button-link
            [title]="'Zusatz'"
            [customIcon]="'assets/icons/dreieck.svg'"
            (click)="showAdditions = !showAdditions"
            [customIconClass]="showAdditions ? 'rotated180 dreieck' : ''"
          ></app-bss-button-link>
          <div *ngIf="showAdditions" style="margin-bottom: 10px">
            <div *ngFor="let additions of objectAddress.Zusatz; let i = index">
              <app-bss-plain-text-box
                *ngIf="additions != ''"
                [labelText]="objectAdditionalDesignations[i]"
                [value]="objectAddress.Zusatz[i]"
              ></app-bss-plain-text-box>
            </div>
          </div>

          <div *ngIf="!isNewAddress" class="linkBox">
            <app-bss-button-link
              [title]="'Medien'"
              [path]="'medien/ObjectAddress/' + objectAddress.Guid"
              [displayCount]="currentFilesAmount"
            ></app-bss-button-link>
            <app-bss-button-link [title]="'Zum Kunden'" [path]="customerPath"></app-bss-button-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-bss-speed-dial
  *ngIf="allowedToEdit && dataChanged"
  id="saveButton"
  icon="check"
  class="add-button"
  label="Speichern"
  [index]="1"
  (Click)="save()"
></app-bss-speed-dial>

<app-bss-speed-dial
  *ngIf="!isNewAddress && !dataChanged"
  icon="add"
  class="add-button"
  label="Neuer Reparaturauftrag"
  [index]="2"
  (Click)="navigateTo('reparaturauftrag/neu/objektadresse/' + objectAddress.Guid)"
></app-bss-speed-dial>
<app-bss-speed-dial
  *ngIf="allowedToEdit && !dataChanged"
  icon="photo"
  class="add-button"
  label="Neues Medium"
  [index]="1"
  (Click)="newImages()"
></app-bss-speed-dial>

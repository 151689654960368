<h2 class="content-block">Offene Posten</h2>

<div class="content-block">
  <app-bss-list
    [id]="'address-vacant-positions'"
    [inputDataSource]="vacantPositions"
    [searchExpressions]="searchExpressions"
    [emptyInputDataMessage]="'Es sind keine offenen Posten vorhanden'"
  ></app-bss-list>
</div>

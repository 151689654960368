<app-bss-select-box
  [showClearButton]="true"
  [labelText]="'Raumvorlage'"
  [items]="roomBooks"
  [placeholder]="'Raumvorlage wählen'"
  (selectedValue)="addVorlage($event)"
  [displayExpr]="'Bezeich'"
>
</app-bss-select-box>

<br />
<div>oder</div>
<br />

<app-bss-textbox [labelText]="'Raumvorlage'" [placeholder]="'Freie Raumvorlage'" [(value)]="aufmass.RBezeich">
</app-bss-textbox>

<button class="bss-button block-button primary-button" style="margin-top: 20px" (click)="save()">Übernehmen</button>

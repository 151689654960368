<div *ngIf="contactPerson" class="card contact-person-card">
  <div class="flexContainer">
    <div class="flexContainerItem" style="display: flex">
      <div class="flexContainerItem">
        <p *ngIf="contactPerson.Anrede">Anrede: {{ contactPerson.Anrede }}</p>
        <p *ngIf="contactPerson.Name">Name: {{ contactPerson.Name }}</p>
        <p *ngIf="contactPerson.Abteilung">Abteilung: {{ contactPerson.Abteilung }}</p>
        <p *ngIf="contactPerson.Position">Position: {{ contactPerson.Position }}</p>
      </div>
      <div (click)="toggleContactBar()" style="padding: 0 15px">
        <i class="dx-icon dx-icon-chevrondown"></i>
      </div>
    </div>
    <div *ngIf="showContactBar" class="flexContainerItem">
      <app-bss-contact-bar
        [telephoneNumber]="contactPerson.Telefon"
        [mobileNumber]="contactPerson.Mobil"
        [eMailAddress]="contactPerson.EMail"
      ></app-bss-contact-bar>
    </div>
  </div>
</div>

<h2 class="content-block">Medien</h2>

<div *ngIf="fileList && fileList.length != 0" class="content-block bottom-padding">
  <div class="imagesContainer">
    <div class="imageBox" *ngFor="let file of fileList; let i = index">
      <img
        width="128"
        height="128"
        *ngIf="file.PreviewPictureData"
        [src]="file.PreviewPictureData"
        (click)="getImageElement(file, i)"
      />
      <div class="imageName">{{ getImageName(file) }}</div>
    </div>
  </div>

  <app-bss-picture-popup
    *ngIf="selectedFile"
    [InputFile]="selectedFile"
    [newVersion]="newVersion"
    [showPopup]="showImg"
    (closePopupEvent)="showImg = false"
  >
  </app-bss-picture-popup>
</div>

<div *ngIf="fileList && fileList.length == 0" class="content-block no-data-message">Es sind keine Medien vorhanden</div>

<input
  #uploader
  id="uploadButton"
  hidden
  type="file"
  multiple="true"
  (change)="addDocument($event)"
  onclick="value=null"
  multiple="multiple"
  [accept]="allowedFileTypes"
/>

<app-bss-speed-dial
  *ngIf="allowedToEdit && !filesAdded"
  icon="photo"
  label="Medium hinzufügen"
  [index]="1"
  (Click)="uploader.click()"
></app-bss-speed-dial>
<app-bss-speed-dial
  *ngIf="filesAdded"
  id="saveButton"
  icon="check"
  label="speichern"
  [index]="1"
  (Click)="saveDocuments()"
></app-bss-speed-dial>

<dx-popup
  [width]="'90%'"
  [height]="'auto'"
  [title]="'Dateiname bearbeiten'"
  [dragEnabled]="false"
  [showCloseButton]="true"
  [hideOnOutsideClick]="true"
  (onHiding)="currentFile = undefined"
  container=".dx-viewport"
  [visible]="currentFile != undefined"
>
  <app-bss-textbox [placeholder]="'Bildname'" [(value)]="fileName" (valueChange)="validateFilename($event)">
  </app-bss-textbox>
  <br />
  <div *ngIf="invalidCharInFilename">
    <p style="color: red">Dateinamen dürfen folgende Zeichen nicht enthalten:</p>
    <p style="color: red; word-spacing: 20px">{{ invalidCharString }}</p>
  </div>
  <br />
  <div *ngIf="newVersion">
    <app-bss-textarea [maxHeight]="100" [placeholder]="'Beschreibung'" [(value)]="description"> </app-bss-textarea>
    <br />
  </div>
  <button
    [disabled]="invalidCharInFilename"
    class="bss-button primary-button fullWidth"
    [ngStyle]="{ opacity: invalidCharInFilename ? '0.3' : '1' }"
    (click)="parseAndAddFile(currentFile, fileName, description)"
  >
    Übernehmen
  </button>
</dx-popup>

import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import * as uuid from 'uuid';
import { RoomBookPosition } from '../models/aufmass/RoomBookPosition';
import { Aufmass } from './Aufmass';
import { IndexedDBTypes } from './dbType';

export class RoomTemplate extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('RoomTemplate') KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') Uuid: string = null;
  @IndexedDBTypes.IndexField('number') Haustyp: number = null;
  @IndexedDBTypes.DataField('number') Raum: number = null;
  @IndexedDBTypes.DataField('number') Etage: number = null;
  @IndexedDBTypes.DataField('number') Wohnung: number = null;
  @IndexedDBTypes.DataField('string') Bezeich: string = null;

  constructor(roomTemplate: RoomTemplate) {
    super();
    GlobalHelper.assignIfPropertyExists(this, roomTemplate);
  }

  static toString(): string {
    return 'RoomTemplate';
  }

  public isRoomBookTemplate(): boolean {
    return this.Raum === 0 && this.Wohnung === 0 && this.Etage === 0;
  }

  /**@description Converts a template into a concrete room book position */
  toRoomBookPosition(measurement: Aufmass): RoomBookPosition {
    const roomBookPosition = new RoomBookPosition(null);
    roomBookPosition.Uuid = uuid.v4();
    roomBookPosition.AufmId = measurement.AufmId;
    roomBookPosition.Bezeich = this.Bezeich;
    roomBookPosition.Stw_ID = this.Etage;
    roomBookPosition.Wng_ID = this.Wohnung;
    roomBookPosition.Raumb_ID = this.Raum;
    roomBookPosition.NrAufmId = 1;
    delete roomBookPosition.items;
    delete roomBookPosition.text;
    return roomBookPosition;
  }
}

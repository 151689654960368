<h2 class="content-block">Freies Aufmaß</h2>

<div class="content-block bottom-padding">
  <app-bss-list
    [id]="sliderNames.roomMeasurements"
    [inputDataSource]="measurements"
    [emptyInputDataMessage]="'Es sind keine Freienaufmaße vorhanden'"
    [searchExpressions]="searchExpressions"
  >
  </app-bss-list>
</div>

<app-bss-speed-dial
  icon="add"
  class="add-button"
  label="Neues Freies Aufmaß"
  (Click)="createFreeMeasurement()"
></app-bss-speed-dial>

import { Injectable } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { HWAddress, HWAnlage, HWTermin, ServiceAuftrag } from 'apps/handwerkPWA/src/app/entities';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { Subject } from 'rxjs/internal/Subject';
import { ServiceOrderState } from '../../config/Konstanten';
import { ServiceOrderNotInProgress, ServiceOrderNotMainEmployee, beginImpossible } from '../../config/TextKonstanten';
import { SyncObject } from '../../entities/models/SyncObject';
import { Medien } from '../../entities/repository/Medien';
import { ControllerService } from '../globalServices/controller.service';
import { HWGlobalSettingService } from '../globalServices/hwGlobalSetting.service';
import { AddressService } from './address.service';
import { AppointmentService } from './appointment.service';
import { DataService } from './data.service';
import { MaintenanceSystemService } from './maintenanceSystem.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceOrderService implements DataService {
  serviceName = 'ServiceOrderService';
  stuecklistenChange = new Subject<ServiceAuftrag>();

  constructor(
    private dialogService: DialogService,
    private restService: RestService,
    private controllerService: ControllerService,
    private addressService: AddressService,
    private maintenanceSystemService: MaintenanceSystemService,
    private appointmentService: AppointmentService,
    private rightsService: RightsService,
    private globalSettingService: HWGlobalSettingService,
  ) {}

  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    await this.pushToWebService(userInfo);
    await this.getFromWebService(userInfo, silent);
  }

  /**
   * TODO
   * muss noch refactored werden
   */
  async findOneBy(selector: string, value: unknown): Promise<ServiceAuftrag> {
    const employees = await this.addressService.getAllBy('ADRTYP', 'M');
    const allMaintenanceSystems = await this.maintenanceSystemService.getAnlagenFromIDB();
    const userInfo = await this.globalSettingService.getUserInfo();
    const appointments = await this.appointmentService.getAllAppointmentsFromIDB(userInfo);

    const serviceOrderData = await this.controllerService.getData<ServiceAuftrag[]>('ServiceAuftrag');
    const serviceOrders: ServiceAuftrag[] = [];
    for (const data of serviceOrderData) {
      const serviceAuftrag = new ServiceAuftrag(data, employees, allMaintenanceSystems, appointments);
      serviceOrders.push(serviceAuftrag);
    }

    let serviceOrder: ServiceAuftrag = null;
    if (selector === 'UUID') serviceOrder = serviceOrders.find(order => order.UUID === value);
    if (selector === 'Dokumentid') serviceOrder = serviceOrders.find(order => order.Dokumentid === value);
    return serviceOrder;
  }

  async getFromWebService(userInfo: UserInfo, silent: boolean): Promise<void> {
    const right = this.rightsService.getCurrentRight();
    if (!right.employeeRights.showObjektadressen)
      // Erst alle nicht synchronisierte Daten pushen
      await this.pushToWebService(userInfo);
    const employees = await this.addressService.getAllBy('ADRTYP', 'M');
    const maintenanceSystems = await this.maintenanceSystemService.getAnlagenFromIDB();
    const appointments = await this.appointmentService.getAllAppointmentsFromIDB(userInfo);

    const targetUrl = 'GetServiceAuftraege';
    if (!silent) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...hole Serviceaufträge...');
    }
    const responseData = await this.restService.returnData<ServiceAuftrag[]>(targetUrl, userInfo);
    const serviceOrders: ServiceAuftrag[] = [];
    for (const data of responseData) {
      const serviceOrder = new ServiceAuftrag(data, employees, maintenanceSystems, appointments);
      serviceOrders.push(serviceOrder);
    }
    await this.writeServiceOrderToIDB(serviceOrders, true);
  }

  /**@description Gets all the ServiceOrders with Details from the IDB*/
  async getAllServiceOrdersWithAllDetailsFromIDB(
    employees: HWAddress[],
    maintenanceSystems: HWAnlage[],
    appointments: HWTermin[],
    customerNumbers?: string,
  ): Promise<ServiceAuftrag[]> {
    const serviceOrderData = await this.controllerService.getData<ServiceAuftrag[]>(
      'ServiceAuftrag',
      customerNumbers,
      'KUNDE',
    );
    const serviceOrdersWithAppointment: ServiceAuftrag[] = [];
    const serviceOrderWithoutAppointment: ServiceAuftrag[] = [];
    for (const data of serviceOrderData) {
      const serviceAuftrag = new ServiceAuftrag(data, employees, maintenanceSystems, appointments);
      if (serviceAuftrag.TerminObject) serviceOrdersWithAppointment.push(serviceAuftrag);
      else serviceOrderWithoutAppointment.push(serviceAuftrag);
    }

    if (serviceOrdersWithAppointment.length > 0 && serviceOrderWithoutAppointment.length > 0) {
      return serviceOrdersWithAppointment
        .sort((a, b) => b.TerminObject.startDate.getTime() - a.TerminObject.startDate.getTime())
        .concat(serviceOrderWithoutAppointment);
    } else if (serviceOrdersWithAppointment.length > 0 && serviceOrderWithoutAppointment.length === 0) {
      return serviceOrdersWithAppointment.sort(
        (a, b) => b.TerminObject.startDate.getTime() - a.TerminObject.startDate.getTime(),
      );
    } else {
      return serviceOrderWithoutAppointment;
    }
  }

  /**@description Gets the ServiceOrders without details from the IDB*/
  async getAllServiceOrdersWithoutDetailsFromIDB(): Promise<ServiceAuftrag[]> {
    const serviceOrderData = await this.controllerService.getData<ServiceAuftrag[]>('ServiceAuftrag');
    const serviceOrders: ServiceAuftrag[] = [];
    for (const data of serviceOrderData) {
      serviceOrders.push(new ServiceAuftrag(data));
    }
    return serviceOrders;
  }

  /**@description Gets all the orderNumbers from the IDB*/
  async getAllServiceOrderNumbersFromIDB(): Promise<string[]> {
    const serviceOrderData = await this.controllerService.getData<ServiceAuftrag[]>('ServiceAuftrag');
    return serviceOrderData?.map(serviceOrder => serviceOrder.Auftragsnummer);
  }

  /**@description Sends a ServiceOrder to the WebService to be saved in the Database*/
  async sendServiceOrderToWebService(serviceAuftrag: ServiceAuftrag, silent: boolean): Promise<ServiceAuftrag> {
    if (!silent)
      void this.dialogService.openLoadingDialog('Serviceauftrag', '...speichere Änderungen am Serviceauftrag...');
    const success = !!(await this.restService.returnData<ServiceAuftrag>('SaveServiceauftrag', serviceAuftrag, silent));
    serviceAuftrag.edited = success ? false : true;
    await this.updateOrderInIDB(serviceAuftrag);
    if (!silent) this.dialogService.closeLoadingDialog();
    return serviceAuftrag;
  }

  async finalizeOrder(serviceOrder: ServiceAuftrag, silent: boolean): Promise<void> {
    serviceOrder.setOrderStatus(ServiceOrderState.Complete);
    const targetUrl = 'FinalizeServiceOrder';
    if (!silent) void this.dialogService.openLoadingDialog('Serviceauftrag', '...schließe Serviceauftrag ab...');
    const success = await this.restService.returnData<boolean>(targetUrl, serviceOrder, silent);
    if (!silent) this.dialogService.closeLoadingDialog();
    if (success) {
      await this.deleteServiceOrderInIDB(serviceOrder);
      return;
    }
    await this.updateOrderInIDB(serviceOrder); // fehlerfall
  }

  async acceptServiceOrder(
    serviceOrder: ServiceAuftrag,
    employees: HWAddress[],
    userInfo: UserInfo,
  ): Promise<ServiceAuftrag> {
    serviceOrder.acceptOrder(userInfo);
    void this.dialogService.openLoadingDialog('Zuordnung', '...Versuche Serviceauftrag zuzuordnen...');
    const targetUrl = 'acceptServiceOrder';
    const newOrderData = await this.restService.returnData<ServiceAuftrag>(targetUrl, serviceOrder, true);
    const anlage = serviceOrder.AnlageObject;
    const appointment = await this.appointmentService.findOneBy('id', serviceOrder.Terminid);

    const returnedServiceOrder = new ServiceAuftrag(newOrderData, employees, [anlage], [appointment]);
    this.dialogService.closeLoadingDialog();
    if (returnedServiceOrder.isUserMainEmployee(userInfo)) {
      await this.updateOrderInIDB(returnedServiceOrder);
      const employee = employees.find(e => e.KU_NR === returnedServiceOrder.Monteur1);
      appointment.MA_ID_LIST = returnedServiceOrder.Monteur1;
      appointment.mitarbeiter = returnedServiceOrder.Monteur1;
      appointment.mitarbeiterName = employee.NAME;
      await this.appointmentService.updateInIDB(appointment);
      return returnedServiceOrder;
    }
    return null;
  }

  async deleteServiceOrderInIDB(serviceAuftrag: ServiceAuftrag): Promise<void> {
    await this.controllerService.deleteData('ServiceAuftrag', 'UUID', serviceAuftrag.UUID);
  }

  /**
   * @description Überträgt zuerst nicht übertragen items ans handwerk, dann nimmt man die liste der nun zugeordneten und übertragenen items
   * und weißt sie der ursprünglichen liste der nicht übertragenen idbRepairOrders zu um sie dann abzuschließen
   */
  async pushToWebService(userInfo: UserInfo): Promise<void> {
    const allAddresses = await this.addressService.getAll();
    const employees = allAddresses.filter(address => address.ADRTYP === 'M');
    const maintenanceSystems = await this.maintenanceSystemService.getAnlagenFromIDB();
    const appointments = await this.appointmentService.getAllAppointmentsFromIDB(userInfo);

    const iDBServiceOrders = await this.getAllServiceOrdersWithAllDetailsFromIDB(
      employees,
      maintenanceSystems,
      appointments,
    );

    const untransferredOrders = iDBServiceOrders?.filter(order => order.edited);
    const transferResults = [];
    for (const order of untransferredOrders) {
      transferResults.push(this.sendServiceOrderToWebService(order, false));
    }
    await Promise.all(transferResults);

    const finalizeResults = [];
    const notFinalizedOrders = iDBServiceOrders?.filter(order => order.finalizeHasFailed());
    for (const order of notFinalizedOrders) {
      finalizeResults.push(this.finalizeOrder(order, false));
    }
    await Promise.all(finalizeResults);
  }

  hasEditRight(serviceOrder: ServiceAuftrag, userInfo: UserInfo, silent = false): boolean {
    if (!serviceOrder.isUserMainEmployee(userInfo)) {
      if (!silent) void this.dialogService.openErrorMessage(beginImpossible, ServiceOrderNotMainEmployee);
      return false;
    }
    if (!(serviceOrder.Status === ServiceOrderState.InProgress)) {
      if (!silent) void this.dialogService.openErrorMessage(beginImpossible, ServiceOrderNotInProgress);
      return false;
    }
    return true;
  }

  getRequiredObjects(): SyncObject[] {
    return [HWAddress, HWAnlage, HWTermin, Medien];
  }

  private async updateOrderInIDB(serviceAuftrag: ServiceAuftrag): Promise<void> {
    await this.deleteServiceOrderInIDB(serviceAuftrag);
    await this.writeServiceOrderToIDB([serviceAuftrag], false);
  }

  /**@description saves the ServiceOrders to the IDB */
  private async writeServiceOrderToIDB(serviceOrder: ServiceAuftrag[], clear: boolean): Promise<void> {
    if (clear) {
      await this.controllerService.clearStore('ServiceAuftrag');
    }
    await this.controllerService.setData('ServiceAuftrag', serviceOrder);
  }
}

import { Injectable } from '@angular/core';
import { GlobalSettings } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import { PwaLoginResponse, UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { BehaviorSubject } from 'rxjs';
import { baseWebServiceVersionString } from '../entities/models/user/Feature';
import { FeatureNames } from '../entities/models/user/FeatureCheck';
import { DialogService } from './dialog.service';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  current = new BehaviorSubject<PwaLoginResponse>(null);

  constructor(
    private restService: RestService,
    private dialogService: DialogService,
    private globalSettingService: HWGlobalSettingService,
  ) {}

  /**@description Checks authentication, minimum handwerkDb version and web service version. */
  async checkLogin(
    userInfo: UserInfo,
    silent = false,
  ): Promise<{
    isOkay: boolean;
    response: PwaLoginResponse;
  }> {
    const response = await this.restService.returnData<PwaLoginResponse>('checklogin', userInfo.mandant, silent);
    return await this.reactOnLoginResponse(response, userInfo);
  }

  /**@description Same as check login call, but only for versions without authentication */
  async checkVersion(userInfo: UserInfo): Promise<{
    isOkay: boolean;
    response: PwaLoginResponse;
  }> {
    const response = await this.restService.returnData<PwaLoginResponse>('checkVersionPwa', userInfo.mandant, true);
    return await this.reactOnLoginResponse(response, userInfo, true);
  }

  private async handleError(errorHeader: string, errorMessage: string): Promise<void> {
    const htmlString =
      errorMessage +
      ' Nähere Informationen erhalten Sie in der <a href="https://myblueapp.de/handwerk/produktinformationen/" target="_blank">FAQ</a> .';
    await this.dialogService.openConfirmDialog(errorHeader, null, 'Ok', null, false, htmlString);
  }

  /**@description Evaluates a login response */
  private async reactOnLoginResponse(
    response: PwaLoginResponse,
    userInfo: UserInfo,
    callFromGui = false,
  ): Promise<{
    isOkay: boolean;
    response: PwaLoginResponse;
  }> {
    if (!response) return { isOkay: false, response: null };

    const loginResponse = new PwaLoginResponse(response);

    if (!callFromGui) {
      this.current.next(loginResponse);
      // get the userInfo new due to possible new Allocation from prior requests
      userInfo = await this.globalSettingService.getUserInfo();
      userInfo.branding = loginResponse.handwerkBranding;
      await this.globalSettingService.setEntity(userInfo, GlobalSettings.UserInfo);
    }

    const product = callFromGui ? 'aktuellen Rechteverwaltung' : 'my blue:app hand:werk';

    if (GlobalHelper.isNullOrUndefined(loginResponse.webserviceVersion)) {
      await this.handleError(
        'Inkompatibler Webservice',
        `Der Webservice passt nicht zur ${product}. Sie benötigen mindestens einen Webservice mit der Version ${baseWebServiceVersionString} .`,
      );
      return { isOkay: false, response: loginResponse };
    }

    const baseFunctionality = loginResponse.featureCheck(FeatureNames.base);

    if (baseFunctionality.available) return { isOkay: true, response: loginResponse };

    // Web service version too low => prevent further work
    if (!baseFunctionality.webServiceHighEnough) {
      await this.handleError(
        'Inkompatibler Webservice',
        `Der Webservice  ${loginResponse.webserviceVersion} passt nicht zur ${product}. Sie benötigen mindestens einen Webservice mit der Version ${baseFunctionality.minWebServiceString} .`,
      );
      return { isOkay: false, response: loginResponse };
    }

    // HandwerksDb version too low => prevent further work
    if (!baseFunctionality.dbHighEnough) {
      await this.handleError(
        'Inkompatible Datenbankversion',
        `Die Datenbankversion ${loginResponse.handwerkDBVersion} Ihres Handwerksmandanten ${userInfo.mandant} passt nicht zur ${product}.` +
          ` Sie benötigen mindestens eine Version ${baseFunctionality.minDBString} .` +
          ` Bitte aktualisieren Sie Ihre Handwerksdatenbank bzw, laden Sie ggf. die aktuelle Version Ihrer Handwerkersoftware, um eine fehlerfreie Nutzung der ${product} zu gewährleisten.`,
      );
      return { isOkay: false, response: loginResponse };
    }
    return { isOkay: true, response: loginResponse };
  }
}

export class Belegpositionen {
  Artikelnummer: string;
  Gesamtpreis: string;
  ID: string;
  Kurztext: string;
  Menge: string;
  Positionsnummer: string;
  Rabatt: string;
  Belegnummer: string;
  Zeit: number;
  XFlags: string;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Belegpositionen) {
    Object.assign(this, data);
  }
}

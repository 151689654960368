import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { Module } from './RightCategory';

export class MaintenanceRights extends Module {
  inputMeasuredValues = true;
  enableMaintenanceSystems = true;
  handleOrder = true;

  constructor(maintenanceRights: MaintenanceRights) {
    super();
    GlobalHelper.assignIfPropertyExists(this, maintenanceRights);
  }
}

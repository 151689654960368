import { Component, Input } from '@angular/core';
import { HWEmailData } from '../../../entities';

@Component({
  selector: 'app-email-message-card',
  templateUrl: './email-message-card.component.html',
  styleUrls: ['./email-message-card.component.scss'],
})
export class EmailMessageCardComponent {
  @Input() message: HWEmailData;
}

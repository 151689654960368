export class ValueNamePair {
  displayName: string;
  name: string;
  value: number;

  constructor(name: string) {
    this.name = name;
    this.displayName = name?.charAt(0)?.toUpperCase() + name?.slice(1);
  }
}

<div class="dx-field">
  <div *ngIf="labelText" class="dx-field-label">{{ labelText }}</div>
  <div class="dx-field-value" [ngClass]="{ 'input-with-button': buttonIcon }">
    <dx-number-box
      [format]="format"
      [max]="max"
      [min]="min"
      [(value)]="value"
      valueChangeEvent="keyup"
      [disabled]="disabled"
      [placeholder]="placeholder"
      (onValueChanged)="valueChanged(value)"
    ></dx-number-box>
    <dx-button *ngIf="buttonIcon" [icon]="buttonIcon" (onClick)="btnClicked()"></dx-button>
  </div>
</div>

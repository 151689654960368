import {
  HWRepairOrderItem,
  PositionsArt,
  PositionsId,
} from 'apps/handwerkPWA/src/app/entities/repository/HWRepairOrderItem';
import { LongTypeHelper } from 'apps/handwerkPWA/src/app/helper/entities/HWRepairOrderItem/longTypeHelper';
import { BaseDocumentPosition, DokumentPositionsTyp } from 'apps/handwerkPWA/src/app/interfaces';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { TimeHelper } from 'libs/shared/src/lib/helper/timeHelper';
import * as uuid from 'uuid';
import { Steuer } from './Steuer';

export class Dokumentposition implements BaseDocumentPosition {
  /**@description Updated die Itemproperties korrekt */
  ID: string | PositionsId = null;
  LineID = 0;
  Flags = '0000';
  xFlags = '00000000';
  PosNr: string = null;
  H_Ebene = 0;
  Lief_Gew: string = null;
  Nummer: string = null;
  Kennzahl: string = null;
  BESTELLNR: string = null;
  KURZTEXT: string = null;
  ME: string = null;
  Menge = 1;
  VERPEINH: string = null;
  MEJEVERP = 0;
  PJVPEINH = 0;
  Zeit = 0;
  LohnsatzEK = 0;
  LohnSVK_0 = 0;
  LohnSVK_1 = 0;
  LohnSVK_2 = 0;
  LohnSVK_3 = 0;
  LohnSVK_4 = 0;
  LohnSVK_5 = 0;
  LohnSVK_6 = 0;
  LohnSVK_7 = 0;
  LohnSVK_8 = 0;
  LohnSVK_9 = 0;
  /**aktuell verwendeter lohn im dokument */
  LohnSVK_G = 0;
  MatEK = 0;
  MatVK_G = 0;
  MatVK_0 = 0;
  MatVK_1 = 0;
  MatVK_2 = 0;
  MatVK_3 = 0;
  MatVK_4 = 0;
  MatVK_5 = 0;
  MatVK_6 = 0;
  MatVK_7 = 0;
  MatVK_8 = 0;
  MatVK_9 = 0;
  FreEK = 0;
  FreVK = 0;
  SonEK = 0;
  SonVK = 0;
  GerEK = 0;
  GerVK = 0;
  GerVK_0 = 0;
  GerVK_1 = 0;
  GerVK_2 = 0;
  GerVK_3 = 0;
  GerVK_4 = 0;
  GerVK_5 = 0;
  GerVK_6 = 0;
  GerVK_7 = 0;
  GerVK_8 = 0;
  GerVK_9 = 0;
  FreVK_0 = 0;
  FreVK_1 = 0;
  FreVK_2 = 0;
  FreVK_3 = 0;
  FreVK_4 = 0;
  FreVK_5 = 0;
  FreVK_6 = 0;
  FreVK_7 = 0;
  FreVK_8 = 0;
  FreVK_9 = 0;
  SonVK_0 = 0;
  SonVK_1 = 0;
  SonVK_2 = 0;
  SonVK_3 = 0;
  SonVK_4 = 0;
  SonVK_5 = 0;
  SonVK_6 = 0;
  SonVK_7 = 0;
  SonVK_8 = 0;
  SonVK_9 = 0;
  RabattSatz = 0;
  ProvS_Mat = 0;
  ProvS_Lohn = 0;
  RabattWert = 0;
  UStKz: string = null;
  CuKennZ = 0;
  CuGewicht = 0;
  CuPreis = 0;
  LARMenge = 0;
  SARMenge = 0;
  Zusatz_1: string = null;
  Zusatz_2: string = null;
  LinkFields = 0;
  TextData: string = null;
  AddData: string = null;
  Abschlag: string = null;
  L_Aufmass: string = null;
  KTLTMode = 0;
  LTXData: string = null;
  KTXData: string = null;
  ATXData: string = null;
  HideFields = 0;
  Bemerkung: string = null;
  ErlKonto: string = null;
  KStelle: string = null;
  LeiEbene = 0;
  VKPrGrp = 0;
  PEinhKz = '001';
  PEinhMenge = 0;
  CuPreisVK = 0;
  RSDaten: string = null;
  GAEBDaten: string = null;
  GAEBStatus = 0;
  UGLDATEN: string = null;
  positionType: DokumentPositionsTyp = 'DokumentPosition';
  Deleted = false;
  private Leistungspositionen: HWRepairOrderItem[] = [];
  private LeistungsID: string = null;

  private isRepairorderPosition = false;

  constructor(data: Dokumentposition | HWRepairOrderItem | null, priceGroup = '0') {
    if (GlobalHelper.isNullOrUndefined(data)) return;
    GlobalHelper.assignIfPropertyExists(this, data);
    if (data instanceof HWRepairOrderItem) this.fillFromReapairorderitem(data, priceGroup);
  }

  getLeistungsId(): string {
    return this.LeistungsID;
  }

  getUniqueIdentifier(): string {
    return this.LineID?.toString();
  }

  getTransferredLeistungspositionen(): HWRepairOrderItem[] {
    return this.Leistungspositionen;
  }

  /**@description Updated die Itemproperties korrekt */
  updateItem(
    changeType: 'Preis' | 'Zeit' | 'Menge' | 'Bezeichnung' | 'Langtext',
    neuerWert: number | string,
    isGesamtpreis = false,
  ): void {
    const neuerNumberWert = typeof neuerWert === 'number' ? neuerWert : NaN;
    const neuerWertString = typeof neuerWert === 'string' ? neuerWert : '';
    switch (changeType) {
      case 'Preis': {
        this.updateCorrectPrice(neuerNumberWert, isGesamtpreis);
        return;
      }
      case 'Zeit':
      case 'Menge': {
        this.setZeitOrMenge(neuerNumberWert, changeType);
        return;
      }
      case 'Bezeichnung': {
        this.setLangtext(neuerWertString);
        this.setBezeichnung(neuerWertString);
        return;
      }
      case 'Langtext': {
        this.setLangtext(neuerWertString);
        this.setBezeichnung(neuerWertString);
        return;
      }
    }
  }
  setBezeichnung(value: string): void {
    this.KURZTEXT = value;
    this.KTXData = value;
  }

  setLangtext(value: string): void {
    this.LTXData = value;
  }

  getLangtext(): string {
    return this.KURZTEXT;
  }

  /**@description Berechnet für jedes einzelne Item den Anhand seines Identiiers und der zugrundeliegenden Formel den jeweiligen Gesammtverkaufspreis des Items */
  calculateVerkaufspreisSteuerpreis(
    item: HWRepairOrderItem | Dokumentposition,
    Steuern: Steuer[],
  ): { gesammtPreis: number; steuerPreis: number } {
    const umsatzSteuer = item.getSteuersatz(Steuern) / 100;
    const gesammtPreisValue = this.getGrundverkaufspreis() * item.getMengeOrStunden();
    const steuerPreisValue = gesammtPreisValue * umsatzSteuer;
    return { gesammtPreis: gesammtPreisValue, steuerPreis: steuerPreisValue };
  }

  /**
   * @description Setzt die Leistungsid - wenn id mitgegeben wird, kommt diese von der Leistung und wird an die Positionen
   * der Leistung übergeben
   */
  setLeistungsID(leistungsIDOfLeistung?: string): void {
    const leistungsId = leistungsIDOfLeistung ? leistungsIDOfLeistung : uuid.v4();
    this.LeistungsID = leistungsId;
  }

  getSteuersatz(Steuern: Steuer[]): number {
    const steuerSchluessel = this.UStKz;
    const steuer = Steuer.findSteuerInUse(Steuern, steuerSchluessel);
    return steuer.Steuersatz;
  }

  getSteuerSchluessel(Steuern: Steuer[]): string {
    const steuerSchluessel = this.UStKz;
    const steuer = Steuer.findSteuerInUse(Steuern, steuerSchluessel);
    return steuer.Id;
  }

  getLongtype(): PositionsArt {
    const ident = this.ID;
    const longType = LongTypeHelper.getLongtypeFromId(ident, this.isRepairorderPosition);
    if (longType === 'Artikel') return this.getIsLohnBasedOnXflags() ? 'Lohn' : 'Artikel';
    return longType;
  }

  getMengenEinheit(): string {
    if (this.getLongtype() === 'Lohn') return 'STD.';
    return this.ME;
  }

  /**@description Gibt je nach art den Grundreis zurück */
  getGrundverkaufspreis(): number {
    const art = this.getLongtype();
    if (art === 'Lohn' && this.isUnterposition()) return this['LohnSVK_' + this.getPriceGroup()] as number;
    let preis: number;
    switch (art) {
      case 'Textposition': {
        // Textposition
        preis = 0;
        break;
      }
      case 'Artikel': {
        // normale Artikel
        preis = this.MatVK_G;
        break;
      }
      case 'Leistung': {
        // Leistung
        preis = this.getLeistungspreis(this);
        break;
      }
      case 'Lohn': {
        // Lohn
        preis = this.LohnSVK_G;
        break;
      }
      case 'Gerät': {
        preis = this.GerVK;
        break;
      }
      case 'Sonstiges': {
        preis = this.SonVK;
        break;
      }
      case 'Fremdleistung': {
        preis = this.FreVK;
        break;
      }
      default: {
        // fallback
        preis = 0;
      }
    }
    return preis;
  }

  getLeistungspreis(position: Dokumentposition): number {
    const zeit = position.Zeit;
    const lohn = zeit === 0 ? zeit : position.LohnSVK_G * (zeit / 60);
    const leistungsPrice = lohn + position.MatVK_G + position.SonVK + position.FreVK + position.GerVK;
    return leistungsPrice;
  }

  getEinzelDisplayprice(): number {
    const basePrice = this.getGrundverkaufspreis();
    const returnPrice = GlobalHelper.convertToRoundedDisplayString(basePrice);
    const returnPriceNumber = parseFloat(returnPrice.replace(',', '.'));
    return returnPriceNumber;
  }

  /**@description Gibt den Kompletten verkaufspreis formatiert zurück */
  getGesamtDisplayPrice(): number {
    const basePrice = this.getGrundverkaufspreis() * this.getMengeOrStunden();
    const returnPrice = GlobalHelper.convertToRoundedDisplayString(basePrice);
    const returnPriceNumber = parseFloat(returnPrice.replace(',', '.'));
    return returnPriceNumber;
  }

  getEinzelpreisDescription(): string {
    const type = this.getLongtype();
    let description = 'Einzelpreis';
    if (type === 'Lohn') {
      description = 'Verrechnungssatz pro Stunde';
    }
    return description;
  }

  getGesamtpreisDescription(): string {
    const type = this.getLongtype();
    const autolohn = false;
    let description = 'Gesamtpreis';
    if (type === 'Lohn') {
      description = 'Verrechnungssatz';
    }
    if (autolohn) {
      description = 'Verrechnungssatz stand jetzt';
    }
    return description;
  }

  /**@description Setzt den Preis je nach Typ an der Korrekten stelle */
  updateCorrectPrice(neuerPreis: number, isGesamtpreis: boolean): void {
    const longType = this.getLongtype();
    if (isGesamtpreis) {
      neuerPreis = neuerPreis / this.getMengeOrStunden();
    }
    switch (longType) {
      case 'Artikel': {
        this.MatVK_G = neuerPreis;
        return;
      }
      case 'Leistung': {
        // Leistung / Textposition
        //position.lei = neuerPreis;
        return;
      }
      case 'Lohn': {
        // Lohn
        this.LohnSVK_G = neuerPreis;
        return;
      }
      case 'Gerät': {
        // geräte#
        this.GerVK = neuerPreis;
        return;
      }
      case 'Fremdleistung': {
        // fremdleistung
        this.FreVK = neuerPreis;
        return;
      }
      case 'Sonstiges': {
        // sonstiges
        this.SonVK = neuerPreis;
        return;
      }
    }
  }

  isUnterposition(): boolean {
    const id = this.ID;
    if (this.getLongtype() === 'Undefiniert') return false;
    if (id === id.toLocaleLowerCase()) return true;
    return false;
  }

  getBezeichnung(): string {
    return this.KURZTEXT;
  }

  getDisplayMengeString(): string {
    const description = this.getMengenDescription();
    const mengeAndUnit = this.getMengeAndUnit();
    return description + ': ' + mengeAndUnit;
  }

  getMengenDescription(): string {
    const type = this.getLongtype();
    let description = 'Menge';
    if (type === 'Lohn') {
      description = 'Zeit';
    }
    return description;
  }

  getMengeAndUnit(): string {
    if (this.getLongtype() === 'Lohn') {
      const mengeAndTimeUnit = TimeHelper.getStundenMinutenString(this.Zeit);
      return mengeAndTimeUnit;
    }
    const mengenEinheit = GlobalHelper.isNullOrUndefined(this.ME) ? '' : this.ME;
    const mengeAndUnit = this.Menge + ' ' + mengenEinheit;
    return mengeAndUnit;
  }

  getEinzelpreisDisplayLine(): string {
    const returnPrice = this.getEinzelDisplayprice();
    const returnPriceGerman = GlobalHelper.convertToRoundedDisplayString(returnPrice);
    const description = this.getEinzelpreisDescription();
    return description + ': ' + returnPriceGerman + ' €';
  }

  getGesamtpreisDisplayLine(): string {
    const returnPrice = this.getGesamtDisplayPrice();
    const returnPriceGerman = GlobalHelper.convertToRoundedDisplayString(returnPrice);
    const description = this.getGesamtpreisDescription();
    return description + ': ' + returnPriceGerman + ' €';
  }

  getSuchbegriff(): string {
    return '';
  }

  /**
   * @description Nur direkt aufgeführte Posten (Artikel,Lohn,Leistung...) ohne Unterpositionen
   * @param withUnterposition Zusätzlich unterpositionen
   */
  isRechnungsposten(withUnterposition?: boolean, arbeitsbeschreibung?: string): boolean {
    const longType = this.getLongtype();
    const isRechnungsposten = longType !== 'Undefiniert';
    const isUnterposten = this.isUnterposition();
    const isArbeitsbeschreibung = this.isArbeitsbeschreibung(arbeitsbeschreibung);
    if (isArbeitsbeschreibung) return false;
    if (withUnterposition) return isRechnungsposten;
    return isRechnungsposten && !isUnterposten;
  }

  /**@description Guckt ob es sich um eines Position mit positionsnummer handelt (eine Rechnungsposition, die keine Texpotsition ist) */
  isPostenWithPosNr(): boolean {
    const rechnungPosten = this.isRechnungsposten();
    const isNotTextposition = this.getLongtype() !== 'Textposition';
    return rechnungPosten && isNotTextposition;
  }

  getPriceGroup(): string {
    return (1 + this.VKPrGrp).toString();
  }

  /**@description Schaut ob es sich um eine Leistung handelt und an Stelle xxAx der Flags eine 0 steht */
  getPrintPartList(): boolean {
    if (this.getLongtype() !== 'Leistung') return false;
    const printStuecklistenFlag = this.Flags?.substring(2, 3);
    const printStueckliste = printStuecklistenFlag === '0';
    return printStueckliste;
  }

  toggleStuecklistenPrint(): void {
    const isLeistung = this.getLongtype() === 'Leistung';
    if (isLeistung) {
      const printListenFlag = this.Flags?.substring(2, 3);
      const updatedFlagSetting =
        printListenFlag === '1' ? this.replaceCharAt(this.Flags, 2, '0') : this.replaceCharAt(this.Flags, 2, '1');
      this.Flags = updatedFlagSetting;
      return;
    }

    const printStuecklistenFlag = this.Flags?.substring(3, 4);
    const newFlagSetting =
      printStuecklistenFlag === '1' ? this.replaceCharAt(this.Flags, 3, '0') : this.replaceCharAt(this.Flags, 3, '1');
    this.Flags = newFlagSetting;
  }

  /**
   * @description Rechnungspositionen werden immer im Druck gezeigt,außer es handelt sich um eine Unterposition. Diese werden gezeigt wenn eine 0 an der letzten stelle der Flags steht
   * (xxx0) .
   *  */
  shouldBePrinted(): boolean {
    const isUnterposition = this.isUnterposition();
    if (!isUnterposition) return true;
    const printStuecklistenitemFlag = this.Flags?.substring(3, 4) === '0';
    return printStuecklistenitemFlag;
  }

  isArbeitsbeschreibung(arbeitsBeschreibung: string): boolean {
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(arbeitsBeschreibung)) return false;
    const cleanBeschreibung = GlobalHelper.removeControlCharacters(arbeitsBeschreibung);
    const langText = this.getLangtext();
    const cleanLangtext = GlobalHelper.removeControlCharacters(langText);
    return cleanLangtext?.includes(cleanBeschreibung);
  }

  getPosNr(): string {
    if (GlobalHelper.isNullOrUndefined(this.PosNr)) return '';
    return this.PosNr;
  }

  getMengeOrStunden(): number {
    const longType = this.getLongtype();
    if (longType === 'Lohn') return this.Zeit / 60;
    return this.Menge;
  }
  /**@description Setzt je nach Kontext entweder Zeit order Menge */
  private setZeitOrMenge(neuerNumberWert: number, changeType: 'Zeit' | 'Menge'): void {
    if (changeType === 'Zeit') {
      this.Zeit = neuerNumberWert;
      this.Menge = 1;
      return;
    }
    if (changeType === 'Menge') this.Menge = neuerNumberWert;
  }

  /**@description Nimmt ein RepairOrderitem das als suchergebnis kommt und füllt damit die Felder der Dokumentposition */
  private fillFromReapairorderitem(item: HWRepairOrderItem, priceGroup = '0'): void {
    this.VKPrGrp = Number(priceGroup);
    this.KURZTEXT = item.Bezeichnung;
    this.LTXData = item.RTFData;
    this.FreEK = item.EUFREEK;
    this.FreVK = item.FreVK;
    this.FreVK_0 = item.EUFREVK1;
    this.FreVK_1 = item.EUFREVK2;
    this.FreVK_2 = item.EUFREVK3;
    this.FreVK_3 = item.EUFREVK4;
    this.FreVK_4 = item.EUFREVK5;
    this.FreVK_5 = item.EUFREVK6;
    this.FreVK_6 = item.EUFREVK7;
    this.FreVK_7 = item.EUFREVK8;
    this.FreVK_8 = item.EUFREVK9;
    this.FreVK_9 = item.EUFREVK10;
    this.GerEK = item.EUGEREK;
    this.GerVK = item.GerVK;
    this.GerVK_0 = item.EUGERVK1;
    this.GerVK_1 = item.EUGERVK2;
    this.GerVK_2 = item.EUGERVK3;
    this.GerVK_3 = item.EUGERVK4;
    this.GerVK_4 = item.EUGERVK5;
    this.GerVK_5 = item.EUGERVK6;
    this.GerVK_6 = item.EUGERVK7;
    this.GerVK_7 = item.EUGERVK8;
    this.GerVK_8 = item.EUGERVK9;
    this.GerVK_9 = item.EUGERVK10;
    this.LohnsatzEK = item.LohnEK;
    this.LohnSVK_G = item['EULOHNS' + priceGroup];
    this.LohnSVK_0 = item.EULOHNS1;
    this.LohnSVK_1 = item.EULOHNS2;
    this.LohnSVK_2 = item.EULOHNS3;
    this.LohnSVK_3 = item.EULOHNS4;
    this.LohnSVK_4 = item.EULOHNS5;
    this.LohnSVK_5 = item.EULOHNS6;
    this.LohnSVK_6 = item.EULOHNS7;
    this.LohnSVK_7 = item.EULOHNS8;
    this.LohnSVK_8 = item.EULOHNS9;
    this.LohnSVK_9 = item.EULOHNS10;
    this.MatEK = item.EK;
    this.MatVK_G = item['EUMATVK' + priceGroup];
    this.MatVK_0 = item.EUMATVK1;
    this.MatVK_1 = item.EUMATVK2;
    this.MatVK_2 = item.EUMATVK3;
    this.MatVK_3 = item.EUMATVK4;
    this.MatVK_4 = item.EUMATVK5;
    this.MatVK_5 = item.EUMATVK6;
    this.MatVK_6 = item.EUMATVK7;
    this.MatVK_7 = item.EUMATVK8;
    this.MatVK_8 = item.EUMATVK9;
    this.MatVK_9 = item.EUMATVK10;
    this.SonEK = item.SonEK;
    this.SonVK = item.SonVK;
    this.SonVK_0 = item.EUSONVK1;
    this.SonVK_1 = item.EUSONVK2;
    this.SonVK_2 = item.EUSONVK3;
    this.SonVK_3 = item.EUSONVK4;
    this.SonVK_4 = item.EUSONVK5;
    this.SonVK_5 = item.EUSONVK6;
    this.SonVK_6 = item.EUSONVK7;
    this.SonVK_7 = item.EUSONVK8;
    this.SonVK_8 = item.EUSONVK9;
    this.SonVK_9 = item.EUSONVK10;
    this.H_Ebene = item.Ebene;
    this.ErlKonto = item.ErloesKonto;
    this.Zeit = item.Zeit;
    this.Menge = item.Menge;
    this.ME = item.MengenEinheit;
    // this.PEinhMenge = item.PEMENGE;
    this.RSDaten = item.RSDaten;
    this.UStKz = item.UstSchl;
    this.isRepairorderPosition = true;
    // this.VKPrGrp = parseInt(priceGroup, 10);
    this.ID = this.getIDFromRepairOrderItem(item);
    this.Lief_Gew = this.getLongtype() === 'Leistung' ? item.GEWERK : item.Lieferant;
    this.Nummer = item.Nummer;
    const longType = item.getLongtype(); // wichtig vom repairOrderItem - die dokumentposition enthält erst durch korrekt gesetze xflags den lohntyp
    this.xFlags = this.createXFlagString(longType);
    if (longType === 'Textposition') {
      this.setTextpositionSpecificData(item);
    }
    this.positionType = 'DokumentPosition';
    this.LeistungsID = item.getLeistungsId();
  }

  private setTextpositionSpecificData(item: HWRepairOrderItem): void {
    this.TextData = null;
    this.UStKz = null;
    this.VKPrGrp = 0;
    this.Menge = 0;
    this.Nummer = '';
    this.LTXData = item.Bezeichnung;
    this.KTXData = item.Bezeichnung;
    this.TextData = item.Bezeichnung;
  }

  /**@description Zeigen besonderer Eigenschaften des Dokuments */
  private createXFlagString(longType: PositionsArt): string {
    let xflagsNumber = 0;
    if (longType === 'Lohn') xflagsNumber = xflagsNumber + 80001;
    let xFlagsString = xflagsNumber.toString();
    while (xFlagsString.length !== 8)
      // führende nullen ergänzen bis auf 8 stellen
      xFlagsString = '0' + xFlagsString;
    return xFlagsString;
  }

  /**@description Achtung: Anders als bei Auftragspositionen bilden sowohl Lohn als auch Material auf "M" ab - dies wird hier korrigiert */
  private getIDFromRepairOrderItem(item: HWRepairOrderItem): string {
    const repairItemID = item.Ident;
    if (repairItemID === 'S' || repairItemID === 'M') return 'M';
    if (repairItemID === 's' || repairItemID === 'm') return 'm';
    return repairItemID;
  }

  /**@description Guckt ob ein Artikel nicht doch ein Lohn ist */
  private getIsLohnBasedOnXflags(): boolean {
    if (this.xFlags.endsWith('1')) return true;
    return false;
  }

  /**@description Ersetzt den Charakter an stelle Index durch replacement string */
  private replaceCharAt(inputString: string, index: number, replacement: string): string {
    return inputString.substring(0, index) + replacement + inputString.substring(index + replacement.length);
  }
}

<div *ngIf="positionRequest" class="content-block">
  <div class="flexContainer" style="margin-bottom: 10px">
    <div class="flexContainerItem">
      <h2 style="margin: 0">Position hinzufügen</h2>
    </div>
    <div class="cart" (click)="goToRepairOrderItems()">
      <i class="dx-icon dx-icon-cart"></i>
      <span *ngIf="itemCountBadgeNumber != 0" class="badge">{{ itemCountBadgeNumber }}</span>
    </div>
  </div>

  <div style="margin-bottom: 10px">
    <app-bss-select-box
      [items]="types"
      [value]="requestType"
      (selectedValue)="postenArtChange($event)"
    ></app-bss-select-box>
  </div>

  <div
    *ngIf="requestType == 'Artikel' || requestType == 'Leistung' || requestType == 'Kostenart'"
    class="flexContainer"
  >
    <div class="flexContainerItem">
      <dx-text-box
        id="searchBox"
        (onEnterKey)="searchItems(positionRequest)"
        [(value)]="positionRequest.SearchText"
        valueChangeEvent="keyup"
        placeholder="Suche"
        [showClearButton]="true"
      >
      </dx-text-box>
    </div>
    <div style="padding-left: 10px">
      <dx-button [icon]="'search'" class="default-button" (click)="searchItems(positionRequest)"></dx-button>
    </div>
  </div>

  <div *ngIf="responsePositions && !positionRequest.isManuellePosition()">
    <dx-list
      [searchEnabled]="searchEnabled"
      class="positions-list"
      [searchExpr]="searchExpressionForResponseList"
      [dataSource]="responsePositions"
    >
      <div class="card" (click)="selectItem(item)" *dxTemplate="let item of 'item'">
        <!-- Alle außer lohn, manuell und textposition -->
        <div *ngIf="requestType != 'Lohn' && requestType != 'Textposition'">
          <p>
            <b>{{ item.MatNr }} {{ item.Such }}</b>
          </p>
          <p>{{ item.KurzText }}</p>
        </div>

        <!-- Lohn -->
        <div *ngIf="requestType == 'Lohn'">
          {{ item.getBezeichnung() }}
        </div>

        <!-- Textposition -->
        <div *ngIf="requestType == 'Textposition'">{{ item.Nummer }} {{ item.SuchText }}</div>
      </div>
    </dx-list>
  </div>

  <app-bss-number-popup
    *ngIf="showPopup"
    [showTextBox]="false"
    [OpenOnInit]="true"
    [title]="getPopupTitle(selectedObject)"
    [description]="getPopupContent(selectedObject)"
    (outputValue)="setMengeOrZeit(selectedObject, $event, positionRequest)"
    [inputValue]="1"
    [mengenTyp]="requestType == 'Lohn' ? 'STD' : selectedObject.MengenEinheit"
    (cancelOutput)="showPopup = false"
    [showAutoLohnButton]="order.Autolohn?.isActive && requestType == 'Lohn'"
    (autolohnButtonClicked)="setMengeOrZeit(selectedObject, $event, positionRequest)"
    [DecimalPlaces]="2"
    [DecimalPlacesInputEnabled]="true"
    [ValidateInputDecimals]="true"
  >
  </app-bss-number-popup>

  <app-manuelle-position
    *ngIf="positionRequest.isManuellePosition()"
    [mengeneinheiten]="mengeneinheiten"
    [isAutolohnActive]="order.Autolohn?.isActive"
    (position)="addItemToOrder($event)"
  >
  </app-manuelle-position>

  <app-km-allowance
    *ngIf="positionRequest.isKmAllowance()"
    [amount]="maintenanceSystem.Anlagenstandort.ANFAHRTKM"
    (position)="addItemToOrder($event)"
  ></app-km-allowance>
</div>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxListComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { SliderNames } from '../../config/Konstanten';
import { DragEvent } from '../../interfaces/DragEvent';

/**Wichtig: Verwendet man asynchrone Daten als Input muss ein ngIf gekoppelt an den letzten asynchronen Input verwendet werden */
@Component({
  selector: 'app-bss-list',
  templateUrl: './bss-list.component.html',
  styleUrls: ['./bss-list.component.scss'],
})
export class BssListComponent implements OnInit, OnChanges {
  @Input() searchExpressions: string[];
  @Input() inputDataSource: any[];
  @Input() priceRight: boolean;
  @Input() searchEnabled = true;
  @Input() id: string;
  @Input() listClass: string;
  @Input() secondaryDataSource;
  @Input() userInfo: UserInfo;
  @ViewChild(DxListComponent, { static: false }) list: DxListComponent;
  @Input() dragAndDrop = false;
  @Input() emptyInputDataMessage: string;
  @Output() itemDragged = new EventEmitter<DragEvent>();
  @Output() updatedDataSource = new EventEmitter<any>();
  dataSource: DataSource;
  create = new Date();
  activeStateEnabled = false;
  sliderNames = SliderNames;

  ngOnChanges(changes: SimpleChanges): void {
    const dataSourceChanged = changes.inputDataSource;
    if (dataSourceChanged) {
      this.setDataSource(this.inputDataSource);
      return;
    }
  }

  ngOnInit(): void {
    if (this.searchEnabled && this.searchExpressions?.length === 0) throw new Error('Search expressions are required');
    this.setDataSource(this.inputDataSource);
  }

  removeItem(item: unknown): void {
    const index = this.inputDataSource.indexOf(item, 0);
    if (index > -1) {
      this.inputDataSource.splice(index, 1);
    }
    this.reAssignDataSource(this.inputDataSource);
    this.updatedDataSource.emit(this.dataSource);
  }

  /**@description Ändert sich von Außen die Liste,muss die Data source neu zugewiesen werden */
  reAssignDataSource<Type>(updatedDataSource: Type[]): void {
    this.setDataSource(updatedDataSource);
    this.updatedDataSource.emit(this.dataSource);
  }

  dragEnd(event: DragEvent): void {
    this.itemDragged.emit(event);
  }

  private setDataSource(nextDataSource: any[]): void {
    this.dataSource = new DataSource({
      store: new ArrayStore({
        data: nextDataSource,
      }),
      paginate: true,
      pageSize: 50,
    });
  }
}

import { Anlagendaten } from 'apps/handwerkPWA/src/app/entities/models/wartung-und-service/Anlagendaten';
import { Anlagenstandort } from 'apps/handwerkPWA/src/app/entities/models/wartung-und-service/Anlagenstandort';
import { Anlagentermin } from 'apps/handwerkPWA/src/app/entities/models/wartung-und-service/Anlagentermin';
import {
  MessungenFromHandwerk,
  Messwertsatz,
  Messwerttabelle,
  MesswertTabellenEintrag,
} from 'apps/handwerkPWA/src/app/entities/models/wartung-und-service/Messwertsatz';
import { Technischedaten } from 'apps/handwerkPWA/src/app/entities/models/wartung-und-service/Technischedaten';
import { ConvertHelper } from 'apps/handwerkPWA/src/app/helper/entities/HWAnlage/convertHelper';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { UuidEntity } from '../../interfaces';
import { SyncObject } from '../models/SyncObject';
import { IndexedDBTypes } from './dbType';
import { HWAddress } from './HWAddress';
import { HWDatenblatt } from './HWDatenblatt';

export class HWAnlage extends IndexedDBTypes.DbType implements UuidEntity, SyncObject {
  @IndexedDBTypes.KlassenName('HWAnlage') KlassenName: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: number;
  @IndexedDBTypes.IndexField('string') ANLAGE: string = null; // Anlagennummer
  @IndexedDBTypes.DataField('string') projectNumber: string = null;
  @IndexedDBTypes.DataField('string') Anlagenbeschreibung: string = null; // Anlagenbeschreibung - rtf konvertiert zu Klartext
  @IndexedDBTypes.DataField('string') ABESCHR: string = null; // Arbeitsbeschreibung-Reiter im handwerk
  @IndexedDBTypes.DataField('string') BESCHR: string = null; // Bemerkung-Reiter im Handwerk
  @IndexedDBTypes.IndexField('string') UUID: string = null;
  @IndexedDBTypes.DataField('string') RTFDATA: string = null; // Anlagenbeschreibung - in rtf
  @IndexedDBTypes.DataField('Anlagendaten') Anlagendaten: Anlagendaten;
  @IndexedDBTypes.DataField('Anlagenstandort') Anlagenstandort: Anlagenstandort;
  @IndexedDBTypes.DataField('Anlagentermin') Anlagentermin: Anlagentermin;
  @IndexedDBTypes.DataField('TechnischeDaten') TechnischeDaten: Technischedaten;
  @IndexedDBTypes.DataField('HWDatenblatt') Datenblatt: HWDatenblatt; // enthält die Felder die im handwerk unter "technische Daten" stehen
  @IndexedDBTypes.DataField('Messwertsatz') Messwertsatz: Messwertsatz;
  @IndexedDBTypes.DataField('string') ZUSAETZE: string[] = null;

  Anlagenmessungen: MessungenFromHandwerk[] = null; // Temporäres Feld um Messungen aus dem Webservice zwischenzulagern bevor es dem Messwertsatzobjekt zugeordnet wird

  constructor(data: HWAnlage, customers: HWAddress[], datenblaetter: HWDatenblatt[]) {
    super();
    GlobalHelper.assignIfPropertyExists(this, data);
    this.Anlagendaten = new Anlagendaten(customers, datenblaetter, data);
    this.Anlagenstandort = new Anlagenstandort(data);
    this.Anlagentermin = new Anlagentermin(data);
    this.TechnischeDaten = data ? new Technischedaten(data['ZUSAETZE']) : new Technischedaten(null);
    if (this.RTFDATA) {
      this.Anlagenbeschreibung = ConvertHelper.convertToPlain(this.RTFDATA);
    }
  }

  static toString(): string {
    return 'HWAnlage';
  }

  // Folgend Werte, die aus dem Endpunkt kommen, aber bisher nicht zugeordnet werden konnten

  // ALTLW: null
  // BEZ: "Heizkessel Öl"
  // CHILD: null
  // GEAENDERT: 44054.59843388889
  // NACHRICHT: 1
  // NEULW: null
  // ORIGIN: null
  // PARENT: null
  // SOLLWERTE: (18) ["15,4", "24,3", "171,5", "2,6", "50,0", "13,5", "6,5", "93,5", "4", "1,14", "-0,29", "", "", "", "", "", "", ""]
  // TODO: false
  // TXDATA: null
  // TXFIELDS: null
  // USELWTONW: false
  // USER: 3
  // USERTTONW: false
  // VK: 144.73

  getUuid(): string {
    return this.UUID;
  }

  /**@description Fügt den Anlagedaten Datenblatt, Zusatzbeschreibungen, TechnischeDaten und Messwertsätze hinzu */
  addAdditionalAnlagendata(datenblaetter: HWDatenblatt[]): void {
    this.addDatenblattAndZusatzbeschreibung(datenblaetter);
    const datenblatt = this.Datenblatt;
    if (!datenblatt) {
      return;
    }
    this.addTechnischeDatenInfos(datenblatt);
    const anlagenDaten = this.Anlagendaten;
    this.addMesswertsatz(anlagenDaten, datenblatt);
    const desiredValue = this.Messwertsatz.Sollwerttabelle;
    this.sortAnlagenmessungenIntoObject(anlagenDaten, desiredValue, this.Anlagenmessungen);
  }

  private addDatenblattAndZusatzbeschreibung(datenblaetter: HWDatenblatt[]): void {
    const datenblatt = datenblaetter.find(blatt => blatt.NR === this.Anlagendaten.DATENBLNR);
    this.Datenblatt = datenblatt;
  }

  private addTechnischeDatenInfos(datenblatt: HWDatenblatt): void {
    this.TechnischeDaten.Bezeichnungen = datenblatt.ZUSAETZE;
    this.TechnischeDaten.BezeichnungenExtended = this.getExtendedBezeichnungen(datenblatt.ZUSAETZE);
  }
  private getExtendedBezeichnungen(ZUSAETZE: string[]): string[] {
    const additionsExtended: string[] = [];
    let index = 0;
    for (const addition of ZUSAETZE) {
      if (addition === '') {
        additionsExtended.push('Zusatz ' + (index + 1));
      } else {
        additionsExtended.push(addition);
      }
      index++;
    }
    return additionsExtended;
  }

  private addMesswertsatz(anlagendaten: Anlagendaten, datenblatt: HWDatenblatt): void {
    const messwertSatz = new Messwertsatz(anlagendaten);
    messwertSatz.Sollwerttabelle = new Messwerttabelle(anlagendaten);
    messwertSatz.Sollwerttabelle.Beschreibung = 'XYZSTandard';
    if (!datenblatt) {
      return;
    }
    const feldanzahl = datenblatt.FELDER.length;
    const descriptions = datenblatt.FELDER;
    const messwerte = datenblatt.VORGABEN;
    const units = datenblatt.EINHEITEN;
    for (let index = 0; index < feldanzahl; index++) {
      const description = descriptions[index];
      const messwert = messwerte[index];
      const unit = units[index];
      if (unit === '' && messwert === '' && description === '') {
        continue;
      }
      const messwertTabellenEintrag = new MesswertTabellenEintrag(description, messwert, unit);
      messwertSatz.Sollwerttabelle.MesswertTabellenEintraege.push(messwertTabellenEintrag);
    }
    this.Messwertsatz = messwertSatz;
  }
  private measurementsFromHWToMeasurementTable(
    vorlage: Messwerttabelle,
    inputAnlagenmessungen: MessungenFromHandwerk,
  ): Messwerttabelle {
    vorlage.Beschreibung = inputAnlagenmessungen.Beschreibung;
    vorlage.Messdatum = inputAnlagenmessungen.Messdatum.substring(0, 10);
    const vorlagenMessfelder = vorlage.MesswertTabellenEintraege;
    let index = 0;
    for (const field of vorlagenMessfelder) {
      field.Messwert = inputAnlagenmessungen.messwerte[index];
      index++;
    }
    return vorlage;
  }

  private sortAnlagenmessungenIntoObject(
    anlagenDaten: Anlagendaten,
    desiredValueTable: Messwerttabelle,
    inputAnlagenmessungen: MessungenFromHandwerk[],
  ): void {
    if (GlobalHelper.isNullOrUndefined(inputAnlagenmessungen)) {
      return;
    }
    for (const measurement of inputAnlagenmessungen) {
      const vorlage = new Messwerttabelle(anlagenDaten, desiredValueTable);
      const messwertTabelle = this.measurementsFromHWToMeasurementTable(vorlage, measurement);
      this.Messwertsatz.Istwerttabellen.push(messwertTabelle);
    }
  }
}

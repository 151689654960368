import { PositionType } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { Gewerk, Lieferant, PositionSettings } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';

export class Positionrequest {
  requestType: 'Offlinepositions' | 'Liverequest';
  mandant: string = null;
  monteur: string = null;
  /** Wenn leergelassen, werden alle Positionen zurückgegeben die auf den Gewerks/Lieferantenfilter zutreffen */
  SearchText = '';
  Lieferanten: Lieferant[] = null;
  Gewerk: Gewerk[] = null;
  useFilterLieferanten = false;
  useFilterGewerk = false;
  handwerkTableName: string = null;
  leistungsPositionsGewerk: string = null;
  leistungsPositionsLeistungsnummer: string = null;
  type: PositionType = null;
  kundenPriceGroup: string = null;
  skip?: number;
  take?: number;

  constructor(mandant: string, monteur: string) {
    this.mandant = mandant;
    this.monteur = monteur;
  }

  isManuellePosition(): boolean {
    return (this.type as string) === 'Manuelle Position';
  }

  isKmAllowance(): boolean {
    return (this.type as string) === 'Km Pauschale';
  }

  addPositionSettingData(positionSettingData: PositionSettings): void {
    GlobalHelper.assignIfPropertyExists(this, positionSettingData);
  }

  changeType(type: PositionType): void {
    if (type === 'Lohn') {
      this.SearchText = '';
    }
    this.type = type;
    this.handwerkTableName = this.getHandwerkTableName(type);
  }

  getType(): PositionType {
    return this.type;
  }

  /**@description Gibt den zugehörigen handwerks tabellennamen zum typ zurück */
  getHandwerkTableName(type: PositionType): string {
    switch (type) {
      case 'Artikel': {
        return 'MATERIAL';
      }
      case 'Lohn': {
        return 'LOHNART';
      }
      case 'Leistung': {
        return 'LEISTUNG';
      }
      case 'Kostenart': {
        return 'KOSTART';
      }
      case 'Leistungsposition': {
        return 'LSTPOS';
      }
    }
    return 'UndefeniertP';
  }
}

<h2 class="content-block">Adressen</h2>

<div class="content-block bottom-padding">
  <dx-select-box
    class="address-type-select"
    [items]="addressTypes"
    [(value)]="adressType"
    fieldTemplate="field"
    (onValueChanged)="filterChanged($event.value)"
  >
    <div *dxTemplate="let data of 'field'">
      <div class="custom-item">
        <img *ngIf="data == 'Kunden'" src="assets\icons\Kunden.png" class="icon-select" />
        <img *ngIf="data == 'Lieferanten'" src="assets\icons\Lieferanten.png" class="icon-select" />
        <img *ngIf="data == 'Freie Adressen'" src="assets\icons\master.png" class="icon-select" />
        <img *ngIf="data == 'Mitarbeiter'" src="assets\icons\admin.png" class="icon-select" />
        <img *ngIf="data == 'Objektadressen'" src="assets\icons\objektadr.png" class="icon-select" />
        <dx-text-box class="product-name" [value]="data" [readOnly]="true"></dx-text-box>
      </div>
    </div>
    <div *dxTemplate="let data of 'item'">
      <div class="custom-item">
        <img *ngIf="data == 'Kunden'" src="assets\icons\Kunden.png" class="icon-select" />
        <img *ngIf="data == 'Lieferanten'" src="assets\icons\Lieferanten.png" class="icon-select" />
        <img *ngIf="data == 'Freie Adressen'" src="assets\icons\master.png" class="icon-select" />
        <img *ngIf="data == 'Mitarbeiter'" src="assets\icons\admin.png" class="icon-select" />
        <img *ngIf="data == 'Objektadressen'" src="assets\icons\objektadr.png" class="icon-select" />
        <div class="product-name">
          {{ data }}
        </div>
      </div>
    </div>
  </dx-select-box>

  <app-bss-list
    [id]="'adresses'"
    [inputDataSource]="filteredAddresses"
    [searchExpressions]="searchExpressions"
    [emptyInputDataMessage]="'Es sind keine Adressen unter diesem Filter vorhanden'"
  ></app-bss-list>

  <app-bss-speed-dial
    *ngIf="employeeRights?.addressRights?.editCustomers || employeeRights?.addressRights?.editFreeAddresses"
    icon="add"
    [index]="1"
    label="Neue Adresse"
    (Click)="newAddress()"
  ></app-bss-speed-dial>
</div>

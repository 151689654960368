<ng-content></ng-content>

<div class="menu-container">
  <dx-tree-view
    [animationEnabled]="false"
    [items]="navigationItems | async"
    keyExpr="path"
    selectionMode="single"
    [focusStateEnabled]="false"
    expandEvent="click"
    expandedExpr="isExpanded"
    (onItemClick)="onItemClick($event.itemData)"
    width="100%"
  >
    <div *dxTemplate="let item of 'item'" [ngStyle]="{ opacity: item.customDisabled ? '0.5' : '1' }">
      <i *ngIf="item.icon" [class]="'dx-icon dx-icon-' + item.icon" style="position: relative">
        <div *ngIf="item.icon === 'find' && (newThings | async)" class="discoverDot"></div>
      </i>
      <span *ngFor="let _ of [].constructor(item.recursionLevel !== 0 ? item.recursionLevel - 1 : 0)">
        &nbsp; &nbsp;</span
      >
      <span>{{ item.text }}</span>
    </div>
  </dx-tree-view>
</div>

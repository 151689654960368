import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { noBackAvailable } from '../../../config/Konstanten';
import { SyncObject } from '../../../entities/models/SyncObject';
import { ActivityTrackerService } from '../../../services/dataServices/activityTracker.service';
import { ControllerService } from '../../../services/globalServices/controller.service';
import { LocalstorageService } from '../../../services/globalServices/localstorage.service';
import { SyncService } from '../../../services/globalServices/sync.service';
import { SyncObjectService } from '../../../services/globalServices/syncObject.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  menuToggleEnabled = false;
  backExists = true;
  routingSubscription: Subscription;
  syncObjects: BehaviorSubject<SyncObject[]>;

  hiddenFeatureCounter = 0;

  newThings: BehaviorSubject<boolean>;

  constructor(
    private routingService: RoutingService,
    private syncObjectService: SyncObjectService,
    private syncService: SyncService,
    private dialogService: DialogService,
    private controllerService: ControllerService,
    private localStorageService: LocalstorageService,
    private activityTrackerService: ActivityTrackerService,
  ) {
    this.newThings = this.syncService.newThingsDiscovered;
    this.syncObjects = this.syncObjectService.currentSyncObjects;
  }

  ngOnDestroy(): void {
    this.routingSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.routingSubscription = this.routingService.currentRoute.subscribe(newRoute => {
      this.reactOnNewRoute(newRoute);
    });
  }

  /**@description Reacts to a new route by then looking if Back button and Sync button should be shown */
  reactOnNewRoute(newRoute: string): void {
    if (newRoute.startsWith('/')) newRoute = newRoute.substring(1);
    this.backExists = !noBackAvailable.includes(newRoute);
  }

  toggleMenu = (): void => {
    const currentMenuState = this.routingService.menuOpen.value;
    this.routingService.menuOpen.next(!currentMenuState);
  };

  routeBack = (): void => {
    this.routingService.routeBack();
  };

  synchronize = async (): Promise<void> => {
    const activity = await this.activityTrackerService.getCurrentActivityFromIDB();
    activity.CallsManualSync++;
    await this.activityTrackerService.saveActivityToIDB(activity);
    this.routingService.nextMenuState(false);
    await this.syncService.syncButtonCall();
    this.routingService.reload();
  };

  /**@description Wird 5 mal in Folge auf header geklickt, kann damit die Datenbank zurückgesetzt werden */
  async hiddenFeature(): Promise<void> {
    const currentRoute = this.routingService.lastRoutes[0];
    if (currentRoute !== '/Login') {
      this.hiddenFeatureCounter = 0;
      return;
    }
    this.hiddenFeatureCounter++;
    if (this.hiddenFeatureCounter < 5) {
      return;
    }
    const responseDelete = await this.dialogService.openConfirmDialog(
      'Achtung',
      'Mit dieser Funktion löschen Sie alle mobilen Daten. ' +
        'Nicht synchronisierte Daten gehen unwiederbringlich verloren.',
      'Datenbank löschen',
      'Nicht löschen',
      false,
    );
    if (responseDelete) {
      this.hiddenFeatureCounter = 0;
      await this.controllerService.upgradeIndexedDB();
      await this.routingService.navigateTo('/Reload' + '/Login');
      this.localStorageService.clearLocalSessionData();
    }
  }
}

@NgModule({
  imports: [CommonModule, DxButtonModule, DxToolbarModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}

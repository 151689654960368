import { Injectable } from '@angular/core';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BaseInformationTracker } from '../../entities/repository/BaseInformationTracker';
import { ControllerService } from '../globalServices/controller.service';
import { HWGlobalSettingService } from '../globalServices/hwGlobalSetting.service';

@Injectable({
  providedIn: 'root',
})
export class BaseInformationTrackerService {
  constructor(
    private controllerService: ControllerService,
    private globalSettingService: HWGlobalSettingService,
    private rightService: RightsService,
    private deviceDetectorService: DeviceDetectorService,
    private restService: RestService
  ) {}

  async createBaseInformationEntity(): Promise<BaseInformationTracker> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const rights = this.rightService.getCurrentRight();
    const browser = this.deviceDetectorService.browser;
    const maxLicenses = await this.restService.returnData<number>('getClientLimit', undefined, true);
    const usedLicenses = await this.restService.returnData<number>('getCurrentClients', undefined, true);
    const baseInformation = new BaseInformationTracker(undefined, userInfo, rights, maxLicenses, usedLicenses, browser);
    return baseInformation;
  }

  public async getAll(): Promise<BaseInformationTracker[]> {
    return await this.controllerService.getData(BaseInformationTracker.toString());
  }

  async saveBaseInformationToIDB(baseInformation: BaseInformationTracker): Promise<void> {
    await this.deleteBaseInformation(baseInformation);
    await this.controllerService.setData(BaseInformationTracker.toString(), [baseInformation]);
  }

  async deleteBaseInformation(baseInformation: BaseInformationTracker): Promise<void> {
    await this.controllerService.deleteData(
      BaseInformationTracker.toString(),
      'Date',
      baseInformation.dateTime.toString()
    );
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';
import { formulaSearch } from '../../../config/search-constants';
import { Aufmass, RoomBook, RoomName } from '../../../entities';
import { RoomBookPosition } from '../../../entities/models/aufmass/RoomBookPosition';
import { MeasurementService } from '../../../services/dataServices/measurement.service';
import { RoomBookService } from '../../../services/dataServices/roomBook.service';
import { RoomNameService } from '../../../services/dataServices/roomName.service';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';

@Component({
  selector: 'app-masskette-edit',
  templateUrl: './masskette-edit.component.html',
  styleUrls: ['./masskette-edit.component.scss'],
})
export class MassketteEditComponent implements OnInit, OnDestroy {
  measurement: Aufmass;
  room: RoomBookPosition;
  roomBookEntry: RoomBook;
  width: number;
  height: number;
  count: number;
  roomNames: RoomName[];
  /** Specifies whether the measurement fields are to be shown or the measurement formula is to be entered directly */
  freeInput = true;
  inputDone = false;
  bezugsFlaechen: RoomBook[];
  selectedBezug: RoomBook;
  currentRoomBookEntries: RoomBook[];
  saveSubscription: Subscription;
  searchExpressions = formulaSearch;

  constructor(
    private routingService: RoutingService,
    private measurementService: MeasurementService,
    private roomNameService: RoomNameService,
    private roomBookService: RoomBookService,
    private globalSettingService: HWGlobalSettingService,
    private dialogService: DialogService
  ) {}
  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    const { dimensionChainId, currentRoomBookEntries } = await this.loadData();
    this.handleNewOrExisting(dimensionChainId, currentRoomBookEntries);
    this.saveSubscription = this.routingService.save.subscribe(() => void this.save());
  }
  /**@description Try to parse height, width and length from the formula */
  parseDimensions(formulaToParseShape: string): void {
    const factorSplit = formulaToParseShape.split('*');
    if (factorSplit.length !== 2 && factorSplit.length !== 3) return;
    const width = parseInt(factorSplit[0], 10);
    const height = parseInt(factorSplit[1], 10);
    const count = factorSplit.length === 3 ? parseInt(factorSplit[2], 10) : 1;
    if (isNaN(width) || isNaN(height) || isNaN(count)) return;
    this.width = width;
    this.height = height;
    this.count = count;
    this.freeInput = false;
  }

  measurementChanged(newValueString: string, type: 'hoehe' | 'breite' | 'anzahl'): void {
    const newValue = parseInt(newValueString, 10);
    if (isNaN(newValue)) return;
    switch (type) {
      case 'anzahl':
        this.count = newValue;
        break;
      case 'hoehe':
        this.height = newValue;
        break;
      case 'breite':
        this.width = newValue;
        break;
    }
    if (this.count && this.height && this.width) {
      const newFormula = `${this.count}*${this.height}*${this.width}`;
      this.roomBookEntry.useFormula(newFormula);
    }
  }

  selectedBezugsArea(value: RoomBook): void {
    this.selectedBezug = value;
  }

  async save(): Promise<void> {
    this.roomBookEntry.Aufmid = this.measurement.AufmId;
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(this.roomBookEntry.Bezeich)) {
      void this.dialogService.openErrorMessage('Fehler', 'Sie haben die Fläche noch nicht benannt. ');
      return;
    }
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(this.roomBookEntry.Aufmass)) {
      void this.dialogService.openErrorMessage('Fehler', 'Die Formel darf nicht leer sein. ');
      return;
    }
    if (this.roomBookEntry.IsAbzug && !this.selectedBezug) {
      void this.dialogService.openErrorMessage(
        'Fehler',
        'Dieses Flächenaufmass ist als Abzug gekennzeichnet, es wurde aber kein Bezug gewählt. '
      );
      return;
    }
    this.roomBookEntry.fillLineIdDeductionId(this.currentRoomBookEntries, this.selectedBezug);

    this.measurement = await this.roomBookService.saveSingleArea(
      this.roomBookEntry,
      this.room,
      this.measurement,
      false
    );
    this.routingService.routeBack(true);
  }

  async deleteEntry(): Promise<void> {
    const continueDelete = await this.dialogService.openConfirmDialog(
      'Achtung',
      'Möchten Sie diese Maßkette wirklich löschen? ',
      'Löschen',
      'Nicht löschen',
      false
    );
    if (!continueDelete) return;
    await this.roomBookService.deleteSingleArea(this.roomBookEntry, this.measurement, false);
    this.routingService.routeBack(true);
  }

  private async loadData(): Promise<{ dimensionChainId: string; currentRoomBookEntries: RoomBook[] }> {
    const measurementUuid = this.routingService.getRouteParam('uuid');
    this.measurement = await this.measurementService.findOneBy('Uuid', measurementUuid);
    const roomId = this.routingService.getRouteParam('roomId');
    const roomBookPositions = this.measurement.getRoomBookPosition();
    this.room = roomBookPositions.find(position => position.Uuid === roomId);
    const dimensionChainId = this.routingService.getRouteParam('massketteId');
    const currentRoomBookEntries = await this.roomBookService.getAllRoomBooksByPosition(this.room, this.measurement);
    this.roomNames = await this.roomNameService.getAllAreaDesignations();
    return { dimensionChainId, currentRoomBookEntries };
  }

  /**@description Depending on whether it is a new or existing entry, make the presetting */
  private handleNewOrExisting(dimensionChainId: string, currentRoomBookEntries: RoomBook[]): void {
    this.currentRoomBookEntries = currentRoomBookEntries;
    if (dimensionChainId === 'neu') {
      this.roomBookEntry = new RoomBook(null, this.room);
      this.roomBookEntry.createNewFromScratch(currentRoomBookEntries, this.room);
    } else {
      this.roomBookEntry = new RoomBook(
        this.roomBookService.findOneRoomBookBy(this.measurement, 'Uuid', dimensionChainId)
      );
      if (this.roomBookEntry.Aufmass?.includes('*')) this.parseDimensions(this.roomBookEntry.Aufmass);
    }
    this.inputDone = !GlobalHelper.isNullOrUndefinedOrEmptyString(this.roomBookEntry.Aufmass);
    this.bezugsFlaechen = currentRoomBookEntries.filter(area => !area.IsAbzug && area.Uuid !== this.roomBookEntry.Uuid);
    if (this.roomBookEntry.IsAbzug)
      this.selectedBezug = this.bezugsFlaechen.find(area => area.Lineid === this.roomBookEntry.Lineid);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { ServiceOrderState } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { ServiceOrderNotMainEmployee, beginImpossible } from 'apps/handwerkPWA/src/app/config/TextKonstanten';
import { HWAddress, HWAnlage, ServiceAuftrag } from 'apps/handwerkPWA/src/app/entities';
import { ActivityTrackerService } from 'apps/handwerkPWA/src/app/services/dataServices/activityTracker.service';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { MaintenanceSystemService } from 'apps/handwerkPWA/src/app/services/dataServices/maintenanceSystem.service';
import { ServiceOrderService } from 'apps/handwerkPWA/src/app/services/dataServices/serviceOrder.service';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import { MediaService } from 'apps/handwerkPWA/src/app/services/globalServices/media.service';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-service-order-edit',
  templateUrl: './service-order-edit.component.html',
  styleUrls: ['./service-order-edit.component.scss'],
})
export class ServiceOrderEditComponent implements OnInit, OnDestroy {
  serviceOrder: ServiceAuftrag;
  maintenanceSystem: HWAnlage;
  positionCount = 0;
  showPreview = true;
  userInfo: UserInfo;
  selectableEmployees: HWAddress[];
  currentFilesAmount: string;
  currentPositionAmount: string;
  customer: HWAddress;
  allEmployees: HWAddress[];
  selectedEmployees: HWAddress[];
  stateText: string;
  betreff: string;
  firstEmployee: BehaviorSubject<HWAddress> = new BehaviorSubject<HWAddress>(null);
  secondEmployee: BehaviorSubject<HWAddress> = new BehaviorSubject<HWAddress>(null);
  isMainEmployee: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  serviceOrderStates = ServiceOrderState;
  canBeginServiceOrder: boolean;

  constructor(
    private globalSettingService: HWGlobalSettingService,
    private maintenanceSystemService: MaintenanceSystemService,
    private serviceOrderService: ServiceOrderService,
    private dialogService: DialogService,
    private routingService: RoutingService,
    private addressService: AddressService,
    private activityTrackerService: ActivityTrackerService,
    private mediaService: MediaService
  ) {}

  async ngOnInit(): Promise<void> {
    const orderId = this.routingService.getRouteParam('uuid');
    this.serviceOrder = await this.serviceOrderService.findOneBy('UUID', orderId);
    this.betreff = this.serviceOrder.getBetreff();
    this.selectedEmployees = this.serviceOrder.getZusatzMonteure();

    this.userInfo = await this.globalSettingService.getUserInfo();

    this.maintenanceSystem = await this.maintenanceSystemService.findOneAnlageBy('ANLAGE', this.serviceOrder.Anlage);
    const workSpecification = this.maintenanceSystem?.ABESCHR;
    this.positionCount = this.serviceOrder
      .getPositions()
      ?.filter(position => position.isRechnungsposten(false, workSpecification))?.length;

    const assignedEmployees = this.serviceOrder.getAllMonteureInOrder();
    const assignedEmployeeNumbers = assignedEmployees?.flatMap(employee => employee.KU_NR);

    await this.assignSelectableEmployees(assignedEmployeeNumbers);

    if (assignedEmployeeNumbers?.length === 0)
      await this.tryAcceptingOrder(this.serviceOrder, this.userInfo, this.selectableEmployees);
    await this.getFileAmount(this.maintenanceSystem);
    this.currentPositionAmount = GlobalHelper.calculateDataAmount(
      this.serviceOrder.getPositions()?.filter(position => position.isRechnungsposten(false, workSpecification))
    );
    this.customer = this.maintenanceSystem.Anlagendaten.Kundenobjekt;

    this.isMainEmployee.next(this.serviceOrder.isUserMainEmployee(this.userInfo));

    this.stateText = this.serviceOrder.getStatusText();

    this.canBeginServiceOrder = this.checkRights(true);
  }
  async ngOnDestroy(): Promise<void> {
    if (this.serviceOrder.edited)
      this.serviceOrder = await this.serviceOrderService.sendServiceOrderToWebService(this.serviceOrder, false);
  }

  async tryAcceptingOrder(serviceOrder: ServiceAuftrag, userInfo: UserInfo, employees: HWAddress[]): Promise<void> {
    const wantToAccept = await this.dialogService.openConfirmDialog(
      'Serviceauftrag',
      'Dieser Serviceauftrag ist Ihnen noch nicht zugeordnet. Möchten Sie den Serviceauftrag übernehmen?',
      'Übernehmen',
      'Nicht übernehmen',
      false
    );

    if (!wantToAccept) {
      this.routingService.routeBack();
      return;
    }

    await this.activityTrackerService.countUpAutoSyncs();

    const returnedOrder = await this.serviceOrderService.acceptServiceOrder(serviceOrder, employees, userInfo);
    if (returnedOrder) {
      this.routingService.reload();
      return;
    }

    // accepted and returned a value
    const errorMessage = 'Der Serviceauftrag wurde bereits einem Monteur zugeordnet.';
    await this.dialogService.openErrorMessage('Serviceauftrag', errorMessage);
    this.routingService.routeBack();
  }

  updateEmployeeList(currentEmployees: HWAddress[]): void {
    this.serviceOrder.updateAdditionalEmployeeList(currentEmployees);
    this.serviceOrder.edited = true;
    this.routingService.dataChanged.next(false);
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  finishOrder(): void {
    if (!this.checkRights(false)) return;
    const path = `auftragsabschluss/wartungsauftrag/${this.serviceOrder.UUID}`;
    void this.navigateTo(path);
  }

  goToPositions(): void {
    if (!this.checkRights(false)) return;
    const path = `auftragpositionen/wartungsauftrag/${this.serviceOrder.UUID}/hinzufuegen`;
    void this.navigateTo(path);
  }

  checkRights(silent: boolean): boolean {
    return this.serviceOrderService.hasEditRight(this.serviceOrder, this.userInfo, silent);
  }

  /**
   * @description Attempts to accept the order. In case of success (user has confirmed, order comes back successfully accepted)
   * the page is reloaded. Otherwise, routed back
   */
  async tryBegin(): Promise<void> {
    if (!this.serviceOrder.isUserMainEmployee(this.userInfo))
      void this.dialogService.openErrorMessage(beginImpossible, ServiceOrderNotMainEmployee);
    const confirmation = await this.dialogService.openConfirmDialog(
      'Auftragsbeginn',
      'Auftrag jetzt beginnen?',
      'Ok',
      'Abbruch',
      false
    );
    if (!confirmation) return;
    this.serviceOrder.setOrderStatus(ServiceOrderState.InProgress);
    await this.activityTrackerService.countUpAutoSyncs();
    this.serviceOrder = await this.serviceOrderService.sendServiceOrderToWebService(this.serviceOrder, false);
    const success = !this.serviceOrder.edited;
    if (success) {
      this.routingService.reload();
    } else {
      await this.dialogService.openErrorMessage(
        'Serviceauftrag',
        'Der Serviceauftrag wurde nicht erfolgreich begonnen.'
      );
      this.routingService.routeBack();
    }
  }

  public async reloadWithSaving(): Promise<void> {
    await this.activityTrackerService.countUpAutoSyncs();
    this.serviceOrder = await this.serviceOrderService.sendServiceOrderToWebService(this.serviceOrder, true);
    this.routingService.reload();
  }

  async selectSecondEmployee(selectedEmployee: HWAddress): Promise<void> {
    this.serviceOrder.Monteur2 = selectedEmployee.KU_NR;
    await this.reloadWithSaving();
  }

  /**@description Takes the customer numbers of already assigned Employees and sets the selectable additional Employees */
  private async assignSelectableEmployees(assignedEmployeeNumbers: string[]): Promise<void> {
    // Get all Employees
    this.allEmployees = await this.addressService.getAllBy('ADRTYP', 'M');
    // Filter out terminated Employees
    this.allEmployees = this.allEmployees.filter(
      employee => employee.LeaveDate == null || employee.LeaveDate >= new Date()
    );
    // Filter out already assigned Employees
    this.selectableEmployees = this.allEmployees.filter(employee => !assignedEmployeeNumbers.includes(employee.KU_NR));
    const firstEmployee = this.allEmployees.find(employee => employee.KU_NR === this.serviceOrder.Monteur1);
    this.firstEmployee.next(firstEmployee);
    const secondEmployee = this.allEmployees.find(employee => employee.KU_NR === this.serviceOrder.Monteur2);
    this.secondEmployee.next(secondEmployee);
  }

  private async getFileAmount(anlage: HWAnlage): Promise<void> {
    const files = await this.mediaService.getAllBy('Baseuuid', anlage.UUID);
    this.currentFilesAmount = GlobalHelper.calculateDataAmount(files);
  }
}

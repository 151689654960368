export class AuthorizationHelper {
  /**@description Takes a point-separated version and gives back the appropriate decimal representation */
  static versionStringToDecimalVersion(versionString: string): number {
    const numberArray = versionString.split('.');
    let currentPower = numberArray.length - 1;
    let versionValue = 0;

    for (const numberString of numberArray) {
      const currentValue = parseInt(numberString, 10);
      const currentDecimalValue = currentValue * Math.pow(10, currentPower);
      versionValue = versionValue + currentDecimalValue;
      currentPower = currentPower - 1;
    }

    return versionValue;
  }
}

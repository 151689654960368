import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';

export class Choice<Type> {
  name: string;
  value: Type;
  disabled: boolean;

  constructor(name: string, value: Type, disabled = false) {
    this.name = name;
    this.value = value;
    this.disabled = disabled;
  }
}

@Component({
  selector: 'app-bss-radiobutton',
  templateUrl: './bss-radiobutton.component.html',
  styleUrls: ['./bss-radiobutton.component.scss'],
})
export class BssRadiobuttonComponent<Type> implements AfterViewInit {
  @Input() label: string;
  @Input() choices: Choice<Type>[];
  @Input() currentValue: string;
  @Input() useOuterMargin = true;
  @Output() selection = new EventEmitter<Type>();
  initialClick = true;

  ngAfterViewInit(): void {
    this.selectCurrentValue();
  }
  selectionChange(choice: Choice<Type>): void {
    if (this.initialClick) {
      this.initialClick = false;
      return;
    }
    this.selection.emit(choice.value);
  }

  /**@description Klickt den Defaultwert an - wenn keiner mitgegeben wird die erste nicht disabled-Wahl getroffen */
  private selectCurrentValue(): void {
    let defaultId = this.choices?.find(choice => choice.value === this.currentValue)?.name;
    if (GlobalHelper.isNullOrUndefined(defaultId))
      defaultId = this.choices.find(choice => choice.disabled !== true)?.name;
    const defaultElement = document.getElementById(defaultId);
    defaultElement.click();
  }
}

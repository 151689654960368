import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { Choice } from 'apps/handwerkPWA/src/app/components/form-items/bss-radiobutton/bss-radiobutton.component';
import {
  GlobalSettings,
  RepairOrderStates,
  StuecklistenGlobalPrintSetting,
  deDateFormat,
} from 'apps/handwerkPWA/src/app/config/Konstanten';
import {
  Base64Pdf,
  HWAddress,
  HWMonteur,
  HWRepairOrder,
  ServiceAuftrag,
  Steuer,
} from 'apps/handwerkPWA/src/app/entities';
import { BaseAuftrag, BaseDocumentPosition } from 'apps/handwerkPWA/src/app/interfaces';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { MonteurService } from 'apps/handwerkPWA/src/app/services/dataServices/monteur.service';
import { RepairOrderService } from 'apps/handwerkPWA/src/app/services/dataServices/repairOrder.service';
import { ServiceOrderService } from 'apps/handwerkPWA/src/app/services/dataServices/serviceOrder.service';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import jsPDF from 'jspdf';
import { Right, Setting, UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { MailService } from 'libs/shared/src/lib/services/mail.service';
import { PrintService } from 'libs/shared/src/lib/services/print.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { editImpossible, noEditServiceOrderReason } from '../../config/TextKonstanten';
import { positionsSearch } from '../../config/search-constants';
import { ActivityTrackerService } from '../../services/dataServices/activityTracker.service';
import { NachrichtenService } from '../../services/dataServices/nachrichten.service';

@Component({
  selector: 'app-repair-order-finalize',
  templateUrl: './order-finalize.component.html',
  styleUrls: ['./order-finalize.component.scss'],
})
export class OrderFinalizeComponent implements OnInit, OnDestroy {
  itemContainer = 'itemContainerPrices';
  itemSums: { CompleteSum: string; TaxedSum: string; NettoSum: string };
  showUnterschrift = false;
  order: BaseAuftrag;
  saveSubscription: Subscription;
  userInfo: UserInfo;
  zahlungsArten: Choice<number>[];
  rights: Right;
  doSendMail: boolean;
  showPreview: boolean;
  printPrices: boolean;
  stuecklistSettings = [
    new Choice('An', StuecklistenGlobalPrintSetting.On),
    new Choice('Aus', StuecklistenGlobalPrintSetting.Off),
    new Choice('Individuell', StuecklistenGlobalPrintSetting.Individual),
  ];
  selectedStuecklistenSetting: string;
  stuecklistenSubscription: Subscription;
  isRepairOrder = false;
  displayPositions: BaseDocumentPosition[];
  showPrices: boolean;
  employee: HWMonteur;
  viewFinished = false;
  allowPricesShow: boolean;
  mailAddress: string;
  customer: HWAddress;
  allAddresses: HWAddress[];
  searchExpressions = positionsSearch;

  constructor(
    private globalSettingService: HWGlobalSettingService,
    private routingService: RoutingService,
    private printService: PrintService,
    private addressService: AddressService,
    private repairOrderService: RepairOrderService,
    private employeeService: MonteurService,
    private dialogService: DialogService,
    private rightsService: RightsService,
    private mailService: MailService,
    private serviceOrderService: ServiceOrderService,
    private nachrichtenService: NachrichtenService,
    private activityTrackerService: ActivityTrackerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.registerSaveButton();
    this.userInfo = await this.globalSettingService.getUserInfo();
    this.employee = await this.employeeService.getMonteurFromIDB();
    const steuern = this.employee.Steuern;
    const isEditable = await this.loadOrder(this.userInfo);
    this.allowPricesShow = this.rightsService.getCurrentRight().employeeSettings.showPrices;
    if (!isEditable) {
      await this.dialogService.openErrorMessage(editImpossible, noEditServiceOrderReason);
      this.routingService.routeBack();
      return;
    }
    this.handleRights(this.order, steuern);
    this.displayPositions = this.order.getDisplayPositions();
    if (this.order instanceof HWRepairOrder) this.addEndOfArbeitsZeitAndCalculateAutolohn(this.order);
    if (this.order instanceof ServiceAuftrag) this.getStuecklistenSettings(this.order);
    this.allAddresses = await this.addressService.getAll();
    this.customer = this.getCustomerFromOrderNumber();
    this.mailAddress = this.customer?.GLOBEMAIL;
    this.stuecklistenSubscription = this.serviceOrderService.stuecklistenChange.subscribe(next => {
      this.getStuecklistenSettings(next);
    });
    this.viewFinished = true;
  }

  public getCustomerFromOrderNumber(): HWAddress {
    const customerNumber = this.order.getKundennummer();
    return this.allAddresses.find(address => address.KU_NR === customerNumber);
  }

  async loadOrder(userInfo: UserInfo): Promise<boolean> {
    const orderType = this.routingService.getRouteParam('type');
    const orderId = this.routingService.getRouteParam('id');

    if (orderType === 'reparaturauftrag') {
      this.order = await this.repairOrderService.findOneBy('Guid', orderId);
    } else if (orderType === 'wartungsauftrag') {
      this.order = await this.serviceOrderService.findOneBy('UUID', orderId);
    }
    return this.order.isEditable(userInfo);
  }

  /**
   * @description Reagiert auf die Schalterstellung. Wird Schalter auf "AN" gestellt, werden alle bisher nicht angezeigten stücklisten getoggelt. Analog bei aus
   * Anschließend wird die neue Einstellung ans Handwerk übertragen
   */
  async changeStuecklistenSettings(stuecklistenChoiceName: StuecklistenGlobalPrintSetting): Promise<void> {
    const order = this.order as ServiceAuftrag;
    if (stuecklistenChoiceName === 'Individuell') return;
    order.setAllStuecklistenPrintsettings(stuecklistenChoiceName);
    await this.serviceOrderService.sendServiceOrderToWebService(order, false);
    this.displayPositions = this.order.getDisplayPositions();
  }

  /**
   * @description Guckt, ob die Anzahl der Arbeitszeiten gerade ist. Ist dies der Fall, hat der Nutzer wohl die Sicht verlassen ohne abzuschließen
   * . In diesem Fall muss die Endzeit wieder aus den Auftragszeiten herausgerechnet werden.
   */
  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
    this.stuecklistenSubscription?.unsubscribe();
    if (GlobalHelper.isNullOrUndefined(this.order) || this.order.auftragsArt !== 'Reparaturauftrag') {
      return;
    }
    const repairOder = this.order as HWRepairOrder;
    const workingTimes = repairOder.Arbeitszeiten;
    if (workingTimes.length < 0) {
      return;
    }
    const amount = workingTimes.length;
    if (amount % 2 === 0) {
      repairOder.Arbeitszeiten.pop();
    }
  }

  async finalizeOrder(order: BaseAuftrag, base64Unterschrift: Base64Pdf, sendEmail: boolean): Promise<void> {
    const settings = await this.globalSettingService.getEntity<Setting>(GlobalSettings.Settings);
    const rights = this.rightsService.getCurrentRight();
    const allEmployees = this.allAddresses.filter(address => address.ADRTYP === 'M');
    await this.tryOrderFinalize(order, base64Unterschrift, this.customer, settings, rights, sendEmail, allEmployees);
  }

  private async saveArbeitsbericht(nextRoute: string): Promise<void> {
    if (!(this.order instanceof HWRepairOrder)) return;
    await this.repairOrderService.sendRepairOrderToWebService(this.order, true);
    await this.routingService.navigateTo(nextRoute);
    this.dialogService.closeLoadingDialog();
  }

  private registerSaveButton(): void {
    this.saveSubscription = this.routingService.save.subscribe((nextRoute: string) => {
      void this.saveArbeitsbericht(nextRoute);
    });
  }

  /**
   * @description
   * Ließt aus dem Serviceauftrag die Stuecklisten settings der Leistung und belegt so den Switch korrekt (wird auch jedes mal angestoßen,wenn in einer individuellen Dokumentkarte
   * auf das Ändern der Stücklisten geklickt wird) */
  private getStuecklistenSettings(changeFromBehaviorSubject: ServiceAuftrag): void {
    if (changeFromBehaviorSubject.objectType !== 'ServiceAuftrag') return;
    const positions = changeFromBehaviorSubject.getPositions();
    const leistungen = positions.filter(pos => pos.getLongtype() === 'Leistung');
    if (leistungen?.length === 0) return;
    const allOn = leistungen.every(leistung => leistung.getPrintPartList() === true);
    const allOff = leistungen.every(leistung => leistung.getPrintPartList() === false);
    if (allOn) {
      this.selectedStuecklistenSetting = this.stuecklistSettings[0].value;
      return;
    }
    if (allOff) {
      this.selectedStuecklistenSetting = this.stuecklistSettings[1].value;
      return;
    }
    this.selectedStuecklistenSetting = this.stuecklistSettings[2].value as string;
  }

  private handleRights(order: BaseAuftrag, steuern: Steuer[]): void {
    this.rights = this.rightsService.getCurrentRight();
    const rights = this.rights;
    if (order instanceof HWRepairOrder && order.PreiseAnzeigen === false) {
      this.itemContainer = 'itemContainer';
    }
    // const ecDisabled = !rights.employeesettings.sumUp;
    const barDisabled = !rights.employeeRights.repairOrderRights.allowCashPayment;
    this.zahlungsArten = [new Choice('Rechnung', 0), new Choice('EC', 1, false), new Choice('Bar', 2, barDisabled)];
    this.isRepairOrder = this.order instanceof HWRepairOrder ? true : false;
    this.showPreview = rights.employeeSettings.pdfPreview ? true : false;
    this.doSendMail = rights.employeeSettings.sendOrderMail ? true : false;
    this.printPrices = rights.employeeSettings.printPrices ? true : false;
    this.itemSums = this.order.calculateItemSums(steuern);
    const showPricesRight = this.rightsService.getCurrentRight().employeeSettings.showPrices;
    this.showPrices = order.getShowPrices(showPricesRight);
  }

  /**@description Versucht den auftragsabschluss - ob dieser zumindest offline erfolgen kann,hängt vom druck ab */
  private async tryOrderFinalize(
    order: BaseAuftrag,
    base64Unterschrift: Base64Pdf,
    customer: HWAddress,
    settings: Setting,
    rights: Right,
    sendEmail: boolean,
    allEmployees: HWAddress[]
  ): Promise<void> {
    const orderType = order instanceof ServiceAuftrag ? 'Serviceauftrag' : 'Reparaturauftrag';
    if (order instanceof HWRepairOrder) {
      if (order.Bezahlung === 1) this.callSumUp();
      order.ArbeitDat = moment(new Date()).format(deDateFormat);
    }
    void this.dialogService.openLoadingDialog('Auftragsabschluss', `Der Abschluss des ${orderType} wird durchgeführt.`);
    const printResult = this.getPrintResult(order, base64Unterschrift, customer, settings, rights, allEmployees);
    if (GlobalHelper.isNullOrUndefined(printResult?.DataUri)) return;
    order.assignPdfDatauri(printResult.DataUri, base64Unterschrift.data);
    await this.activityTrackerService.countUpAutoSyncs();
    if (order instanceof ServiceAuftrag) await this.serviceOrderService.finalizeOrder(order, false);
    if (order instanceof HWRepairOrder) {
      order.Status = RepairOrderStates.Completed;
      await this.repairOrderService.sendRepairOrderToWebService(order, true);
    }
    if (sendEmail) {
      const successEmailData = await this.mailService.sendOrderMail(order, printResult.DataUri, this.mailAddress);
      await this.nachrichtenService.writeEmailToIDB(successEmailData);
    }

    if (this.showPreview) this.printService.savePDF(printResult.Doc, order.getOrderNumber());
    this.routingService.dataChanged.next(false);
    this.dialogService.closeLoadingDialog();
    await this.routingService.routeBackAfterOrderFinish(order);
  }

  /**@description Versucht den eigentlichen "Druck" des PDFs durchzuführen */
  private getPrintResult(
    order: BaseAuftrag,
    base64Unterschrift: Base64Pdf,
    customer: HWAddress,
    settings: Setting,
    rights: Right,
    allEmployees: HWAddress[]
  ): { DataUri: string; Doc: jsPDF } {
    let printResult: { DataUri: string; Doc: jsPDF };
    try {
      printResult = this.printService.printOrderCompletion(
        order,
        base64Unterschrift,
        this.printPrices,
        this.employee,
        settings,
        rights,
        customer,
        allEmployees
      );
      return printResult;
    } catch (err) {
      this.dialogService.closeLoadingDialog();
      void this.dialogService.openErrorMessage(
        'Auftragsabschluss',
        'Der Auftragsabschluss konnte nicht durchgeführt werden, da die zugehörige PDF nicht erstellt werden konnte.'
      );
      throw new Error(err);
    }
  }

  private addEndOfArbeitsZeitAndCalculateAutolohn(order: HWRepairOrder): void {
    order.addArbeitszeit();
    if (order.Autolohn.isActive) order.setAutolohnEndtimes();
  }

  /**@description Ruft die Sumup-Api auf (mobiles Bezahlsystem) */
  private callSumUp(): void {
    const sum = this.itemSums.CompleteSum.replace(',', '.');
    const sumUpPrefix =
      'sumupmerchant://pay/1.0?affiliate-key=95ede6f3-d53a-48be-8436-705076f8e213&app-id=de.bluesolution.TopAppPWA&amount=';
    window.open(
      sumUpPrefix + sum + '&currency=EUR&title=' + this.order.getOrderNumber() + '&callback=topapppwa://result'
    );
  }
}

<dx-popup
  [width]="'95%'"
  [maxWidth]="'500px'"
  [title]="InputFile.getOriginalName()"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="showPopup"
  (onHidden)="closePopup()"
>
  <div *dxTemplate="let data of 'content'">
    <button class="bss-button primary-button fullWidth" (click)="openInFullscreen(InputFile)">
      In neuem Tab öffnen
    </button>
    <dx-scroll-view height="90%">
      <br />
      <br /><br />

      <img [src]="InputFile.Data" />

      <div *ngIf="description">
        <br /><br />
        <app-bss-textarea [labelText]="'Beschreibung'" [value]="description"></app-bss-textarea>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<h2 class="content-block">Raumaufmaß</h2>

<div class="content-block bottom-padding">
  <app-bss-list
    [id]="sliderNames.roomMeasurements"
    [inputDataSource]="measurements"
    [emptyInputDataMessage]="'Es sind keine Raumaufmaße vorhanden'"
    [searchExpressions]="searchExpression"
  >
  </app-bss-list>
</div>

<app-bss-speed-dial
  icon="add"
  class="add-button"
  label="Neues Raumaufmaß"
  (Click)="createMeasurement()"
></app-bss-speed-dial>

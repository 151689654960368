import { Injectable } from '@angular/core';
import CryptoES from 'crypto-es';
const secret = 'bsssmpt';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  encrypt(input: string): string {
    return CryptoES.AES.encrypt(input, secret).toString();
  }

  decrypt(input: string): string {
    return CryptoES.AES.decrypt(input, secret).toString(CryptoES.enc.Utf8);
  }
}

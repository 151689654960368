import { Injectable } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { AutolohnInformation, HWAddress, HWRepairOrder, HWTermin } from 'apps/handwerkPWA/src/app/entities';
import { RepairOrderItemHelper } from 'apps/handwerkPWA/src/app/helper/services/repairOrderHelper';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { TimeHelper } from 'libs/shared/src/lib/helper/timeHelper';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { RepairOrderStates } from '../../config/Konstanten';
import { SyncObject } from '../../entities/models/SyncObject';
import { Medien } from '../../entities/repository/Medien';
import { ConnectionDialogues, ConnectionService } from '../globalServices/connection.service';
import { ControllerService } from '../globalServices/controller.service';
import { AddressService } from './address.service';
import { AppointmentService } from './appointment.service';
import { BaseService } from './base.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class RepairOrderService implements DataService {
  serviceName = 'ServiceOrderService';
  constructor(
    private controllerService: ControllerService,
    private appointmentService: AppointmentService,
    private dialogService: DialogService,
    private restService: RestService,
    private rightsService: RightsService,
    private connectionService: ConnectionService,
    private routingService: RoutingService,
    private baseService: BaseService,
    private addressService: AddressService,
  ) {}

  async findOneBy(selector: string, value: string): Promise<HWRepairOrder> {
    return await this.baseService.findOneBy(HWRepairOrder, selector, value);
  }

  async synchronize(userInfo: UserInfo, silent: boolean): Promise<void> {
    await this.pushToWebService(userInfo, silent);
    await this.getFromWebService(userInfo, silent);
  }

  async acceptRepairOrder(repairOrder: HWRepairOrder, userInfo: UserInfo): Promise<HWRepairOrder> {
    repairOrder.Monteur1 = userInfo.monteur;
    void this.dialogService.openLoadingDialog('Zuordnung', '...Versuche Reparaturauftrag zuzuordnen...');
    const targetUrl = 'AcceptRepairOrder';
    const newOrderData = await this.restService.returnData<HWRepairOrder>(targetUrl, repairOrder, true);
    const returnedRepairOrder = new HWRepairOrder(newOrderData, repairOrder.Kunde);
    this.dialogService.closeLoadingDialog();
    // checks if it was saved successfully
    if (returnedRepairOrder.Monteur1 === '') return null;

    // save the new values in  the IDB
    await this.updateRepairOrderInIDB(returnedRepairOrder, returnedRepairOrder.TerminId);
    const appointment = await this.appointmentService.findOneBy('id', returnedRepairOrder.TerminId);
    const employee = await this.addressService.findOneBy('KU_NR', repairOrder.Monteur1);
    appointment.mitarbeiter = returnedRepairOrder.Monteur1;
    appointment.MA_ID_LIST = returnedRepairOrder.Monteur1;
    appointment.mitarbeiterName = employee.NAME;
    await this.appointmentService.updateInIDB(appointment);

    return returnedRepairOrder;
  }

  /**@description Overwrites the entry in the IDB*/
  public async updateRepairOrderInIDB(repairOrder: HWRepairOrder, oldAppointmentId: string): Promise<void> {
    if (oldAppointmentId) await this.appointmentService.updateOrderAppointment(repairOrder);
    else await this.appointmentService.createLocalAppointmentForRepairOrder(repairOrder, repairOrder.getKunde());

    await this.deleteOrderInIDB(repairOrder);
    await this.controllerService.setData('HWRepairOrder', [repairOrder]);
  }

  /**@description Gets a repairOrder based on the orderNumber */
  async getOrderByOrderNumber(orderNumber: string): Promise<HWRepairOrder> {
    const repairOrders = await this.getAllRepairOrdersFromIDB(true);
    const repairOrder = repairOrders.find(order => order.Nummer === orderNumber);
    return repairOrder;
  }

  /**
   * @description Get all repair orders from the IDB that are not completed.
   * @param giveAll If set to true, also gives back completed orders
   */
  async getAllRepairOrdersFromIDB(giveAll?: boolean): Promise<HWRepairOrder[]> {
    const allRepairOrders = await this.baseService.getAll(HWRepairOrder);
    const outputRepairOrder: HWRepairOrder[] = [];
    for (const repairOrder of allRepairOrders) {
      const notFinished =
        repairOrder.Status !== RepairOrderStates.Completed && repairOrder.Status !== RepairOrderStates.Done;
      const isValid = giveAll || notFinished;
      if (isValid) outputRepairOrder.push(repairOrder);
    }
    return RepairOrderItemHelper.sortDateSortableArray(outputRepairOrder);
  }

  async sendRepairOrderToWebService(repairOrder: HWRepairOrder, silent = false): Promise<HWRepairOrder> {
    //Checks if user is online and saves changes to push them later
    const isOnline = await this.connectionService.checkOnline(ConnectionDialogues.PushData);
    if (!isOnline) {
      repairOrder.edited = true;
      await this.updateRepairOrderInIDB(repairOrder, repairOrder.TerminId);
      return null;
    }
    if (!silent)
      void this.dialogService.openLoadingDialog('Reparaturauftrag', '...speichere Änderungen am Reparaturauftrag...');
    repairOrder.addWorkingTimeInformation();
    repairOrder.Termin = repairOrder?.Termin?.substring(0, 16);
    const autoWagePositions = this.extractAutoWageInformation([repairOrder]);
    const positions = repairOrder.getPositions();
    positions.forEach(position =>
      GlobalHelper.isNullOrUndefined(position.TkID) ? (position.TkID = -1) : position.TkID,
    ); // Enthält keine TKID , dann muss -1 zum neu erzeugen egsetzt werden
    const response = await this.restService.returnData<HWRepairOrder>('SaveAuftraege', [repairOrder], silent); // expects an Array
    const savedOrder = await this.handleSaveResponse(response, repairOrder, autoWagePositions);
    this.dialogService.closeLoadingDialog();
    return savedOrder;
  }

  async addRepairOrder(repairOrder: HWRepairOrder, silent = false): Promise<HWRepairOrder> {
    const response = await this.restService.returnData<HWRepairOrder>('AddAuftrag', repairOrder, silent);
    if (GlobalHelper.isNullOrUndefined(response)) {
      if (silent) return null;
      await this.dialogService.openConfirmDialog(
        'Fehler',
        'Der Auftrag konnte nicht angelegt werden. Um Aufträge anzulegen wird eine Verbindung zum Webservice benötigt',
        'Ok',
        null,
        false,
        null,
      );
      return null;
    }
    response.Arbeitszeiten = repairOrder.Arbeitszeiten;
    const createdOrder = new HWRepairOrder(response, repairOrder.Kunde);
    await this.updateRepairOrderInIDB(createdOrder, null);
    return createdOrder;
  }

  getEndTimeDate(appointment: Date, repairOrder: HWRepairOrder): Date {
    const endTimeDate = new Date(appointment);
    const split = repairOrder.Endzeit?.split(':');
    if (split?.length !== 2) return null;
    endTimeDate.setHours(parseInt(split[0], 10), parseInt(split[1], 10));
    if (endTimeDate < appointment) {
      repairOrder.Endzeit = `${TimeHelper.padTime(appointment.getHours())}:${TimeHelper.padTime(
        appointment.getMinutes(),
      )}`;
      return appointment;
    }
    return endTimeDate;
  }

  /**
   * @description Überträgt zuerst nicht übertragen items ans handwerk, dann nimmt man die liste der nun zugeordneten und übertragenen items
   * und weißt sie der ursprünglichen liste der nicht übertragenen idbRepairOrders zu um sie dann abzuschließen
   */
  async pushToWebService(userInfo: UserInfo, silent: boolean): Promise<void> {
    const iDBRepairOrders = await this.getAllRepairOrdersFromIDB(true);
    const untransferredOrders = iDBRepairOrders?.filter(order => order.edited);
    const response = [];
    for (const order of untransferredOrders) {
      response.push(this.sendRepairOrderToWebService(order, silent));
    }
    await Promise.all(response);
  }

  async getFromWebService(userInfo: UserInfo, silent = true): Promise<void> {
    if (!silent) {
      void this.dialogService.openLoadingDialog('Synchronisation', '...hole Aufträge...');
    }
    await this.pushToWebService(userInfo, silent);
    const url = 'RepAuftraege/mandant/' + userInfo.mandant + '/username/' + userInfo.monteur;
    const repairOrderData = await this.restService.returnData<HWRepairOrder[]>(url, null, !silent);
    if (GlobalHelper.isNullOrUndefined(repairOrderData)) {
      return null;
    }
    const repairOrders: HWRepairOrder[] = [];
    const right = this.rightsService.getCurrentRight();
    const addresses = await this.addressService.getAll();
    for (const order of repairOrderData) {
      const orderCustomer = addresses.find(customer => customer.KU_NR === order.KundenNummer);
      const repairOrder = new HWRepairOrder(order, orderCustomer);
      if (repairOrder.Status === RepairOrderStates.Completed || repairOrder.Status === RepairOrderStates.Done) {
        continue;
      }
      repairOrder.PreiseAnzeigen = right.employeeSettings.showPrices;
      const containsSubLeistung = repairOrder
        ?.getPositions()
        ?.some(position => position.getLongtype() === 'Unterleistung');
      if (containsSubLeistung) {
        const subject = repairOrder.Betreff || '';
        await this.dialogService.openErrorMessage(
          'Mehrstufige Leistung',
          'Der Auftrag ' +
            subject +
            ' ' +
            repairOrder.Nummer +
            ' enthält mehrstufige Leistung(en) und kann in der my blue:app hand:werk zur Zeit leider nicht verarbeitet werden.',
        );
        continue;
      }
      repairOrder.assignLeistungsIds();
      repairOrders.push(repairOrder);
    }
    const iDBRepairOrders = await this.getAllRepairOrdersFromIDB();
    const autoWageInformation = this.extractAutoWageInformation(iDBRepairOrders);
    this.assignAutoWageInformation(repairOrders, autoWageInformation);
    await this.writeRepairOrderToIDB(repairOrders, true);
  }

  getRequiredObjects(): SyncObject[] {
    return [HWAddress, HWTermin, Medien];
  }

  sortRepairOrders(repairOrders: HWRepairOrder[]): HWRepairOrder[] {
    return repairOrders.sort((a, b) => {
      const partsA = a.Nummer.split('/');
      const partsB = b.Nummer.split('/');
      // Check the year
      const sortNumber = GlobalHelper.compareFunction(partsA[1], partsB[1], true);
      if (sortNumber !== 0) return sortNumber;
      // Checks the orderNumber
      return GlobalHelper.compareFunction(partsA[0], partsB[0], true);
    });
  }

  /**@description extracts the autoWage information from the repairOrders if they have autoWagePositions or if its active */
  extractAutoWageInformation(repairOrders: HWRepairOrder[]): AutolohnInformation[] {
    const autoWageInformationArr: AutolohnInformation[] = [];
    for (const order of repairOrders) {
      const autoWageActive =
        order.getPositions()?.some(position => position.isAutolohnActive === true) || order.Autolohn?.isActive;
      if (!autoWageActive) continue;
      const autoWagePositions = order.getPositions()?.filter(position => position.isAutolohnActive === true);
      const orderNumber = order.Nummer;
      const autoWage = order.Autolohn;
      const workTimes = order.Arbeitszeiten;
      const autoWageInformation = new AutolohnInformation(autoWage, orderNumber, autoWagePositions, workTimes);
      autoWageInformationArr.push(autoWageInformation);
    }
    return autoWageInformationArr;
  }

  /**@description Assigns autoWageInformation */
  assignAutoWageInformation(repairOrders: HWRepairOrder[], autoWageInformationArray: AutolohnInformation[]): void {
    for (const autoWageInformation of autoWageInformationArray) {
      const orderToAssignTo = repairOrders?.find(order => order.Nummer === autoWageInformation.Auftragsnummer);
      if (GlobalHelper.isNullOrUndefined(orderToAssignTo)) continue;
      orderToAssignTo.Autolohn = autoWageInformation.Autolohn;
      orderToAssignTo.Arbeitszeiten = autoWageInformation.Arbeitszeiten;
      for (const autoWagePosition of autoWageInformation.AutolohnPositions) {
        const positionInOrder = orderToAssignTo
          .getPositions()
          ?.find(position => position.UuidInAuftragspositionen === autoWagePosition.UuidInAuftragspositionen);
        if (positionInOrder) positionInOrder.isAutolohnActive = true;
      }
    }
  }

  /**@description checks if the save was a success and tries to save Data in case it wasn't successful */
  private async handleSaveResponse(
    response: HWRepairOrder,
    oldOrder: HWRepairOrder,
    autoWagePositions: AutolohnInformation[],
  ): Promise<HWRepairOrder> {
    // Error message from WebService
    const alreadyClosed = response?.Betreff === 'ZschonZabgeschlossenZ';
    if (alreadyClosed) {
      await this.dialogService.openErrorMessage(
        'Fehler',
        'Der Auftrag wurde bereits abgeschlossen. Eine Synchronisation ist nicht mehr möglich!',
      );
    }
    const rejectedSuccessfully = response?.Success && response?.Status === RepairOrderStates.Rejected;
    if (alreadyClosed || rejectedSuccessfully) {
      await this.deleteOrderInIDB(oldOrder);
      this.routingService.routeBack();
      return oldOrder;
    }

    const saveFailed = GlobalHelper.isNullOrUndefined(response) || !response?.Success;
    const finalizeFailed = saveFailed && oldOrder.Status === RepairOrderStates.Completed;
    if (finalizeFailed) oldOrder.edited = true;
    if (saveFailed) {
      const oldPositions = oldOrder.getPositions();
      for (const pos of oldPositions) pos.notUpdated = true;
      await this.updateRepairOrderInIDB(oldOrder, oldOrder.TerminId);
      return oldOrder;
    }
    const savedOrder = new HWRepairOrder(response, oldOrder.Kunde);
    savedOrder.PreiseAnzeigen = oldOrder.PreiseAnzeigen;
    savedOrder.Arbeitszeiten = oldOrder.Arbeitszeiten;
    savedOrder.assignLeistungsIds();
    this.assignAutoWageInformation([savedOrder], autoWagePositions);
    await this.updateRepairOrderInIDB(savedOrder, oldOrder.TerminId);
    return savedOrder;
  }

  private async deleteOrderInIDB(repairOrder: HWRepairOrder): Promise<void> {
    await this.baseService.destroy(HWRepairOrder, 'Guid', repairOrder.Guid);
  }

  /**@description Saves the repairOrder in the IDB*/
  private async writeRepairOrderToIDB(repairOrders: HWRepairOrder[], clear: boolean): Promise<void> {
    if (clear) {
      await this.controllerService.clearStore('HWRepairOrder');
    }

    await this.controllerService.setData('HWRepairOrder', repairOrders);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Anschrift, HWRepairOrder, HWTermin } from 'apps/handwerkPWA/src/app/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-repair-order-card',
  templateUrl: './repair-order-card.component.html',
  styleUrls: ['./repair-order-card.component.scss'],
})
export class RepairOrderCardComponent implements OnInit {
  @Input() repairOrder: HWRepairOrder;
  @Input() termin: HWTermin;
  @Input() allAuftraege: HWRepairOrder[];
  showContactBar = false;
  address: Anschrift;

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {
    if (!GlobalHelper.isNullOrUndefined(this.termin)) {
      this.repairOrder = this.setTerminAuftrag(this.termin, this.allAuftraege);
    }
    const arbeitsortAnschrift = new Anschrift(null, null, null);
    arbeitsortAnschrift.arbeitsortToAnschrift(this.repairOrder.ArbeitsOrt);
    this.repairOrder.Kunde.ArbeitsortAnschrift = arbeitsortAnschrift;
    this.address = arbeitsortAnschrift;
  }

  /**@description Wenn der Termin einen Zugehörigen Auftrag hat */
  setTerminAuftrag(termin: HWTermin, repairOrders: HWRepairOrder[]): HWRepairOrder {
    const terminRep = repairOrders.find(rep => rep.Nummer === termin.Referenz);
    const terminRepairOrder: HWRepairOrder = terminRep;
    return terminRepairOrder;
  }

  async goToRepairOrderDetail(): Promise<void> {
    await this.routingService.navigateTo('reparaturauftrag/' + this.repairOrder.Guid + '/edit');
  }

  toggleContactBar(): void {
    this.showContactBar = !this.showContactBar;
  }
}

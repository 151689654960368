import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValueChangedEvent } from 'devextreme/ui/switch';

@Component({
  selector: 'app-bss-switchbox',
  templateUrl: './bss-switchbox.component.html',
  styleUrls: ['./bss-switchbox.component.scss'],
})
export class BssSwitchboxComponent {
  @Input() labelText;
  @Input() disabled = false;
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<boolean>();
  @Output() disabledClick = new EventEmitter<void>();

  valueChanged(event: ValueChangedEvent): void {
    if (event.value === event.previousValue) return;
    this.valueChange.emit(this.value);
  }

  click(): void {
    if (this.disabled) this.disabledClick.emit();
  }
}

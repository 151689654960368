import { Injectable } from '@angular/core';
import { GlobalSettings } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import * as uuid from 'uuid';
import { Right, Role, UserInfo } from '../entities';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private restService: RestService, private globalSettingService: HWGlobalSettingService) {}

  /**@description gets the roles from the WebService and adds admin and restricted */
  async getAllRolesFromWebService(userInfo: UserInfo): Promise<Role[]> {
    const responseData = await this.restService.returnData<Role[]>('ReadHandwerkPWARolesNew', userInfo.mandant);

    let parsedRoles = responseData.map(role => {
      const right = new Right(role.right);
      return new Role(right, role.roleName);
    });
    parsedRoles = parsedRoles.concat(this.addAdminAndRestrictedRole());
    return parsedRoles;
  }

  /**@description Sends a new role to the WebService */
  async sendRoleToWebService(role: Role, userInfo: UserInfo): Promise<void> {
    delete role.right.employeeRights.showObjektadressen;
    const rightString = JSON.stringify(role.right);
    const sendRole = { roleName: role.roleName, Right: rightString, mandant: userInfo.mandant };
    await this.restService.returnData('WriteHandwerkPWARole', sendRole);
  }

  async deleteRole(role: Role, userInfo: UserInfo): Promise<void> {
    const rightString = JSON.stringify(role.right);
    const sendRole = { roleName: role.roleName, Right: rightString, mandant: userInfo.mandant };
    await this.restService.returnData('DeleteHandwerkPWARole', sendRole);
  }

  /**@description sends the userInfo to the webService which wants the role as a string  */
  async sendEmployeeRoleToWebService(userInfo: UserInfo): Promise<void> {
    delete userInfo.role.right.employeeRights.showObjektadressen;
    const sendEmployee = {
      mandant: userInfo.mandant,
      employeeNumber: userInfo.employeeNumber,
      employeeName: userInfo.employeeName,
      role: userInfo.role,
    };
    await this.restService.returnData('WriteHandwerkPWARightNew', sendEmployee);
  }

  async getEmployeesRoleFromWebService(userInfo: UserInfo, silent = false, currentRight?: Right): Promise<Role> {
    let employeeRoleData: Role;
    // userInfo.releaseVersion is only set on HandwerkPWA and not in Rights or Settings GUI
    if (!userInfo.Device.LicenceUuid && userInfo.releaseVersion) {
      // resets the licenses for this employee
      employeeRoleData = await this.restService.returnData<Role>('ReadHandwerkPWARightsNew', userInfo, silent);
      userInfo.Device.LicenceUuid = uuid.v4();
      await this.globalSettingService.setEntity(userInfo, GlobalSettings.UserInfo);
    }
    employeeRoleData = await this.restService.returnData<Role>('ReadHandwerkPWARightsNew', userInfo, silent);

    const rightData: Right = employeeRoleData?.right;
    const right = !employeeRoleData ? currentRight : new Right(rightData);
    const roleNameString = employeeRoleData?.roleName;
    const role = new Role(right, roleNameString);
    return role;
  }

  /**@description adds the two standard roles */
  private addAdminAndRestrictedRole(): Role[] {
    const roles: Role[] = [];

    let right = new Right(null);
    right.createSuperRight();
    roles.push(new Role(right, 'Administrator'));

    right = new Right(null);
    right.createRestrictedRight();
    roles.push(new Role(right, 'Restriktiert'));

    return roles;
  }
}

import { Injectable } from '@angular/core';
import { RoomName } from '../../entities';
import { ControllerService } from '../globalServices/controller.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class RoomNameService {
  constructor(private baseService: BaseService, private controllerService: ControllerService) {}

  async getAll(): Promise<RoomName[]> {
    return await this.baseService.getAll(RoomName);
  }

  async getAllBy(selector: string, value: string): Promise<RoomName[]> {
    return await this.baseService.getAllBy(RoomName, selector, value);
  }

  async findOneBy(selector: string, value: string): Promise<RoomName> {
    return await this.baseService.findOneBy(RoomName, selector, value);
  }

  async destroy(selector: string, value: string): Promise<void> {
    await this.baseService.destroy(RoomName, selector, value);
  }

  async overrideLocal(roomNames: RoomName[]): Promise<void> {
    await this.controllerService.clearStore(RoomName.toString());
    await this.controllerService.setData(RoomName.toString(), roomNames);
  }

  async getAllAreaDesignations(): Promise<RoomName[]> {
    return await this.baseService.getAllBy(RoomName, 'Typ', '0');
  }
}

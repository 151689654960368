import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { SourceOfFileList, SourceOfFileListString, invalidChars } from 'apps/handwerkPWA/src/app/config/Konstanten';
import {
  Aufmass,
  HWAddress,
  HWAnlage,
  HWFile,
  HWObjectAddress,
  HWRepairOrder,
  ServiceAuftrag,
} from 'apps/handwerkPWA/src/app/entities';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { ObjectaddressService } from 'apps/handwerkPWA/src/app/services/dataServices/objectAddress.service';
import { FeatureNames } from 'libs/shared/src/lib/entities/models/user/FeatureCheck';
import { GlobalHelper, HTMLInputEvent } from 'libs/shared/src/lib/helper/globalHelper';
import { AuthorizationService } from 'libs/shared/src/lib/services/authorization.service';
import { FileDownloadService } from 'libs/shared/src/lib/services/file-download.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';
import { RoomBookPosition } from '../../../entities/models/aufmass/RoomBookPosition';
import { Medien } from '../../../entities/repository/Medien';
import { ActivityTrackerService } from '../../../services/dataServices/activityTracker.service';
import { MaintenanceSystemService } from '../../../services/dataServices/maintenanceSystem.service';
import { MeasurementService } from '../../../services/dataServices/measurement.service';
import { RepairOrderService } from '../../../services/dataServices/repairOrder.service';
import { ServiceOrderService } from '../../../services/dataServices/serviceOrder.service';
import { DocumentService } from '../../../services/globalServices/document.service';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';
import { MediaService } from '../../../services/globalServices/media.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit, OnDestroy {
  @ViewChild('uploader') uploaderInput: ElementRef<HTMLInputElement>;
  selectedFile: HWFile | Medien;
  showImg: boolean;
  filesAdded = false;
  fileList: Array<Medien | HWFile> = [];
  saveMediumSubscription: Subscription;
  fileName: string;
  currentFile: File;
  invalidCharInFilename: boolean;
  /** NewVersion decided if the user is uploading a HWFile or a Medien file */
  newVersion = true;
  description: string;
  allowedToEdit = false;
  invalidCharString = invalidChars.join(' ');
  allowedFileTypes = ['.jpg', '.bmp', '.jpeg', '.png', '.pdf'];

  constructor(
    private documentService: DocumentService,
    private addressService: AddressService,
    private dialogService: DialogService,
    private objectAddressService: ObjectaddressService,
    private routingService: RoutingService,
    private serviceOrderService: ServiceOrderService,
    private repairOrderService: RepairOrderService,
    private maintenanceSystemService: MaintenanceSystemService,
    private activityTrackerService: ActivityTrackerService,
    private rightsService: RightsService,
    private authorizationService: AuthorizationService,
    private measurementService: MeasurementService,
    private mediaService: MediaService,
    private globalSettingService: HWGlobalSettingService,
    private fileDownloadService: FileDownloadService,
  ) {}

  async ngOnInit(): Promise<void> {
    // Subscription for saving
    this.saveMediumSubscription = this.routingService.save.subscribe(() => void this.saveDocuments());
    await this.getFileList();
    this.getAllowedFileTypes();
    if (this.routingService.getCurrentRoute().endsWith('upload')) this.triggerUpload();
  }

  async getFileList(): Promise<void> {
    const sourceOfFileList = await this.getSourceOfFileList();
    this.allowedToEdit = this.getEditRights(sourceOfFileList);
    if (sourceOfFileList instanceof HWAddress || sourceOfFileList instanceof HWObjectAddress) {
      this.newVersion = this.authorizationService.current.getValue().featureCheck(FeatureNames.mediaTable2).available;
      if (!this.newVersion) {
        this.fileList = sourceOfFileList.Files.map(file => new HWFile(file));
        return;
      }
    }
    this.newVersion = true;
    this.fileList = await this.mediaService.getAllBy('Baseuuid', sourceOfFileList.getUuid());
    return;
  }

  getEditRights(sourceOfFileList: SourceOfFileList): boolean {
    const rights = this.rightsService.getCurrentRight().employeeRights;
    if (sourceOfFileList instanceof HWAddress) {
      return this.addressService.getEditRights(sourceOfFileList, rights.addressRights);
    } else if (sourceOfFileList instanceof HWObjectAddress) {
      return rights.addressRights.editObjectAddresses;
    } else if (sourceOfFileList instanceof HWRepairOrder) return rights.repairOrderRights.enableModule;
    else if (sourceOfFileList instanceof ServiceAuftrag) return rights.maintenanceRights.handleOrder;
    else if (sourceOfFileList instanceof HWAnlage) return rights.maintenanceRights.enableMaintenanceSystems;
    else if (sourceOfFileList instanceof Aufmass) return rights.measurementRights.enableModule;
    else if (sourceOfFileList instanceof RoomBookPosition) return rights.measurementRights.enableModule;
    // went through all types
    return true;
  }

  getAllowedFileTypes(): void {
    if (!this.newVersion) return;
    const fileTypes = this.allowedFileTypes;

    // Old HWFiles could not display txt and csv
    if (!fileTypes.includes('.txt')) fileTypes.push('.txt');
    if (!fileTypes.includes('.csv')) fileTypes.push('.csv');
    this.uploaderInput.nativeElement.accept = fileTypes.join(',');
  }

  async getImageElement(inputFile: HWFile | Medien, listIndex: number): Promise<void> {
    const bssFile = inputFile instanceof Medien ? new Medien(inputFile) : new HWFile(inputFile);
    if (this.newVersion) delete (bssFile as Medien).PreviewPictureData;
    let documentUrl = bssFile.Data;
    const isText = this.documentService.isDocumentText(inputFile);

    if (!documentUrl) {
      if (bssFile instanceof HWFile) documentUrl = await this.documentService.getHWFileDataUrlFromWebService(bssFile);
      else documentUrl = await this.mediaService.getMediaDataUrlFromWebService(bssFile);
      bssFile.setDataUrl(documentUrl);
      this.fileList[listIndex] = bssFile;
    }

    if (this.documentService.isDocumentPdf(inputFile)) {
      this.fileDownloadService.downloadFileFromDataUrl(documentUrl, 'application/pdf', bssFile.getOriginalName());
      return;
    }

    if (isText) {
      this.documentService.openText(documentUrl);
      return;
    }
    this.selectedFile = bssFile;
    this.showImg = true;
  }

  /**@description When a document is added to the respective address (upload click) */
  async addDocument(event: Event): Promise<void> {
    const htmlEvent = event as HTMLInputEvent;
    const fileList = Array.from(htmlEvent.target.files);
    const promises = [];
    for (const file of fileList) {
      const currentFileNameSplit = GlobalHelper.splitFilenameAndExtension(file.name);
      const fileExtension = currentFileNameSplit.extension;
      const allowedFileTypes = this.allowedFileTypes;

      const allowed = allowedFileTypes.some(type => type === '.' + fileExtension?.toLowerCase());

      if (!allowed) {
        void this.dialogService.openErrorMessage(
          'Fehler',
          `Unerlaubtes Dateiformat: ${fileExtension} (${file.name}) - Erlaubt sind: ${allowedFileTypes.join(', ')}`,
        );
        continue;
      }
      if (fileList.length === 1) {
        // A file, name choose yourself, PopUp opens
        this.currentFile = file;
        this.fileName = currentFileNameSplit.name;
      } else promises.push(this.parseAndAddFile(file, null, null));
    }
    await Promise.all(promises);
  }

  /**@description The file is now assembled (possibly with a new name) and passed to the web service*/
  async parseAndAddFile(file: File, fileName: string, description: string): Promise<void> {
    if (file.type === 'text/plain' || 'text/csv' || 'application/pdf') {
      const sizeInMb = file.size / (1024 * 1024);
      if (sizeInMb > 100) {
        await this.dialogService.openErrorMessage(
          'Fehler',
          'Es können nur Dateien bis 100mb hochgeladen werden! Aktuelle Größe: ' + sizeInMb.toFixed(2) + 'mb',
        );
        return;
      }
    }
    const fileNameSplit = GlobalHelper.splitFilenameAndExtension(file.name);
    if (!fileName) fileName = fileNameSplit.name;
    const newFileName = `${fileName}.${fileNameSplit.extension}`;
    const source = await this.getSourceOfFileList();
    const parsedFile = this.newVersion
      ? await Medien.createMedium(file, newFileName, source, description)
      : await HWFile.createHWFile(file, newFileName);
    this.fileName = undefined;
    this.description = undefined;
    this.currentFile = undefined;
    const currentFileDuplicate = this.documentService.checkFileExists(this.fileList, parsedFile);
    if (currentFileDuplicate) {
      await this.dialogService.openErrorMessage('Fehler', 'Ein Medium mit gleichen Namen existiert bereits.');
      return;
    }
    const sourceOfFileList = await this.getSourceOfFileList();
    if (parsedFile instanceof HWFile) parsedFile.addRelation(sourceOfFileList);
    this.fileList.unshift(parsedFile);
    this.filesAdded = true;
    this.routingService.dataChanged.next(true);
  }

  async saveDocuments(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserInfo();
    this.filesAdded = false;
    const baseFile = this.fileList[0];
    if (!baseFile) return;
    void this.dialogService.openLoadingDialog('Dateitransfer', 'Medien werden übertragen.');
    await this.activityTrackerService.countUpAutoSyncs();
    if (this.newVersion) {
      this.mediaService.convertImages(this.fileList as Medien[]);
      await this.mediaService.sendMedia(this.fileList as Medien[]);
    } else {
      const documents = await this.documentService.sendDocumentsToWebService(userInfo, this.fileList as HWFile[]);
      await this.assignAddressInfoOld(documents);
    }
    this.dialogService.closeLoadingDialog();
    this.routingService.dataChanged.next(false);
    this.routingService.routeBack();
  }

  validateFilename(newValue: string): void {
    if (!newValue) return;
    this.invalidCharInFilename = invalidChars.some(character => newValue.includes(character));
  }

  ngOnDestroy(): void {
    this.saveMediumSubscription?.unsubscribe();
  }

  getImageName(file: HWFile | Medien): string {
    if (file instanceof HWFile) return file.NameWithoutEnding;
    if (file instanceof Medien) return file.Orgname;
    return '';
  }

  private async getSourceOfFileList(): Promise<SourceOfFileList> {
    const type = this.routingService.getRouteParam('type') as SourceOfFileListString;
    const guid = this.routingService.getRouteParam('guid');
    const secondGuid = this.routingService.getRouteParam('secondGuid');

    switch (type) {
      case 'RoomBookPosition': {
        const aufmass = await this.measurementService.findOneBy('Uuid', guid);
        return aufmass.getRoomBookPosition().find(raumbPos => raumbPos.Uuid === secondGuid);
      }
      case 'Aufmass':
        return await this.measurementService.findOneBy('Uuid', guid);
      case 'Address':
        return await this.addressService.findOneBy('Guid', guid);
      case 'ObjectAddress':
        return await this.objectAddressService.findOneBy('Guid', guid);
      case 'RepairOrder':
        return await this.repairOrderService.findOneBy('Guid', guid);
      case 'ServiceOrder': {
        const order = await this.serviceOrderService.findOneBy('UUID', guid);
        return await this.maintenanceSystemService.findOneAnlageForMediaViewBy('ANLAGE', order.Anlage);
      }
      case 'Anlage':
        return await this.maintenanceSystemService.findOneAnlageForMediaViewBy('UUID', guid);
    }
  }

  private triggerUpload(): void {
    const uploadButton = document.getElementById('uploadButton') as HTMLButtonElement;
    uploadButton.click();
  }

  /**@description Steps that were necessary before the introduction of the media table */
  private async assignAddressInfoOld(documents: HWFile[]): Promise<void> {
    const baseFile = this.fileList[0] as HWFile;
    if (baseFile.LfdNr) await this.objectAddressService.updateDocumentsInAddressLocally(documents);
    else if (baseFile.Kundennummer) await this.addressService.updateDocumentsInAddressLocally(documents);
  }
}

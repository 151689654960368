import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoomBook } from '../../repository/RoomBook';
import { RoomBookPosition } from './RoomBookPosition';

export class Room {
  /** all areas of this room are deleted beforehand */
  clearRoom = false;
  RaumbuchEntries: RoomBook[] = [];
  pictureDataBase64: string = null;
  raumbposUuid: string = null;

  constructor(entries: RoomBook[], pictureDataBase64: string, roomBookPosition: RoomBookPosition, clearRoom = false) {
    this.RaumbuchEntries = entries;
    this.pictureDataBase64 = GlobalHelper.base64To64Raw(pictureDataBase64);
    this.clearRoom = clearRoom;
    this.raumbposUuid = roomBookPosition.Uuid;
  }
}

import { Component, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { Item } from 'devextreme/ui/tree_view';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { MeasurementHelper } from 'libs/shared/src/lib/helper/measurementHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { RoomBookPositionLevels } from '../../../config/Konstanten';
import { Aufmass, HWAddress, RoomName, RoomTemplate } from '../../../entities';
import { RoomBookPosition } from '../../../entities/models/aufmass/RoomBookPosition';
import { AddressService } from '../../../services/dataServices/address.service';
import { MeasurementService } from '../../../services/dataServices/measurement.service';
import { RoomBookService } from '../../../services/dataServices/roomBook.service';
import { RoomNameService } from '../../../services/dataServices/roomName.service';
import { RoomTemplateService } from '../../../services/dataServices/roomTemplate.service';
import { MediaService } from '../../../services/globalServices/media.service';

@Component({
  selector: 'app-raumbuch-edit',
  templateUrl: './raumbuch-edit.component.html',
  styleUrls: ['./raumbuch-edit.component.scss'],
})
export class RoomBookEditComponent implements OnInit {
  measurement: Aufmass;
  roomBook: RoomTemplate;
  roomBookAsTreeNodes: RoomBookPosition[];
  selectedRoom: RoomBookPosition;
  roomNames: RoomName[];
  possibleRoomNames: RoomName[];
  showPopup = false;
  roomBookPositions: RoomBookPosition[];
  selectedRoomBookAsRoot = false;
  customer: HWAddress;
  currentFilesAmount: string;
  showRoomTemplatePopup = false;
  roomTemplateExists = false;
  finished = false;
  roomBookPositionLevels = RoomBookPositionLevels;

  constructor(
    private routingService: RoutingService,
    private measurementService: MeasurementService,
    private roomTemplateService: RoomTemplateService,
    private roomNameService: RoomNameService,
    private dialogService: DialogService,
    private addressService: AddressService,
    private roomBookService: RoomBookService,
    private mediaService: MediaService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.measurement = await this.buildUpTree();
    await this.findRoomBook(this.measurement);
    this.roomNames = await this.roomNameService.getAll();
    this.possibleRoomNames = RoomName.filterRoomsPerLevel(this.roomNames, 3);
    if (this.roomTemplateExists) {
      await this.selectRoom(this.roomBook);
    }
    const files = await this.mediaService.getAllBy('Baseuuid', this.measurement.getUuid());
    this.currentFilesAmount = GlobalHelper.calculateDataAmount(files);
    this.finished = true;
  }

  /**
   * @description Opens the "neuer Raum" popup, except when no layer is selected. Then the user is asked whether to insert at deepest level
   */
  async addRoom(): Promise<void> {
    if (this.selectedRoom) {
      this.showPopup = true;
      return;
    }
    const insertToRoomBook = await this.dialogService.openConfirmDialog(
      'Ebene wählen',
      'Sie haben keine Ebene ausgewählt. Möchten Sie eine Position an tiefster Ebene hinzufügen?',
      'An unterster Ebene hinzufügen',
      'Abbrechen',
      false,
    );
    if (insertToRoomBook) {
      await this.selectRoom(this.roomBook);
      void this.addRoom();
    }
  }

  /**@description Deletes a room book position and all subnodes / measurements of it */
  async deleteRoom(measurement: Aufmass, roomEntity: RoomBookPosition): Promise<void> {
    const confirmation = await this.dialogService.openConfirmDialog(
      'Achtung',
      'Sind Sie sicher, dass Sie diese Raumbuchposition und alle Unterpositionen löschen wollen?',
      'Löschen',
      'Nicht löschen',
      false,
    );
    if (!confirmation) return;
    await this.measurementService.deleteRoom(measurement, roomEntity, false);
    await this.buildUpTree();
  }

  selectRoomTemplate(): void {
    this.showRoomTemplatePopup = true;
  }

  /**@description Selects a RoomBookPosition entity - if it is the deepest level (a space) then it will be routed to it*/
  async selectRoom(roomEntity: RoomTemplate | RoomBookPosition | Item): Promise<void> {
    this.selectedRoomBookAsRoot = false;
    if (roomEntity instanceof RoomTemplate) roomEntity = await this.handleRoomEntityIsRoomBook(roomEntity);
    this.selectedRoom = roomEntity as RoomBookPosition;
    const levelForRoomNames = this.selectedRoom.level ? this.selectedRoom.level - 1 : 3;
    this.possibleRoomNames = RoomName.filterRoomsPerLevel(this.roomNames, levelForRoomNames);
  }

  gotoRoomMeasurement(): void {
    if (!this.selectedRoom.Uuid) {
      void this.dialogService.openErrorMessage('Fehler', 'Für diesen Knoten kann kein Raumaufmaß erstellt werden.');
      return;
    }
    void this.routingService.navigateTo(`aufmass/${this.measurement.Uuid}/raum/${this.selectedRoom.Uuid}`);
  }

  async addPositionToMeasurement(roomBookPosition: RoomBookPosition): Promise<void> {
    await this.measurementService.addRoom(this.measurement, roomBookPosition, false, false);
    await this.buildUpTree();
    await this.reselectPreviousSelected();
    this.showPopup = false;
  }

  editAufmass(): void {
    void this.routingService.navigateTo(`aufmass/editierung/${this.measurement.Uuid}`);
  }

  gotoDimensionChain(): void {
    void this.routingService.navigateTo(
      `aufmass/${this.measurement.Uuid}/raum/${this.selectedRoom.Uuid}/masskette/neu`,
    );
  }

  drawGroundPlan(): void {
    void this.routingService.navigateTo(`aufmass/grundriss/${this.measurement.Uuid}/${this.selectedRoom.Uuid}`);
  }

  openMaps(strasse: string, plz: string, ort: string): void {
    window.open(`https://www.google.com/maps/search/?api=1&query=${strasse}%2C+${ort}_blank`);
  }

  async newImages(): Promise<void> {
    await this.routingService.navigateTo(
      `medien/RoomBookPosition/${this.measurement.Uuid}/upload/${this.selectedRoom.Uuid}`,
    );
  }

  /**@description Selects the previous node */
  private async reselectPreviousSelected(): Promise<void> {
    const previousSelected = this.findPreviousTreeNode();
    if (!previousSelected && this.selectedRoomBookAsRoot) {
      // last node was in the room book
      await this.selectRoom(this.roomBook);
      return;
    }
    if (previousSelected?.items?.length > 0) previousSelected.expanded = true;
    if (previousSelected) {
      previousSelected.selected = true;
      await this.selectRoom(previousSelected);
    }
  }

  /**@description Iterates through floors and apartments to find the last node*/
  private findPreviousTreeNode(): RoomBookPosition {
    if (!this.selectedRoom) return null;
    const previousUuid = this.selectedRoom?.Uuid;
    for (const floor of this.roomBookAsTreeNodes) {
      if (floor.Uuid === previousUuid) return floor;
      const apartments = floor?.items as RoomBookPosition[];
      for (const apartment of apartments) if (apartment.Uuid === previousUuid) return apartment;
    }
    return null;
  }

  private async loadMeasurement(): Promise<Aufmass> {
    const measurementUuid = this.routingService.getRouteParam('uuid');
    const measurement = await this.measurementService.findOneBy('Uuid', measurementUuid);
    this.customer = await this.addressService.findOneBy('KU_NR', measurement.Kunde);
    return measurement;
  }

  /**@description Builds the tree structure of the display from the room book positions - returns the measurement, since it is loaded anyway.*/
  private async buildUpTree(): Promise<Aufmass> {
    const measurement = await this.loadMeasurement();
    this.roomBookPositions = measurement.getRoomBookPosition();
    for (const position of this.roomBookPositions) {
      await this.roomBookService.addHasDimensionChain(position);
    }
    this.roomBookAsTreeNodes = MeasurementHelper.buildTreeNodes(this.roomBookPositions);
    MeasurementHelper.assignParentIds(this.roomBookPositions, this.roomBookAsTreeNodes);
    return measurement;
  }

  private async findRoomBook(measurement: Aufmass): Promise<void> {
    if (measurement.RVorlage === 0 && GlobalHelper.isNullOrUndefinedOrEmptyString(measurement.RBezeich)) {
      this.roomTemplateExists = false;
      return;
    }
    this.roomTemplateExists = true;
    if (measurement.RVorlage === 0) {
      // 0 = without template ,hängt lose drin
      const mockRoomBook = new RoomTemplate(null);
      mockRoomBook.Bezeich = measurement.RBezeich;
      this.roomBook = mockRoomBook;
      return;
    }
    const templates = await this.roomTemplateService.getAllRoomBookTemplates();
    this.roomBook = MeasurementHelper.getRoomBookTemplate(templates, this.measurement.RVorlage);
  }

  private async handleRoomEntityIsRoomBook(roomEntity: RoomTemplate): Promise<RoomBookPosition> {
    const previousSelected = this.findPreviousTreeNode();
    if (previousSelected) {
      previousSelected.expanded = false;
      previousSelected.selected = false;
    }
    const floors = MeasurementHelper.getFloors(this.roomBookPositions);
    const mockParent = new RoomBookPosition(null);
    mockParent.createRoomBookAsRoomBookPosition(roomEntity, floors);
    this.selectedRoomBookAsRoot = true;
    await this.buildUpTree();
    return mockParent;
  }
}

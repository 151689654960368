import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DialogService } from '@handwerk-pwa/shared';
import { environment } from 'apps/handwerkPWA/src/environments/environment';
import { EmailConnectionInfo, Setting, UserInfo } from 'libs/shared/src/lib/entities';
import { Subscription, interval } from 'rxjs';
import { GlobalSettings } from '../../config/Konstanten';
import { AppOnlySettings } from '../../entities';
import { BeforeInstallPromptEvent, DataToConserve } from '../../interfaces';
import { SliderService } from '../dataServices/slider.service';
import { ConnectionDialogues, ConnectionService } from './connection.service';
import { ControllerService } from './controller.service';
import { HWGlobalSettingService } from './hwGlobalSetting.service';

@Injectable({
  providedIn: 'root',
})
export class UpdatepwaService implements OnDestroy {
  isInstalled = false;
  private deferredPromptEvent: BeforeInstallPromptEvent = null;
  private updateTimerSubscription: Subscription;

  constructor(
    private updates: SwUpdate,
    private dialogService: DialogService,
    private controllerService: ControllerService,
    private globalSettingService: HWGlobalSettingService,
    private connectionService: ConnectionService,
    private sliderService: SliderService,
  ) {}
  ngOnDestroy(): void {
    this.updateTimerSubscription?.unsubscribe();
  }

  /**Registriert die events für A2HS. Die Funktion sollte so früh wie möglich gecallt werden.*/
  public storeInstallEvent(): void {
    this.isInstalled = window.matchMedia('(display-mode: standalone)').matches;
    window.addEventListener('beforeinstallprompt', (event: BeforeInstallPromptEvent) => {
      this.deferredPromptEvent = event;
    });
    window.addEventListener('appinstalled', () => {
      this.isInstalled = true;
    });
  }

  /** @description Triggers the install to home screen message */
  public async triggerInstall(): Promise<void> {
    if (this.deferredPromptEvent == null) return;
    await this.deferredPromptEvent.prompt();
    const choiceResult = await this.deferredPromptEvent.userChoice;
    this.isInstalled = choiceResult.outcome === 'accepted' ? true : false;
  }

  /** @description periodically checks if there is a Update of the app */
  async startPeriodicCheck(): Promise<void> {
    if (!environment.production) return;
    const timeInMinutes = 60;
    const timeInMilliseconds = timeInMinutes * 60 * 1000;
    await this.intervalCheckUpdate();
    const intervalObservable$ = interval(timeInMilliseconds);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.updateTimerSubscription = intervalObservable$.subscribe(async () => {
      await this.intervalCheckUpdate();
    });
  }

  /** @description Opens the update dialog to notify the User and ask for permission to update */
  public async askUserForUpdate(): Promise<boolean> {
    const response = await this.openUpdateDialog();

    if (!response) return false;

    const online = await this.connectionService.checkOnline(ConnectionDialogues.Update);
    if (!online) return false;
    await this.doUpdateRoutine();
    return true;
  }

  /**@description pushes all unpushed Data and logouts the device, license and push and then does the Update  */
  public async doUpdateRoutine(): Promise<void> {
    await this.globalSettingService.setEntity('UpdateDone', GlobalSettings.UpdateDone);
    //activateUpdate NICHT BENUTZEN!!!!
    // await this.updates.activateUpdate();
    window.location.reload();
  }

  /** @description updates the IDB while conserving important data */
  public async afterUpdateInitiated(): Promise<UserInfo> {
    void this.dialogService.openLoadingDialog('Update', 'Update wird durchgeführt');
    const relevantData: DataToConserve = await this.getDataToConserve();
    await this.controllerService.upgradeIndexedDB();
    await this.setConservedData(relevantData);
    this.dialogService.closeLoadingDialog();
    return relevantData.userInfo;
  }

  async checkManuallyForUpdates(): Promise<void> {
    await this.updates.checkForUpdate();
  }

  /**@description Checks if an update is available and asks the User to update their App */
  private async intervalCheckUpdate(): Promise<void> {
    const updateAvailable = await this.updates.checkForUpdate();
    if (updateAvailable) {
      await this.askUserForUpdate();
    }
  }

  private async openUpdateDialog(): Promise<boolean> {
    const htmlString =
      'Es liegt ein Update für Ihre my blue:app® - hand:werk vor. Die Funktionserweiterungen finden' +
      ' Sie <a href="https://myblueapp.de/handwerk/produktinformationen/" target="_blank">hier</a>. Möchten Sie das Update jetzt durchführen?';
    const response = await this.dialogService.openConfirmDialog(
      'Update',
      null,
      'Durchführen',
      'Verschieben',
      false,
      htmlString,
      true,
    );
    return response;
  }

  /**@description Schreibt die gesammelten Daten wieder in die IDB (nur tun falls vorhanden, bspw. kann man bereits abgemeldet sein oder war noch nie angemeldet) */
  private async setConservedData(relevantData: DataToConserve): Promise<void> {
    if (relevantData.userInfo)
      await this.globalSettingService.setEntity(relevantData.userInfo, GlobalSettings.UserInfo);
    if (relevantData.settings)
      await this.globalSettingService.setEntity(relevantData.settings, GlobalSettings.Settings);
    if (relevantData.appOnlySetting)
      await this.globalSettingService.setEntity(relevantData.appOnlySetting, GlobalSettings.AppOnlySettings);
    if (relevantData.sliderSettings)
      await this.globalSettingService.setEntity(relevantData.sliderSettings, GlobalSettings.MultiViewSettings);
    if (relevantData.emailConnectionInfo)
      await this.globalSettingService.setEntity(relevantData.emailConnectionInfo, 'EmailConnectionInfo');
  }

  /**@description Sammelt Daten die nach dem Update nicht verloren gehen sollen  */
  private async getDataToConserve(): Promise<DataToConserve> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const settings = await this.globalSettingService.getEntity<Setting>('Einstellungen');
    const emailConnectionInfo = await this.globalSettingService.getEntity<EmailConnectionInfo>('EmailConnectionInfo');
    const multiViewSettings = await this.sliderService.getDataFromIndexDB();
    const appOnlySetting: AppOnlySettings = await this.globalSettingService.getEntity<AppOnlySettings>(
      GlobalSettings.AppOnlySettings,
    );
    return {
      userInfo: userInfo,
      settings: settings,
      appOnlySetting: appOnlySetting,
      emailConnectionInfo: emailConnectionInfo,
      sliderSettings: multiViewSettings,
    };
  }
}

<h2 class="content-block">Reparaturaufträge</h2>

<div class="content-block bottom-padding">
  <dx-select-box
    class="repairOrder-type-select"
    [dataSource]="statusTypes"
    [displayExpr]="'stateName'"
    [(value)]="selectedStatusType"
    (valueChange)="filterStatusTrigger($event)"
  >
    <div *dxTemplate="let data of 'item'">
      <div class="custom-item">
        <div class="address-type-box address-type-box-margin" [ngStyle]="{ 'background-color': data.stateColor }"></div>
        <div class="product-name">
          {{ data.stateName }}
        </div>
      </div>
    </div>
  </dx-select-box>

  <app-bss-list
    [id]="'repairOrder'"
    [inputDataSource]="filteredRepairOrders"
    [searchExpressions]="searchExpression"
    [emptyInputDataMessage]="'Es sind keine Reparaturaufträge unter diesem Filter vorhanden'"
  ></app-bss-list>
</div>

<app-bss-speed-dial
  icon="add"
  [index]="1"
  label="Neuer Reparaturauftrag"
  (Click)="newRepairOrder()"
></app-bss-speed-dial>

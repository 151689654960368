import { PositionType } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { Positionrequest } from 'apps/handwerkPWA/src/app/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';

export class RepairOrderItemHelper {
  /**@description Prüft ob die nötigen Einstellungen vorhanden sind um eine Entscheidung zu treffen */
  static cantUseFilterSettings(positionRequest: Positionrequest, type: PositionType): boolean {
    if (GlobalHelper.isNullOrUndefined(positionRequest)) {
      return true;
    }
    if (type !== 'Artikel' && type !== 'Leistung') {
      return true;
    }
    if (type === 'Artikel' && GlobalHelper.isNullOrUndefined(positionRequest.Lieferanten)) {
      return true;
    }
    if (type === 'Artikel' && !positionRequest.useFilterLieferanten) {
      return true;
    }
    if (type === 'Leistung' && GlobalHelper.isNullOrUndefined(positionRequest.Gewerk)) {
      return true;
    }
    if (type === 'Leistung' && !positionRequest.useFilterGewerk) {
      return true;
    }
    return false;
  }
}

import { BuildingElementTemplate } from '../entities/models/aufmass/BuildingElementTemplate';

const areaOfCircle = '3,141*([length]*[length])';

const door: BuildingElementTemplate = {
  name: 'Tür',
  iconPath: 'assets/icons/Tür.svg',
  labelLength: 'Breite',
  labelHeight: 'Höhe',
  labelDepth: 'Laibungstiefe',
  mandatoryFields: ['length', 'height'],
  optionalFields: ['depth'],
  allFields: ['length', 'height', 'depth'],
  inRoom: false,
};

const window: BuildingElementTemplate = {
  name: 'Fenster',
  iconPath: 'assets/icons/Fenster.svg',
  labelLength: 'Breite',
  labelHeight: 'Höhe',
  labelDepth: 'Laibungstiefe',
  mandatoryFields: ['length', 'height'],
  optionalFields: ['depth'],
  allFields: ['length', 'height', 'depth'],
  inRoom: false,
};

const roundPillar: BuildingElementTemplate = {
  name: 'Säule rund',
  iconPath: 'assets/icons/Säule.svg',
  labelLength: 'Durchmesser',
  labelHeight: '',
  labelDepth: '',
  mandatoryFields: ['length'],
  optionalFields: [],
  allFields: ['length'],
  inRoom: true,
  inFloor: true,
  alternatingFormula: areaOfCircle,
};

const rectangularPillar: BuildingElementTemplate = {
  name: 'Säule rechteckig',
  iconPath: 'assets/icons/Säule.svg',
  labelLength: 'Breite',
  labelHeight: 'Tiefe',
  labelDepth: '',
  mandatoryFields: ['length', 'height'],
  optionalFields: [],
  allFields: ['length', 'height'],
  inRoom: true,
  inFloor: true,
};

const deductionWall: BuildingElementTemplate = {
  name: 'Abzugfläche Wand',
  iconPath: 'assets/icons/Durchbruch.svg',
  labelLength: 'Breite',
  labelHeight: 'Höhe',
  labelDepth: 'Laibungstiefe',
  mandatoryFields: ['length', 'height'],
  optionalFields: ['depth'],
  allFields: ['length', 'height', 'depth'],
  inRoom: false,
};

const deductionCeiling: BuildingElementTemplate = {
  name: 'Abzugfläche Decke',
  iconPath: 'assets/icons/Deckenlampe.svg',
  labelLength: 'Breite',
  labelHeight: 'Länge',
  labelDepth: '',
  mandatoryFields: ['length', 'height'],
  optionalFields: [],
  allFields: ['length', 'height'],
  inRoom: true,
  inFloor: false,
};

const deductionFloor: BuildingElementTemplate = {
  name: 'Abzugfläche Boden',
  iconPath: 'assets/icons/Bauteil.svg',
  labelLength: 'Breite',
  labelHeight: 'Länge',
  labelDepth: '',
  mandatoryFields: ['length', 'height'],
  optionalFields: [],
  allFields: ['length', 'height'],
  inRoom: true,
  inFloor: true,
};

export const BuildingElementTemplates: BuildingElementTemplate[] = [
  door,
  window,
  roundPillar,
  rectangularPillar,
  deductionWall,
  deductionCeiling,
  deductionFloor,
];

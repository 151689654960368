import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { DxMultiViewComponent } from 'devextreme-angular';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { MultiViewSite } from '../../entities/repository/MultiViewSite';
import { HWGlobalSettingService } from '../../services/globalServices/hwGlobalSetting.service';

@Component({
  selector: 'app-bss-multiView',
  templateUrl: './bss-multiView.component.html',
  styleUrls: ['./bss-multiView.component.scss'],
})
export class BssMultiViewComponent implements AfterViewInit {
  @Input() multiViewItems: MultiViewSite[];
  @ViewChild('multiView') multiView: DxMultiViewComponent;
  currentViewIndex = -1;

  constructor(private globalSettingService: HWGlobalSettingService, private routingService: RoutingService) {}

  ngAfterViewInit(): void {
    this.setLastViewIndexFromGlobalSettings();
  }

  nextView(): void {
    this.nextViewIndex();
    this.setView(this.currentViewIndex);
  }

  previousView(): void {
    this.previousViewIndex();
    this.setView(this.currentViewIndex);
  }

  setView(viewIndex: number): void {
    this.multiView.selectedIndex = viewIndex;
    this.setCorrectSliderBtn(viewIndex);
    this.globalSettingService.letzterStartseitenIndex = viewIndex;
  }

  goToPage(path: string): void {
    void this.routingService.navigateTo(path);
  }

  selectionChanged(viewIndex: number): void {
    this.currentViewIndex = viewIndex;
    this.setCorrectSliderBtn(viewIndex);
  }

  /** Private Functions */

  private nextViewIndex(): void {
    this.currentViewIndex = (this.currentViewIndex + 1) % this.multiViewItems.length;
  }

  private previousViewIndex(): void {
    const nextSite = this.currentViewIndex - 1;
    if (nextSite < 0) this.currentViewIndex = this.multiViewItems.length - 1;
    else this.currentViewIndex = nextSite;
  }

  private setCorrectSliderBtn(viewIndex: number): void {
    const sliderButtons = document.getElementsByClassName('slider-btn') as HTMLCollectionOf<HTMLElement>;
    const buttonArray = Array.from(sliderButtons);
    let index = 0;
    for (const button of buttonArray) {
      button.style.backgroundColor = '#d4d4d4';
      if (index === viewIndex) {
        button.style.backgroundColor = '#00314c';
      }
      index++;
    }
  }

  private setLastViewIndexFromGlobalSettings(): void {
    const lastIndex = this.globalSettingService.letzterStartseitenIndex;
    if (lastIndex) {
      this.setView(lastIndex);
    }
  }
}

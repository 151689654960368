/**
 * JPEG Quality factor. 70% sieht noch akzeptabel aus, ist aber trotzdem deutlich kleiner in der Größe
 */
const jpgQualityFactor = 0.7;

/**
 * Maximale Auflösung, ab der eine Komprimierung stattfindet.
 * Gleichzeitig auch die Ziel-Auflösung, auf die komprimiert wird
 */
const maxResolution = 1200;

export class DocumentHelper {
  /**
   * Durch Regex aus DataUrl die entsprechenden Stellen raus schneiden um base64 String zu erhalten
   * @param dataUrl ursprüngliche DataUrl der Datei
   * @returns base64 String
   */
  static dataUrlToBase64(dataUrl: string): string {
    return dataUrl.replace(/^(data:image|data:application)\/(png|jpg|jpeg|pdf);base64,/, '');
  }

  /**
   * Durch Regex aus DataUrl die entsprechenden Stellen raus schneiden um base64 String zu erhalten
   * @param base64Data base64 string
   * @param contentType string z.B.: "application/pdf", "image/jpeg",...
   * @returns blob
   */
  static base64toBlob(base64Data: string, contentType: string): Blob {
    const byteCharacters = window.atob(base64Data);
    const ab = new ArrayBuffer(byteCharacters.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteCharacters.length; i++) {
      ia[i] = byteCharacters.charCodeAt(i);
    }
    return new Blob([ab], { type: contentType });
  }

  /**
   * Holt eine DataUrl zu einem Blob.
   * @param blob
   */
  static async readBlobAsDataUrlAsync(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (): void => {
        const dataUrl = reader.result as string;
        resolve(dataUrl);
      };

      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  //** Only needed on HWFiles */
  static getContentType(base64String: string): string {
    const byteCharacters = window.atob(base64String);
    // .png
    if (byteCharacters.startsWith('PNG')) return 'image/png';
    // .jpg / .jpeg
    if (byteCharacters.startsWith('ÿØÿà')) return 'image/jpg';
    // .bmp files (Mono, 16 colors, 256 colors, 24bit)
    if (byteCharacters.startsWith('BM')) return 'image/jpg';
    // .pdf
    if (byteCharacters.startsWith('%PDF')) return 'application/pdf';
    // .csv
    if (byteCharacters.startsWith('%CSV')) return 'text/plain';
    // .txt
    if (byteCharacters.startsWith('%TXT')) return 'text/plain';
    return null;
  }

  // converts a base64 string of a picture to a file
  static createJpgFromBase64(fileName: string, base64Picture: string): File {
    // decode base64 string and create Blob object
    const byteCharacters = atob(base64Picture.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });

    // create file from Blob object
    const file = new File([blob], fileName + '.jpg', { type: 'image/jpeg' });
    return file;
  }

  static async blobToDataUrl(blobData: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blobData);

      reader.onload = (): void => {
        const dataUrl = reader.result as string;
        resolve(dataUrl);
      };

      reader.onerror = (): void => {
        reject(reader.error);
      };
    });
  }

  /**
   * Compresses the picture to have the longer
   * @param file Picture as File
   * @returns Compressed picture as blob
   */
  static async customCompress(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = (): void => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        if (height > maxResolution || width > maxResolution) {
          if (height > width) {
            width = (width * maxResolution) / height;
            height = maxResolution;
          } else if (width > height) {
            height = (height * maxResolution) / width;
            width = maxResolution;
          } else {
            height = maxResolution;
            width = maxResolution;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          blob => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Failed to compress image'));
            }
          },
          'image/jpeg',
          jpgQualityFactor
        );
      };

      img.onerror = (e): void => {
        reject(e);
      };

      img.src = URL.createObjectURL(file);
    });
  }
}

import { FeatureNames } from './FeatureCheck';
export const baseWebServiceVersionString = '3.0.2.25'; // Minimum web service version

export const basis: Feature = {
  name: FeatureNames.base,
  minWebServiceVersion: baseWebServiceVersionString,
  minHandwerkDBVersion: '68.4',
  order: 0,
};

//Change to new Media view in Addresses
export const mediaTable2: Feature = {
  name: FeatureNames.mediaTable2,
  minWebServiceVersion: baseWebServiceVersionString,
  minHandwerkDBVersion: '73.0',
  order: 4,
};

export const features: Feature[] = [basis, mediaTable2];

export class Feature {
  name: FeatureNames;
  minWebServiceVersion: string;
  minHandwerkDBVersion: string;
  /** Ascending number to more easily view feature hierarchies - 0: Basic, 1: Maintenance Service... */
  order: number;

  /**@description Gets the feature object by the feature name */
  static getFeature(featureName: FeatureNames): Feature {
    switch (featureName) {
      case FeatureNames.base:
        return basis;
      case FeatureNames.mediaTable2:
        return mediaTable2;
    }
  }
}

import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { SyncObject } from '../models/SyncObject';
import { IndexedDBTypes } from './dbType';

export class Projekt extends IndexedDBTypes.DbType implements SyncObject {
  @IndexedDBTypes.KlassenName('Projekt') KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') Uuid: string = null;
  @IndexedDBTypes.DataField('string') Nummer: string = null;
  @IndexedDBTypes.IndexField('string') Name: string = null;
  @IndexedDBTypes.DataField('string') Betreff: string = null;
  @IndexedDBTypes.DataField('string') Kunde: string = null;
  @IndexedDBTypes.DataField('string') KdSuch: string = null;

  constructor(projekt: Projekt) {
    super();
    GlobalHelper.assignIfPropertyExists(this, projekt);
  }

  static toString(): string {
    return 'Projekt';
  }
}

import { Right, UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { IndexedDBTypes } from './dbType';

export class BaseInformationTracker extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('BaseInformationTracker') KlassenName: string;
  @IndexedDBTypes.DataField('string') installation_ID: string;
  @IndexedDBTypes.DataField('string') device_ID: string;
  @IndexedDBTypes.DataField('string') installationType: string;
  @IndexedDBTypes.DataField('string') usedPWALicense: string;
  @IndexedDBTypes.DataField('number') licenseCount: number;
  @IndexedDBTypes.DataField('number') usedLicenseSlots: number;
  @IndexedDBTypes.DataField('string') mandant: string;
  @IndexedDBTypes.DataField('string') monteur: string;
  @IndexedDBTypes.DataField('string') operatingsystem: string;
  @IndexedDBTypes.DataField('string') usedBrowser: string;
  @IndexedDBTypes.DataField('Date') dateTime: Date;
  @IndexedDBTypes.KeyDBField('string') private AutoKey: string;

  constructor(
    baseInformation: BaseInformationTracker,
    userInfo: UserInfo,
    rights: Right,
    licenseCount: number,
    usedLicenseSlots: number,
    usedBrowser: string
  ) {
    super();
    GlobalHelper.assignIfPropertyExists(this, baseInformation);
    // Information from UserInfo
    this.mandant = userInfo.mandant;
    this.device_ID = userInfo.Device.LicenceUuid;
    this.monteur = userInfo.monteur;

    // Information from WebService
    this.installation_ID = userInfo.uuid;
    this.installationType = userInfo.branding;
    this.licenseCount = licenseCount;
    this.usedLicenseSlots = usedLicenseSlots;

    // Information from device detector
    this.operatingsystem = userInfo.Device.Devos;
    this.usedBrowser = usedBrowser;

    this.usedPWALicense = rights.employeeRights.showObjektadressen ? 'Premium' : 'Basic';

    this.dateTime = new Date();
    this.dateTime.setHours(0, 0, 0, 0);
  }

  static toString(): string {
    return 'BaseInformationTracker';
  }
}

<h2 class="content-block">Ansprechpartner</h2>

<div class="content-block">
  <app-bss-list
    [id]="'address-contact-persons'"
    [inputDataSource]="contactPersons"
    [searchExpressions]="searchExpressions"
    [emptyInputDataMessage]="'Es sind keine Ansprechpartner vorhanden'"
  ></app-bss-list>
</div>

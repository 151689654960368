import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuickButtonIcons } from '../../../config/Konstanten';

@Component({
  selector: 'app-bss-plain-text-box',
  templateUrl: './bss-plain-text-box.component.html',
  styleUrls: ['./bss-plain-text-box.component.scss'],
})
export class BssPlainTextBoxComponent {
  @Input() labelText;
  @Input() value;
  @Input() valueArray;
  @Input() buttonIcon: QuickButtonIcons;
  @Input() buttonEnabled = true;
  @Input() hintText: string;
  @Input() hintClass = 'bss-alert-danger';
  @Output() buttonClick = new EventEmitter<void>();

  buttonClicked(): void {
    this.buttonClick.emit();
  }
}

export abstract class RightCategory {
  // turns every boolean in the subClass to true
  allowEverything(): void {
    for (const prop in this) {
      if (this.hasOwnProperty(prop) && typeof this[prop] === 'boolean') {
        this[prop] = true as this[Extract<keyof this, string>];
      }
    }
  }

  // turns every boolean in the subClass to false
  restrictEverything(): void {
    for (const prop in this) {
      if (this.hasOwnProperty(prop) && typeof this[prop] === 'boolean') {
        this[prop] = false as this[Extract<keyof this, string>];
      }
    }
  }
}

export abstract class Module extends RightCategory {
  enableModule = true;
}

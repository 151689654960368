<div class="content-block message-new bottom-padding">
  <div class="form-block">
    <h2>Neue Nachricht</h2>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <app-bss-select-box
          [labelText]="'Auftrag'"
          [showClearButton]="true"
          [placeholder]="'auswählen'"
          [items]="selectableRepairOrders"
          [displayExpr]="displayExpr"
          [value]="repairOrder"
          (selectedValue)="repairOrder = $event"
        ></app-bss-select-box>
        <app-bss-textbox [labelText]="'Betreff'" [(value)]="message.Betreff"></app-bss-textbox>
        <app-bss-textarea [labelText]="'Nachricht'" [(value)]="message.Text"></app-bss-textarea>
      </div>
    </div>
  </div>
</div>

<app-bss-speed-dial
  id="saveButton"
  icon="check"
  class="add-button"
  label="speichern"
  [index]="1"
  (Click)="save()"
></app-bss-speed-dial>

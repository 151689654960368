import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { BuildingElement, CoordinateLabel, CoordinatePoint, MeasurementRoute } from '../..';
import { RoomBook } from '../../repository/RoomBook';
import { RoomBookPosition } from './RoomBookPosition';

export class MeasurementConstruct {
  pictureDataBase64: string = null;
  MeasurementRoutes: MeasurementRoute[] = [];
  labels: CoordinateLabel[] = [];
  buildingElement: BuildingElement[] = [];
  roomHeight: MeasurementRoute;
  floorArea: MeasurementRoute;
  ceilingArea: MeasurementRoute;
  usedGridFactor: number;

  constructor(
    pictureDataBase64: string,
    MeasurementRoutes: MeasurementRoute[],
    buildingElements: BuildingElement[],
    labels: CoordinateLabel[],
  ) {
    this.pictureDataBase64 = pictureDataBase64;
    this.MeasurementRoutes = MeasurementRoutes;
    this.buildingElement = buildingElements;
    this.labels = labels;
  }

  addRoomAreas(): void {
    this.roomHeight = this.newRoomBaseStructure('r', 'Raumhöhe');
    this.floorArea = this.newRoomBaseStructure('b', 'Bodenfläche');
    this.ceilingArea = this.newRoomBaseStructure('d', 'Deckenfläche');
  }

  tryConstructFloorAndCeilingFormula(allLengths: number[]): void {
    let formula = '';
    const fourCorners = allLengths.length === 4;
    if (!fourCorners) return;
    const firstWallLength = allLengths[0];
    const secondWallLength = allLengths[1];
    const thirdWallLength = allLengths[2];
    const fourthWallLength = allLengths[3];
    if (allLengths.every(wallLength => wallLength === firstWallLength))
      // Square
      formula = `${firstWallLength}*${firstWallLength}`;
    if (firstWallLength === thirdWallLength && secondWallLength === fourthWallLength)
      // rectangle
      formula = `${firstWallLength}*${secondWallLength}`;
    this.floorArea.formula = formula;
    this.ceilingArea.formula = formula;
  }

  convertToRoomBookEntries(room: RoomBookPosition): RoomBook[] {
    const currentEntries: RoomBook[] = [];
    const routes = this.MeasurementRoutes;
    const buildingElement = this.buildingElement;
    const roomHeight = this.roomHeight;
    const routeAsEntries = routes.map(route => route.toRoomBookEntry(currentEntries, room, roomHeight.length));
    const ceilingAsEntry = this.ceilingArea.toRoomBookEntry(currentEntries, room, 1, this.ceilingArea.formula);
    const floorAsEntry = this.floorArea.toRoomBookEntry(currentEntries, room, 1, this.floorArea.formula);
    const buildingElementAsEntries = buildingElement.map(mappedBuildingElement =>
      mappedBuildingElement.toRoomBookEntry(currentEntries, room, roomHeight.length),
    );
    const result: RoomBook[] = [];
    result.push(...routeAsEntries);
    result.push(ceilingAsEntry);
    result.push(floorAsEntry);
    result.push(...buildingElementAsEntries);
    return result;
  }

  /**@description Checks if the lengths of all walls have been measured */
  checkAllWallsMeasured(): boolean {
    const allLengths = this.MeasurementRoutes?.flatMap(entry => entry.length);
    const allWallsMeasured = allLengths.every(length => !GlobalHelper.isNullOrUndefined(length));
    return allWallsMeasured;
  }

  /**@description Iterates over all mandatory fields of all BuildingElements and looks so whether all mandatory fields are filled */
  checkAllBuildingElementsMeasured(): boolean {
    const allBuildingElements = this.buildingElement;
    let allBuildingElementsMeasured = true;
    for (const buildingElement of allBuildingElements) {
      const template = buildingElement.template;
      for (const mandatoryField of template.mandatoryFields) {
        allBuildingElementsMeasured =
          allBuildingElementsMeasured && !GlobalHelper.isNullOrUndefined(buildingElement[mandatoryField]);
      }
    }
    return allBuildingElementsMeasured;
  }

  isRectangle(): boolean {
    if (this.MeasurementRoutes.length !== 4) return false;
    const wall1and3Parallel = this.MeasurementRoutes[0].pointDistance === this.MeasurementRoutes[2].pointDistance;
    const wall2and4Parallel = this.MeasurementRoutes[1].pointDistance === this.MeasurementRoutes[3].pointDistance;
    return wall1and3Parallel && wall2and4Parallel;
  }

  private newRoomBaseStructure(
    bezeichner: string,
    label: 'Raumhöhe' | 'Bodenfläche' | 'Deckenfläche',
  ): MeasurementRoute {
    const mockCoordinatePoint = new CoordinatePoint(1, 1);
    const structure = new MeasurementRoute(mockCoordinatePoint, mockCoordinatePoint, 1);
    structure.label = bezeichner;
    structure.labelLong = label;
    if (label !== 'Raumhöhe') structure.length = 0;
    if (label === 'Bodenfläche') structure.isFloor = true;
    if (label === 'Deckenfläche') structure.isCeiling = true;
    return structure;
  }
}

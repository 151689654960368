import { HWRepairOrderItem, PositionsId } from 'apps/handwerkPWA/src/app/entities/repository/HWRepairOrderItem';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { ManuellePosition } from './ManuellePosition';

export class Textposition extends HWRepairOrderItem {
  Nummer: string = null;
  SuchText: string = null;
  Text: string = null;
  Mobil: boolean = null;
  UUID: string = null;
  Ident: PositionsId = 'T';
  RTFDATA: string = null;

  constructor(data: HWRepairOrderItem | ManuellePosition) {
    super(data);
    GlobalHelper.assignIfPropertyExists(this, data);
    this.MengenEinheit = '';
    this.RTFDATA = this.Text;
    this.Bezeichnung = this.SuchText;
    this.LangText = this.Text;
    this.KurzText = this.Text;
    this.SuchText = GlobalHelper.isNullOrUndefinedOrEmptyString(this.SuchText) ? this.Text : this.SuchText;
    this.Suchbegriff = this.SuchText;
    this.ID = 'T';
    this.Menge = 1;
  }
}

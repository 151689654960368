<h2 class="content-block">W&S Aufträge</h2>

<div class="content-block">
  <app-bss-list
    [id]="'W&S Aufträge'"
    [inputDataSource]="serviceAuftraege | async"
    [secondaryDataSource]="true"
    [searchExpressions]="searchExpressions"
    [emptyInputDataMessage]="'Es sind keine W&S Aufträge vorhanden'"
  ></app-bss-list>
</div>

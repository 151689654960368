import { CoordinatePoint } from './CoordinatePoint';

export class Shape {
  name: string = null;
  coordinatePointRoute: CoordinatePoint[] = [];
  iconPath?: string = null;
  formulaId?: number = null;

  static generateBlueprint(
    form: Shape,
    scaleFactorX: number,
    scaleFactorY: number,
    offsetX: number,
    offsetY: number
  ): CoordinatePoint[] {
    const blueprint: CoordinatePoint[] = [];
    const pointRoute = form.coordinatePointRoute.slice();
    for (const point of pointRoute) {
      const newPoint = new CoordinatePoint(
        point.xCoordinate * scaleFactorX + offsetX,
        point.yCoordinate * scaleFactorY + offsetY
      );
      blueprint.push(newPoint);
    }
    return blueprint;
  }
}

export class Technischedaten {
  Bezeichnungen: string[] = null; // müssen irgendwo stehen
  ZUSAETZE: string[] = []; // Werte der Technischen Daten
  BezeichnungenExtended: string[];

  constructor(data: string[]) {
    if (!data) return;
    // assignIfPropertyExists(this, data);
    for (const additions of data) {
      this.ZUSAETZE.push(additions);
    }
  }
}

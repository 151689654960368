import { AddressShortTypes } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { Anschrift } from 'apps/handwerkPWA/src/app/entities/models/Anschrift';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { BaseAddress, UuidEntity } from '../../interfaces';
import { Kundenpreis } from '../models/Kundenpreis';
import { SyncObject } from '../models/SyncObject';
import { HWFile } from './HWFile';
import { IndexedDBTypes } from './dbType';

export class HWAddress extends IndexedDBTypes.DbType implements BaseAddress, UuidEntity, SyncObject {
  @IndexedDBTypes.KlassenName('HWAddress') KlassenName: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: number;
  @IndexedDBTypes.IndexField('string') KU_NR: string;
  @IndexedDBTypes.IndexField('string') ADRTYP: AddressShortTypes;
  @IndexedDBTypes.DataField('string') BEMERKUNG: string;
  @IndexedDBTypes.DataField('string') LAND: string;
  @IndexedDBTypes.DataField('string') NAME: string;
  @IndexedDBTypes.DataField('string') NAME2: string;
  @IndexedDBTypes.DataField('string') ORT: string;
  @IndexedDBTypes.DataField('string') TEL: string;
  @IndexedDBTypes.DataField('string') FAX: string;
  @IndexedDBTypes.DataField('string') FUNK_PRIV: string;
  @IndexedDBTypes.DataField('string') ANSPRECH: string;
  @IndexedDBTypes.DataField('string') BRANCHE: string;
  @IndexedDBTypes.DataField('string') WWW: string;
  @IndexedDBTypes.DataField('string') GEBURTSTAG: string;
  @IndexedDBTypes.DataField('string') GLOBEMAIL: string;
  @IndexedDBTypes.DataField('string') PLZ: string;
  @IndexedDBTypes.DataField('string') STRASSE: string;
  @IndexedDBTypes.DataField('string') SUCH: string;
  @IndexedDBTypes.DataField('string') mandant: string;
  @IndexedDBTypes.DataField('string') status: string;
  @IndexedDBTypes.DataField('boolean') LIEFERSPERRE: boolean;
  @IndexedDBTypes.DataField('string') HERRFRAU: string;
  @IndexedDBTypes.DataField('string') FA_TITEL: string;
  @IndexedDBTypes.DataField('string') POSTFACH: string;
  @IndexedDBTypes.DataField('string') Zusatz: string[];
  /** Nur für die alten HW Datenbanken unter der 73.0, damals wurden die Daten noch als Dokument gespeichert
   * Sind sonst nur noch in HWAdressen vorhanden und Werden beim sync direkt mit geholt
   * Die neuen HWDatenbanken speichern die Datein al Medien ab
   */
  @IndexedDBTypes.DataField('Files') Files: HWFile[] = [];
  @IndexedDBTypes.DataField('string') ImageNameString = '';
  @IndexedDBTypes.DataField('Date') LeaveDate: Date;
  @IndexedDBTypes.DataField('Anschrift') Anschrift: Anschrift;
  @IndexedDBTypes.DataField('Anschrift')
  ArbeitsortAnschrift: Anschrift; /** An object that exists only within a customer within an order */
  /** Price group of the customer from 01-09 */
  @IndexedDBTypes.DataField('string') priceGroup: string;
  @IndexedDBTypes.DataField('Kundenpreis[]') Kundenpreise: Kundenpreis[];
  @IndexedDBTypes.IndexField('string') Guid: string;
  // Constructor sets the passed data with Object.assign during initialization
  constructor(data: HWAddress) {
    super();
    Object.assign(this, data);
    this.cleanAdditions();
    if (GlobalHelper.isNullOrUndefined(this.Files)) {
      this.Files = [];
    }
    if (!GlobalHelper.isNullOrUndefined(this.priceGroup) && this.priceGroup.startsWith('0')) {
      this.priceGroup = this.priceGroup.replace('0', '');
    }

    this.Anschrift = new Anschrift(this.STRASSE, this.PLZ, this.ORT);
  }

  static toString(): string {
    return 'HWAddress';
  }

  getUuid(): string {
    return this.Guid;
  }

  getCustomerNumber(): string {
    return this.KU_NR;
  }

  getLfdNr(): string {
    return undefined;
  }

  /**@description Cleans the additional fields */
  private cleanAdditions(): void {
    const cleanAdditions: string[] = [];
    if (GlobalHelper.isNullOrUndefined(this.Zusatz)) {
      return;
    }
    for (const addition of this.Zusatz) {
      if (!GlobalHelper.isNullOrUndefined(addition) && addition !== '') {
        cleanAdditions.push(addition);
      }
    }
    this.Zusatz = cleanAdditions;
  }
}

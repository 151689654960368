import { GlobalHelper } from './globalHelper';

export class FormulaHelper {
  static removeArithmethic(toCheck: string): string {
    const numeric = new RegExp('[0-9]', 'g');
    toCheck = toCheck.replaceAll(numeric, '');
    const metaCharacters = ['[', ']', '(', ')', '*', '/', '+', '-', ',', '.'];
    for (const character of metaCharacters) toCheck = toCheck.replaceAll(character, '');
    toCheck = toCheck.replaceAll(' ', '');
    return toCheck;
  }

  /**@description Prüft ob eine valide Formel aus der berechnet werden kann vorliegt */
  static validateFormula(toCheck: string): boolean {
    if (GlobalHelper.isNullOrUndefined(toCheck)) return false;
    if (!FormulaHelper.couldBeFormula(toCheck)) return false;
    let evaluated = NaN;
    try {
      evaluated = eval(toCheck?.replaceAll(',', '.').replaceAll('[', '').replaceAll(']', '')) as number;
    } catch (err) {
      return false;
    }
    return !isNaN(evaluated);
  }
  /**@description Prüft ob nur zeichen einer formel enhtalten sind */
  private static couldBeFormula(toCheck: string): boolean {
    toCheck = FormulaHelper.removeArithmethic(toCheck);
    return toCheck === '';
  }
}

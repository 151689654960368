import { Component, Input } from '@angular/core';
import { HWAnlage } from 'apps/handwerkPWA/src/app/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-system-card',
  templateUrl: './system-card.component.html',
  styleUrls: ['./system-card.component.scss'],
})
export class SystemCardComponent {
  @Input() anlage: HWAnlage;
  showContactBar = false;

  constructor(private routingService: RoutingService) {}

  async goToSystemDetail(): Promise<void> {
    await this.routingService.navigateTo(`wartungsanlage/${this.anlage.UUID}/edit`);
  }

  toggleContactBar(): void {
    this.showContactBar = !this.showContactBar;
  }
}

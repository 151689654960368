<div [ngClass]="useOuterMargin ? 'outerLabelGrid outerMargin' : 'outerLabelGrid'">
  <div class="customSwitchLabel">{{ label }}:</div>
  <div class="choicesFlex">
    <div *ngFor="let choice of choices" [ngStyle]="{ color: choice.disabled ? '#8080806e' : '' }">
      <input
        (click)="selectionChange(choice)"
        type="radio"
        [id]="choice.name"
        [disabled]="choice.disabled"
        [name]="'bssRadioButton'"
      />
      <label [for]="choice.name">{{ choice.name }}</label
      ><br />
    </div>
  </div>
</div>

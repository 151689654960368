<dx-popup
  [width]="'90%'"
  [maxWidth]="'500px'"
  [height]="height"
  [title]="title"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="showPopup"
  (onHidden)="showPopup = false"
>
  <div *ngFor="let checkBoxInput of localCheckBoxInputsArray">
    <dx-check-box
      (valueChange)="lastChangedName = checkBoxInput.name"
      [text]="checkBoxInput.name"
      [(value)]="checkBoxInput.value"
    >
    </dx-check-box>
    <br />
    <br />
  </div>
  <button
    class="bss-button primary-button"
    (click)="onConfirmClick(allowNothingSelected, lastChangedName)"
    style="width: 100%"
  >
    Übernehmen
  </button>
</dx-popup>

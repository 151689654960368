import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { Gewerk } from '../Gewerk';
import { Lieferant } from '../Lieferant';

export class PositionSettings {
  /**Soll eine Mandantenspezifische Einstellung verwendet werden */
  useFilterLieferanten = false;
  /**Soll eine Gewerkspezifische Einstellung verwendet werden */
  useFilterGewerk = false;
  Lieferanten: Lieferant[] = [];
  Gewerk: Gewerk[] = [];

  constructor(positionSettings: PositionSettings) {
    GlobalHelper.assignIfPropertyExists(this, positionSettings);
  }
}

export enum FeatureNames {
  base = 'basis',
  mediaTable2 = 'mediaTable2',
}

export class FeatureCheck {
  name: string;
  dbHighEnough: boolean;
  webServiceHighEnough: boolean;
  available: boolean;
  minWebServiceString: string;
  minDBString: string;
  betaUser: boolean;

  constructor(
    featureName: FeatureNames,
    dbHighEnough: boolean,
    webServiceHighEnough: boolean,
    minWebServiceString: string,
    minDBString: string,
    betaUser: boolean
  ) {
    this.name = featureName;
    this.betaUser = betaUser;
    this.dbHighEnough = dbHighEnough;
    this.webServiceHighEnough = webServiceHighEnough;
    this.available = dbHighEnough && webServiceHighEnough;
    this.minWebServiceString = minWebServiceString;
    this.minDBString = minDBString;
  }
}

import { HWNachricht, HWRepairOrder, ServiceAuftrag } from '../../entities';
import { SyncObject } from '../../entities/models/SyncObject';

export class BackgroundHelper {
  static changesInToRoute(changesIn: SyncObject[]): '/nachrichten' | '/reparaturauftraege' | '/wartungsauftraege' {
    if (changesIn?.includes(HWRepairOrder)) return '/reparaturauftraege';
    if (changesIn?.includes(HWNachricht)) return '/nachrichten';
    if (changesIn?.includes(ServiceAuftrag)) return '/wartungsauftraege';
    return null;
  }

  /**@description Übersetzt Änderungsdatensätze in einen Nutzerlesbaren String */
  static changesInToString(changesIn: SyncObject[]): 'Nachrichten' | 'Reparaturaufträgen' | 'Serviceaufträge' | string {
    let changesString = '';
    for (let change of changesIn) {
      switch (change) {
        case HWNachricht:
          changesString += 'Nachrichten, ';
          break;
        case HWRepairOrder:
          changesString += 'Reparaturaufträgen, ';
          break;
        case ServiceAuftrag:
          changesString += 'Serviceaufträgen, ';
          break;
      }
    }
    const lastCommaRegEx = new RegExp(', +$');
    changesString = changesString.replace(lastCommaRegEx, '');
    return changesString;
  }
}

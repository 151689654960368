<h2 class="content-block">Impressum</h2>

<div class="content-block dx-card responsive-paddings">
  blue:solution software GmbH<br />
  Albert-Einstein-Str. 12a <br />
  48431 Rheine<br />
  Deutschland<br />
  <br />
  Geschäftsführer: Rudolf Melching<br />
  Internet: https://myblueapp.de<br />
  <br />
  Telefon: 059719144860<br />
  Telefax: 0597191448690<br />
  <br />
  E-Mail: info@bluesolution.de<br />
  Sitz der Gesellschaft: Rheine<br />
  <br />
  Registergericht: Amtsgericht Steinfurt<br />
  Registernummer: HRB4779<br />
  Ust-ID: DE217491877 <br />
  <br />
  <br />
  <h2>Haftung für Inhalte:</h2>
  <p>
    Die Inhalte unserer Applikation wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
    Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Dienstanbieter sind wir gemäß § 7 Abs.1 TMG
    für eigene Inhalte in dieser Applikation nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
    als Dienstanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder
    nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
    Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
    Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
    von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. Übersetzung, Druck,
    Vervielfältigung sowie Speicherung in Datenverarbeitungsanlagen nur mit ausdrücklicher Genehmigung der blue:solution
    software GmbH. Alle genannten Marken und Logos sind Marken der entsprechenden Eigentümer oder der blue:solution
    software GmbH.
  </p>
  <br />

  <h2>Haftung für Links:</h2>
  <p>
    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
    können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets
    der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
    Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
    erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
    Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
    entfernen.
  </p>
  <br />

  <h2>Urheberrecht:</h2>
  <p>
    Die durch den Hersteller erstellten Inhalte und Werke in dieser Applikation unterliegen dem deutschen Urheberrecht.
    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
    bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Applikation
    sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte Applikation nicht vom
    Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
    gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
  </p>
</div>

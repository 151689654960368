import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxListComponent } from 'devextreme-angular';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';

@Component({
  selector: 'app-bss-suggestion-field',
  templateUrl: './bss-suggestion-field.component.html',
  styleUrls: ['./bss-suggestion-field.component.scss'],
})
export class BssSuggestionFieldComponent<Type> implements OnInit {
  @Input() value: string;
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() buttonStyle: string;
  @Input() placeholder: string;
  @Input() labelText: string;
  @Input() headline: string;
  @Input() inputDataSource: Type[];
  @Input() customDisplayExpression: string;
  @Input() dataTypeName: string;
  @Output() valueChange = new EventEmitter<string>();
  @ViewChild(DxListComponent, { static: false }) list: DxListComponent;
  @Input() searchExpressions: string[];
  showPopup = false;
  maxLength: number;
  searchValue: string;

  ngOnInit(): void {
    this.searchValue = this.value;
    if (this.searchExpressions.length === 0) throw new Error('Search expressions are required');
  }

  /**@description Wenn geklickt,wird der aktuelle Suchbegriff als Wahl durchgereicht */
  onManualSaveClick(currentSearchExpression: string): void {
    this.emitValue(currentSearchExpression);
  }

  onItemClick(item: Type): void {
    const expression: string = item[this.customDisplayExpression] as string;
    this.emitValue(expression);
  }

  getPropertyValue(item: Type): string {
    const propertyValue: string = item[this.customDisplayExpression] as string;
    if (GlobalHelper.isNullOrUndefined(propertyValue)) {
      return null;
    }
    return propertyValue;
  }

  private emitValue(expression: string): void {
    this.valueChange.emit(expression);
    this.showPopup = false;
  }
}

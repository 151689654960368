import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import {
  AppOnlySettings,
  Aufmass,
  BuildingElement,
  CoordinateLabel,
  CoordinatePoint,
  MeasurementConstruct,
  MeasurementRoute,
} from 'apps/handwerkPWA/src/app/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { MeasurementHelper } from 'libs/shared/src/lib/helper/measurementHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';
import { BuildingElementTemplates } from '../../../config/BuildingElementTemplate';
import { GlobalSettings } from '../../../config/Konstanten';
import { Shapes } from '../../../config/MeasurementShapes';
import { BuildingElementTemplate } from '../../../entities/models/aufmass/BuildingElementTemplate';
import { MeasurementDrawStack } from '../../../entities/models/aufmass/MeasurementDrawStack';
import { InputMode, MeasurementDrawStackElement } from '../../../entities/models/aufmass/MeasurementDrawStackElement';
import { RoomBookPosition } from '../../../entities/models/aufmass/RoomBookPosition';
import { Shape } from '../../../entities/models/aufmass/Shape';
import { DocumentHelper } from '../../../helper/services/documentHelper';
import { MeasurementService } from '../../../services/dataServices/measurement.service';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';

const iconSize = 40;
let height: number;
let width: number;
let clientHeight: number;
let context: CanvasRenderingContext2D;
@Component({
  selector: 'app-aufmass-draw',
  templateUrl: './aufmass-draw.component.html',
  styleUrls: ['./aufmass-draw.component.scss'],
})
export class AufmassDrawComponent implements OnInit, OnDestroy {
  @ViewChild('bssCanvas', { static: true }) bssCanvas: ElementRef<HTMLCanvasElement>;
  measurementCoordinatePoints: CoordinatePoint[] = [];
  buildingElementsCoordinatePoints: CoordinatePoint[] = [];
  grid: MeasurementRoute[] = [];
  aufmassRoutes: MeasurementRoute[] = [];
  buildingElementRoutes: BuildingElement[] = [];
  coordinateLabels: CoordinateLabel[] = [];
  gridEnabled = false;
  label: string;
  currentMode: InputMode = 'none';
  textInput = '';
  room: RoomBookPosition;
  aufmass: Aufmass;
  buildingElements = BuildingElementTemplates;
  currentBuildingElement: BuildingElementTemplate;
  routeClosed = false;
  drawStackElements: MeasurementDrawStackElement[] = [];
  shapes: Shape[] = Shapes;
  appOnlySettings: AppOnlySettings;
  userFactor: number;
  saveSubscription: Subscription;

  constructor(
    private routingService: RoutingService,
    private measurementService: MeasurementService,
    private dialogService: DialogService,
    private globalSettingService: HWGlobalSettingService,
  ) {}

  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.routingService.dataChanged.next(true);
    const stack = await this.loadRoom();
    this.appOnlySettings = await this.globalSettingService.getEntity<AppOnlySettings>(GlobalSettings.AppOnlySettings);
    this.setSizes();
    this.drawBackground('white');
    this.saveSubscription = this.routingService.save.subscribe(() => void this.saveCurrentLocally(false));
    if (!stack || !(this.drawStackElements.length > 0)) {
      this.createGridOnUserSetting(this.appOnlySettings);
      return;
    }

    if (stack?.gridEnabled) this.drawGrid();
    this.rebuildDrawingFromStack(false);
  }

  setCurrentMode(mode: InputMode, template?: BuildingElementTemplate): void {
    const hasRemovedPart = this.removeUnpairedBuildingParts();
    if (hasRemovedPart) this.rebuildDrawingFromStack(false);

    this.currentMode = mode;
    this.currentBuildingElement = template;
  }

  async askForNew(): Promise<void> {
    const confirm = await this.dialogService.openConfirmDialog(
      'Achtung',
      'Soll die Grundriss-Skizze endgültig verworfen werden?',
      'Ja',
      'Nein',
    );
    if (confirm) {
      this.resetAufmassDrawing();
      this.createGridOnUserSetting(this.appOnlySettings);
    }
  }

  toggleGrid(): void {
    this.gridEnabled = !this.gridEnabled;
    this.rebuildDrawingFromStack(false);
  }

  drawGrid(): void {
    this.gridEnabled = true;
    const heightBiggerWidth = height > width;
    this.userFactor = GlobalHelper.isNullOrUndefined(this.aufmass.usedGridFactor)
      ? this.appOnlySettings.aufmassRasterFactor
      : this.aufmass.usedGridFactor;
    const step = heightBiggerWidth
      ? Math.round(height / 40) * (this.userFactor / 50)
      : Math.round(width / 40) * (this.userFactor / 50);
    const iterationLevel = heightBiggerWidth ? height : width;
    let steps = 0;
    for (let heightPosition = 0; heightPosition < iterationLevel; heightPosition = heightPosition + step) {
      const lineXStart = new CoordinatePoint(0, heightPosition);
      const lineXEnd = new CoordinatePoint(width, heightPosition);
      const verticalLine = new MeasurementRoute(lineXStart, lineXEnd, 99);
      const lineYStart = new CoordinatePoint(step * steps, 0);
      const lineYEnd = new CoordinatePoint(step * steps, height);
      const horizontalLine = new MeasurementRoute(lineYStart, lineYEnd, 99);
      this.grid.push(verticalLine);
      this.grid.push(horizontalLine);
      this.drawLine(verticalLine, 'lightgrey');
      this.drawLine(horizontalLine, 'lightgrey');
      steps++;
    }
  }

  /**@description Writes a text from the text input function into the image */
  addTextToCanvas(textInput: string): void {
    const textInputField = document.getElementById('textinput') as HTMLInputElement;
    const xCoordinatePx = textInputField.style.left;
    const xCoordinate = parseInt(xCoordinatePx.replace('px', ''), 10);
    const yCoordinatePx = textInputField.style.top;
    const yCoordinate = parseInt(yCoordinatePx.replace('px', ''), 10);
    const inputCoordinatePoint = new CoordinatePoint(xCoordinate, yCoordinate);
    const label = new CoordinateLabel(inputCoordinatePoint, textInput);
    const stackElement = new MeasurementDrawStackElement(
      inputCoordinatePoint,
      this.gridEnabled,
      'textInput',
      null,
      textInput,
    );
    this.drawStackElements.push(stackElement);
    this.coordinateLabels.push(label);
    this.addLabels(inputCoordinatePoint, textInput, 'green');
    this.setCurrentMode('none');
    textInputField.style.display = 'none';
    this.textInput = '';
  }

  /**@description Event when the canvas is clicked - paints dots on it depending on the mode */
  clickOnCanvas(
    eventOrCoordinatePoint: MouseEvent | CoordinatePoint,
    gridEnabled: boolean,
    currentMode: InputMode,
    currentBuildingElements?: BuildingElementTemplate,
    text?: string,
  ): void {
    const point =
      eventOrCoordinatePoint instanceof MouseEvent
        ? new CoordinatePoint(eventOrCoordinatePoint.x, eventOrCoordinatePoint.y)
        : eventOrCoordinatePoint;
    const stackElement = new MeasurementDrawStackElement(
      point,
      gridEnabled,
      currentMode,
      currentBuildingElements,
      text,
    );
    if (currentMode === 'drawPoints' && this.routeClosed) return;
    currentMode = this.getCorrectDrawMode(gridEnabled, currentMode, currentBuildingElements);
    if (currentMode !== 'none' && currentMode !== 'textInput') this.drawStackElements.push(stackElement);

    switch (currentMode) {
      case 'textInput':
        this.setTextToCanvas(point);
        if (stackElement?.text) {
          this.addTextToCanvas(stackElement.text);
        }
        return;
      case 'drawPoints':
        this.wallClick(point, false);
        return;
      case 'wallGrid':
        this.wallClick(point, true);
        return;
      case 'buildingElementRoom':
        this.placeBuildingElementInRoom(point);
        return;
      case 'buildingElementWall':
        this.placeBuildingElementsInWall(point);
        return;
    }
  }

  /**@description Resets the last done by rebuilding the stack to the last element */
  rebuildDrawingFromStack(undoLastStep = true): void {
    if (undoLastStep && this.drawStackElements.length !== 0) this.removeItemsFromDrawStack();
    const correctStack = this.drawStackElements.slice();
    const gridOn = this.gridEnabled;
    this.resetAufmassDrawing();
    this.gridEnabled = gridOn;
    if (this.gridEnabled) this.drawGrid();
    for (const stackElement of correctStack) {
      const stackElementMode = stackElement.currentMode;
      if (stackElementMode === 'buildingElement') this.currentBuildingElement = stackElement.currentBuildingElement;
      this.clickOnCanvas(
        stackElement.event,
        stackElement.gridEnabled,
        stackElementMode,
        stackElement.currentBuildingElement,
        stackElement.text,
      );
    }
    this.drawStackElements = correctStack;
  }

  async createMeasurementConstruct(): Promise<void> {
    if (!this.routeClosed) {
      void this.dialogService.openErrorMessage(
        'Strecke nicht abgeschlossen',
        'Der Grundriss ist nicht abgeschlossen, eine Messung wäre nicht sinnvoll.',
      );
      return;
    }
    await this.saveCurrentLocally(true);
  }

  matchingBuildingElementsInARow(): number {
    let count = 0;
    const length = this.drawStackElements.length;

    if (length < 2) {
      return count;
    }

    const newestObject = this.drawStackElements[length - 1];
    if (!this.isWallBuildingElement(newestObject)) {
      return count;
    }
    count++;
    for (let i = length - 2; i >= 0; i--) {
      if (!this.isWallBuildingElement(this.drawStackElements[i])) break;
      if (newestObject?.currentBuildingElement === this.drawStackElements[i]?.currentBuildingElement) {
        count++;
      } else {
        break;
      }
    }
    return count;
  }

  isWallBuildingElement(element: MeasurementDrawStackElement): boolean {
    return element.currentBuildingElement && !element.currentBuildingElement.inRoom;
  }

  removeLastBuildingElement(): void {
    this.buildingElementsCoordinatePoints.pop();
    this.drawStackElements.pop();
  }

  getPreviousDrawElements(index: number): MeasurementDrawStackElement {
    const stackItems = this.drawStackElements;
    if (stackItems.length < index) return null;
    const item = stackItems[stackItems.length - index];
    return item;
  }

  /**@description Draws the shapeTemplate */
  drawFromShape(form: Shape): void {
    const offsetY = 80;
    const scaleSide = screen.height > screen.width ? screen.width : screen.height;
    const scaleFactor = scaleSide * 0.25;
    const blueprint = Shape.generateBlueprint(form, scaleFactor, scaleFactor, 0, offsetY);
    for (const point of blueprint) this.clickOnCanvas(point, this.gridEnabled, 'drawPoints');
  }

  private createGridOnUserSetting(settings: AppOnlySettings): void {
    if (settings.aufmassGridOnStart) this.drawGrid();
  }

  /**@description Loads the room entity */
  private async loadRoom(): Promise<MeasurementDrawStack> {
    const guid = this.routingService.getRouteParam('aufmassid');
    const roomId = this.routingService.getRouteParam('roomId');
    this.aufmass = await this.measurementService.findOneBy('Uuid', guid);
    const roomBookPosition = this.aufmass.getRoomBookPosition();
    this.room = roomBookPosition.find(position => position.Uuid === roomId);
    const drawStack = this.aufmass.drawStack.find(stack => stack.roomUuid === roomId);
    if (drawStack) {
      this.drawStackElements = drawStack.MeasurementDrawStackElements;
      return drawStack;
    }
    return null;
  }

  /**@description Saves the sizes for canvas/calculations */
  private setSizes(): void {
    height = window.innerHeight;
    width = window.innerWidth;
    clientHeight = this.bssCanvas.nativeElement.clientHeight;
    this.bssCanvas.nativeElement.height = this.bssCanvas.nativeElement.clientHeight;
    this.bssCanvas.nativeElement.width = this.bssCanvas.nativeElement.clientWidth;
    context = this.bssCanvas.nativeElement.getContext('2d');
  }

  /**@description Colors the canvas */
  private drawBackground(color: string): void {
    context.beginPath();
    context.rect(0, 0, width, clientHeight);
    context.fillStyle = color;
    context.fill();
  }

  private wallClick(point: CoordinatePoint, drawOnGrid: boolean): void {
    const drawnCoordinatePoint = this.drawRectangle(point, this.measurementCoordinatePoints, true, 'red', drawOnGrid);
    this.measurementCoordinatePoints.push(drawnCoordinatePoint);
    this.drawMeasurementRoute();
  }

  private setTextToCanvas(point: CoordinatePoint): void {
    const textInput = document.getElementById('textinput') as HTMLInputElement;
    textInput.style.left = point.xCoordinate + 'px';
    textInput.style.top = point.yCoordinate + 'px';
    textInput.style.display = 'block';
  }

  /**@descriptionPaints a CoordinatePoint on an existing route - returns the route on which the point was painted.*/
  private placeBuildingElementsOnRoute(point: CoordinatePoint, route: MeasurementRoute[]): MeasurementRoute {
    const routeOfBuildingElement = route.find(searchRoute => searchRoute.isPointOnMeasurementRoute(point, 20));
    if (!routeOfBuildingElement) return null;
    const pointOnRoute = routeOfBuildingElement.getPointOnRoute(point);
    this.drawRectangle(pointOnRoute, null, false, 'blue', false);
    this.buildingElementsCoordinatePoints.push(pointOnRoute);
    return routeOfBuildingElement;
  }

  /**@description Connects the last two Coordinate Points to a distance and adds them to the route */
  private drawMeasurementRoute(isBuildingElement?: boolean, abzugFromUuid?: string): void {
    const points = isBuildingElement ? this.buildingElementsCoordinatePoints : this.measurementCoordinatePoints;
    const length = points.length;
    // no line possible yet
    if (length < 2) return;
    const startPoint = points[length - 2];
    const endPoint = points[length - 1];

    if (isBuildingElement) {
      this.drawBuildingElement(startPoint, endPoint, abzugFromUuid);
      return;
    }
    this.drawWand(length, startPoint, endPoint);
  }

  private drawWand(length: number, startPoint: CoordinatePoint, endPoint: CoordinatePoint): void {
    const index = length - 1;
    const color = 'red';
    const aufmassRoute = new MeasurementRoute(startPoint, endPoint, index);
    this.drawLine(aufmassRoute, color);
    this.addLabels(aufmassRoute.middleCoordinatePoint, aufmassRoute.label, color);
    this.aufmassRoutes.push(aufmassRoute);
    this.routeClosed = MeasurementRoute.isRouteClosed(this.aufmassRoutes);
  }

  private drawBuildingElement(startPoint: CoordinatePoint, endPoint: CoordinatePoint, deductionFromUuid: string): void {
    const template = this.currentBuildingElement;
    const buildingElementIndex = this.buildingElementRoutes.length + 1;
    const buildingElementColor = 'blue';
    this.buildingElementsCoordinatePoints = [];
    const elementIndex = this.buildingElementRoutes?.filter(
      bauElementEntry => bauElementEntry.labelLong?.startsWith(template.name),
    )?.length;
    const alternatingIndex = elementIndex ? elementIndex + 1 : 1;
    const buildingElement = new BuildingElement(
      startPoint,
      endPoint,
      template,
      buildingElementIndex,
      deductionFromUuid,
      alternatingIndex,
    );
    this.drawLine(buildingElement, buildingElementColor);
    this.addLabels(buildingElement.middleCoordinatePoint, buildingElement.label, buildingElementColor);
    this.drawIcon(buildingElement.middleCoordinatePoint, buildingElement.template.iconPath);
    this.buildingElementRoutes.push(buildingElement);
    this.setCurrentMode('none');
  }

  /**@description Paint an icon at the point */
  private drawIcon(middleCoordinatePoint: CoordinatePoint, iconPath: string): void {
    const size = iconSize;
    const image = new Image(width, clientHeight);
    image.src = iconPath;
    context.drawImage(image, middleCoordinatePoint.xCoordinate, middleCoordinatePoint.yCoordinate, size, size);
  }

  /**@description Paints a CoordinatePoint - checks if it is close to an existing one and then interprets the point as a connection with already existing one */
  private drawRectangle(
    point: CoordinatePoint,
    measurementCoordinatePoints: CoordinatePoint[],
    connectToCloseDot = true,
    color = 'red',
    drawOnGrid = this.gridEnabled,
  ): CoordinatePoint {
    context.fillStyle = color;
    const thickNess = 10;
    const offSet = thickNess / 2;
    if (drawOnGrid) point = this.getGridIntersection(point);
    if (connectToCloseDot) point = MeasurementHelper.getExistingPointIfClose(point, measurementCoordinatePoints);
    context.fillRect(point.xCoordinate - offSet, point.yCoordinate - offSet, thickNess, thickNess);
    return point;
  }

  /**@description Write a text in the picture*/
  private addLabels(point: CoordinatePoint, label: string, color = 'red'): void {
    context.font = '20px Arial';
    context.fillStyle = color;
    context.fillText(label, point.xCoordinate, point.yCoordinate);
  }

  private drawLine(measurementRoute: MeasurementRoute, color = 'black'): void {
    const { startCoordinatePoint, endCoordinatePoint } = measurementRoute;

    context.save();
    context.strokeStyle = color;
    context.beginPath();
    context.moveTo(startCoordinatePoint.xCoordinate, startCoordinatePoint.yCoordinate);
    context.lineTo(endCoordinatePoint.xCoordinate, endCoordinatePoint.yCoordinate);
    context.stroke();
    context.restore();
  }

  private getGridIntersection(point: CoordinatePoint): CoordinatePoint {
    const grid = this.grid;
    let shortestXDistance = width;
    let shortestYDistance = height;
    let shortestXPoint = new CoordinatePoint(width, height);
    let shortestYPoint = new CoordinatePoint(width, height);
    for (const line of grid) {
      const xDistance = Math.abs(point.xCoordinate - line.startCoordinatePoint.xCoordinate);
      if (xDistance < shortestXDistance) {
        shortestXDistance = xDistance;
        shortestXPoint = line.startCoordinatePoint;
      }
      const yDistance = Math.abs(point.yCoordinate - line.startCoordinatePoint.yCoordinate);
      if (yDistance < shortestYDistance) {
        shortestYDistance = yDistance;
        shortestYPoint = line.startCoordinatePoint;
      }
    }

    const closestIntersection = new CoordinatePoint(shortestXPoint.xCoordinate, shortestYPoint.yCoordinate);
    return closestIntersection;
  }

  private placeBuildingElementsInWall(point: CoordinatePoint): void {
    const aufmassRouteCorrespondingToBuildingElement = this.placeBuildingElementsOnRoute(point, this.aufmassRoutes);
    if (aufmassRouteCorrespondingToBuildingElement) {
      this.drawMeasurementRoute(true, aufmassRouteCorrespondingToBuildingElement.uuid);
    } else this.drawStackElements.pop();
  }

  private placeBuildingElementInRoom(point: CoordinatePoint): void {
    this.buildingElementsCoordinatePoints.push(point);
    const mockSecondPointForMockRoute = new CoordinatePoint(point.xCoordinate + 1, point.yCoordinate + 1);
    this.buildingElementsCoordinatePoints.push(mockSecondPointForMockRoute);
    const position = this.currentBuildingElement.inFloor ? 'boden' : 'decke';

    this.drawMeasurementRoute(true, position);
  }

  /**@description resets everything to "new" */
  private resetAufmassDrawing(): void {
    this.aufmassRoutes = [];
    this.measurementCoordinatePoints = [];
    this.coordinateLabels = [];
    this.buildingElementRoutes = [];
    this.buildingElementsCoordinatePoints = [];
    this.routeClosed = false;
    this.drawStackElements = [];
    context.clearRect(0, 0, this.bssCanvas.nativeElement.width, this.bssCanvas.nativeElement.height);
    this.drawBackground('white');
    this.setCurrentMode('none');
  }

  private async saveCurrentLocally(gotoMeasurement: boolean): Promise<void> {
    if (this.measurementCoordinatePoints.length === 0) {
      this.routingService.routeBack();
      return;
    }
    const aufmass = this.aufmass;
    const canvas = this.bssCanvas.nativeElement;
    const resize = MeasurementHelper.getMeasurementPictureBoundaries(this.measurementCoordinatePoints, iconSize);
    const pictureDataBase64 = await MeasurementHelper.cutoutOfCanvas(
      canvas.toDataURL('image/jpeg'),
      resize.boundaryX,
      resize.boundaryY,
      resize.width,
      resize.height,
    );
    const pictureFile = DocumentHelper.createJpgFromBase64('picture', pictureDataBase64);
    const compressPicture = await DocumentHelper.customCompress(pictureFile);
    const dataUrl = await DocumentHelper.blobToDataUrl(compressPicture);
    const aufmassConstruct = new MeasurementConstruct(
      dataUrl,
      this.aufmassRoutes,
      this.buildingElementRoutes,
      this.coordinateLabels,
    );
    aufmassConstruct.addRoomAreas();
    aufmass.usedGridFactor = this.userFactor;
    aufmass.assignMeasurementConstructToCorrectRoom(this.room, aufmassConstruct);
    aufmass.assignDrawStackElements(this.room.Uuid, this.drawStackElements, this.gridEnabled);
    await this.measurementService.overrideOneLocal(aufmass);
    this.routingService.dataChanged.next(false);
    if (gotoMeasurement) void this.routingService.navigateTo(`aufmass/messung/${aufmass.Uuid}/${this.room.Uuid}`);
    else this.routingService.routeBack();
  }

  private getCorrectDrawMode(
    gridEnabled: boolean,
    currentMode: InputMode,
    currentBuildingElement: BuildingElementTemplate,
  ): InputMode {
    if (gridEnabled && currentMode === 'drawPoints') currentMode = 'wallGrid';
    if (currentMode === 'buildingElement' && currentBuildingElement.inRoom) currentMode = 'buildingElementRoom';
    if (currentMode === 'buildingElement' && !currentBuildingElement.inRoom) currentMode = 'buildingElementWall';
    return currentMode;
  }

  private removeItemsFromDrawStack(): void {
    const removedUnpaired = this.removeUnpairedBuildingParts();
    if (removedUnpaired) return;
    const lastItem = this.getPreviousDrawElements(1);
    // Two points are needed to display a building Element in a wall
    if (this.isWallBuildingElement(lastItem)) {
      this.removeLastBuildingElement();
      this.removeLastBuildingElement();
    } else this.drawStackElements.pop();
  }

  // Checks if there are unpaired building parts and removes them if necesarry
  // Used when a single dot for a door is placed on a wall and then switches drawMode
  private removeUnpairedBuildingParts(): boolean {
    const itemsInARow = this.matchingBuildingElementsInARow();
    if (itemsInARow % 2 === 1) {
      this.removeLastBuildingElement();
      return true;
    }
    return false;
  }
}

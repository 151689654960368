import { Injectable } from '@angular/core';
import { PwaLoginResponse, Right } from 'libs/shared/src/lib/entities';
import { FeatureNames } from 'libs/shared/src/lib/entities/models/user/FeatureCheck';
import { AuthorizationService } from 'libs/shared/src/lib/services/authorization.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { datenschutzPath, hilfePath } from '../../config/Konstanten';
import { NavigationItem } from '../../entities';
import { HWGlobalSettingService } from './hwGlobalSetting.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationItemService {
  logOut: NavigationItem = new NavigationItem('Abmelden', '/abmelden', 'runner');

  // Logout as default incase of error when loading PWA
  currentNavigationItems = new BehaviorSubject<NavigationItem[]>([this.logOut]);
  constructor(
    private rightsService: RightsService,
    private stateService: StateService,
    private authorizationService: AuthorizationService,
    private globalSettingService: HWGlobalSettingService
  ) {
    const loggedInSubscription = this.stateService.loggedIn;
    const currentRight = this.rightsService.getCurrentRightAsync();
    const currentAuthorization = this.authorizationService.current;

    combineLatest([loggedInSubscription, currentRight, currentAuthorization]).subscribe(
      ([loggedIn, right, authorization]: [boolean, Right, PwaLoginResponse]) => {
        this.getCurrentNavigationItems(loggedIn, right, authorization);
      }
    );
  }

  /**
   * Gets called when one of the values changes
   * @param loggedIn Is the user currently logged in
   * @param inputRight The rights of the user
   * @param authorization Login response with current Version of Software and database to check features
   * Changes the navigation used in the sideMenu component
   */
  private getCurrentNavigationItems(loggedIn: boolean, inputRight: Right, authorization: PwaLoginResponse): void {
    const navigationItems = loggedIn ? this.getLoggedInItems(inputRight, authorization) : this.getLoggedOutItems();

    this.currentNavigationItems.next(navigationItems);
  }

  private getLoggedInItems(inputRight: Right, authorization: PwaLoginResponse): NavigationItem[] {
    // Right is null during loginProcess because loggedIn is true before the User loaded Rights
    const employeeRights = inputRight?.employeeRights;
    if (!employeeRights) return [];
    const isPremium = employeeRights.showObjektadressen;
    const hasBaseFeature = authorization?.featureCheck(FeatureNames.base).available;
    const messages = new NavigationItem(
      'Nachrichten',
      '/nachrichten',
      undefined,
      employeeRights.repairOrderRights.enableModule,
      hasBaseFeature
    );
    const repairOrders = new NavigationItem(
      'Reparaturaufträge',
      '/reparaturauftraege',
      undefined,
      employeeRights.repairOrderRights.enableModule,
      hasBaseFeature
    );

    const maintenanceSystems = new NavigationItem(
      'Anlagen',
      '/wartungsanlagen',
      undefined,
      employeeRights.maintenanceRights.enableMaintenanceSystems,
      isPremium,
      hasBaseFeature
    );
    const maintenanceOrders = new NavigationItem(
      'Aufträge',
      '/wartungsauftraege',
      undefined,
      employeeRights.maintenanceRights.handleOrder,
      isPremium,
      hasBaseFeature
    );

    const roomMeasurements = new NavigationItem(
      'Raumaufmaß',
      '/aufmasse',
      undefined,
      employeeRights.measurementRights.enableModule,
      isPremium,
      hasBaseFeature
    );
    const freeMeasurement = new NavigationItem(
      'Freies Aufmaß',
      '/freeMeasurement',
      undefined,
      employeeRights.measurementRights.enableModule,
      isPremium,
      hasBaseFeature
    );
    const showFreeMeasurement =
      !this.globalSettingService.isInProdEnvironment() && !this.globalSettingService.isInQsEnvironment();

    const modules: NavigationItem[] = [
      new NavigationItem(
        'Reparaturcenter',
        undefined,
        undefined,
        employeeRights.repairOrderRights.enableModule,
        isPremium,
        hasBaseFeature,
        [repairOrders, messages],
        false
      ),
      new NavigationItem(
        'Wartung und Service',
        undefined,
        undefined,
        employeeRights.maintenanceRights.enableModule,
        isPremium,
        hasBaseFeature,
        [maintenanceSystems, maintenanceOrders],
        false
      ),
      new NavigationItem(
        'Aufmaß',
        undefined,
        undefined,
        employeeRights.measurementRights.enableModule,
        isPremium,
        hasBaseFeature,
        showFreeMeasurement ? [roomMeasurements, freeMeasurement] : [roomMeasurements]
      ),
    ];

    const baseItems: NavigationItem[] = [
      new NavigationItem('Startseite', '/startseite', 'home'),
      new NavigationItem('Adressen', '/adressen', 'user'),
      new NavigationItem('Belege', '/belege', 'verticalaligntop', employeeRights.receiptRights.enableModule),
      new NavigationItem('Kalender', '/kalender', 'event', employeeRights.appointmentRights.enableModule),
      new NavigationItem(
        'Module',
        'module',
        'box',
        true,
        employeeRights.showObjektadressen,
        hasBaseFeature,
        modules,
        false
      ),
      new NavigationItem('Entdecken', '/entdecken', 'find'),
      new NavigationItem('Einstellungen', '/einstellungen', 'preferences'),
      new NavigationItem('Synchronisation', '/Synchronisation', 'refresh'),
    ];

    return baseItems.concat(this.getOtherNavigationItems()).concat(this.logOut);
  }

  private getLoggedOutItems(): NavigationItem[] {
    const loginNavItem = new NavigationItem('Login', '/Login', 'user');

    return [loginNavItem].concat(this.getOtherNavigationItems());
  }

  private getOtherNavigationItems(): NavigationItem[] {
    return [
      new NavigationItem('Impressum', '/impressum', 'info'),
      new NavigationItem('Datenschutz', datenschutzPath, 'checklist'),
      new NavigationItem('Hilfe', hilfePath, 'help'),
    ];
  }
}

import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { IndexedDBTypes } from './dbType';

/**############ Wird aktuell nicht benötigt ############# */
export class AufmPosX extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('AufmPosX') KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') Uuid: string = null;
  @IndexedDBTypes.DataField('string') AufmId: string = null;
  @IndexedDBTypes.DataField('number') PosLineId: number = null;
  @IndexedDBTypes.DataField('number') LineId: number = null;
  @IndexedDBTypes.DataField('number') AbzugId: number = null;
  @IndexedDBTypes.DataField('number') RaumbId: number = null;
  @IndexedDBTypes.DataField('number') Rpos_Id: number = null;
  @IndexedDBTypes.DataField('number') RaumLineId: number = null;
  @IndexedDBTypes.DataField('string') Bezeich: string = null;
  @IndexedDBTypes.DataField('number') ZResult: number = null;
  @IndexedDBTypes.DataField('string') Datum: string = null;
  @IndexedDBTypes.DataField('boolean') IsAbzug: boolean = null;
  @IndexedDBTypes.DataField('boolean') NotCalc: boolean = null;
  @IndexedDBTypes.DataField('string') Aufmass: string = null;
  @IndexedDBTypes.DataField('number') Id: number = null;
  constructor(aufmPosX: AufmPosX) {
    super();
    GlobalHelper.assignIfPropertyExists(this, aufmPosX);
  }

  static toString(): string {
    return 'AufmPosX';
  }
}

<div class="flexContainer card repair-order-card" *ngIf="repairOrder" [ngClass]="repairOrder?.StatusObject.stateClass">
  <div class="flexContainerItem" style="display: flex">
    <div class="flexContainerItem" (click)="goToRepairOrderDetail()">
      <b>{{ repairOrder.Nummer }} - {{ repairOrder.Termin }}</b
      ><br />
      <b>Betreff: {{ repairOrder.Betreff }}</b> <br />
      <p>{{ repairOrder.AusfAnspr }}</p>
      <div *ngIf="address">
        <p *ngIf="address.FallbackDisplayString">
          {{ address.FallbackDisplayString }}
        </p>
        <p *ngIf="address.Strasse">{{ address.Strasse }}</p>
        <p *ngIf="address.Ort">{{ address.Postleitzahl }} {{ address.Ort }}</p>
      </div>
    </div>
    <div (click)="toggleContactBar()" style="padding: 0 15px">
      <i class="dx-icon dx-icon-chevrondown"></i>
    </div>
  </div>
  <div *ngIf="showContactBar" class="flexContainerItem">
    <app-bss-contact-bar
      [telephoneNumber]="repairOrder.Kunde.TEL"
      [mobileNumber]="repairOrder.Kunde.FUNK_PRIV"
      [address]="repairOrder.Kunde.Anschrift"
      [website]="repairOrder.Kunde.WWW"
      [eMailAddress]="repairOrder.Kunde.GLOBEMAIL"
    ></app-bss-contact-bar>
  </div>
</div>

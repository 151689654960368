<div class="content-block bottom-padding" *ngIf="serviceOrder">
  <div class="form-block">
    <h2>Servicedetails</h2>

    <div *ngIf="customer?.LIEFERSPERRE === true" class="bss-alert bss-alert-danger">Liefersperre hinterlegt</div>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <div class="dx-field">
            <div class="dx-field-label">Status</div>
            <div class="dx-field-value" style="display: flex; margin-bottom: 10px">
              {{ stateText }}
            </div>
          </div>

          <app-bss-plain-text-box
            *ngIf="serviceOrder.Auftragsnummer"
            [labelText]="'Serviceauftrag'"
            [value]="serviceOrder.Auftragsnummer"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            labelText="Monteur 1"
            [value]="(firstEmployee | async)?.NAME"
          ></app-bss-plain-text-box>
          <app-bss-select-box
            labelText="Monteur 2"
            [displayExpr]="'NAME'"
            [placeholder]="(secondEmployee | async)?.NAME"
            [items]="selectableEmployees"
            (selectedValue)="selectSecondEmployee($event)"
            [disabled]="userInfo?.monteur !== serviceOrder.Monteur1"
          ></app-bss-select-box>
          <app-bss-array-organizer
            *ngIf="selectableEmployees"
            [label]="'Zusätzliche Monteure'"
            [selectedValues]="selectedEmployees"
            [possibleChoices]="selectableEmployees"
            [displayProperty]="'NAME'"
            (newArray)="updateEmployeeList($event)"
            [labelText]="'Monteur auswählen'"
            [placeholder]="'Monteur auswählen'"
            (closingWithEditedContent)="reloadWithSaving()"
          >
          </app-bss-array-organizer>
          <app-bss-plain-text-box *ngIf="betreff" [labelText]="'Betreff'" [value]="betreff"></app-bss-plain-text-box>
        </div>
      </div>
      <div class="dx-fieldset">
        <div class="fieldbox">
          <app-bss-plain-text-box
            *ngIf="serviceOrder.TerminObject?.start"
            [labelText]="'Beginn'"
            [value]="serviceOrder.TerminObject?.start?.substring(0, 16)"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            *ngIf="serviceOrder.TerminObject?.finish"
            [labelText]="'Ende'"
            [value]="serviceOrder.TerminObject?.finish?.substring(0, 16)"
          ></app-bss-plain-text-box>
        </div>
      </div>
      <div class="dx-fieldset">
        <app-bss-toggle-textarea
          *ngIf="maintenanceSystem"
          [buttonText]="'Arbeitsbeschreibung'"
          [(value)]="maintenanceSystem.ABESCHR"
          [disabled]="true"
        ></app-bss-toggle-textarea>

        <div class="card-detail" *ngIf="maintenanceSystem">
          <app-system-card [anlage]="maintenanceSystem"></app-system-card>
        </div>

        <app-bss-button-link
          [title]="'Positionen'"
          [displayCount]="currentPositionAmount"
          [path]="'auftragpositionen/wartungsauftrag/' + serviceOrder.UUID"
        ></app-bss-button-link>

        <app-bss-button-link
          [title]="'Medien'"
          [path]="'medien/ServiceOrder/' + serviceOrder.UUID"
          [displayCount]="currentFilesAmount"
        >
        </app-bss-button-link>
      </div>
    </div>
  </div>
</div>

<div *ngIf="serviceOrder">
  <app-bss-speed-dial
    icon="photo"
    label="Medium hinzufügen"
    [index]="2"
    (Click)="navigateTo('medien/ServiceOrder/' + serviceOrder.UUID + '/upload')"
  >
  </app-bss-speed-dial>

  <app-bss-speed-dial
    *ngIf="serviceOrder.Status === serviceOrderStates.Assigned"
    icon="add"
    label="Auftrag beginnen"
    [index]="0"
    (Click)="tryBegin()"
    (ClickDisabled)="checkRights(false)"
    [disabled]="!(isMainEmployee | async)"
  >
  </app-bss-speed-dial>

  <app-bss-speed-dial
    *ngIf="serviceOrder.Status === serviceOrderStates.InProgress"
    icon="check"
    label="Auftrag abschließen"
    (Click)="finishOrder()"
    [index]="1"
  >
  </app-bss-speed-dial>

  <app-bss-speed-dial
    *ngIf="serviceOrder.Status === serviceOrderStates.InProgress"
    icon="add"
    label="Position hinzufügen"
    [index]="0"
    (Click)="goToPositions()"
  >
  </app-bss-speed-dial>
</div>

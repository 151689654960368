<h2 class="content-block">Kontakte</h2>

<div class="content-block">
  <app-bss-list
    [id]="'address-contacts'"
    [inputDataSource]="contacts"
    [searchExpressions]="searchExpressions"
    [emptyInputDataMessage]="'Es sind keine Kontakte vorhanden'"
  ></app-bss-list>
</div>

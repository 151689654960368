import * as uuid from 'uuid';

export class ModuleCalls {
  uuid: string;
  moduleName: string;
  callCount: number;

  constructor(moduleName: string, callCount: number) {
    this.uuid = uuid.v4();
    this.moduleName = moduleName;
    this.callCount = callCount;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RecursiveHelper {
  recursiveFlatMap<Type, Key extends keyof Type>(inputItems: Type[], property: Key): Type[] {
    return inputItems.concat(this.getRecursiveItemsFlat(inputItems, property));
  }

  private getRecursiveItemsFlat<Type, Key extends keyof Type>(inputItems: Type[], property: Key): Type[] {
    const propertyName = property.toString();
    let newItems = inputItems.flatMap(e => e[propertyName]);

    if (newItems.some(e => e[propertyName].length !== 0)) {
      newItems = newItems.concat(this.getRecursiveItemsFlat(newItems, property));
    }

    return newItems;
  }
}

import { Component, OnInit } from '@angular/core';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import { environment } from 'apps/handwerkPWA/src/environments/environment';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  qrEnabled = false;
  isProduction: boolean;
  manuellLogin = false;
  userInfo: UserInfo;
  showHiddenUpload = false;

  constructor(
    private loginService: LoginService,
    private globalSettingService: HWGlobalSettingService,
    private routingService: RoutingService,
  ) {}

  async logOutt(): Promise<void> {
    await this.loginService.logOut();
  }

  async ngOnInit(): Promise<void> {
    const hostname = window.location.hostname;
    const productionUrl =
      hostname.startsWith('192.') || hostname.startsWith('local') || hostname.startsWith('topappdev') ? false : true;
    this.isProduction = environment.production && productionUrl;

    const userInfoFromParams = this.checkForRouteparametersForManuallLogin();
    if (!GlobalHelper.isNullOrUndefined(userInfoFromParams)) {
      this.userInfo = userInfoFromParams;
      this.manuellLogin = true;
      return;
    }

    const userInfoFromIDB = await this.globalSettingService.getUserInfo();
    this.userInfo = GlobalHelper.isNullOrUndefined(userInfoFromIDB) ? new UserInfo(null) : userInfoFromIDB;
    if (!GlobalHelper.isNullOrUndefined(this.userInfo) && !GlobalHelper.isNullOrUndefined(this.userInfo.pin)) {
      await this.loginService.loginUser(this.userInfo, this.userInfo.updateDonePwa);
    }
  }

  /**@description Logt den Nutzer per Button Login ein */
  createUserInfoFromLogindata(uuidValue: string, monteurString: string, mobilePin: string, mandantValue: string): void {
    const userInfo = new UserInfo(null);
    userInfo.uuid = uuidValue;
    userInfo.monteur = monteurString;
    userInfo.employeeNumber = userInfo.monteur;
    userInfo.pin = mobilePin;
    userInfo.mandant = mandantValue;

    void this.loginService.loginUser(userInfo);
  }

  /**@description Logt den Nutzer über seinen gescannten QR-Code ein */
  qrLogin(userInfoData: UserInfo): void {
    const userInfo = new UserInfo(userInfoData);
    void this.loginService.loginUser(userInfo);
  }

  /**@description Wird aufgerufen, wenn in der QRComponent auf 'Abbrechen' geklickt wird */
  scanStop(event: boolean): void {
    this.qrEnabled = event;
  }

  activateQrScan(event: MouseEvent): void {
    this.qrEnabled = true;
    if (event.ctrlKey)
      // wenn strg gedrückt gehalten wird beim klick auf den button, ist im qrscan der upload an
      this.showHiddenUpload = true;
  }

  /**@description Ließt url parameter und wenn zumindest die uuid gefunden wurde, wird ein manueller login damit getriggert */
  private checkForRouteparametersForManuallLogin(): UserInfo {
    const employeeNumberValue = this.routingService.getRouteParam('employeeNumber');
    const brandingValue = this.routingService.getRouteParam('branding');
    const mandantValue = this.routingService.getRouteParam('mandant');
    const uuidValue = this.routingService.getRouteParam('uuid');
    const userInfo = new UserInfo(null);
    userInfo.monteur = employeeNumberValue;
    userInfo.mandant = mandantValue;
    userInfo.branding = brandingValue;
    userInfo.uuid = uuidValue;

    if (!mandantValue) return null;
    return userInfo;
  }
}

import { PositionsArt } from 'apps/handwerkPWA/src/app/entities';

export class LongTypeHelper {
  static getLongtypeFromId(ident: string, isRepairorderPosition: boolean): PositionsArt {
    switch (ident) {
      case 'M':
      case 'm': {
        // normale Artikel
        return 'Artikel';
      }
      case 'J':
      case 'l': {
        // Leistung
        return 'Leistung';
      }
      case 'l': {
        return 'Unterleistung';
      }
      case 'S':
      case 's': {
        // Lohn
        if (isRepairorderPosition) {
          return 'Lohn';
        } else {
          return 'Undefiniert';
        }
      }
      case 'D':
      case 'd': {
        // geräte
        return 'Gerät';
      }
      case 'F':
      case 'f': {
        // fremdleistung
        return 'Fremdleistung';
      }
      case 'O':
      case 'o': {
        // sonstiges
        return 'Sonstiges';
      }
      case 'T': {
        // sonstiges
        return 'Textposition';
      }
      default: {
        // fallback
        return 'Undefiniert';
      }
    }
  }

  static getLongtypeFromKundenpreisId(ident: string): PositionsArt {
    switch (ident) {
      case 'M': {
        // normale Artikel
        return 'Artikel';
      }
      case 'L': {
        // Lohn
        return 'Lohn';
      }
      default: {
        // fallback
        return 'Undefiniert';
      }
    }
  }
}

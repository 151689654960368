export class NavigationItem {
  text: string;
  path: string;
  hasRight? = true;
  hasLicense? = true;
  hasFeature?: boolean;
  icon?: string;
  isExpanded?: boolean;
  items?: NavigationItem[];
  customDisabled?: boolean;
  recursionLevel: number;

  constructor(
    text: string,
    path: string,
    icon?: string,
    hasRight = true,
    hasLicense = true,
    hasFeature = true,
    items: NavigationItem[] = [],
    isExpanded?: boolean
  ) {
    this.recursionLevel = this.recursionLevel ? this.recursionLevel : 0;
    this.text = text;
    this.path = path;
    this.hasRight = hasRight;
    this.hasLicense = hasLicense;
    this.hasFeature = hasFeature;
    this.icon = icon;
    this.addRecursionLevels(items);
    this.items = items;
    this.isExpanded = isExpanded;
    this.customDisabled = !this.hasRight || !this.hasFeature || !this.hasLicense;
  }

  addRecursionLevels(items: NavigationItem[], recursionLevel = 0): NavigationItem[] {
    for (const item of items) {
      item.recursionLevel = recursionLevel + 1;
      if (item.items.length !== 0) {
        item.addRecursionLevels(item?.items, recursionLevel + 1);
      }
    }
    return items;
  }
}

import { Injectable } from '@angular/core';
import { ControllerService } from '../globalServices/controller.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor(protected controllerService: ControllerService) {}

  public async getAll<Type>(className: new (data) => Type): Promise<Type[]> {
    const rawDataArray = await this.controllerService.getData<Type[]>(className.toString());

    return this.rawDataArrayToObjectsArray(className, rawDataArray);
  }

  public async getAllBy<Type>(className: new (data) => Type, selector: string, value: string): Promise<Type[]> {
    const rawDataArray = await this.controllerService.getData<Type[]>(className.toString(), value, selector);

    return this.rawDataArrayToObjectsArray(className, rawDataArray);
  }

  public async getAllByTwoSelector<Type>(
    className: new (data) => Type,
    selector: string,
    value: string,
    selector2: string,
    value2: unknown
  ): Promise<Type[]> {
    const rawDataArray = await this.controllerService.getData<Type[]>(className.toString(), value, selector);
    const objectsArray = this.rawDataArrayToObjectsArray(className, rawDataArray);
    const filtered = objectsArray.filter(entry => entry[selector2] === value2);
    return filtered;
  }

  public async findOneBy<Type>(className: new (data) => Type, selector: string, value: string): Promise<Type> {
    const rawData = await this.controllerService.getData(className.toString(), value, selector);
    const object = new className(rawData[0]);

    return object;
  }

  public async findOneByTwoSelector<Type>(
    className: new (data) => Type,
    selector: string,
    value: string,
    selector2: string,
    value2: unknown
  ): Promise<Type> {
    const allData = await this.getAllBy(className, selector, value);
    return allData.find(entry => entry[selector2] === value2);
  }

  public async destroy<Type>(className: new (data) => Type, selector: string, value: string): Promise<void> {
    await this.controllerService.deleteData(className.toString(), selector, value);
  }

  public rawDataArrayToObjectsArray<Type>(className: new (data) => Type, rawDataArray: Type[]): Type[] {
    const objectsArray: Type[] = [];

    rawDataArray?.map(rawData => objectsArray.push(new className(rawData)));

    return objectsArray;
  }
}

import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import {
  AppOnlySettings,
  Aufmass,
  BuildingElement,
  MeasurementConstruct,
  MeasurementRoute,
  RoomName,
} from 'apps/handwerkPWA/src/app/entities';
import { FormulaHelper } from 'libs/shared/src/lib/helper/formelHelper';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { timer } from 'rxjs';
import { BssMeasuringFieldComponent } from '../../../components';
import { GlobalSettings } from '../../../config/Konstanten';
import { formulaSearch } from '../../../config/search-constants';
import { Room } from '../../../entities/models/aufmass/Room';
import { RoomBookPosition } from '../../../entities/models/aufmass/RoomBookPosition';
import { MeasurementService } from '../../../services/dataServices/measurement.service';
import { RoomBookService } from '../../../services/dataServices/roomBook.service';
import { RoomNameService } from '../../../services/dataServices/roomName.service';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';

@Component({
  selector: 'app-aufmass-messung',
  templateUrl: './aufmass-messung.component.html',
  styleUrls: ['./aufmass-messung.component.scss'],
})
export class AufmassMessungComponent implements OnInit {
  @ViewChildren(BssMeasuringFieldComponent) measureFields: QueryList<BssMeasuringFieldComponent>;
  measurementConstruct: MeasurementConstruct;
  roomNames: RoomName[];
  room: RoomBookPosition;
  aufmass: Aufmass;
  wallsDone = false;
  saveAble = false;
  distanceMeterMode = true;
  handleFieldJumpManually: boolean;
  searchExpressions = formulaSearch;

  constructor(
    private routingService: RoutingService,
    private measurementService: MeasurementService,
    private roomNameService: RoomNameService,
    private roomBookService: RoomBookService,
    private globalSettingService: HWGlobalSettingService,
    private dialogService: DialogService,
  ) {}

  async ngOnInit(): Promise<void> {
    const guid = this.routingService.getRouteParam('aufmassid');
    const roomId = this.routingService.getRouteParam('roomId');
    this.aufmass = await this.measurementService.findOneBy('Uuid', guid);
    const roomBookPositions = this.aufmass.getRoomBookPosition();
    this.room = roomBookPositions.find(position => position.Uuid === roomId);
    this.roomNames = await this.roomNameService.getAllAreaDesignations();
    this.measurementConstruct = this.room.reconstructMeasurementConstruct();

    const appOnlySettings = await this.globalSettingService.getEntity<AppOnlySettings>(GlobalSettings.AppOnlySettings);
    this.handleFieldJumpManually =
      appOnlySettings.aufmassAutoFillRectangleRooms && this.measurementConstruct.isRectangle();
    this.wallsDone = this.measurementConstruct?.MeasurementRoutes.every(
      entry => !GlobalHelper.isNullOrUndefined(entry.length),
    );
    this.saveAble = this.measurementConstruct?.MeasurementRoutes.every(
      entry => !GlobalHelper.isNullOrUndefined(entry.length),
    );
  }

  /**@description Sobald ein Wert geändert wird - prüft anschließend ob damit alle Pflichtmessungen erfüllt sind (alle Wände,Höhe,alle Pflichtfelder aller BuildingElements) */
  measurementChanged(
    route: MeasurementRoute | BuildingElement,
    event: string,
    handleFieldJumpManually: boolean,
    heightOrDepth?: 'hoehe' | 'tiefe',
  ): void {
    if (!heightOrDepth) route.length = parseFloat(event);
    if (heightOrDepth === 'hoehe') (route as BuildingElement).height = parseFloat(event);
    if (heightOrDepth === 'tiefe') (route as BuildingElement).depth = parseFloat(event);

    if (handleFieldJumpManually) this.fillParallelWall(route);

    const allWallsMeasured = this.measurementConstruct.checkAllWallsMeasured();

    if (allWallsMeasured) {
      this.measurementConstruct.tryConstructFloorAndCeilingFormula(
        this.measurementConstruct.MeasurementRoutes.flatMap(entry => entry.length),
      );
      this.wallsDone = true;
    }
    const allBuildingElementsMeasured = this.measurementConstruct.checkAllBuildingElementsMeasured();
    const heightMeasured = !GlobalHelper.isNullOrUndefined(this.measurementConstruct.roomHeight.length);

    this.saveAble = allWallsMeasured && allBuildingElementsMeasured && heightMeasured;

    if (handleFieldJumpManually) this.jumpToNextEmptyField();
  }

  validateFormula(formula: string, fromInput?: 'Deckenfläche' | 'Bodenfläche'): boolean {
    const valid = formula ? FormulaHelper.validateFormula(formula) : true;
    if (!valid)
      void this.dialogService.openErrorMessage(
        'Eingabefehler',
        `Ihre Eingabe ${formula} ist ungültig und kann nicht berechnet werden. Bitte geben Sie nur Zahlen, Rechenzeichen und Klammern ein.`,
      );
    if (valid && fromInput === 'Deckenfläche')
      this.measurementConstruct.ceilingArea.formula = formula?.replaceAll('.', ',');
    if (valid && fromInput === 'Bodenfläche')
      this.measurementConstruct.floorArea.formula = formula?.replaceAll('.', ',');
    return valid;
  }

  async measurementComplete(): Promise<void> {
    const measurement = this.aufmass;
    const roomBookPosition = this.room;
    const measurementConstruct = this.measurementConstruct;
    this.setFloorCeilingToZeroIfUndefined(measurementConstruct);
    if (
      !this.validateFormula(measurementConstruct.floorArea.formula) ||
      !this.validateFormula(measurementConstruct.ceilingArea.formula)
    )
      return;
    this.routingService.dataChanged.next(false);

    measurement.assignMeasurementConstructToCorrectRoom(roomBookPosition, measurementConstruct);

    const roomBookEntry = measurementConstruct.convertToRoomBookEntries(roomBookPosition);

    const room = new Room(roomBookEntry, measurementConstruct.pictureDataBase64, roomBookPosition, true);

    await this.roomBookService.saveFloorPlan(measurement, room, false);
    await this.measurementService.overrideOneLocal(measurement);
    void this.routingService.navigateTo(`aufmass/${measurement.Uuid}/raum/${roomBookPosition.Uuid}`);
  }

  /**
   * @description
   *  Bei einer rechteckigen Wand, bei der gegenüberliegende Seiten autoamtisch gefüllt werden sollen, springt
   * dies die nächste leere Fläche an */
  private jumpToNextEmptyField(): void {
    const delay = timer(300); // minimaler delay war wegen tieferer asynchronität nötig - dann wirkte aber ein höherer delay optisch besser
    delay.subscribe(() => {
      const nextEmptyField = this.measureFields.find(field =>
        GlobalHelper.isNullOrUndefinedOrEmptyString(field.measurement),
      );
      nextEmptyField?.clickNextMeasuringField(nextEmptyField.fieldIndex - 1, true);
    });
  }

  /**@description Setzt die Laenge einer gegenüberliegenden wand , wenn diese noch unbelegt ist */
  private fillParallelWall(route: MeasurementRoute): void {
    const parallelWall = this.measurementConstruct.MeasurementRoutes.find(
      entity => entity.pointDistance === route.pointDistance && entity.label !== route.label,
    );
    if (!parallelWall) return;
    parallelWall.length = GlobalHelper.isNullOrUndefined(parallelWall.length) ? route.length : parallelWall.length;
  }

  private setFloorCeilingToZeroIfUndefined(aufmassConstruct: MeasurementConstruct): void {
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(aufmassConstruct.floorArea.formula))
      aufmassConstruct.floorArea.formula = '0';
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(aufmassConstruct.ceilingArea.formula))
      aufmassConstruct.ceilingArea.formula = '0';
  }
}

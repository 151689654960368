export const noLicenceLong = 'Ihnen fehlt die nötige Zugriffslizenz für diese Funktion.';

export const noLicenceShort = 'Lizenz nicht vorhanden';

export const noRightLong = 'Ihnen fehlt das nötige Zugriffsrecht für diese Funktion.';

export const noRightShort = 'Recht nicht vorhanden';

export const featureUnavailableShort = 'Feature nicht verfügbar';

export const featureUnavailableLong =
  'Dieses Programmfeature steht Ihnen nicht zur Verfügung, da die Version Ihres Handwerksmandanten oder Webservices zu niedrig ist.';

export const editImpossible = 'Bearbeitung nicht möglich';

export const noEditServiceOrderReason =
  'Der Auftrag kann nicht bearbeitet werden, da Sie nicht der Hauptmonteur sind oder der Auftrag noch nicht begonnen wurde.';

export const ServiceOrderNotInProgress =
  'Der Auftrag kann nicht bearbeitet werden, da der Auftrag noch nicht begonnen wurde.';

export const ServiceOrderNotMainEmployee =
  'Der Auftrag kann nicht bearbeitet werden, da Sie nicht der Hauptmonteur sind.';

export const beginImpossible = 'Auftragsbeginn nicht möglich';

export const beginImpossibleReason = 'Der Auftrag kann nicht begonnen werden, da Sie nicht der Hauptmonteur sind.';

export const readonlyPosition =
  'Sie können diese Position nicht bearbeiten, da Sie nicht der Hauptmonteur sind oder der Auftrag noch nicht begonnen wurde.';

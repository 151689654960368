import { Belegpositionen } from 'apps/handwerkPWA/src/app/entities/models/Belegpositionen';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { TimeHelper } from 'libs/shared/src/lib/helper/timeHelper';
import moment from 'moment';
import { deDateFormat } from '../../config/Konstanten';
import { SyncObject } from '../models/SyncObject';
import { IndexedDBTypes } from './dbType';
const belegstatusArray: string[] = [
  'In Arbeit',
  'Offen',
  'Abgelehnt',
  'In Ausführung',
  'Ausgeliefert',
  'Wiedervorlage',
  'Erledigt',
];

export class HWBeleg extends IndexedDBTypes.DbType implements SyncObject {
  @IndexedDBTypes.KlassenName('HWBeleg') KlassenName: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: number;
  /**Ist die Kundennummer */
  @IndexedDBTypes.IndexField('string') kunde: string;
  /**name wird in Belegposition als "belegnummer" geführt */
  @IndexedDBTypes.IndexField('string') name: string;
  @IndexedDBTypes.DataField('string') Belegart: string;
  @IndexedDBTypes.DataField('string') betreff: string;
  @IndexedDBTypes.DataField('string') typundnr: string;
  @IndexedDBTypes.DataField('string') brutto: string;
  @IndexedDBTypes.DataField('string') datum: string;
  @IndexedDBTypes.DataField('string') mahnst: string;
  @IndexedDBTypes.DataField('string') mwst: string;
  @IndexedDBTypes.DataField('string') netto: string;
  @IndexedDBTypes.DataField('string') nummer: string;
  @IndexedDBTypes.DataField('string') rechbez: string;
  @IndexedDBTypes.DataField('string') status: string;
  @IndexedDBTypes.DataField('string') PLZ: string;
  @IndexedDBTypes.DataField('string') STRASSE: string;
  @IndexedDBTypes.DataField('string') SUCH: string;
  @IndexedDBTypes.DataField('string') username: string;
  @IndexedDBTypes.DataField('string') kundenname = '';
  @IndexedDBTypes.DataField('Belegpositionen[]') Belegpositionen: Belegpositionen[] = [];
  @IndexedDBTypes.DataField('string') statusReadable: string;
  @IndexedDBTypes.DataField('Date') addedToIdb: Date;
  @IndexedDBTypes.DataField('string') jahr: string;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: HWBeleg) {
    super();
    Object.assign(this, data);
    this.addTimeStampIfNotExisting();
    this.addStatusReadable(this.status);
    this.addJahr(this.datum);
  }

  static toString(): string {
    return 'HWBeleg';
  }

  /**@description Wenn noch keiner vorhanden ist, wird der timestamp hinzugefügt */
  addTimeStampIfNotExisting(): void {
    if (!GlobalHelper.isNullOrUndefined(this.addedToIdb)) {
      return;
    }
    const now = new Date();
    this.addedToIdb = now;
  }

  addBelegposition(belegPosition: Belegpositionen): void {
    if (GlobalHelper.isNullOrUndefined(this.Belegpositionen)) {
      this.Belegpositionen = [];
    }
    this.Belegpositionen.push(belegPosition);
  }

  addStatusReadable(statusNumberString: string): void {
    if (GlobalHelper.isNullOrUndefined(statusNumberString)) {
      return;
    }
    if (this.Belegart === '2') {
      this.statusReadable = 'Mahnstufe ' + this.status;
      return;
    }
    const statusNumber = parseInt(statusNumberString, 10);
    const statusArt = belegstatusArray[statusNumber];
    this.statusReadable = 'Status: ' + statusArt;
  }

  addJahr(datum: string): void {
    if (GlobalHelper.isNullOrUndefined(datum)) {
      return;
    }
    this.jahr = TimeHelper.getYear(moment(datum, deDateFormat).toDate());
  }
}

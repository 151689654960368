import { MeasurementDrawStackElement } from './MeasurementDrawStackElement';

export class MeasurementDrawStack {
  roomUuid: string;
  MeasurementDrawStackElements: MeasurementDrawStackElement[] = [];
  gridEnabled: boolean;

  constructor(roomUuid: string, stackElements: MeasurementDrawStackElement[], gridEnabled: boolean) {
    this.roomUuid = roomUuid;
    this.MeasurementDrawStackElements = stackElements;
    this.gridEnabled = gridEnabled;
  }
}

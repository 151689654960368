import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { addressContactsSearch } from '../../../config/search-constants';
import { AddressService } from '../../../services/dataServices/address.service';
import { ContactsService } from '../../../services/dataServices/contacts.service';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';

@Component({
  selector: 'app-address-contact',
  templateUrl: './address-contact.component.html',
  styleUrls: ['./address-contact.component.scss'],
})
export class AddressContactComponent implements OnInit {
  searchExpressions = addressContactsSearch;
  contacts = [];

  constructor(
    private globalSettingService: HWGlobalSettingService,
    private contactService: ContactsService,
    private routingService: RoutingService,
    private addressService: AddressService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAddressContacts();
  }

  private async loadAddressContacts(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const addressGuid = this.routingService.getRouteParam('guid');
    const address = await this.addressService.findOneBy('Guid', addressGuid);
    this.contacts = await this.contactService.getKontakteByKundennummerFromWebservice(userInfo, address.KU_NR);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { HWAddress, HWRepairOrder, RepairOrderStatus } from 'apps/handwerkPWA/src/app/entities';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import { Right, UserInfo } from 'libs/shared/src/lib/entities';
import { ContactHelper } from 'libs/shared/src/lib/helper/contactHelper';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { TimeHelper } from 'libs/shared/src/lib/helper/timeHelper';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DxDateType, RepairOrderStates, deDateTimeFormat } from '../../../config/Konstanten';
import { ActivityTrackerService } from '../../../services/dataServices/activityTracker.service';
import { RepairOrderService } from '../../../services/dataServices/repairOrder.service';
import { MediaService } from '../../../services/globalServices/media.service';

@Component({
  selector: 'app-repair-order-edit',
  templateUrl: './repair-order-edit.component.html',
  styleUrls: ['./repair-order-edit.component.scss'],
})
export class RepairOrderEditComponent implements OnInit, OnDestroy {
  repairOrder: HWRepairOrder;
  repairOrderStates: RepairOrderStatus[] = RepairOrderStatus.createAllStates();
  availableStates: RepairOrderStatus[];
  ansprechTelefonVorhanden = false;
  customer: HWAddress;
  selectableEmployees: HWAddress[];
  allEmployees: HWAddress[];
  firstEmployee: BehaviorSubject<HWAddress> = new BehaviorSubject<HWAddress>(null);
  secondEmployee: BehaviorSubject<HWAddress> = new BehaviorSubject<HWAddress>(null);
  autoLohnOptions = [0, 5, 10, 15];
  currentAutolohnRounding = 0;
  autoLohnChangeRight: boolean;
  roundingAllowed: boolean;
  positionsAnzahl = '';
  preisRecht: boolean;
  routeChanged: Subscription;
  userInfo: UserInfo;
  rights: Right;
  messagePath: string;
  orderType = 'reparaturauftrag';
  orderId: string;
  minEnde: Date;
  maxEnde: Date;
  currentFilesAmount: string;
  blocked = false;
  appointment: Date;
  currentEndTime: Date;
  locationText: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  dateType = DxDateType;
  callPhone = ContactHelper.callPhone;

  constructor(
    private globalSettingService: HWGlobalSettingService,
    private repairOrderService: RepairOrderService,
    private addressService: AddressService,
    private dialogService: DialogService,
    private rightsService: RightsService,
    private routingService: RoutingService,
    private mediaService: MediaService,
    private activityTrackerService: ActivityTrackerService
  ) {}

  ngOnDestroy(): void {
    this.routeChanged?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.userInfo = await this.globalSettingService.getUserInfo();
    // Subscription für den zentralen Floating Button
    this.routeChanged = this.routingService.save.subscribe((nextRoute: string) => {
      void this.saveAndExit(nextRoute);
    });

    this.orderId = this.routingService.getRouteParam('guid');

    this.repairOrder = await this.repairOrderService.findOneBy('Guid', this.orderId);
    this.appointment = TimeHelper.parseTermin(this.repairOrder?.Termin);
    this.parseMinFromTermin(this.appointment, false);
    this.messagePath = `nachricht/neu/${this.orderId}`;

    this.rights = this.rightsService.getCurrentRight();
    this.handleRights(this.rights);
    this.getAnzahlOfEntries();
    const kundenNummer = this.repairOrder.KundenNummer;
    this.customer = await this.addressService.findOneBy('KU_NR', kundenNummer);
    this.availableStates = this.repairOrder.StatusObject.getAvailableStates(this.repairOrderStates);
    this.ansprechTelefonVorhanden = this.determineAnsprechtelefonVorhanden(this.repairOrder);
    const files = await this.mediaService.getAllBy('Baseuuid', this.repairOrder.getUuid());
    this.currentFilesAmount = GlobalHelper.calculateDataAmount(files);
    this.getStandortText();
    await this.updateAssignableEmployees();

    await this.assignEmployee();
  }

  async assignEmployee(): Promise<void> {
    if (this.repairOrder.Monteur1 !== '') return;
    const confirmed = await this.dialogService.openConfirmDialog(
      'Reparaturauftrag',
      'Dieser Reparaturauftrag ist Ihnen noch nicht zugeordnet. Möchten Sie den Reparaturauftrag übernehmen?',
      'Übernehmen',
      'Nicht übernehmen',
      false
    );
    if (!confirmed) {
      this.routingService.routeBack();
      return;
    }

    const repairOrder = await this.repairOrderService.acceptRepairOrder(this.repairOrder, this.userInfo);
    if (!repairOrder?.Monteur1) {
      await this.dialogService.openErrorMessage('Fehler', 'Fehler beim annehmen des Reparaturauftrags');
      this.routingService.routeBack();
    }
    this.repairOrder = repairOrder;
    this.availableStates = this.repairOrder.StatusObject.getAvailableStates(this.repairOrderStates);
  }

  async selectSecondEmployee(selectedEmployee: HWAddress): Promise<void> {
    this.repairOrder.Monteur2 = selectedEmployee.KU_NR;
    this.repairOrder.edited = true;
    await this.updateAssignableEmployees();
    void this.repairOrderChanged();
  }

  async changeAutolohnActive(autolohnActive: boolean): Promise<void> {
    this.repairOrder.Autolohn.isActive = autolohnActive;
    await this.repairOrderChanged();
  }

  async repairOrderChanged(): Promise<void> {
    if (this.blocked) return;
    this.blocked = true;
    const updatedRepairOrder = await this.repairOrderService.sendRepairOrderToWebService(this.repairOrder, true);
    await this.activityTrackerService.countUpAutoSyncs();
    this.repairOrder = updatedRepairOrder;
    this.blocked = false;
  }

  handleRights(right: Right): void {
    const autoLohnPreset = right.employeeSettings.autoWage;
    this.autoLohnChangeRight = right.inAppChangeRights.autoWage;
    this.roundingAllowed = right.inAppChangeRights.rounding;
    // autolohn hat vom rechtegui die werte, aus, nicht runden oder die rundung als string
    this.repairOrder.handleAutolohnAllowed(autoLohnPreset, this.autoLohnChangeRight);
    this.currentAutolohnRounding = this.repairOrder.handleAutolohnPreset(autoLohnPreset);
  }

  async changeAutolohnRundung(rundung: number): Promise<void> {
    this.repairOrder.Autolohn.rundung = rundung;
    if (rundung === 0) {
      await this.repairOrderService.sendRepairOrderToWebService(this.repairOrder, true);
      return;
    }
    await this.callRundungsDialog();
  }

  /**@description Guckt ob Telefondaten des Ansprechpartners hinterlegt sind */
  determineAnsprechtelefonVorhanden(repairOrder: HWRepairOrder): boolean {
    if (!GlobalHelper.isNullOrUndefined(repairOrder.AnsprMobil)) return true;
    if (!GlobalHelper.isNullOrUndefined(repairOrder.AnsprTel)) return true;
    return false;
  }

  getAnzahlOfEntries(): void {
    const repairOrder = this.repairOrder;
    const notDeletedItems = repairOrder.getDisplayPositions();
    if (!GlobalHelper.isNullOrUndefined(notDeletedItems) && notDeletedItems.length > 0)
      this.positionsAnzahl = `(${notDeletedItems.length})`;
  }

  openMaps(arbeitsOrtOrStrasse: string, plz?: string, ortInput?: string): void {
    if (ortInput) {
      const ort = this.repairOrder.Ort;
      const street = this.repairOrder.Strasse;
      window.open('https://www.google.com/maps/search/?api=1&query=' + street + '%2C+' + ort, '_blank');
      return;
    }
    const queryStart = 'https://www.google.com/maps/search/';
    const queryEnd = arbeitsOrtOrStrasse.replaceAll(' ', '+');
    window.open(queryStart + queryEnd, '_blank');
  }

  /**@description Setzt den geänderten Status - dazu wird eine Arbeitszeit(beginn oder ende) hinzugefügt, wenn auf begonnen gesetzt wird
   * oder wenn auf abgebrochen gesetzt wird und man zuvor auf begonnen war (damit nichts als arbeitszeit gezählt wird wenn zwischen angenommen
   * und abgebrochen gewechselt wird)
   */
  async stateChange(newState: RepairOrderStatus): Promise<void> {
    // TODO Wird dass an dieser Stelle überhaupt gebraucht? Gebraucht wird die Funktion nur innerhalb des Dropdown
    if (GlobalHelper.isNullOrUndefined(newState)) {
      return;
    }

    let rejectionConfirmation: boolean;
    if (newState.stateNumber === RepairOrderStates.Rejected) {
      rejectionConfirmation = await this.dialogService.openConfirmDialog(
        'Auftrag ablehnen',
        'Wollen Sie den Auftrag wirklich ablehnen?',
        'Ja',
        'Nein'
      );
      if (!rejectionConfirmation) {
        return;
      }
    }

    this.repairOrder.StatusObject = new RepairOrderStatus(newState.stateNumber);
    this.repairOrder.Status = newState.stateNumber;
    this.availableStates = this.repairOrder.StatusObject.getAvailableStates(this.repairOrderStates);

    this.addArbeitszeitenIfNeeded(newState.stateNumber);
    const repairOrder = await this.repairOrderService.sendRepairOrderToWebService(this.repairOrder, true);
    if (repairOrder && rejectionConfirmation) {
      await this.navigateTo('/reparaturauftraege');
    }
  }
  async callRundungsDialog(): Promise<void> {
    if (this.repairOrder.Autolohn.rundung !== 0)
      this.repairOrder.Autolohn.aufrundung = await this.dialogService.openConfirmDialog(
        'Rundung auswählen',
        '',
        'Aufrunden',
        'Abrunden',
        false
      );
    await this.repairOrderService.sendRepairOrderToWebService(this.repairOrder, true);
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  getStandortText(): void {
    this.locationText.next([this.repairOrder.Standort1, this.repairOrder.Standort2].filter(item => item).join(' / '));
  }

  /**
   * @description Wenn sich der Termin ändert - um anschließend das MinDatum(Uhrzeit zu Parsen),
   */
  parseMinFromTermin(termin: Date, change = true): void {
    if (change) this.repairOrder.Termin = moment(termin).format(deDateTimeFormat);
    const minDate = new Date(termin);
    this.minEnde = minDate;
    const maxDate = new Date(termin);
    maxDate.setHours(23, 59, 59);
    this.maxEnde = maxDate;
    this.currentEndTime = this.repairOrderService.getEndTimeDate(termin, this.repairOrder);
    if (change) void this.repairOrderChanged();
  }

  /**@description Fügt arbeitszeiten hinzu wenn sie gebraucht werden */
  private addArbeitszeitenIfNeeded(state: number): void {
    if (state === RepairOrderStates.InProgress || state === RepairOrderStates.Interrupted)
      this.repairOrder.addArbeitszeit();
  }

  private async updateAssignableEmployees(): Promise<void> {
    let filteredEmployees = this.allEmployees;
    if (!filteredEmployees) {
      const dbEmployees = await this.addressService.getAllBy('ADRTYP', 'M');
      filteredEmployees = dbEmployees.filter(employee => !employee?.LeaveDate || employee.LeaveDate > new Date());
      this.firstEmployee.next(dbEmployees.find(e => e.KU_NR === this.repairOrder.Monteur1));
      this.secondEmployee.next(dbEmployees.find(e => e.KU_NR === this.repairOrder.Monteur2));

      this.allEmployees = filteredEmployees;
    }
    //filter out assigned employees
    this.selectableEmployees = filteredEmployees.filter(
      employee => employee !== this.firstEmployee.getValue() && employee !== this.secondEmployee.getValue()
    );
  }

  /**@description saves  */
  private async saveAndExit(nextRoute: string): Promise<void> {
    await this.repairOrderService.sendRepairOrderToWebService(this.repairOrder, true);
    await this.routingService.navigateTo(nextRoute);
    this.dialogService.closeLoadingDialog();
  }
}

<div class="content-block bottom-padding" *ngIf="measurement">
  <div class="form-block">
    <h2>Raum: {{ room.Bezeich }}</h2>

    <div *ngIf="roomBookEntries?.length == 0">
      Es wurden noch keine Messdaten erfasst. <br /><br />
      Wählen Sie unten rechts "Grundriss-Skizze erstellen", um eine neue Grundriss-Skizze zu erstellen <br /><br />
      oder <br /><br />
      wählen Sie "Grundriss-Skizze bearbeiten", um für eine bereits vorhandenen Grundriss-Skizze Messdaten einzugeben.
    </div>

    <div *ngIf="roomBookEntries?.length > 0">
      <div class="upperGrid">
        <b>Raumbucheinträge:</b>
        <app-bss-button-link
          style="width: 31%"
          *ngIf="roomBookEntries?.length !== 0"
          [title]="'Fertig'"
          [path]="measurementPath"
        >
        </app-bss-button-link>
      </div>

      <dx-data-grid (onRowClick)="gotoDimensionChain($event.data)" class="" [dataSource]="roomBookEntries">
        <dxo-editing [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false" mode="cell"> </dxo-editing>
        <dxi-column
          [width]="'28%'"
          [caption]="'Bezeichnung'"
          [dataField]="'getBezeich'"
          dataType="string"
          [allowEditing]="false"
        >
        </dxi-column>
        <dxi-column
          [width]="'28%'"
          [caption]="'Formel'"
          [dataField]="'measurement'"
          dataType="string"
          [allowEditing]="false"
        >
        </dxi-column>
        <dxi-column
          [width]="'20%'"
          [caption]="'Summe'"
          [dataField]="'Zresult'"
          dataType="number"
          [allowEditing]="false"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </dxi-column>
        <dxi-column
          [width]="'10%'"
          [caption]="'Abzug'"
          [dataField]="'IsAbzug'"
          dataType="boolean"
          [allowEditing]="false"
        >
        </dxi-column>
        <dxi-column
          [width]="'14%'"
          [caption]="'Nicht berechnen'"
          [dataField]="'NotCalc'"
          dataType="boolean"
          [allowEditing]="false"
        >
        </dxi-column>
      </dx-data-grid>

      <div class="sumBox" *ngIf="sums">
        <app-bss-numberbox
          [labelText]="'Brutto (Gesamt)'"
          [value]="sums.gross"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        ></app-bss-numberbox>
        <app-bss-numberbox
          [labelText]="'Netto (Gesamt)'"
          [value]="sums.net"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </app-bss-numberbox>
        <app-bss-numberbox
          [labelText]="'Differenz (Gesamt)'"
          [value]="sums.difference"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </app-bss-numberbox>
      </div>

      <div class="sumBox" *ngIf="sums">
        <app-bss-numberbox
          [labelText]="'Brutto (Wände)'"
          [value]="sums.grossWalls"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </app-bss-numberbox>
        <app-bss-numberbox
          [labelText]="'Netto (Wände)'"
          [value]="sums.netWalls"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </app-bss-numberbox>
        <app-bss-numberbox
          [labelText]="'Differenz (Wände)'"
          [value]="sums.differenceWalls"
          [disabled]="true"
          [format]="{ type: 'fixedPoint', precision: 2 }"
        >
        </app-bss-numberbox>
      </div>
    </div>
  </div>
</div>

<app-bss-speed-dial
  *ngIf="!entriesExists"
  icon="add"
  class="add-button"
  [label]="'Grundriss-Skizze erstellen'"
  [index]="2"
  (Click)="drawGroundPlan()"
>
</app-bss-speed-dial>

<app-bss-speed-dial
  *ngIf="entriesExists"
  icon="redo"
  class="add-button"
  [label]="'Grundriss-Skizze neu erstellen'"
  [index]="2"
  (Click)="drawGroundPlan()"
>
</app-bss-speed-dial>

<app-bss-speed-dial
  *ngIf="entriesExists"
  icon="edit"
  class="add-button"
  label="Grundriss-Messung bearbeiten"
  [index]="1"
  (Click)="editMeasurement()"
>
</app-bss-speed-dial>

<app-bss-speed-dial
  icon="add"
  class="add-button"
  label="Einzelaufmaß hinzufügen"
  [index]="0"
  (Click)="gotoDimensionChain(null)"
>
</app-bss-speed-dial>

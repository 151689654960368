import { Injectable } from '@angular/core';
import { DeviceHelper } from '../helper/deviceHelper';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  downloadFile(file: File): void {
    const blob = new Blob([file], { type: file.type });
    this.triggerDownload(blob, file.name);
  }

  downloadFileFromBase64(base64String: string, fileName: string): void {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    this.triggerDownload(blob, fileName || 'downloadedFile');
  }

  downloadFileFromDataUrl(dataUrl: string, contentType: string, fileName): void {
    const blob = DeviceHelper.b64toBlob(dataUrl, contentType);
    this.triggerDownload(blob, fileName);
  }

  private triggerDownload(blob: Blob, fileName: string): void {
    const a = document.createElement('a');
    const objectUrl = window.URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(objectUrl);
  }
}

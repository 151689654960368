import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import * as uuid from 'uuid';
import { RoomTemplate } from '../..';
import { RoomBookPositionLevels } from '../../../config/Konstanten';
import { UuidEntity } from '../../../interfaces';
import { Aufmass } from '../../repository/Aufmass';
import { BssDxTreeviewNode } from '../BssDxTreeviewNode';
import { BuildingElement } from './BuildingElement';
import { MeasurementConstruct } from './MeasurementConstruct';
import { MeasurementRoute } from './MeasurementRoute';

// Bound to values of RoomTemplate as it gets them from HW
export class RoomBookPosition extends BssDxTreeviewNode implements UuidEntity {
  AufmId: string = null;
  Stw_ID: number = null;
  Wng_ID: number = null;
  Raumb_ID: number = null;
  Rpos_ID: number = null;
  NrAufmId: number = null; // laut Olaf - nächste LineId im RoomBook???
  Bezeich: string = null;
  Uuid: string = null;
  /**Gehört nicht zur Handwerkstabelle */
  MeasurementConstruct: MeasurementConstruct = null;
  transferred: boolean = null;
  icon: string;
  hasDimensionChain: boolean;
  level: RoomBookPositionLevels;

  constructor(roomBookPosition: RoomBookPosition) {
    super();
    GlobalHelper.assignIfPropertyExists(this, roomBookPosition);

    // Assign information for tree nodes
    this.text = this.Bezeich;
  }
  getUuid(): string {
    return this.Uuid;
  }

  /**@description Fills most of the information depending on the level using either a sibling node or a parent node */
  fillNewFromRelative(parent: RoomBookPosition, parentIsRoomBook: boolean, measurement: Aufmass): void {
    const {
      maxFloorId: maxFloorId,
      maxApartmentId: maxApartmentId,
      maxRoomId: maxRoomId,
    } = this.getMaxIds(parent, parentIsRoomBook);
    this.buildClearBaseData(measurement, parent);
    if (parentIsRoomBook) {
      this.buildNewFloor(parent, maxFloorId);
      return;
    }
    if (parent.isFloor()) {
      this.buildNewApartment(parent, maxApartmentId);
      return;
    }
    if (parent.isApartment()) {
      this.buildNewRoom(parent, maxRoomId);
      return;
    }
  }

  assignNextRposid(nextPosLineId: number): void {
    this.Rpos_ID = nextPosLineId;
  }

  isFloor(): boolean {
    return this.Raumb_ID === 0 && this.Wng_ID === 0;
  }

  /**
   * @descriptionThe concept of apartment is critical here - in a multi-family house this relation fits,
   * in a single-family house it corresponds to the level below the floor
   */
  isApartment(): boolean {
    return this.Raumb_ID === 0 && this.Wng_ID !== 0;
  }

  isRoom(): boolean {
    return !this.isFloor() && !this.isApartment();
  }

  findAccordingFloor(position: RoomBookPosition[]): RoomBookPosition {
    const roomBookPosition = position.slice();
    return roomBookPosition.find(sPosition => sPosition.isFloor() && this.Stw_ID === sPosition.Stw_ID);
  }

  findAccordingApartment(positions: RoomBookPosition[]): RoomBookPosition {
    if (this.level >= 2) return null;
    const roomBookPosition = positions.slice();
    return roomBookPosition.find(
      position => position.isApartment() && this.Stw_ID === position.Stw_ID && this.Wng_ID === position.Wng_ID
    );
  }

  findAccordingRoom(positions: RoomBookPosition[]): RoomBookPosition {
    const roomBookPositions = positions.slice();
    return roomBookPositions.find(position => position.isRoom() && this.parentId === position.Uuid);
  }

  /**@description Erstellt mithilfe der RoomBookvorlage und den Etagen eine */
  createRoomBookAsRoomBookPosition(roomEntity: RoomTemplate, floors: RoomBookPosition[]): void {
    this.items = floors;
  }

  /**@description Deletes the information of the tree node, because they do not belong to the actual RoomBookPosition */
  deleteNodeInformation(): void {
    delete this.id;
    delete this.text;
    delete this.items;
    delete this.parentId;
    delete this.selected;
    delete this.expanded;
    delete this.level;
  }

  /**@description Rebuilds the object by going through the constructors again and then assigning values*/
  reconstructMeasurementConstruct(): MeasurementConstruct {
    const pictureData = this.MeasurementConstruct;
    if (!pictureData) return null;
    const reconstruction = new MeasurementConstruct(null, null, null, null);
    GlobalHelper.assignIfPropertyExists(reconstruction, pictureData);
    const measurementRoutes: MeasurementRoute[] = [];
    for (const route of reconstruction?.MeasurementRoutes) {
      const cleanRoute = new MeasurementRoute(null, null, null);
      GlobalHelper.assignIfPropertyExists(cleanRoute, route);
      measurementRoutes.push(cleanRoute);
    }
    const buildingElements: BuildingElement[] = [];
    for (const buildingElement of reconstruction?.buildingElement) {
      const cleanElement = new BuildingElement(null, null, null, null, null, null);
      GlobalHelper.assignIfPropertyExists(cleanElement, buildingElement);
      buildingElements.push(cleanElement);
    }
    reconstruction.MeasurementRoutes = measurementRoutes;
    reconstruction.buildingElement = buildingElements;
    reconstruction.roomHeight = this.MeasurementConstruct.roomHeight;

    const floorArea = new MeasurementRoute(null, null, null);
    GlobalHelper.assignIfPropertyExists(floorArea, this.MeasurementConstruct.floorArea);
    reconstruction.floorArea = floorArea;

    const ceilingArea = new MeasurementRoute(null, null, null);
    GlobalHelper.assignIfPropertyExists(ceilingArea, this.MeasurementConstruct.ceilingArea);
    reconstruction.ceilingArea = ceilingArea;
    return reconstruction;
  }

  private buildClearBaseData(measurement: Aufmass, parent: RoomBookPosition): void {
    this.Bezeich = '';
    this.text = this.Bezeich;
    this.Uuid = uuid.v4();
    this.items = [];
    this.NrAufmId = parent.NrAufmId;
    this.parentId = parent.Uuid;
    this.AufmId = measurement.AufmId;
  }

  private buildNewRoom(apartment: RoomBookPosition, maxRoomId: number): void {
    this.Wng_ID = apartment.Wng_ID;
    this.Stw_ID = apartment.Stw_ID;
    this.Raumb_ID = maxRoomId + 1;
    this.level = RoomBookPositionLevels.room;
  }

  private buildNewApartment(floor: RoomBookPosition, maxApartmentId: number): void {
    this.Stw_ID = floor.Stw_ID;
    this.Raumb_ID = floor.Raumb_ID;
    this.Wng_ID = maxApartmentId + 1;
    this.level = RoomBookPositionLevels.apartment;
  }

  private buildNewFloor(roomBook: RoomBookPosition, maxFloorId: number): void {
    const siblingFloor = roomBook?.items[0] as RoomBookPosition;
    this.Stw_ID = maxFloorId + 1;
    this.Wng_ID = 0;
    this.level = RoomBookPositionLevels.floor;
    this.Raumb_ID = 0;
    if (!siblingFloor) {
      this.NrAufmId = 0;
      return;
    }
    this.NrAufmId = siblingFloor.NrAufmId;
  }

  /**@description Fetches the highest ids of the respective level */
  private getMaxIds(
    parent: RoomBookPosition,
    parentIsRoomBook: boolean
  ): { maxFloorId: number; maxApartmentId: number; maxRoomId: number } {
    const allSiblings = parent.items as RoomBookPosition[];
    if (allSiblings.length === 0) return { maxFloorId: parentIsRoomBook ? -1 : 0, maxApartmentId: 0, maxRoomId: 0 };
    const floorIds = allSiblings.flatMap(room => room.Stw_ID);
    const apartmentIds = allSiblings.flatMap(room => room.Wng_ID);
    const roomIds = allSiblings.flatMap(room => room.Raumb_ID);
    const maxFloorId = Math.max(...floorIds);
    const maxApartmentId = Math.max(...apartmentIds);
    const maxRoomId = Math.max(...roomIds);
    return { maxFloorId, maxApartmentId, maxRoomId };
  }
}

<div class="content-block" *ngIf="currentItem && order">
  <div class="form-block">
    <h2>Position bearbeiten</h2>

    <div class="dx-card responsive-paddings">
      <fieldset [disabled]="!editable">
        <div class="dx-fieldset">
          <app-bss-textarea *ngIf="!editable" [labelText]="'Lese-Modus'" [value]="readonlyPositionText">
          </app-bss-textarea>

          <!-- Differenzierung für full size Bezeichnung, falls Position eine KM-Pauschale ist -->
          <div class="fieldbox" *ngIf="currentItem.Nummer != '{\\rtf1\\ansi\\deff0\\'">
            <app-bss-plain-text-box [labelText]="currentType + '-Nummer'" [value]="currentItem.Nummer">
            </app-bss-plain-text-box>
            <app-bss-textbox
              *ngIf="currentType != 'Textposition'"
              [labelText]="'Bezeichnung'"
              [value]="currentItem.getBezeichnung()"
              (valueChange)="korrektur(currentItem, order, $event, 'Bezeichnung')"
            >
            </app-bss-textbox>
          </div>

          <div style="padding-bottom: 20px" *ngIf="currentItem.Nummer == '{\\rtf1\\ansi\\deff0\\'">
            <app-bss-textbox
              *ngIf="currentType != 'Textposition'"
              [labelText]="'Bezeichnung'"
              [value]="currentItem.getBezeichnung()"
              (valueChange)="korrektur(currentItem, order, $event, 'Bezeichnung')"
            >
            </app-bss-textbox>
          </div>
          <!--  -->

          <app-bss-textarea
            *ngIf="currentType == 'Textposition'"
            [maxLength]="2000"
            [labelText]="'Langtext'"
            [value]="currentItem.getLangtext()"
            (valueChange)="korrektur(currentItem, order, $event, 'Langtext')"
          ></app-bss-textarea>

          <div style="padding-bottom: 20px">
            <app-bss-number-popup
              *ngIf="currentType != 'Textposition' && currentType != 'Lohn'"
              [labelText]="'Menge'"
              [mengenTyp]="currentItem.getMengenEinheit()"
              [title]="'Menge ändern'"
              [description]="currentItem.getBezeichnung()"
              [inputValue]="currentItem.Menge"
              (outputValue)="korrektur(currentItem, order, $event, 'Menge')"
              [DecimalPlaces]="2"
              [DecimalPlacesInputEnabled]="true"
              [ValidateInputDecimals]="true"
            >
            </app-bss-number-popup>
          </div>

          <app-bss-time-input
            *ngIf="currentType == 'Lohn'"
            [lohnPosition]="currentItem"
            (outputMinutes)="korrektur(currentItem, order, $event, 'Zeit')"
          >
          </app-bss-time-input>

          <div class="fieldbox" *ngIf="showPrices && currentType != 'Textposition'">
            <app-bss-number-popup
              [labelText]="einzelpreisDescription"
              [title]="einzelpreisDescription"
              [mengenTyp]="'€'"
              [description]="'Geben Sie den ' + einzelpreisDescription + ' ein'"
              (outputValue)="korrektur(currentItem, order, $event, 'Preis', false)"
              [inputValue]="einzelPreis"
              [disabled]="currentType === 'Leistung'"
              [DecimalPlaces]="2"
              [DecimalPlacesInputEnabled]="true"
              [ValidateInputDecimals]="true"
            >
            </app-bss-number-popup>

            <app-bss-number-popup
              [labelText]="gesamtPreisDescription"
              [title]="gesamtPreisDescription"
              [mengenTyp]="'€'"
              [description]="'Geben Sie den ' + gesamtPreisDescription + ' ein'"
              (outputValue)="korrektur(currentItem, order, $event, 'Preis', true)"
              [inputValue]="gesamtPreis"
              [disabled]="currentType === 'Leistung'"
              [DecimalPlaces]="2"
              [DecimalPlacesInputEnabled]="true"
              [ValidateInputDecimals]="true"
            >
            </app-bss-number-popup>
          </div>

          <div *ngIf="order && leistungsPositionen?.length > 0">
            <b>Stückliste:</b>

            <app-bss-list
              [id]="'stueckliste'"
              [inputDataSource]="leistungsPositionen"
              [priceRight]="showPrices"
              [secondaryDataSource]="order"
              [searchExpressions]="searchExpressions"
              [userInfo]="userInfo"
            ></app-bss-list>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</div>

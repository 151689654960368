import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.scss'],
})
export class ReloadComponent implements OnInit {
  constructor(private routingService: RoutingService) {}

  async ngOnInit(): Promise<void> {
    const currentRoute = this.routingService.getCurrentRoute()?.replace('/reload', '');
    await this.routingService.navigateTo('/' + currentRoute);
  }
}

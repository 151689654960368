<div class="card termincard" (click)="goToAppointmentDetail()">
  <p *ngIf="showDate">
    <b>{{ appointment.indexDate }}</b>
  </p>
  <p *ngIf="!isTask">
    <b>
      {{ appointment.start.substring(11, 16) }} Uhr bis {{ appointment.finish.substring(11, 16) }} Uhr
      {{ appointmentText }}
    </b>
  </p>
  <p *ngIf="isTask"><b>Aufgabe</b></p>

  <p>{{ appointment.location }}</p>
  <p *ngIf="appointment.kundenName">{{ appointment.caption }} - {{ appointment.kundenName }}</p>
  <p *ngIf="!appointment.kundenName">{{ appointment.caption }} - (Kein Kunde eingetragen)</p>
  <p *ngIf="appointment.mitarbeiterName">{{ appointment.mitarbeiterName }}</p>
</div>

<div class="content-block" *ngIf="viewFinished">
  <div class="form-block">
    <h2>Messungen</h2>

    <div class="dx-card responsive-paddings messungen">
      <div style="padding-bottom: 10px">
        <app-bss-select-box
          id="dateSelectBox"
          *ngIf="possibleMesswerttabellen && possibleMesswerttabellen.length != 0"
          [labelText]="'Messwert vom'"
          [items]="possibleMesswerttabellen"
          [displayExpr]="'Messdatum'"
          (selectedValue)="selectMesswertsatz(possibleMesswerttabellen, $event)"
          [value]="selectedMesswertsatz"
        ></app-bss-select-box>
      </div>

      <div *ngIf="possibleMesswerttabellen && possibleMesswerttabellen.length == 0">Keine Messungen vorhanden</div>

      <div class="messungenBox">
        <div style="flex: 1">Beschr.</div>
        <div style="flex: 1">Sollwert</div>
        <div style="flex: 2" class="messungenBox" *ngIf="selectedMesswerteintraege">
          <div style="flex: 2">Istwert</div>
          <div style="flex: 0.8">Einheit</div>
        </div>
      </div>

      <div *ngIf="selectedMesswerteintraege">
        <div style="padding-bottom: 10px">
          <div *ngFor="let sollwert of sollwertTabelle.MesswertTabellenEintraege; let i = index">
            <app-bss-measuring-field
              [label]="sollwert.Beschreibung"
              [noSollwert]="false"
              [sollwert]="sollwert.Messwert"
              [measurement]="selectedMesswerteintraege[i].Messwert"
              [unit]="sollwert.Einheit"
              [disabled]="true"
            >
            </app-bss-measuring-field>
          </div>
        </div>

        <app-bss-toggle-textarea
          *ngIf="sollwertTabelle"
          [buttonText]="'Beschreibung Sollwert'"
          [(value)]="sollwertTabelle.Beschreibung"
          [disabled]="true"
        ></app-bss-toggle-textarea>
        <app-bss-toggle-textarea
          *ngIf="selectedMesswertsatz"
          [buttonText]="'Bemerkung Istwert'"
          [(value)]="selectedMesswertsatz.Beschreibung"
          [disabled]="true"
        ></app-bss-toggle-textarea>
      </div>

      <div *ngIf="!selectedMesswerteintraege">
        <div *ngFor="let sollwert of sollwertTabelle.MesswertTabellenEintraege; let i = index">
          <app-bss-measuring-field
            [label]="sollwert.Beschreibung"
            [sollwert]="sollwert.Messwert"
            [unit]="sollwert.Einheit"
            [disabled]="true"
          >
          </app-bss-measuring-field>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { MeasurementTypes, SliderNames } from '../../../config/Konstanten';
import { measurementSearch } from '../../../config/search-constants';
import { Aufmass } from '../../../entities';
import { MeasurementService } from '../../../services/dataServices/measurement.service';
import { ConnectionDialogues, ConnectionService } from '../../../services/globalServices/connection.service';
import { SyncObjectService } from '../../../services/globalServices/syncObject.service';

@Component({
  selector: 'app-aufmass-index',
  templateUrl: './aufmass-index.component.html',
  styleUrls: ['./aufmass-index.component.scss'],
})
export class AufmassIndexComponent implements OnInit {
  measurements: Aufmass[];
  sliderNames = SliderNames;
  searchExpression = measurementSearch;

  constructor(
    private measurementService: MeasurementService,
    private routingService: RoutingService,
    private connectionService: ConnectionService,
    private syncObjectService: SyncObjectService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.measurements = await this.measurementService.getAllBy('Type', MeasurementTypes.roomMeasurement);
    this.syncObjectService.currentSyncObjects.next([Aufmass]);
  }

  async createMeasurement(): Promise<void> {
    const online = await this.connectionService.checkOnline(ConnectionDialogues.CreateData, false);
    if (online) await this.routingService.navigateTo('/aufmass/neu/type/' + MeasurementTypes.roomMeasurement);
  }
}

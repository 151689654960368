<div class="dx-field">
  <div *ngIf="labelText" class="dx-field-label">{{ labelText }}</div>
  <div class="dx-field-value">
    <dx-text-box
      tabindex="-1"
      (click)="showPopup = !showPopup"
      #labelAndInputTextbox
      [id]="labelText"
      [(value)]="value"
      [maxLength]="maxLength"
      [placeholder]="placeholder"
      [readOnly]="true"
    >
    </dx-text-box>
  </div>
</div>

<dx-popup
  [width]="'100%'"
  [maxWidth]="'500px'"
  [title]="headline"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="showPopup"
>
  <div *dxTemplate="let data of 'content'">
    <button (click)="onManualSaveClick(searchValue)" class="bss-button button-block primary-button" style="width: 100%">
      Sucheingabe {{ dataTypeName ? 'als ' + dataTypeName : '' }} übernehmen
    </button>
    <br /><br />
    <dx-list
      [searchEnabled]="true"
      [(searchValue)]="searchValue"
      [dataSource]="inputDataSource"
      [searchExpr]="searchExpressions"
      class="popup-card-list"
    >
      <div *dxTemplate="let item of 'item'; let i = index">
        <div class="no-margin-p" (click)="onItemClick(item)">
          <b *ngIf="getPropertyValue(item)">{{ getPropertyValue(item) }}</b>
        </div>
      </div>
    </dx-list>
  </div>
</dx-popup>

<dx-list
  *ngIf="inputDataSource?.length > 0"
  [attr.class]="'bss-list ' + listClass"
  [ngClass]="{ 'multiview-reordering': dragAndDrop && dataSource }"
  [activeStateEnabled]="activeStateEnabled"
  [pageLoadMode]="'nextButton'"
  [searchEnabled]="searchEnabled"
  [dataSource]="dataSource"
  [searchExpr]="searchExpressions"
  (onItemReordered)="dragEnd($event)"
>
  <dxo-item-dragging *ngIf="dragAndDrop && dataSource" [data]="dataSource" [allowReordering]="dragAndDrop">
  </dxo-item-dragging>
  <div *dxTemplate="let item of 'item'; let i = index">
    <app-supporting-document-position-card
      *ngIf="id == 'supportingDocPositions'"
      [priceRight]="priceRight"
      [belegposition]="item"
    >
    </app-supporting-document-position-card>

    <app-address-card *ngIf="id == 'adresses'" [address]="item"></app-address-card>

    <app-contact-card *ngIf="id == 'address-contacts'" [contact]="item"></app-contact-card>

    <app-contact-person-card *ngIf="id == 'address-contact-persons'" [contactPerson]="item"></app-contact-person-card>

    <app-vacant-position-card *ngIf="id == 'address-vacant-positions'" [vacantPosition]="item">
    </app-vacant-position-card>

    <app-sale-card *ngIf="id == 'address-sales'" [sale]="item"></app-sale-card>

    <app-supporting-document-card *ngIf="id == 'supporting-documents'" [supportingDocument]="item">
    </app-supporting-document-card>

    <!-- Home slider Kalender und Kalender Modul -->
    <app-appointment-card *ngIf="id == 'Termine'" [appointment]="item"> </app-appointment-card>

    <app-message-card
      *ngIf="id == 'nachrichten'"
      [userInfo]="userInfo"
      [message]="item"
      (deletedMessage)="removeItem($event)"
    >
    </app-message-card>

    <app-email-message-card *ngIf="id == 'nachrichtenEmail'" [message]="item"> </app-email-message-card>

    <app-order-item-card
      *ngIf="id == 'orderItems'"
      [showPrices]="priceRight"
      [position]="item"
      (updatedDataSource)="reAssignDataSource($event)"
      [order]="secondaryDataSource"
      [userInfo]="userInfo"
    >
    </app-order-item-card>

    <app-order-item-card
      *ngIf="id == 'stueckliste'"
      [position]="item"
      [showPrices]="priceRight"
      [order]="secondaryDataSource"
      [userInfo]="userInfo"
      [deleteAble]="false"
    >
    </app-order-item-card>

    <app-order-item-card
      *ngIf="id == 'documentPosition' && dataSource && secondaryDataSource"
      [position]="item"
      [showPrices]="priceRight"
      (updatedDataSource)="reAssignDataSource($event)"
      [order]="secondaryDataSource"
      [userInfo]="userInfo"
    >
    </app-order-item-card>

    <app-repair-order-card *ngIf="id == 'repairOrder'" [repairOrder]="item"></app-repair-order-card>

    <app-system-card *ngIf="id == 'anlagen'" [anlage]="item"></app-system-card>

    <app-appointment-card
      *ngIf="id == 'CustomerAppointment'"
      [appointment]="item"
      [showDate]="true"
    ></app-appointment-card>

    <!-- Home slider Adressen -->
    <app-address-card *ngIf="id == sliderNames.addresses" [address]="item"></app-address-card>

    <!-- Home slider Reparaturaufträge -->
    <app-repair-order-card *ngIf="id == sliderNames.repairOrders" [repairOrder]="item"></app-repair-order-card>

    <!-- Home slider Wartung-Service Anlagen -->
    <app-system-card *ngIf="id == sliderNames.maintenanceSystems" [anlage]="item"></app-system-card>

    <!-- Home slider Wartung-Service Aufträge -->
    <app-service-order-card
      *ngIf="id == sliderNames.serviceOrders"
      [serviceAuftrag]="item"
      [extended]="secondaryDataSource"
    >
    </app-service-order-card>

    <!-- Home slider Aufmass -->
    <app-measurement-card
      *ngIf="id == sliderNames.roomMeasurements || id == sliderNames.freeMeasurement"
      [measurement]="item"
    ></app-measurement-card>

    <app-room-card *ngIf="id == 'Raum'" [room]="item"></app-room-card>
  </div>
</dx-list>
<div *ngIf="!(inputDataSource?.length > 0) && emptyInputDataMessage != ''">{{ emptyInputDataMessage }}</div>

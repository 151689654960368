import { GlobalHelper } from './globalHelper';
export class ContactHelper {
  static callPhone(telephoneNumber: string): void {
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(telephoneNumber)) return;
    window.open(`tel:${telephoneNumber}`);
  }

  static mailTo(mailAddress: string): void {
    if (GlobalHelper.isNullOrUndefinedOrEmptyString(mailAddress)) return;
    window.open(`mailto:${mailAddress}`);
  }

  static goToHomepage(homepage: string): void {
    const prefix = homepage.startsWith('https://') ? '' : 'https://';
    window.open(prefix + homepage, '_blank');
  }

  static openMaps(address: string): void {
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}_blank`);
  }
}

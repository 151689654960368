import { Component, OnInit } from '@angular/core';
import { HWAddress, HWMonteur, HWRepairOrder, HWTermin, ServiceAuftrag } from 'apps/handwerkPWA/src/app/entities';
import { AddressService } from 'apps/handwerkPWA/src/app/services/dataServices/address.service';
import { MonteurService } from 'apps/handwerkPWA/src/app/services/dataServices/monteur.service';
import { OptionChangedEvent } from 'devextreme/ui/calendar';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { TimeHelper } from 'libs/shared/src/lib/helper/timeHelper';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { calendarSyncedMonths } from '../../config/Konstanten';
import { appointmentSearch } from '../../config/search-constants';
import { AppointmentEventType } from '../../entities/repository/HWTermin';
import { AppointmentHelper } from '../../helper/services/terminHelper';
import { AppointmentService } from '../../services/dataServices/appointment.service';
import { RepairOrderService } from '../../services/dataServices/repairOrder.service';
import { HWGlobalSettingService } from '../../services/globalServices/hwGlobalSetting.service';
import { SyncObjectService } from '../../services/globalServices/syncObject.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  currentValue: Date = new Date();
  cellTemplate = 'cell';
  currentFilteredAppointments: HWTermin[];
  selectedAppointment: HWTermin;
  calendarFilter = ['Nur meine Termine'];
  allAppointments: HWTermin[] = [];
  today: Date = new Date();
  selectedFilter = 'Nur meine Termine';
  selectedDate = new Date();
  ownEmployeeNumber: string;
  allEmployees: HWAddress[];
  minDate = TimeHelper.addMonths(new Date(), -calendarSyncedMonths);
  minDateString = TimeHelper.dateTimeToDateString(this.minDate);
  maxDateString = TimeHelper.dateTimeToDateString(TimeHelper.addMonths(new Date(), calendarSyncedMonths));
  searchExpressions = appointmentSearch;

  constructor(
    private appointmentService: AppointmentService,
    private globalSettingService: HWGlobalSettingService,
    private rightsService: RightsService,
    private employeeService: MonteurService,
    private routingService: RoutingService,
    private repairOrderService: RepairOrderService,
    private addressService: AddressService,
    private syncObjectService: SyncObjectService,
  ) {}

  async ngOnInit(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const loggedInEmployee = await this.employeeService.getMonteurFromIDB();
    this.ownEmployeeNumber = loggedInEmployee.AdrNummer;
    const repairOrders = await this.repairOrderService.getAllRepairOrdersFromIDB();
    this.allAppointments = (
      await this.appointmentService.getAllAppointmentsFromIDB(userInfo, null, false, true, repairOrders)
    ).filter(appointment => appointment.eventtype != AppointmentEventType.Exception);
    this.allEmployees = await this.addressService.getAllBy('ADRTYP', 'M');

    await this.addCalenderFilters(loggedInEmployee);

    this.filterChange(this.selectedFilter, this.today);
    this.syncObjectService.currentSyncObjects.next([ServiceAuftrag, HWRepairOrder, HWTermin]);
  }

  async addCalenderFilters(loggedInUser: HWMonteur): Promise<void> {
    if (!this.rightsService.getCurrentRight().employeeRights.appointmentRights.showAll) return;
    this.calendarFilter.push('Alle Termine');

    const loggedInAddress = await this.addressService.findOneBy('KU_NR', loggedInUser.AdrNummer);
    const employeeNames = AppointmentHelper.getEmployeeNamesFromAppointments(this.allAppointments).filter(
      name => name !== loggedInAddress.NAME,
    );
    this.calendarFilter = this.calendarFilter?.concat(employeeNames);
  }

  /**@description Called from the filter change and manually when the date changed. */
  filterChange(selectedValue: string, selectedDate: Date): void {
    this.selectedFilter = selectedValue || this.selectedFilter;
    const filterDate = TimeHelper.dateToDatabaseDate(selectedDate, false, false);
    const employeeFilteredAppointments = this.getAppointmentsForEmployeesFilter(this.selectedFilter);
    const currentFilteredRaw = employeeFilteredAppointments.filter(termin => termin.indexDate === filterDate);
    this.currentFilteredAppointments = AppointmentHelper.appointmentsToMoreSpecificDatasets(
      currentFilteredRaw,
      this.rightsService.getCurrentRight().employeeRights,
    );
    if (!GlobalHelper.isNullOrUndefined(selectedValue))
      // employee filter has changed => recolor days
      this.recolorAppointments(employeeFilteredAppointments);

    this.routingService.dataChanged.next(false);
  }
  /**
   * @description Called when a date is clicked. Sets the date as selected and builds a new default date for that day and preassigns it.
   */
  onValueChange(clickedDate: Date): void {
    this.selectedDate = clickedDate;
    this.filterChange(null, clickedDate);
  }

  /**@description  Called when the user clicks on a calendar field - then checks for a month change */
  onOptionChanged(event: OptionChangedEvent): void {
    // Also triggers with normal FieldClick
    if (event.name === 'isActive') return;
    // Always recolor appointments
    const currentEmployeeFilter = this.getAppointmentsForEmployeesFilter(this.selectedFilter);
    this.recolorAppointments(currentEmployeeFilter);
    if (event.name === 'value') return;

    const previousValue = event.previousValue as Date | 'year' | 'month';
    const value = event.value as Date | 'year' | 'month';

    if (value instanceof Date) {
      let previousMonth: number;
      if (previousValue instanceof Date) {
        previousMonth = previousValue.getMonth();
      }

      // Month changed to current month
      if (value.getMonth() === this.today.getMonth() && value.getMonth() !== previousMonth) {
        value.setDate(this.today.getDate());
        this.currentValue = value;
      }

      // Month change
      else if (value.getMonth() !== this.today.getMonth() && value.getMonth() !== previousMonth) {
        value.setDate(1);
        this.currentValue = value;
      }
    }
  }

  goToToday(): void {
    const todayButton = document.getElementsByClassName('dx-calendar-today-button')[0] as HTMLElement;
    todayButton.click();
  }

  /**@description After clicking on an appointment in the search this event is executed */
  async onAppointmentInSearchSelected(appointment: HWTermin): Promise<void> {
    if (GlobalHelper.isNullOrUndefined(appointment)) {
      return;
    }

    await this.routingService.navigateTo('termin/' + appointment.id);
  }

  async navigateTo(path: string): Promise<void> {
    const dateInMs = this.selectedDate.getTime();
    await this.routingService.navigateTo(`${path}/${dateInMs}`);
  }

  /**@description Colors all existing appointments */
  private recolorAppointments(filteredAppointmentsByEmployee: HWTermin[]): void {
    const cells = document.getElementsByClassName('dx-calendar-cell');
    const cellAmount = cells.length;
    for (let i = 0; i < cellAmount; i++) {
      const cell = cells[i];
      const dataSet = cell['dataset'];
      const date = dataSet.value as string;
      const splitDate = date.split('/');
      const day = splitDate[2];
      const month = splitDate[1];
      const year = splitDate[0];
      const germanDate = day + '.' + month + '.' + year;
      const appointmentOnDay = filteredAppointmentsByEmployee?.some(
        appointment => appointment.indexDate === germanDate,
      );
      if (appointmentOnDay) cell.setAttribute('style', 'background-color: #dadada');
      else cell.setAttribute('style', 'background-color: white;');
    }
  }

  private getAppointmentsForEmployeesFilter(selectedEmployeesFilter: string): HWTermin[] {
    switch (selectedEmployeesFilter) {
      case 'Alle Termine':
        return this.allAppointments;
      case 'Nur meine Termine':
        return this.allAppointments?.filter(
          filterTermin =>
            filterTermin.mitarbeiter === this.ownEmployeeNumber ||
            filterTermin.MA_ID_LIST?.includes(this.ownEmployeeNumber),
        );
      default: {
        const employeeName = this.selectedFilter;
        const employeeNumber = this.allEmployees.find(e => e.NAME === employeeName).getCustomerNumber();
        return this.allAppointments?.filter(filterTermin => filterTermin.MA_ID_LIST?.includes(employeeNumber));
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { supportingDocumentsIndexSearch } from '../../../config/search-constants';
import { HWAddress, HWBeleg } from '../../../entities';
import { AddressService } from '../../../services/dataServices/address.service';
import { BelegService } from '../../../services/dataServices/beleg.service';

@Component({
  selector: 'app-supporting-documents-index',
  templateUrl: './supporting-documents-index.component.html',
  styleUrls: ['./supporting-documents-index.component.scss'],
})
export class SupportingDocumentsIndexComponent implements OnInit {
  labelArrayBelege = ['', '', '', '', ''];
  displayFieldsBelege = supportingDocumentsIndexSearch;
  supportingDocuments: HWBeleg[] = [];
  title = 'Belege über einen Kunden suchen';
  viewFinished = false;
  addressGuid: string;
  customerAddresses: HWAddress[] = [];
  selectedCustomer: HWAddress;

  constructor(
    private addressService: AddressService,
    private belegService: BelegService,
    private routingService: RoutingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.addressGuid = this.routingService.getRouteParam('guid');
    if (this.addressGuid) {
      const address = await this.addressService.findOneBy('Guid', this.addressGuid);
      this.supportingDocuments = await this.belegService.getAllByKundennummer(address.KU_NR);

      if (address.NAME) {
        this.title = 'Belege für ' + address.NAME;
      }
    } else {
      this.customerAddresses = await this.addressService.getAllBy('ADRTYP', 'K');
    }

    this.viewFinished = true;
  }

  async selectedCustomerChanged(selectedCustomer: HWAddress): Promise<void> {
    this.title = 'Belege für ' + selectedCustomer.NAME;
    this.supportingDocuments = await this.belegService.getAllByKundennummer(selectedCustomer.KU_NR);
  }
}

import { Injectable } from '@angular/core';
import { PositionType } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { HWRepairOrderItem, Positionrequest } from 'apps/handwerkPWA/src/app/entities';
import { RepairOrderItemService } from 'apps/handwerkPWA/src/app/services/dataServices/repairOrderItem.service';
import { PositionSettings, Right, UserInfo } from '../entities';
import { GlobalHelper } from '../helper/globalHelper';
import { DialogService } from './dialog.service';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  constructor(
    private dialogService: DialogService,
    private repairOrderItemService: RepairOrderItemService,
    private restService: RestService,
  ) {}

  /**@description Vergleicht die Positionssettings zuvor und aktuell. Ist ein Unterschied festgestellt, wird der Nutzer auf eine aktualisierung seiner Positionen hingewiesen */
  public async actOnNewPositionSettings(
    oldPositionSettings: PositionSettings,
    newPositionSettings: PositionSettings,
    rights: Right,
    userInfo: UserInfo,
  ): Promise<void> {
    if (GlobalHelper.positionSettingChanged(oldPositionSettings, newPositionSettings, 'Lieferanten')) {
      const answer = await this.dialogService.openConfirmDialog(
        'Änderungen',
        'Die Artikel auf Ihrem Gerät sollten aktualisiert werden. Möchten Sie die Artikel jetzt synchronisieren? (Unter Einstellungen können Sie die Synchronisation manuell auslösen.)',
        'Synchronisieren',
        'Nicht synchronisieren',
        false,
      );
      if (answer === true) {
        const art = 'Artikel';
        await this.repairOrderItemService.getOfflinePositionsFromWebService(rights, userInfo, art);
      }
    }
    if (GlobalHelper.positionSettingChanged(oldPositionSettings, newPositionSettings, 'Gewerk')) {
      const answer = await this.dialogService.openConfirmDialog(
        'Änderungen',
        'Die Offline-Leistungen auf Ihrem Gerät sollten aktualisiert werden. Möchten Sie die Leistungen jetzt synchronisieren? (Unter Einstellungen können Sie die Synchronisation manuell auslösen)',
        'Synchronisieren',
        'Nicht synchronisieren',
        false,
      );
      if (answer === true) {
        const art = 'Leistung';
        await this.repairOrderItemService.getOfflinePositionsFromWebService(rights, userInfo, art);
      }
    }
  }

  /**@description Ermittelt die Zeit und die grobe Größe der jeweiligen ausgewählten offline Positionen */
  async testOfflinePositionSyncTime(
    userInfo: UserInfo,
    positionSettings: PositionSettings,
    art: PositionType,
  ): Promise<void> {
    const url = 'OfflinePositions';
    const positionRequest = new Positionrequest(userInfo.mandant, userInfo.employeeNumber);
    positionRequest.addPositionSettingData(positionSettings);
    positionRequest.changeType(art);
    positionRequest.requestType = 'Offlinepositions';
    const startTime = new Date();
    const artReadable = art === 'Leistung' ? 'Leistungen' : 'Artikel';
    void this.dialogService.openLoadingDialog(
      'Synchronisation',
      '...Teste Synchronisationszeit Ihrer ausgewählten offline ' + artReadable + '...',
    );
    const offlinePositions = await this.restService.returnData<HWRepairOrderItem[]>(url, positionRequest);
    this.dialogService.closeLoadingDialog();
    const endTime = new Date();
    const timeDifferenceInSeconds = (endTime.getTime() - startTime.getTime()) / 1000;
    let timeValue = timeDifferenceInSeconds.toString() + ' Sekunden.';
    if (timeDifferenceInSeconds > 60) {
      const minutes = Math.floor(timeDifferenceInSeconds / 60);
      const seconds = Math.floor(timeDifferenceInSeconds % 60);
      timeValue = minutes + ' Minuten und ' + seconds + ' Sekunden.';
    }
    const roughObjSize = JSON.stringify(offlinePositions)?.length;
    const roughObjSizeInMb = Math.floor(roughObjSize / 1000000);
    const presentObjectSizeInMb = roughObjSizeInMb === 0 ? 1 : roughObjSizeInMb;
    this.dialogService.openInformDialog(
      'Ergebnis',
      'Die Synchronisation Ihrer aktuell ausgewählten offline ' +
        artReadable +
        ' betrug ' +
        timeValue +
        ' Die übertragenen Daten sind ca. ' +
        presentObjectSizeInMb +
        'MB groß.',
      'Ok',
    );
  }
}

<div class="content-block bottom-padding" *ngIf="repairOrder">
  <div class="form-block">
    <h2>Reparaturauftrag</h2>

    <div *ngIf="customer?.LIEFERSPERRE === true" class="bss-alert bss-alert-danger">Liefersperre hinterlegt</div>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <div class="dx-field">
            <div class="dx-field-label">Status</div>
            <div class="dx-field-value" style="display: flex">
              <div
                class="address-type-box"
                [ngStyle]="{ 'background-color': repairOrder.StatusObject.stateColor }"
              ></div>
              <p style="margin: 0; padding: 0 0 0 4px">{{ repairOrder.StatusObject.stateName }}</p>
            </div>
          </div>

          <div class="dx-field">
            <div class="dx-field-value">
              <app-bss-select-box
                [items]="availableStates"
                [placeholder]="'Status ändern'"
                [displayExpr]="'actionText'"
                (selectedValue)="stateChange($event)"
              >
              </app-bss-select-box>
            </div>
          </div>

          <app-bss-plain-text-box [labelText]="'Auftragsnummer'" [value]="repairOrder.Nummer"></app-bss-plain-text-box>
          <app-bss-plain-text-box
            [labelText]="'Kunde'"
            [valueArray]="[
              repairOrder.KundenName,
              repairOrder.KundenStrasse,
              repairOrder.KundenPlz + ' ' + repairOrder.KundenOrt
            ]"
            [buttonIcon]="'map'"
            [buttonEnabled]="!!(repairOrder.Strasse && repairOrder.Plz && repairOrder.Ort)"
            (buttonClick)="openMaps(repairOrder.Strasse, repairOrder.Plz, repairOrder.Ort)"
          >
          </app-bss-plain-text-box>
          <app-bss-plain-text-box
            labelText="Monteur 1"
            [value]="(firstEmployee | async)?.NAME"
          ></app-bss-plain-text-box>
          <app-bss-select-box
            labelText="Monteur 2"
            [displayExpr]="'NAME'"
            [placeholder]="(secondEmployee | async)?.NAME"
            [items]="selectableEmployees"
            (selectedValue)="selectSecondEmployee($event)"
            [disabled]="userInfo.monteur !== repairOrder.Monteur1"
          ></app-bss-select-box>
          <app-bss-textbox [labelText]="'Betreff'" [(value)]="repairOrder.Betreff"></app-bss-textbox>
          <app-bss-datetime-box
            [labelText]="'Termin'"
            [value]="appointment"
            [type]="dateType.dateTime"
            (outValue)="parseMinFromTermin($event)"
          ></app-bss-datetime-box>
          <app-bss-datetime-box
            *ngIf="minEnde"
            [labelText]="'Bis'"
            [min]="minEnde"
            [max]="maxEnde"
            [value]="currentEndTime"
            [type]="dateType.time"
            (outStringValue)="repairOrder.Endzeit = $event; repairOrderChanged()"
          >
          </app-bss-datetime-box>
          <app-bss-plain-text-box
            [labelText]="'Auftragsort'"
            [valueArray]="[
              repairOrder.AusfAnspr,
              repairOrder.ArbeitsOrt,
              locationText | async,
              repairOrder.Strasse,
              repairOrder.Plz + ' ' + repairOrder.Ort
            ]"
            [buttonIcon]="'map'"
            (buttonClick)="openMaps(repairOrder.ArbeitsOrt)"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            *ngIf="repairOrder.AnsprTel"
            [labelText]="'Telefon'"
            [value]="repairOrder.AnsprTel"
            (buttonClick)="callPhone(repairOrder.AnsprTel)"
            [buttonIcon]="'tel'"
          ></app-bss-plain-text-box>
          <app-bss-plain-text-box
            *ngIf="repairOrder.AnsprMobil"
            [labelText]="'Mobil'"
            [value]="repairOrder.AnsprMobil"
            (buttonClick)="callPhone(repairOrder.AnsprMobil)"
            [buttonIcon]="'tel'"
          ></app-bss-plain-text-box>
        </div>
      </div>

      <app-bss-toggle-textarea [buttonText]="'Arbeitsauftrag'" [(value)]="repairOrder.Beschreibung">
      </app-bss-toggle-textarea>
      <app-bss-toggle-textarea [buttonText]="'Arbeitsbericht'" [(value)]="repairOrder.Bericht">
      </app-bss-toggle-textarea>
      <app-bss-toggle-textarea [buttonText]="'Bemerkungen'" [(value)]="repairOrder.Bemerkung"></app-bss-toggle-textarea>

      <div class="linkBox">
        <app-bss-button-link
          [title]="'Positionen'"
          [path]="'auftragpositionen/reparaturauftrag/' + repairOrder.Guid"
          [displayCount]="positionsAnzahl"
        ></app-bss-button-link>

        <app-bss-button-link
          [title]="'Medien'"
          [path]="'medien/RepairOrder/' + repairOrder.Guid"
          [displayCount]="currentFilesAmount"
        ></app-bss-button-link>

        <app-bss-button-link
          [title]="'Nachricht senden'"
          [customIcon]="'assets\\icons\\email.png'"
          [path]="messagePath"
        ></app-bss-button-link>
      </div>

      <div class="dx-fieldset">
        <div class="fieldbox">
          <app-bss-switchbox
            [labelText]="'Autolohn'"
            [disabled]="!autoLohnChangeRight"
            (valueChange)="changeAutolohnActive($event)"
            [(value)]="repairOrder.Autolohn.isActive"
          ></app-bss-switchbox>
          <app-bss-select-box
            *ngIf="repairOrder.Autolohn.isActive && roundingAllowed"
            [labelText]="'Rundung auswählen'"
            [items]="autoLohnOptions"
            [value]="currentAutolohnRounding"
            (selectedValue)="changeAutolohnRundung($event)"
            [placeholder]="'Rundung auswählen'"
          >
          </app-bss-select-box>
          <app-bss-switchbox
            *ngIf="rights"
            [labelText]="'Preise anzeigen'"
            [disabled]="!rights.inAppChangeRights.showPrices"
            (valueChange)="repairOrderChanged()"
            [(value)]="repairOrder.PreiseAnzeigen"
          ></app-bss-switchbox>
        </div>
      </div>
    </div>
  </div>
</div>

<app-bss-speed-dial
  icon="photo"
  label="Medium hinzufügen"
  [index]="2"
  (Click)="navigateTo('medien/RepairOrder/' + repairOrder.Guid + '/upload')"
></app-bss-speed-dial>

<app-bss-speed-dial
  icon="check"
  label="Auftrag abschließen"
  [index]="1"
  (Click)="navigateTo('auftragsabschluss/' + orderType + '/' + orderId)"
></app-bss-speed-dial>

<app-bss-speed-dial
  icon="add"
  label="Position hinzufügen"
  [index]="0"
  (Click)="navigateTo('auftragpositionen/' + orderType + '/' + orderId + '/hinzufuegen')"
></app-bss-speed-dial>

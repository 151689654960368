import { BaseDocumentPosition } from 'apps/handwerkPWA/src/app/interfaces';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';

export class Steuer {
  Id: string = null;
  Bez: string = null;
  Steuersatz: number = null;
  New_id: string = null;
  Stdkonto: string = null;
  St_Schl: string = null;
  Stkonto: string = null;
  Ktoskont: string = null;
  Ktomind: string = null;
  Uuid: string = null;
  EndsOn: string = null;
  EndsOnDate: Date;

  constructor(objectData: Steuer) {
    GlobalHelper.assignIfPropertyExists(this, objectData);
    this.EndsOnDate = this.parseDateFromDateString(this.EndsOn);
  }

  static getSteuerBezeichnungOfUsedSteuern(Steuern: Steuer[], items: BaseDocumentPosition[]): string {
    const steuerSchluessel = items?.map(item => item?.getSteuerSchluessel(Steuern));
    const steuerSchluesselSet = new Set(steuerSchluessel);
    const usedSteuern = Steuern?.filter(steuer => steuerSchluesselSet?.has(steuer.Id));
    if (usedSteuern?.length > 0) return usedSteuern[0].Bez;
    return 'Umsatzsteuer 19 %';
  }

  /**@description Guckt ob der Aktuelle Steuerschlüssel gültig ist und gibt dann dessen wert zurück, ansonsten wird der Prozess mit dem jeweils nächsten gültigen probiert */
  static findSteuerInUse(Steuern: Steuer[], steuerSchluesselId = '01'): Steuer {
    const usedSteuer = Steuern.find(steuer => steuer.Id === steuerSchluesselId);
    if (GlobalHelper.isNullOrUndefined(usedSteuer)) {
      const newDeaultSteuer = new Steuer(null);
      newDeaultSteuer.Steuersatz = 19;
      newDeaultSteuer.St_Schl = '01';
      return newDeaultSteuer;
    }
    const usedSteuerEndDate = usedSteuer.EndsOnDate;
    if (GlobalHelper.isNullOrUndefined(usedSteuerEndDate)) return usedSteuer;
    const now = new Date();
    const schlueselStillValid = now < usedSteuerEndDate;
    const nextSteuerSchluessel = usedSteuer.New_id;
    if (schlueselStillValid || GlobalHelper.isNullOrUndefined(nextSteuerSchluessel)) return usedSteuer;
    return this.findSteuerInUse(Steuern, nextSteuerSchluessel);
  }

  /**@description Falls ein Valides Datum vorhanden ist,wird dies zu einem Date Objekt konvertiert */
  private parseDateFromDateString(endsOn: string): Date | null {
    const dateSplit = endsOn?.split('T');
    const dateString = dateSplit?.length === 2 ? dateSplit[0] : null;
    if (dateString) return new Date(dateString);
    return null;
  }
}

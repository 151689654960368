<div *ngIf="viewFinished">
  <div class="content-block" *ngIf="order">
    <div class="form-block">
      <h2>Auftrag abschließen</h2>

      <div class="dx-card responsive-paddings">
        <div
          *ngIf="displayPositions?.length > 0"
          style="padding-bottom: 20px; margin-bottom: 15px; border-bottom: 1px solid #aaa"
        >
          <b>Positionen:</b>
          <dx-scroll-view [showScrollbar]="'always'" [height]="'40vh'">
            <app-bss-list
              [priceRight]="order.getShowPrices(allowPricesShow)"
              [id]="'orderItems'"
              [inputDataSource]="displayPositions"
              [secondaryDataSource]="order"
              [searchExpressions]="searchExpressions"
              [userInfo]="userInfo"
            >
            </app-bss-list>
          </dx-scroll-view>
        </div>

        <div *ngIf="itemSums && showPrices" style="line-height: 24px">
          <div class="flexContainer">
            <div class="flexContainerItem">Netto:</div>
            <div class="flexContainerItem" style="text-align: right">{{ itemSums.NettoSum }} €</div>
          </div>
          <div class="flexContainer">
            <div class="flexContainerItem">MwSt:</div>
            <div class="flexContainerItem" style="text-align: right; text-decoration: underline">
              {{ itemSums.TaxedSum }} €
            </div>
          </div>
          <div class="flexContainer">
            <div class="flexContainerItem">Gesamt:</div>
            <div
              class="flexContainerItem"
              style="text-align: right; text-decoration: underline; text-decoration-style: double"
            >
              {{ itemSums.CompleteSum }} €
            </div>
          </div>
        </div>

        <div *ngIf="rights" class="switchboxes">
          <app-bss-switchbox
            [labelText]="'PDF per E-Mail senden'"
            [disabled]="!rights.inAppChangeRights.mailFromOrder"
            [(value)]="doSendMail"
          ></app-bss-switchbox>
          <div *ngIf="doSendMail" style="padding-bottom: 15px">
            <app-bss-textbox [labelText]="'Empfäger E-Mail-Adresse'" [(value)]="mailAddress"></app-bss-textbox>
          </div>
          <app-bss-switchbox
            [labelText]="'PDF nach Abschluss'"
            [disabled]="!rights.inAppChangeRights.pdfPreview"
            [(value)]="showPreview"
          ></app-bss-switchbox>
          <app-bss-switchbox
            [labelText]="'Preise drucken'"
            [disabled]="!rights.inAppChangeRights.printPrices"
            [(value)]="printPrices"
          ></app-bss-switchbox>
        </div>

        <app-bss-radiobutton
          class="radioSwitch"
          *ngIf="isRepairOrder"
          [label]="'Zahlungsart'"
          [choices]="zahlungsArten"
          [currentValue]="$any(order).Bezahlung.toString()"
          (selection)="$any(order).Bezahlung = $event"
        >
        </app-bss-radiobutton>

        <app-bss-radiobutton
          class="radioSwitch"
          *ngIf="selectedStuecklistenSetting"
          [label]="'Alle Stücklisten beim Druck auflösen'"
          [choices]="stuecklistSettings"
          [currentValue]="selectedStuecklistenSetting"
          (selection)="changeStuecklistenSettings($event)"
        >
        </app-bss-radiobutton>

        <app-bss-toggle-textarea
          *ngIf="isRepairOrder"
          [buttonText]="'Arbeitsbericht'"
          [(value)]="$any(order).Bericht"
        ></app-bss-toggle-textarea>

        <app-bss-button-link
          [title]="'Unterschreiben & Abschließen'"
          (click)="showUnterschrift = true"
        ></app-bss-button-link>
      </div>
    </div>
  </div>

  <app-signature-pad
    *ngIf="showUnterschrift"
    [showPopup]="showUnterschrift"
    [defaultSigningPerson]="order.getUnterschriftsperson()"
    (unterschriftBase64)="finalizeOrder(order, $event, doSendMail)"
    (stopEvent)="showUnterschrift = false"
  >
  </app-signature-pad>
</div>

<div class="card raum-card" *ngIf="room">
  <div class="flexContainer">
    <div class="flexContainerItem">
      <div class="flexContainer">
        <div class="flexContainerItem">
          <b>{{ room.Bezeich }}</b
          ><br />
        </div>
      </div>
    </div>
  </div>
</div>

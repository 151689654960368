import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ManuellePosition, TimeInput, TimeInputType } from 'apps/handwerkPWA/src/app/entities';
import { BaseDocumentPosition } from '../../../interfaces';

@Component({
  selector: 'app-bss-time-input',
  templateUrl: './bss-time-input.component.html',
  styleUrls: ['./bss-time-input.component.scss'],
})
export class BssTimeInputComponent implements OnInit {
  @Input() lohnPosition: ManuellePosition | BaseDocumentPosition;
  @Output() outputMinutes = new EventEmitter<number>();
  @Input() additionalCss: string;
  timeInput = new TimeInput(60, 'Minutes');

  ngOnInit(): void {
    if (this.lohnPosition instanceof ManuellePosition) {
      const initialValue = this.lohnPosition?.Zeit;
      if (initialValue) this.timeInput.updateTime(initialValue, 'Minutes');
    }
  }

  valueChanged(newValue: number, type: TimeInputType): void {
    this.timeInput.updateTime(newValue, type);
    this.timeInput.displayMinutes = this.timeInput.displayMinutes;
    this.outputMinutes.emit(this.timeInput.displayMinutes);
  }
}

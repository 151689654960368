<div class="button-link" (click)="clickAction()">
  <div class="flexContainer">
    <div class="flexContainerItem button-text">
      {{ title }}
    </div>
    <div *ngIf="displayCount" class="button-text">
      {{ displayCount }}
    </div>
    <div *ngIf="showHasContent" class="button-icon">
      <img [src]="infoIconSource" [class]="'dreieck'" />
    </div>
    <div class="button-icon" *ngIf="secondCustomIcon">
      <img [src]="secondCustomIcon" [ngClass]="secondCustomIconClass" />
    </div>
    <div class="button-icon">
      <img [src]="customIcon ? customIcon : defaultIcon" [ngClass]="customIconClass" />
    </div>
  </div>
</div>

import { BuildingElementTemplate } from './BuildingElementTemplate';
import { CoordinatePoint } from './CoordinatePoint';
import { MeasurementRoute } from './MeasurementRoute';

export class BuildingElement extends MeasurementRoute {
  height?: number = null;
  depth?: number = null;
  template: BuildingElementTemplate = null;
  deductionFromUuid: string = null;
  constructor(
    startCoordinatePoint: CoordinatePoint,
    endCoordinatePoint: CoordinatePoint,
    template: BuildingElementTemplate,
    index: number,
    deductionFromUuid: string,
    alternatingIndex: number
  ) {
    super(startCoordinatePoint, endCoordinatePoint, index);
    this.label = 'b' + index;
    this.labelLong = template?.name + alternatingIndex;
    this.template = template;
    this.deductionFromUuid = deductionFromUuid;
  }
}

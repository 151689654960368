import { noPreviewImage, noPreviewPdf, noPreviewTxt } from 'apps/handwerkPWA/src/assets/images/noPreviewImage';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import * as uuid from 'uuid';
import { Aufmass, HWAnlage, ServiceAuftrag } from '..';
import { MediumDataTypes, SourceOfFileList, allowedPictureTypes } from '../../config/Konstanten';
import { DocumentHelper } from '../../helper/services/documentHelper';
import { SyncObject } from '../models/SyncObject';
import { RoomBookPosition } from '../models/aufmass/RoomBookPosition';
import { HWAddress } from './HWAddress';
import { HWObjectAddress } from './HWObjectAddress';
import { HWRepairOrder } from './HWRepairOrder';
import { IndexedDBTypes } from './dbType';

export enum MediaTable {
  MeasurementMedia = 'AUFMMEDIEN',
  RepairOrderMedia = 'AUFTRMEDIEN',
  MaintenanceMedia = 'WSMEDIEN',
  AddressMedia = 'ADRMEDIEN',
}

/** Media like in the 'Medien' view of HW reparaturcenter accepts the following datatypes:
 *
 * .jpg, .pdf, .txt, .csv all picture files are automatically converted to .jpg
 */
export class Medien extends IndexedDBTypes.DbType implements SyncObject {
  @IndexedDBTypes.KlassenName('Medien') KlassenName: string;
  @IndexedDBTypes.IndexField('string') Baseuuid: string;
  @IndexedDBTypes.DataField('number') Datatype: MediumDataTypes;
  @IndexedDBTypes.DataField('number') SortIdx: number;
  @IndexedDBTypes.DataField('number') Userid: number;
  @IndexedDBTypes.DataField('string') Beschreibung: string;
  @IndexedDBTypes.DataField('string') Data: string;
  @IndexedDBTypes.DataField('string') Orgname: string;
  @IndexedDBTypes.DataField('string') Bezeichnung: string;
  @IndexedDBTypes.DataField('string') Erstelldatum: string;
  @IndexedDBTypes.DataField('string') Geaendert: string;
  @IndexedDBTypes.DataField('boolean') Send: boolean;
  @IndexedDBTypes.DataField('string') Tablename: MediaTable;
  @IndexedDBTypes.KeyDBField('string') UUID: string;
  PreviewPictureData: string;

  constructor(data: Medien) {
    super();
    this.UUID = uuid.v4();
    Object.assign(this, data);
    if (!this.Bezeichnung) this.Bezeichnung = this.Orgname;
    this.addMissingPreviewIcon();
  }

  static toString(): string {
    return 'Medien';
  }

  static async createMedium(
    file: File,
    fileName: string,
    source: SourceOfFileList,
    description?: string,
  ): Promise<Medien> {
    const medium = new Medien(null);
    medium.Send = false;
    if (file.type === ('text/plain' || 'text/csv')) {
      const dataText = await DocumentHelper.readBlobAsDataUrlAsync(file);
      medium.Data = DocumentHelper.dataUrlToBase64(dataText);
      medium.Datatype = MediumDataTypes.Text;
    }
    if (file.type === 'application/pdf') {
      const dataUrlPdf = await DocumentHelper.readBlobAsDataUrlAsync(file);
      medium.Data = DocumentHelper.dataUrlToBase64(dataUrlPdf);
      medium.Datatype = MediumDataTypes.PDF;
    }
    // Medien
    const cleanFileType = file.type.replace('image/', '');
    if (allowedPictureTypes.includes(cleanFileType)) {
      const compressedFile = await DocumentHelper.customCompress(file);
      const dataUrlPicture = await DocumentHelper.readBlobAsDataUrlAsync(compressedFile);
      medium.PreviewPictureData = dataUrlPicture;
      medium.Data = dataUrlPicture;
      medium.Datatype = MediumDataTypes.Picture;
    }
    medium.Orgname = fileName;
    medium.Beschreibung = description;
    medium.addAssignment(source);
    medium.addMissingPreviewIcon();
    return medium;
  }

  static addMedienSignature(dataType: MediumDataTypes, data: string): string {
    let prefix = '';
    switch (dataType) {
      case MediumDataTypes.Text: {
        prefix = 'data:text/plain;base64,';
        break;
      }
      case MediumDataTypes.Picture: {
        prefix = 'data:image/png;base64,';
        break;
      }
      case MediumDataTypes.PDF: {
        prefix = 'data:application/pdf;base64,';
        break;
      }
    }
    return prefix + data;
  }

  /**@description Weist die Uuid des Basisdatensatzes und somit auch den BaseMediumType zu */
  addAssignment(source: SourceOfFileList): void {
    this.Baseuuid = source.getUuid();
    if (source instanceof HWAddress || source instanceof HWObjectAddress) this.Tablename = MediaTable.AddressMedia;

    if (source instanceof Aufmass || source instanceof RoomBookPosition) this.Tablename = MediaTable.MeasurementMedia;

    if (source instanceof HWRepairOrder) this.Tablename = MediaTable.RepairOrderMedia;

    if (source instanceof ServiceAuftrag || source instanceof HWAnlage) this.Tablename = MediaTable.MaintenanceMedia;
  }

  setDataUrl(documentUrl: string): void {
    this.Data = documentUrl;
    if (this.Datatype === MediumDataTypes.Picture) this.PreviewPictureData = documentUrl;
    else this.addMissingPreviewIcon();
  }

  getOriginalName(): string {
    return this.Orgname;
  }

  setOriginalName(inputName: string): void {
    this.Orgname = inputName;
  }

  addMissingPreviewIcon(): void {
    if (!GlobalHelper.isNullOrUndefinedOrEmptyString(this.PreviewPictureData)) return;
    switch (this.Datatype) {
      case MediumDataTypes.PDF:
        this.PreviewPictureData = noPreviewPdf;
        break;
      case MediumDataTypes.Text:
        this.PreviewPictureData = noPreviewTxt;
        break;
      case MediumDataTypes.Picture:
        this.PreviewPictureData = noPreviewImage;
        break;
    }
  }
}

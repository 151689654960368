<div *ngIf="viewFinished">
  <h2 class="content-block">{{ title }}</h2>

  <div class="content-block">
    <app-bss-select-box
      *ngIf="!addressGuid"
      [items]="customerAddresses"
      displayExpr="NAME"
      searchMode="contains"
      [searchExpr]="'NAME'"
      [minSearchLength]="1"
      [showDataBeforeSearch]="true"
      (selectedValue)="selectedCustomerChanged($event)"
    ></app-bss-select-box>
    <br />
    <app-bss-list
      [id]="'supporting-documents'"
      [inputDataSource]="supportingDocuments"
      [searchExpressions]="displayFieldsBelege"
    ></app-bss-list>
    <div *ngIf="!(supportingDocuments?.length > 0) && selectedCustomer">{{ 'Es sind keine Belege vorhanden' }}</div>
  </div>
</div>

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ContentReadyEvent } from 'devextreme/ui/speed_dial_action';
import { SpeedDialCssClasses } from '../../../config/Konstanten';

@Component({
  selector: 'app-bss-speed-dial',
  templateUrl: './bss-speed-dial.component.html',
  styleUrls: ['./bss-speed-dial.component.scss'],
})
export class BssSpeedDialComponent implements OnChanges {
  @Input() icon: string;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() index: number;
  @Input() disabledCssClasses: SpeedDialCssClasses[] = [
    SpeedDialCssClasses.graySpeedDial,
    SpeedDialCssClasses.graySpeedDialLabelBackground,
  ];
  @Output() Click = new EventEmitter<void>();
  @Output() ClickDisabled = new EventEmitter<void>();
  disabledStyle = true;
  ownActionElement: Element;

  onClick(): void {
    if (this.disabled) {
      this.ClickDisabled.emit();
      return;
    }
    if (!this.disabled) {
      this.Click.emit();
      return;
    }
  }

  ngOnChanges(): void {
    this.disabledStyle = this.disabled;
    this.setCss();
  }

  setCss(): void {
    if (!this.ownActionElement) return;
    // if (DeviceHelper.isUserAgentIOS()) this.ownActionElement.classList.add(SpeedDialCssClasses.iosTransform);
    if (this.disabledStyle) {
      for (const a of this.disabledCssClasses) {
        this.ownActionElement.classList.add(a);
      }
      return;
    }
    if (!this.disabledStyle) {
      for (const a of this.disabledCssClasses) {
        this.ownActionElement.classList.remove(a);
      }
      return;
    }
  }

  onContentReady(event: ContentReadyEvent): void {
    // actionElement ist ist kein HTMLElement[], obwohl es als ein array mit einem Element ist. fehler von devextreme?
    const elements = event.actionElement as unknown as HTMLElement[];
    this.ownActionElement = elements[0];

    this.setCss();
  }
}

import { Injectable } from '@angular/core';
import { PwaLoginResponse, Right } from 'libs/shared/src/lib/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { AuthorizationService } from 'libs/shared/src/lib/services/authorization.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { StateService } from './state.service';
import { SyncService } from './sync.service';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  constructor(
    private routingService: RoutingService,
    private rightsService: RightsService,
    private authorizationService: AuthorizationService,
    private syncService: SyncService,
    private stateService: StateService
  ) {}

  /**@description Behält Sessioninformation vor einem Browser Refresh */
  keepSessionData(): void {
    GlobalHelper.saveToLocalStorage('routes', this.routingService.lastRoutes);
    GlobalHelper.saveToLocalStorage('loggedIn', this.stateService.loggedIn.value);
    GlobalHelper.saveToLocalStorage('right', this.rightsService.getCurrentRight());
    GlobalHelper.saveToLocalStorage('authorization', this.authorizationService.current.getValue());
    GlobalHelper.saveToLocalStorage('discover', this.syncService.newThingsDiscovered.getValue());
  }

  /**@description Lädt Sessioninformation wenn die app (neu) gestartet wird */
  readSessionData(): void {
    this.routingService.lastRoutes = GlobalHelper.readFromLocalStorage('routes') || [];
    const loggedIn: boolean = GlobalHelper.readFromLocalStorage('loggedIn') || false;
    this.stateService.loggedIn.next(loggedIn);
    const currentRight = GlobalHelper.readFromLocalStorage('right', Right);
    this.rightsService.updateCurrentRight(currentRight);
    const currentAuthorization = GlobalHelper.readFromLocalStorage('authorization', PwaLoginResponse);
    this.authorizationService.current.next(currentAuthorization);
    const discover: boolean = GlobalHelper.readFromLocalStorage('discover') || false;
    this.syncService.newThingsDiscovered.next(discover);
  }

  /**@description Löscht die Daten des Localstorage und setzt die werte wo sie herkommen auf defaultwerte zurück */
  clearLocalSessionData(): void {
    localStorage.clear();
    this.routingService.lastRoutes = [];
    this.stateService.loggedIn.next(false);
    this.rightsService.updateCurrentRight(null);
    this.authorizationService.current.next(null);
    this.syncService.newThingsDiscovered.next(false);
  }
}

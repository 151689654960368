<header>
  <dx-toolbar class="header-toolbar">
    <dxi-item *ngIf="menuToggleEnabled" location="before" cssClass="menu-button">
      <div
        *dxTemplate
        (click)="toggleMenu()"
        class="dx-button dx-button-normal dx-button-mode-text dx-widget dx-button-has-icon"
        aria-label="menu"
        tabindex="0"
        role="button"
      >
        <div class="dx-button-content" style="position: relative">
          <i class="dx-icon dx-icon-menu"></i>
          <div *ngIf="newThings | async" class="discoverDot"></div>
        </div>
      </div>
    </dxi-item>

    <dxi-item
      *ngIf="backExists"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'back',
        stylingMode: 'text',
        onClick: routeBack
      }"
    >
    </dxi-item>

    <dxi-item location="center">
      <div class="header-title no-select" *dxTemplate (click)="hiddenFeature()">hand:werk</div>
    </dxi-item>

    <dxi-item
      *ngIf="(syncObjects | async).length !== 0"
      location="after"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'refresh',
        stylingMode: 'text',
        onClick: synchronize
      }"
    >
    </dxi-item>
  </dx-toolbar>
</header>

import { Component, OnInit } from '@angular/core';
import { HWAddress, HWObjectAddress } from 'apps/handwerkPWA/src/app/entities';
import { MaintenanceSystemService } from 'apps/handwerkPWA/src/app/services/dataServices/maintenanceSystem.service';
import { ObjectaddressService } from 'apps/handwerkPWA/src/app/services/dataServices/objectAddress.service';
import { ServiceOrderService } from 'apps/handwerkPWA/src/app/services/dataServices/serviceOrder.service';
import { EmployeeRights } from 'libs/shared/src/lib/entities';
import { TimeHelper } from 'libs/shared/src/lib/helper/timeHelper';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { MeasurementTypes, SliderNames } from '../../config/Konstanten';
import { MultiViewSite } from '../../entities/repository/MultiViewSite';
import { AppointmentHelper } from '../../helper/services/terminHelper';
import { AddressService } from '../../services/dataServices/address.service';
import { AppointmentService } from '../../services/dataServices/appointment.service';
import { MeasurementService } from '../../services/dataServices/measurement.service';
import { RepairOrderService } from '../../services/dataServices/repairOrder.service';
import { SliderService } from '../../services/dataServices/slider.service';
import { HWGlobalSettingService } from '../../services/globalServices/hwGlobalSetting.service';
import { SyncService } from '../../services/globalServices/sync.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  rights: EmployeeRights;
  isPremium: boolean;
  multiViewSites: MultiViewSite[] = [];
  finishedLoading: boolean;

  constructor(
    private globalSettingService: HWGlobalSettingService,
    private rightsService: RightsService,
    private addressService: AddressService,
    private objectAddressService: ObjectaddressService,
    private repairOrderService: RepairOrderService,
    private appointmentService: AppointmentService,
    private maintenanceAndService: MaintenanceSystemService,
    private serviceOrderService: ServiceOrderService,
    private routingService: RoutingService,
    private measurementService: MeasurementService,
    private syncService: SyncService,
    private sliderService: SliderService
  ) {}

  async ngOnInit(): Promise<void> {
    this.rights = this.rightsService.getCurrentRight().employeeRights;
    this.isPremium = this.rightsService.isPremium();
    this.finishedLoading = true;
    await this.setMultiViewDataInOrder();
    await this.syncService.getNewThingsToDiscover();
  }
  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  /**@description Gets all the data needed for the MultiView and their sets order */
  private async setMultiViewDataInOrder(): Promise<void> {
    const allSliders = await this.sliderService.getDataFromIndexDB();
    const multiViewItems = allSliders.filter(view => !view.userDisabled && this.sliderService.filterByRight(view));

    const userInfo = await this.globalSettingService.getUserInfo();

    /** Things needed in multiple places */
    const allAddresses = await this.getAllAddresses();
    const allAppointments = await this.appointmentService.getAllAppointmentsFromIDB(userInfo);
    const allMeasurements = await this.measurementService.getAll();

    const allMaintenanceSystems = await this.maintenanceAndService.getAnlagenFromIDB();
    for (const view of multiViewItems) {
      switch (view.cardName) {
        case SliderNames.addresses:
          view.dataArray = allAddresses;
          break;
        case SliderNames.repairOrders:
          const allRepairOrders = this.repairOrderService.sortRepairOrders(
            await this.repairOrderService.getAllRepairOrdersFromIDB()
          );
          view.dataArray = allRepairOrders;
          break;
        case SliderNames.appointments:
          const today = TimeHelper.dateToDatabaseDate(new Date());
          view.title = `Termine ${today}`;
          const appointmentsForDay = AppointmentHelper.getAllAppointmentsForCustomerToday(allAppointments);
          const appointmentsWithDetails = AppointmentHelper.appointmentsToMoreSpecificDatasets(
            appointmentsForDay,
            this.rights
          );
          view.dataArray = appointmentsWithDetails;
          break;
        case SliderNames.maintenanceSystems:
          view.dataArray = allMaintenanceSystems;
          break;
        case SliderNames.serviceOrders:
          const employees = allAddresses.filter(address => address.ADRTYP === 'M') as HWAddress[];
          const allServiceOrders = await this.serviceOrderService.getAllServiceOrdersWithAllDetailsFromIDB(
            employees,
            allMaintenanceSystems,
            allAppointments
          );
          view.dataArray = allServiceOrders;
          break;
        case SliderNames.roomMeasurements:
          view.dataArray = allMeasurements.filter(e => e.Type === MeasurementTypes.roomMeasurement);
          break;
        case SliderNames.freeMeasurement:
          view.dataArray = allMeasurements.filter(e => e.Type === MeasurementTypes.freeMeasurement);
          break;
      }
    }
    this.multiViewSites = multiViewItems;
  }

  private async getAllAddresses(): Promise<(HWAddress | HWObjectAddress)[]> {
    let allAddresses: (HWAddress | HWObjectAddress)[] = [];
    const addresses = await this.addressService.getAllDisplayable();
    allAddresses = addresses.filter(address => address.LeaveDate == null || address.LeaveDate >= new Date());
    const allObjectAddresses = await this.objectAddressService.getAll();
    allAddresses = allAddresses.concat(allObjectAddresses);
    return allAddresses;
  }
}

<div class="contactBar flexContainer">
  <dx-button
    class="flexContainerItem contact-button"
    stylingMode="text"
    icon="tel"
    type="normal"
    [text]="telephoneDescription ? 'Telefon' : 'Festnetz'"
    [disabled]="!telephoneNumber"
    (onClick)="callPhone(telephoneNumber)"
  ></dx-button>
  <dx-button
    class="flexContainerItem contact-button"
    stylingMode="text"
    icon="tel"
    type="normal"
    [text]="'Mobil'"
    [disabled]="!mobileNumber"
    (onClick)="callPhone(mobileNumber)"
  ></dx-button>
  <dx-button
    class="flexContainerItem contact-button"
    stylingMode="text"
    icon="message"
    type="normal"
    [text]="'E-Mail'"
    [disabled]="!eMailAddress"
    (onClick)="mailTo(eMailAddress)"
  ></dx-button>
  <dx-button
    *ngIf="address"
    class="flexContainerItem contact-button"
    stylingMode="text"
    icon="map"
    type="normal"
    [text]="'Navigieren'"
    [disabled]="!(address.Strasse && address.Ort) && !address?.FallbackSearchString"
    (onClick)="openMaps(address)"
  ></dx-button>
  <dx-button
    class="flexContainerItem contact-button"
    stylingMode="text"
    icon="globe"
    type="normal"
    [text]="'Web'"
    [disabled]="!website"
    (onClick)="goToHomepage(website)"
  ></dx-button>
</div>

import { GlobalHelper } from '../../../helper/globalHelper';
import { AuthorizationHelper } from '../../../helper/services/authorizationHelper';
import { Feature, features } from './Feature';
import { FeatureCheck, FeatureNames } from './FeatureCheck';

export class PwaLoginResponse {
  authenticated: boolean;
  handwerkDBVersion = '60.0'; // Default zu tiefe aber valide VErsions
  handwerkDBVersionValue: number;
  webserviceVersion = '3.0.2.10';
  webserviceVersionValue: number;
  betaUser = false;
  handwerkBranding: string;
  constructor(data: PwaLoginResponse) {
    Object.assign(this, data);
    this.handwerkDBVersionValue = AuthorizationHelper.versionStringToDecimalVersion(this.handwerkDBVersion);
    this.webserviceVersionValue = AuthorizationHelper.versionStringToDecimalVersion(this.webserviceVersion);
  }

  /**@description Maps the feature name to minimum versions and checks if they are met */
  featureCheck(featureName: FeatureNames): FeatureCheck {
    const feature = Feature.getFeature(featureName);
    const minDbversionString = feature.minHandwerkDBVersion;
    const minWebServiceVersionString = feature.minWebServiceVersion;
    const requiredDBVersion = AuthorizationHelper.versionStringToDecimalVersion(minDbversionString);
    const requiredWebServiceVersion = AuthorizationHelper.versionStringToDecimalVersion(minWebServiceVersionString);

    const isDbHighEnough = this.handwerkDBVersionValue >= requiredDBVersion;
    const isWebServiceHighEnough = this.webserviceVersionValue >= requiredWebServiceVersion;
    return new FeatureCheck(
      featureName,
      isDbHighEnough,
      isWebServiceHighEnough,
      minWebServiceVersionString,
      minDbversionString,
      this.betaUser
    );
  }

  /**@description Gives the highest possible feature, incrementally depending on which minimum versions are reached */
  getHighestFeaturePossible(): FeatureNames {
    const sortedFeatures = features.sort((a, b) => GlobalHelper.compareFunction(a.order, b.order, true));
    for (const feature of sortedFeatures) {
      const dbCheckPassed =
        this.handwerkDBVersionValue >= AuthorizationHelper.versionStringToDecimalVersion(feature.minHandwerkDBVersion);
      const webserviceCheckPassed =
        this.webserviceVersionValue >= AuthorizationHelper.versionStringToDecimalVersion(feature.minWebServiceVersion);
      if (dbCheckPassed && webserviceCheckPassed) return feature.name;
    }
    return FeatureNames.base;
  }
}

import { Datesortable } from 'apps/handwerkPWA/src/app/interfaces';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';

export class RepairOrderItemHelper {
  static compareDates(a: Datesortable, b: Datesortable): number {
    return GlobalHelper.compareFunction(a.getSortDate(), b.getSortDate(), true);
  }

  /**@description Sortiert ein Array welches das Interface DateSortable implementiert hat */
  static sortDateSortableArray<Type>(dateSortables: Datesortable[]): Type[] {
    dateSortables = dateSortables.sort(this.compareDates);
    return dateSortables as Type[];
  }
}

<div class="content-block bottom-padding" *ngIf="handleFieldJumpManually != undefined">
  <div class="form-block dx-card responsive-paddings">
    <dx-scroll-view [height]="'40vh'">
      <div style="text-align: center">
        <img id="aufmassImage" [src]="measurementConstruct.pictureDataBase64" />
      </div>
    </dx-scroll-view>

    <dx-scroll-view [height]="'40vh'">
      <b>Wände</b>
      <div class="element-box" *ngFor="let strecke of measurementConstruct.MeasurementRoutes; let i = index">
        <div class="element-row">
          <div class="element-label">
            <app-bss-textbox [disabled]="true" [value]="strecke.label"></app-bss-textbox>
          </div>
          <div class="element-field">
            <app-bss-suggestion-field
              [headline]="'Wählen Sie eine Flächenbezeichnung'"
              [dataTypeName]="'Flächenbezeichnung'"
              [(value)]="strecke.labelLong"
              [inputDataSource]="roomNames"
              [customDisplayExpression]="'Bezeichng'"
              [searchExpressions]="searchExpressions"
            >
            </app-bss-suggestion-field>
          </div>
        </div>
        <div class="element-row">
          <div class="element-label text-label">Länge:</div>
          <div class="element-field">
            <app-bss-measuring-field
              [jumpToNext]="!handleFieldJumpManually"
              [keyboardPosition]="'bottom'"
              [title]="'Breite ' + strecke.labelLong + ' (' + strecke.label + ')'"
              [fieldIndex]="i"
              [measurement]="strecke.length?.toString()"
              (measurementValueChange)="measurementChanged(strecke, $event, handleFieldJumpManually)"
              [unit]="'m'"
              [placeholder]="'Länge eingeben'"
              [distanceMeterMode]="distanceMeterMode"
              [beginningEmpty]="true"
              [useDxTemplate]="true"
            >
            </app-bss-measuring-field>
          </div>
        </div>
      </div>

      <div class="element-box" *ngIf="measurementConstruct.roomHeight">
        <div class="element-row">
          <div class="element-label">
            <app-bss-textbox [disabled]="true" [value]="measurementConstruct.roomHeight.label"></app-bss-textbox>
          </div>
          <div class="element-field">
            <app-bss-measuring-field
              [jumpToNext]="!handleFieldJumpManually"
              [keyboardPosition]="'bottom'"
              [title]="'Raumhöhe'"
              [fieldIndex]="measurementConstruct.MeasurementRoutes.length"
              [measurement]="measurementConstruct.roomHeight.length?.toString()"
              (measurementValueChange)="
                measurementChanged(measurementConstruct.roomHeight, $event, handleFieldJumpManually)
              "
              [unit]="'m'"
              [placeholder]="'Raumhöhe eingeben'"
              [distanceMeterMode]="distanceMeterMode"
              [beginningEmpty]="true"
              [useDxTemplate]="true"
            >
            </app-bss-measuring-field>
          </div>
        </div>
      </div>

      <div class="element-box" *ngIf="wallsDone">
        <div class="element-row">
          <div class="element-label">
            <app-bss-textbox [disabled]="true" [value]="measurementConstruct.ceilingArea.label"></app-bss-textbox>
          </div>
          <div class="element-field">
            <app-bss-suggestion-field
              [headline]="'Wählen Sie eine Deckenflächenbezeichnung'"
              [dataTypeName]="'Deckenflächenbezeichnung'"
              [(value)]="measurementConstruct.ceilingArea.labelLong"
              [inputDataSource]="roomNames"
              [customDisplayExpression]="'Bezeichng'"
              [searchExpressions]="searchExpressions"
            >
            </app-bss-suggestion-field>
          </div>
        </div>
        <div class="element-row">
          <div class="element-label text-label">Formel:</div>
          <div class="element-field">
            <app-bss-textbox
              [placeholder]="'Formel eingeben'"
              [value]="measurementConstruct.ceilingArea.formula?.replaceAll('.', ',')"
              (focusOut)="validateFormula($event, 'Deckenfläche')"
            >
            </app-bss-textbox>
          </div>
        </div>
      </div>

      <div class="element-box" *ngIf="wallsDone">
        <div class="element-row">
          <div class="element-label">
            <app-bss-textbox [disabled]="true" [value]="measurementConstruct.floorArea.label"></app-bss-textbox>
          </div>
          <div class="element-field">
            <app-bss-suggestion-field
              [headline]="'Wählen Sie eine Bodenflächenbezeichnung'"
              [dataTypeName]="'Bodenflächenbezeichnung'"
              [(value)]="measurementConstruct.floorArea.labelLong"
              [inputDataSource]="roomNames"
              [customDisplayExpression]="'Bezeichng'"
              [searchExpressions]="searchExpressions"
            >
            </app-bss-suggestion-field>
          </div>
        </div>
        <div class="element-row">
          <div class="element-label text-label">Formel:</div>
          <div class="element-field">
            <app-bss-textbox
              [placeholder]="'Formel eingeben'"
              [value]="measurementConstruct.floorArea.formula?.replaceAll('.', ',')"
              (focusOut)="validateFormula($event, 'Bodenfläche')"
            >
            </app-bss-textbox>
          </div>
        </div>
      </div>

      <b *ngIf="measurementConstruct?.buildingElement?.length > 0">Bauelemente</b>
      <div class="element-box" *ngFor="let element of measurementConstruct.buildingElement; let j = index">
        <div class="element-row">
          <div class="element-label">
            <app-bss-textbox [disabled]="true" [value]="element.label"></app-bss-textbox>
          </div>
          <div class="element-field">
            <app-bss-suggestion-field
              [headline]="'Wählen Sie eine Bezeichnung'"
              [dataTypeName]="'Bezeichnung'"
              [(value)]="element.labelLong"
              [inputDataSource]="roomNames"
              [customDisplayExpression]="'Bezeichng'"
              [searchExpressions]="searchExpressions"
            >
            </app-bss-suggestion-field>
          </div>
        </div>
        <div class="element-row">
          <div class="element-label text-label">{{ element.template.labelLength }}:</div>
          <div class="element-field">
            <app-bss-measuring-field
              [jumpToNext]="!handleFieldJumpManually"
              [keyboardPosition]="'bottom'"
              [title]="element.template.labelLength + ' ' + element.labelLong + ' (' + element.label + ')'"
              [fieldIndex]="measurementConstruct.MeasurementRoutes.length + 2 + (2 * j - 1)"
              [measurement]="element.length?.toString()"
              (measurementValueChange)="measurementChanged(element, $event, handleFieldJumpManually)"
              [unit]="'m'"
              [useDxTemplate]="true"
              [placeholder]="element.template.labelLength"
              [distanceMeterMode]="distanceMeterMode"
              [beginningEmpty]="true"
            >
            </app-bss-measuring-field>
          </div>
        </div>
        <div class="element-row" *ngIf="element.template.allFields.length > 1">
          <div class="element-label text-label">
            {{ element.template.labelHeight }}
          </div>
          <div class="element-field">
            <app-bss-measuring-field
              [jumpToNext]="!handleFieldJumpManually"
              [keyboardPosition]="'bottom'"
              [title]="element.template.labelHeight + ' ' + element.labelLong"
              [fieldIndex]="measurementConstruct.MeasurementRoutes.length + 2 + 2 * j"
              [measurement]="element.height?.toString()"
              [useDxTemplate]="true"
              (measurementValueChange)="measurementChanged(element, $event, handleFieldJumpManually, 'hoehe')"
              [unit]="'m'"
              [placeholder]="element.template.labelHeight"
              [distanceMeterMode]="distanceMeterMode"
              [beginningEmpty]="true"
            >
            </app-bss-measuring-field>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</div>

<app-bss-speed-dial
  *ngIf="saveAble"
  id="saveButton"
  icon="check"
  class="add-button"
  [ngClass]="{ aclass: !saveAble }"
  label="speichern"
  [index]="1"
  (Click)="measurementComplete()"
>
</app-bss-speed-dial>

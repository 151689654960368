import { Injectable, OnDestroy } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { SitesToReloadAfterNewPushData } from 'apps/handwerkPWA/src/app/config/Konstanten';
import { BackgroundHelper } from 'apps/handwerkPWA/src/app/helper/services/backgroundHelper';
import { Right } from 'libs/shared/src/lib/entities';
import { AuthorizationService } from 'libs/shared/src/lib/services/authorization.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription, interval } from 'rxjs';
import { HWNachricht, HWRepairOrder, ServiceAuftrag } from '../../entities';
import { SyncObject } from '../../entities/models/SyncObject';
import { ActivityTrackerService } from '../dataServices/activityTracker.service';
import { MonteurService } from '../dataServices/monteur.service';
import { RepairOrderService } from '../dataServices/repairOrder.service';
import { ServiceOrderService } from '../dataServices/serviceOrder.service';
import { ConnectionService } from './connection.service';
import { HWGlobalSettingService } from './hwGlobalSetting.service';
import { SyncService } from './sync.service';

@Injectable({
  providedIn: 'root',
})
export class BackgroundService implements OnDestroy {
  dailySubscription: Subscription;
  backgroundIntervalSubscription: Subscription;

  constructor(
    private syncService: SyncService,
    private dialogService: DialogService,
    private rightsService: RightsService,
    private routingService: RoutingService,
    private repairOrderService: RepairOrderService,
    private serviceOrderService: ServiceOrderService,
    private employeeService: MonteurService,
    private globalSettingService: HWGlobalSettingService,
    private connectionService: ConnectionService,
    private authorizationService: AuthorizationService,
    private activityTrackerService: ActivityTrackerService,
  ) {}

  ngOnDestroy(): void {
    this.stopBackgroundTasks();
  }

  /**@description Performs daily things: Look for new things in the "Discover area". */
  doDailyCheck(): void {
    const timeIntervalInMinutes = 60 * 24;
    const everyNMinutes$ = interval(timeIntervalInMinutes * 60 * 1000);
    this.dailySubscription = everyNMinutes$.subscribe(() => {
      void this.syncService.getNewThingsToDiscover();
    });
  }

  stopBackgroundTasks(): void {
    this.stopBackgroundSyncInterval();
    this.dailySubscription?.unsubscribe();
  }

  stopBackgroundSyncInterval(): void {
    this.backgroundIntervalSubscription?.unsubscribe();
  }

  /**
   * @description Executes all background tasks, currently: Initiate event handler
   * @value timeInterval Time interval in minutes, 0 = off
   */
  startBackgroundSyncInterval(timeInterval: number): void {
    if (timeInterval === 0) return;
    const everyNMinutes$ = interval(timeInterval * 60 * 1000);
    this.backgroundIntervalSubscription = everyNMinutes$.subscribe(() => void this.doBackgroundRoutine());
  }

  async restartServiceOnPageRefresh(): Promise<void> {
    const signedIn = await this.employeeService.userSignedIn();
    if (!signedIn) return;

    const intervalMinutes = await this.getCurrentSyncInterval();
    this.stopBackgroundSyncInterval();
    this.startBackgroundSyncInterval(intervalMinutes);
  }

  async getCurrentSyncInterval(): Promise<number> {
    const appOnlySettings = await this.globalSettingService.getAppOnlySettings();
    const intervalMinutes = appOnlySettings.backgroundSyncInterval;
    return intervalMinutes;
  }

  private async doBackgroundRoutine(): Promise<void> {
    if (!(await this.connectionService.checkOnline())) return;
    await this.syncService.pushAllUnpushedData(false, true);
    const userInfo = await this.globalSettingService.getUserInfo();
    await this.authorizationService.checkLogin(userInfo, true);
    const rights = await this.rightsService.getSettingsAndRightsFromWebService(userInfo, true, true);
    await this.pushAlternative(rights);
  }

  /**@description Alternative zum Push für IOS und Android mit abgelehntem Push */
  private async pushAlternative(rights: Right): Promise<void> {
    const changes = await this.syncService.getChanges(rights, true);
    await this.activityTrackerService.countUpAutoSyncs();
    const userInfo = await this.globalSettingService.getUserInfo();
    await this.syncService.syncObjects(userInfo, [...changes.syncObjects], true);
    void this.routeOrReloadAfterChanges(
      changes.syncObjects,
      changes.ordersAlreadyInApp,
      changes.serviceOrderNumbersAlreadyInApp,
    );
  }

  private async routeOrReloadAfterChanges(
    changesIn: SyncObject[],
    ordersAlreadyInApp: string[],
    serviceOrderNumbersAlreadyInApp: string[],
  ): Promise<void> {
    const changesInPushData =
      changesIn.includes(HWRepairOrder) || changesIn.includes(HWNachricht) || changesIn.includes(ServiceAuftrag);
    const changesInBoth = changesIn.includes(HWRepairOrder) && changesIn.includes(HWNachricht);
    const changeString = BackgroundHelper.changesInToString(changesIn);
    if (changesInPushData && !changesInBoth) {
      await this.routeToChangedData(changeString, changesIn, ordersAlreadyInApp, serviceOrderNumbersAlreadyInApp);
      return;
    }
    if (changesInPushData)
      this.dialogService.openInformDialog(
        'Daten aktualisiert',
        'Es gibt Neuerungen oder Änderungen in folgenden Datensätzen: ' + changeString,
        'Ok',
      );
    const currentRoute = this.routingService.lastRoutes[0];
    const onReloadableSite = SitesToReloadAfterNewPushData.includes(currentRoute);
    if (onReloadableSite) this.routingService.reload();
  }

  /**@description Asks the user if he wants to navigate to the concretely updated record */
  private async routeToChangedData(
    changeString: string,
    changesIn: SyncObject[],
    ordersAlreadyInApp: string[],
    serviceOrderNumbersAlreadyInApp: string[],
  ): Promise<void> {
    let routeTo: string = BackgroundHelper.changesInToRoute(changesIn);
    let navigateToString = '';
    switch (routeTo) {
      case '/nachrichten':
        navigateToString = 'Zu Nachrichten navigieren';
        break;
      case '/reparaturauftraege':
        navigateToString = 'Zu den Reparaturaufträgen navigieren';
        break;
      case '/wartungsauftraege':
        navigateToString = 'Zu den Serviceaufträgen navigieren';
        break;
    }
    if (routeTo === '/reparaturauftraege')
      ({ routeTo, navigateToString } = await this.tryFindingNewestRepairOrder(
        ordersAlreadyInApp,
        routeTo,
        navigateToString,
      ));

    if (routeTo === '/wartungsauftraege')
      ({ routeTo, navigateToString } = await this.tryFindingNewestServiceOrder(
        serviceOrderNumbersAlreadyInApp,
        routeTo,
        navigateToString,
      ));
    const navigate = await this.dialogService.openConfirmDialog(
      'Daten aktualisiert',
      `Es gibt Neuerungen oder Änderungen in ${changeString} `,
      navigateToString,
      'Schließen',
      true,
    );
    if (navigate) void this.routingService.navigateTo(routeTo);
    return;
  }

  /**@description Try to find the latest order - if successful, the route will be changed to the detail view and the text will be adjusted. */
  private async tryFindingNewestRepairOrder(
    ordersAlreadyInApp: string[],
    routeTo: string,
    navigateToString: string,
  ): Promise<{ routeTo: string; navigateToString: string }> {
    const allOrders = await this.repairOrderService.getAllRepairOrdersFromIDB(true);
    const allOrderNumbers = allOrders.map(order => order.getOrderNumber());
    const newNumbers = allOrderNumbers.filter(order => !ordersAlreadyInApp.includes(order));
    if (newNumbers.length === 1) {
      const newOrderNumber = newNumbers[0];
      const newOrder = allOrders.find(order => order.getOrderNumber() === newOrderNumber);
      routeTo = `/reparaturauftrag/${newOrder.Guid}/edit`;
      navigateToString = `Reparaturauftrag ${newOrder.getOrderNumber() + ' ' + newOrder.Betreff} öffnen`;
    }
    return { routeTo, navigateToString };
  }

  /**@description Versucht neusten Auftrag zu finden - gelingt dies wird die Route geändert auf die Detailansicht und der Text angepasst */
  private async tryFindingNewestServiceOrder(
    serviceOrdersAlreadyInApp: string[],
    routeTo: string,
    navigateToString: string,
  ): Promise<{ routeTo: string; navigateToString: string }> {
    const allOrders: ServiceAuftrag[] = await this.serviceOrderService.getAllServiceOrdersWithoutDetailsFromIDB();
    const allOrderNumbers = allOrders.map(order => order.getOrderNumber());
    const newNumbers = allOrderNumbers.filter(order => !serviceOrdersAlreadyInApp.includes(order));
    if (newNumbers.length === 1) {
      const newOrderNumber = newNumbers[0];
      const newOrder = allOrders.find(order => order.getOrderNumber() === newOrderNumber);
      routeTo = `/wartungsauftrag/${newOrder.UUID}/edit`;
      navigateToString = `Serviceauftrag ${newOrder.getOrderNumber() + ' ' + newOrder.getBetreff()} öffnen`;
    }
    return { routeTo, navigateToString };
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { HWTermin } from 'apps/handwerkPWA/src/app/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { AppointmentType } from '../../../config/Konstanten';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss'],
})
export class AppointmentCardComponent implements OnInit {
  @Input() appointment: HWTermin;
  @Input() showDate = false;
  isTask: boolean;
  appointmentText: string;

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {
    this.isTask = this.appointment.bss_type === AppointmentType.Task;
    this.appointmentText = this.appointment.getAppointmentText();
  }

  /**@description When a specific appointment is clicked */
  async goToAppointmentDetail(): Promise<void> {
    await this.routingService.navigateTo('termin/' + this.appointment.AutoKey);
  }
}

<!-- regulärer scan -->
<div class="scanBackground">
  <zxing-scanner
    #scanner
    (camerasFound)="camerasFoundHandler($event)"
    (scanSuccess)="scanSuccessHandler($event)"
    [formats]="codeFormats"
    [device]="desiredDevice"
  >
  </zxing-scanner>
  <input
    class="hidden"
    id="uploader"
    #uploader
    type="file"
    accept="image/jpeg, image/png"
    (change)="processQrImage($event)"
  />
  <button *ngIf="showHiddenUpload" class="uploadButton" (click)="uploader.click()">QR-Bild auswählen</button>
  <button class="stopBarcode" (click)="stopScanning()" mat-button>
    <a>Abbrechen</a>
  </button>
  <img [src]="cameraSwitchUrl" class="switchBetweenDevices" (click)="switchBetweenDevices()" />
</div>

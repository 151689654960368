<dx-popup
  class="signature-popup"
  [width]="'100%'"
  [height]="'100%'"
  [dragEnabled]="false"
  [showCloseButton]="false"
  [showTitle]="false"
  container=".dx-viewport"
  [(visible)]="showPopup"
>
  <canvas id="canvas" width="100%" style="border: 1px solid black"></canvas>
  <div class="actions-container">
    <button class="action-button" (click)="createUnterschrift(defaultSigningPerson)">
      <img src="/assets/icons/ok.png" alt="" />
    </button>
    <app-bss-textbox [labelText]="'Unterschrift'" [(value)]="defaultSigningPerson" [maxLength]="254"></app-bss-textbox>
    <button class="action-button">
      <img src="/assets/icons/cancelRed.png" alt="" (click)="closePopup()" />
    </button>
  </div>
</dx-popup>

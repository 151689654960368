import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SyncService } from 'apps/handwerkPWA/src/app/services/globalServices/sync.service';
import { discoverBasicUrl, discoverPremiumUrl } from 'libs/shared/src/lib/entities/models/Typedstrings';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
})
export class ExploreComponent implements OnInit {
  discoverUrl: SafeResourceUrl;

  constructor(
    private syncService: SyncService,
    private rightsService: RightsService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const license = this.rightsService.isPremium();
    const discoverUrl = license ? discoverPremiumUrl : discoverBasicUrl;
    this.discoverUrl = this.sanitizer.bypassSecurityTrustResourceUrl(discoverUrl);
    this.syncService.newThingsDiscovered.next(false);
  }
}

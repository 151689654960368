import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { MeasurementHelper } from 'libs/shared/src/lib/helper/measurementHelper';
import { MeasurementFormulaRaw } from './MeasurementFormulaRaw';
import { ValueNamePair } from './ValueNamePair';

export class MeasurementFormula {
  Id: number = null;
  shortName: string = null;
  formula: string = null;
  Erkl: string = null;
  Status: string = null;
  pictureName: string = null;
  Uuid: string = null;
  ImageBase64: string = null;
  ValueNamePairs: ValueNamePair[] = [];

  constructor(formula: MeasurementFormulaRaw) {
    GlobalHelper.assignIfPropertyExists(this, formula);
    if (this.formula) this.ValueNamePairs = MeasurementHelper.convertFormulaToValueNamePairs(this.formula);
  }

  checkAllPairsMeasured(): boolean {
    return this.ValueNamePairs.every(pair => !GlobalHelper.isNullOrUndefined(pair.value));
  }

  generateValueFilledFormula(): string {
    let transformationFormula = this.formula;
    const valueNamePairs = this.ValueNamePairs;
    for (const pair of valueNamePairs) {
      const decimalPlaces = pair.value.toString().includes('.') ? pair.value.toString().split('.')[1]?.length : 0;
      transformationFormula = transformationFormula.replaceAll(pair.name, `${pair.value.toFixed(decimalPlaces)}`);
    }
    transformationFormula = transformationFormula.replaceAll('.', ',');
    return transformationFormula;
  }
}

<div class="dx-field bss-textarea">
  <div *ngIf="labelText" class="dx-field-label">{{ labelText }}</div>
  <div class="dx-field-value">
    <dx-text-area
      [valueChangeEvent]="'input'"
      [(value)]="value"
      (valueChange)="valueChanged($event)"
      [minHeight]="minHeight"
      [maxHeight]="maxHeight"
      [maxLength]="maxLength"
      [readOnly]="disabled"
      [placeholder]="placeholder"
      [autoResizeEnabled]="true"
    ></dx-text-area>
  </div>
</div>

import { Aufmass, HWAddress, HWAnlage, HWObjectAddress, HWRepairOrder, ServiceAuftrag } from '../entities';
import { RoomBookPosition } from '../entities/models/aufmass/RoomBookPosition';

/** Array mit Seiten, von denen ohne Check auf geändert Daten weg geroutet werden soll */
export const ignoreChangesOn: string[] = [
  '/einstellungen',
  'aufmass/:uuid/raumbuch',
  'aufmass/neu',
  'Login/UUID/:uuid/Mandant/:mandant/Employeenumber/:employeeNumber/Branding/:branding',
  '/Login',
  'aufmass/messung/:aufmassid/:roomId',
  '/belege',
];
export const sitesToAutosaveOnChange: string[] = [
  '/reparaturauftrag/:guid/edit',
  '/auftragsabschluss/reparaturauftrag/:guid',
  '/auftragpositionen',
  'aufmass/grundriss/:aufmassid/:roomId',
];

export const noBackAvailable: string[] = ['Login', 'startseite', 'impressum'];
export const noMenuAvailable: string[] = ['Login'];

export const allowedPictureTypes: string[] = ['jpeg', 'jpg', 'png', 'bmp', 'x-ms-bmp', 'x-bmp'];
export const allowedDocumentTypes: string[] = ['pdf', 'txt', 'csv'];
export const allowedDatatypes: string[] = allowedPictureTypes.concat(allowedDocumentTypes);

export type PositionType = 'Artikel' | 'Lohn' | 'Leistung' | 'Kostenart' | 'Leistungsposition' | 'Textposition';

export enum AppointmentType {
  Unknown = '0',
  Event = '1',
  RepairOrder = '2',
  Task = '3',
  Birthday = '4',
  ServiceOrder = '5',
  MaintenanceInvoice = '6',
  MaintenanceAppointment = '7',
}

export type AppointmentTypeText =
  | 'Termin'
  | 'Auftrag'
  | 'Aufgabe'
  | 'Telefon'
  | 'Wartungsrechnung'
  | 'Wartungstermin'
  | 'Geburtstag';

export enum StuecklistenGlobalPrintSetting {
  On = 'An',
  Off = 'Aus',
  Individual = 'Individuell',
}

export const deDateTimeFormatWithSeconds = 'DD.MM.YYYY HH:mm:ss';

export const deDateTimeFormat = 'DD.MM.YYYY HH:mm';

export const deDateFormat = 'DD.MM.YYYY';

export const timeFormat = 'HH:mm:ss';

export const SitesToReloadAfterNewPushData = [
  '/startseite',
  '/adressen',
  '/reparaturauftraege',
  '/kalender',
  '/nachrichten',
  '/wartungsauftraege',
];

export type AddressShortTypes = 'M' | 'F' | 'K' | 'L' | 'O';

export type AddressLongTypes = 'Mitarbeiter' | 'Freie Adressen' | 'Kunden' | 'Lieferanten' | 'Objektadressen';

export type DateSortableObjectType = 'HWRepairOrder' | 'HWTermin' | 'ServiceAuftrag' | 'ServiceAuftragPoolEntry';

export const topService = '/TopService.svc/';

export const datenschutzPath = 'https://bluesolution.de/datenschutzerklaerung/';

export const hilfePath = 'https://myblueapp.de/handwerk/produktinformationen/';

export type QuickButtonIcons = 'map' | 'tel' | 'globe' | 'info' | 'message';

export const lineBrakeRegex = /(?:\r\n|\r|\n)/g;

export type SourceOfFileList =
  | HWAddress
  | HWObjectAddress
  | HWRepairOrder
  | ServiceAuftrag
  | HWAnlage
  | Aufmass
  | RoomBookPosition;

export type SourceOfFileListString =
  | 'Address'
  | 'ObjectAddress'
  | 'RepairOrder'
  | 'ServiceOrder'
  | 'Anlage'
  | 'Aufmass'
  | 'RoomBookPosition';

export enum MediumDataTypes {
  Text = 0,
  Picture = 1,
  PDF = 2,
  Video = 3,
  Audio = 4,
}

export enum SliderNames {
  addresses = 'Adressen',
  repairOrders = 'Reparaturaufträge',
  appointments = 'Termine',
  maintenanceSystems = 'W&S Anlagen',
  serviceOrders = 'W&S Aufträge',
  roomMeasurements = 'Raumaufmaße',
  freeMeasurement = 'Freies Aufmaß',
}

export enum MeasurementTypes {
  freeMeasurement = 'F',
  roomMeasurement = 'R',
  ColumMeasurement = 'S',
}

export const invalidChars = ['\\', '/', ':', '*', '?', '"', '<', '>', '|'];

export enum ServiceOrderState {
  NotMobile = 0,
  Open = 20,
  Assigned = 40,
  InProgress = 60,
  Complete = 80,
  WaitingForPayment = 90,
  Paid = 100,
}

export enum ServiceOrderStateTexts {
  Open = 'Verfügbar',
  Assigned = 'Zugewiesen',
  InProgress = 'In Bearbeitung',
  error = 'Fehler',
}

export enum RepairOrderStates {
  All = -1,
  Open = 0,
  Accepted = 10,
  Rejected = 20,
  Interrupted = 25,
  InProgress = 30,
  Completed = 40,
  Done = 100,
}

export const countryCodes = [
  'A',
  'B',
  'CH',
  'CZ',
  'D',
  'DK',
  'E',
  'EE',
  'F',
  'FIN',
  'GB',
  'GR',
  'H',
  'I',
  'IE',
  'L',
  'LT',
  'LV',
  'M',
  'NL',
  'NOR',
  'P',
  'PL',
  'RU',
  'S',
  'SI',
  'SK',
];

export enum SpeedDialCssClasses {
  graySpeedDial = 'gray-speed-dial-action',
  graySpeedDialLabelBackground = 'gray-speed-dial-label-background',
  iosTransform = 'ios-transform-dx-speed-dial',
}

export const newPageCloseButton =
  '<button type="button" style="height:100px; width:100%; font-size:80px;" onclick="window.close();">Schließen</button>';

export enum RoomBookPositionLevels {
  room = 1,
  apartment = 2,
  floor = 3,
}
export enum DxDateType {
  date = 'date',
  dateTime = 'datetime',
  time = 'time',
}
export const calendarSyncedMonths = 6;

export enum GlobalSettings {
  UpdateDone = 'UpdateDone',
  AdditionalDesignations = 'AdditionalDesignations',
  TextTemplates = 'TextTemplates',
  TimestampTable = 'TimestampTable',
  DiscoverLastModifiedBase = 'DiscoverLastModifiedBase',
  DiscoverLastModifiedPremium = 'DiscoverLastModifiedPremium',
  AppOnlySettings = 'AppOnlySettings',
  Rights = 'Rights',
  Settings = 'Settings',
  UserInfo = 'UserInfo',
  ErrorLoggingEnabled = 'ErrorLoggingEnabled',
  MultiViewSettings = 'MultiViewSettings',
  ActivityTrackerSavingTime = 'activityTrackerSavingTime',
  AppVersion = 'App-Version',
  LoggedIn = 'LoggedIn',
}

import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Aufmass, RoomTemplate } from '../../../entities';
import { MeasurementService } from '../../../services/dataServices/measurement.service';
import { RoomTemplateService } from '../../../services/dataServices/roomTemplate.service';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';

@Component({
  selector: 'app-choose-raumvorlage',
  templateUrl: './choose-raumvorlage.component.html',
  styleUrls: ['./choose-raumvorlage.component.scss'],
})
export class ChooseRaumvorlageComponent implements OnInit {
  @Input() aufmass: Aufmass;
  roomBooks: RoomTemplate[];
  roomTemplates: RoomTemplate[];
  userInfo: UserInfo;

  constructor(
    private roomTemplateService: RoomTemplateService,
    private dialogService: DialogService,
    private measurementService: MeasurementService,
    private routingService: RoutingService,
    private globalSettingService: HWGlobalSettingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.roomBooks = await this.roomTemplateService.getAllRoomBookTemplates();
    this.roomTemplates = await this.roomTemplateService.getAll();
    this.userInfo = await this.globalSettingService.getUserInfo();
  }

  addVorlage(vorlage: RoomTemplate): void {
    if (vorlage instanceof RoomTemplate) {
      this.aufmass.RVorlage = vorlage.Haustyp;
      this.aufmass.RBezeich = vorlage.Bezeich;
    }
    if (vorlage === null) {
      this.aufmass.RVorlage = 0;
      this.aufmass.RBezeich = '';
    }
  }

  async save(): Promise<void> {
    if (!this.aufmass.RBezeich) {
      await this.dialogService.openErrorMessage(
        'Fehlendes Raumbuch',
        'Sie müssen ein Raumbuch wählen oder einen Namen für ein freies Raumbuch eingeben.'
      );
      return;
    }
    if (this.aufmass.RVorlage !== 0) {
      this.aufmass.addRoomBookPositionsFromRoomBookTemplate(this.aufmass.RVorlage, this.roomTemplates);
    }
    await this.measurementService.saveAufmass(this.aufmass, this.userInfo, false);
    void this.routingService.navigateTo(`/aufmass/${this.aufmass.Uuid}/raumbuch`);
  }
}

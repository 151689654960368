import { Component, OnDestroy, OnInit } from '@angular/core';
import { HWAnlage, MesswertTabellenEintrag, Messwerttabelle } from 'apps/handwerkPWA/src/app/entities';
import { MaintenanceSystemService } from 'apps/handwerkPWA/src/app/services/dataServices/maintenanceSystem.service';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-measurement-new',
  templateUrl: './measurement-new.component.html',
  styleUrls: ['./measurement-new.component.scss'],
})
export class MeasurementNewComponent implements OnInit, OnDestroy {
  userInfo: UserInfo;
  anlage: HWAnlage;
  sollwersatz: Messwerttabelle;
  sollwertEintraege: MesswertTabellenEintrag[];
  messung: Messwerttabelle;
  messwerteintraege: MesswertTabellenEintrag[];
  openKeyboard = false;
  subscription: Subscription;

  constructor(
    private globalSettingService: HWGlobalSettingService,
    private maintenanceSystemService: MaintenanceSystemService,
    private routingService: RoutingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.subscription = this.routingService.save.subscribe(() => {
      void this.addMessung();
    });

    this.userInfo = await this.globalSettingService.getUserInfo();
    await this.loadAnlage();
    const anlageDaten = this.anlage.Anlagendaten;
    const messwertsatz = this.anlage.Messwertsatz;
    this.sollwersatz = messwertsatz.Sollwerttabelle;
    this.sollwertEintraege = this.sollwersatz.MesswertTabellenEintraege;

    this.messung = new Messwerttabelle(anlageDaten, this.sollwersatz, true);
    this.messwerteintraege = this.messung.MesswertTabellenEintraege;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  clearIstwerte(messwertEintraege: MesswertTabellenEintrag[]): void {
    for (const eintrag of messwertEintraege) {
      eintrag.Messwert = ' ';
    }
  }

  async addMessung(): Promise<void> {
    await this.maintenanceSystemService.sendMessungToWebservice(this.anlage, this.messung, this.userInfo);
    this.routingService.dataChanged.next(false);
    this.routingService.routeBack();
  }

  async loadAnlage(): Promise<void> {
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.maintenanceSystemService.findOneAnlageBy('UUID', anlageUUID);
  }
}

import { Injectable } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';

export enum ConnectionDialogues {
  GetData = 1,
  Update = 2,
  PushData = 3,
  CreateData = 4,
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  constructor(private dialogService: DialogService) {}

  async checkOnline(dialogOption?: ConnectionDialogues, silent = false): Promise<boolean> {
    const isOnline = navigator.onLine;
    if (isOnline) return true;

    if (!silent) {
      switch (dialogOption) {
        case ConnectionDialogues.GetData:
          await this.dialogService.openWaitingConfirmDialog(
            'Keine Internetverbindung',
            'Zum beziehen der Daten wird eine Internetverbindung benötigt',
            'Ok'
          );
          break;
        case ConnectionDialogues.Update:
          await this.dialogService.openWaitingConfirmDialog(
            'Update',
            'Das Update kann nur bei bestehender Internetverbindung durchgeführt werden',
            'Ok'
          );
          break;
        case ConnectionDialogues.PushData:
          await this.dialogService.openWaitingConfirmDialog(
            'Keine Verbindung zum Handwerk',
            'Zur Zeit besteht keine Internetverbindung. Sobald wieder eine Internetverbindung zur Verfügung steht, werden die Daten automatisch übertragen.',
            'OK'
          );
          break;
        case ConnectionDialogues.CreateData:
          await this.dialogService.openWaitingConfirmDialog(
            'Keine Internetverbindung',
            'Die Erstellung eines neuen Datensatzes ist nur online möglich. Prüfen Sie Ihre Internetverbindung und stellen sicher, dass der Webservice korrekt ausgeführt wird.',
            'OK'
          );
      }
    }
    return false;
  }
}

<div class="dx-field" *ngIf="ready">
  <div *ngIf="labelText" class="dx-field-label">{{ labelText }}</div>
  <div class="dx-field-value">
    <dx-date-box
      [value]="value"
      [type]="type"
      [acceptCustomValue]="false"
      [min]="min"
      [max]="max"
      [displayFormat]="displayFormat"
      pickerType="rollers"
      (onValueChanged)="dateChanged($event, type)"
      [disabled]="disabled"
    ></dx-date-box>
  </div>
</div>

<div class="card belegposition-card">
  <div class="flexContainer">
    <div class="flexContainerItem">
      <b>{{ belegposition.Positionsnummer }} {{ belegposition.Kurztext }}</b>
    </div>
    <div class="flexContainerItem">
      <p>Artikelnummer: {{ belegposition.Artikelnummer }}</p>
    </div>
    <div class="flexContainerItem">
      <p>Menge: {{ menge }}</p>
    </div>
    <div *ngIf="priceRight" class="flexContainerItem">
      <p>Preis: {{ belegposition.Gesamtpreis }} €</p>
    </div>
  </div>
</div>

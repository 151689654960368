import { Component, OnInit } from '@angular/core';
import { Belegpositionen, HWAddress, HWBeleg } from 'apps/handwerkPWA/src/app/entities';
import { BelegService } from 'apps/handwerkPWA/src/app/services/dataServices/beleg.service';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { supportingDocumentSearch } from '../../../config/search-constants';
import { ActivityTrackerService } from '../../../services/dataServices/activityTracker.service';
import { AddressService } from '../../../services/dataServices/address.service';
@Component({
  templateUrl: './supporting-document-show.component.html',
  styleUrls: ['./supporting-document-show.component.scss'],
})
export class SupportingDocumentShowComponent implements OnInit {
  receiptPosition: Belegpositionen[];
  typeAndNumber: string;
  customerName: string;
  address: HWAddress;
  priceRight: boolean;
  contactInformationArray = ['TEL', 'FUNK_PRIV', 'GLOBEMAIL', 'Anschrift', 'WWW'];
  searchExpressions = supportingDocumentSearch;
  viewFinished = false;
  supportingDocument: HWBeleg;
  tax: string;
  netSum: string;
  grossSum: string;

  constructor(
    private receiptService: BelegService,
    private addressService: AddressService,
    private rightsService: RightsService,
    private routingService: RoutingService,
    private activityTrackerService: ActivityTrackerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.priceRight = this.rightsService.getCurrentRight().employeeSettings.showPrices;

    const receipt = this.routingService.getRouteParam('name');
    this.supportingDocument = await this.receiptService.findOneBy('name', receipt);

    await this.activityTrackerService.countUpAutoSyncs();
    this.typeAndNumber = this.supportingDocument.typundnr;
    this.receiptPosition = await this.receiptService.getBelegPositionen(this.supportingDocument);
    this.address = await this.addressService.findOneBy('KU_NR', this.supportingDocument.kunde);

    this.getTax();

    this.viewFinished = true;
  }

  getTax(): void {
    const grossString = this.supportingDocument.brutto;
    const grossAmount = parseFloat(grossString.replace(',', '.'));
    this.grossSum = grossAmount.toFixed(2);

    const netString = this.supportingDocument.netto;
    const netAmount = parseFloat(netString.replace(',', '.'));
    this.netSum = netAmount.toFixed(2);

    this.tax = (grossAmount - netAmount).toFixed(2);
    this.tax = this.tax.replace('.', ',');
  }
}

import { Component, OnInit } from '@angular/core';
import { maintenanceSystemSearch } from 'apps/handwerkPWA/src/app/config/search-constants';
import { HWAnlage } from 'apps/handwerkPWA/src/app/entities';
import { MaintenanceSystemService } from 'apps/handwerkPWA/src/app/services/dataServices/maintenanceSystem.service';
import { SyncObjectService } from 'apps/handwerkPWA/src/app/services/globalServices/syncObject.service';

@Component({
  selector: 'app-systems-index',
  templateUrl: './systems-index.component.html',
  styleUrls: ['./systems-index.component.scss'],
})
export class SystemsIndexComponent implements OnInit {
  anlagen: HWAnlage[];
  searchExpressions = maintenanceSystemSearch;

  constructor(
    private maintenanceSystemService: MaintenanceSystemService,
    private syncObjectService: SyncObjectService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.anlagen = await this.maintenanceSystemService.getAnlagenFromIDB();
    this.syncObjectService.currentSyncObjects.next([HWAnlage]);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { HWAnlage, ServiceAuftrag } from 'apps/handwerkPWA/src/app/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-service-order-card',
  templateUrl: './service-order-card.component.html',
  styleUrls: ['./service-order-card.component.scss'],
})
export class ServiceOrderCardComponent implements OnInit {
  @Input() serviceAuftrag: ServiceAuftrag;
  @Input() extended = false;
  anlage: HWAnlage;
  showContactBar = false;
  orderNumber: string;
  subjectLine: string;
  statusText: string;
  customerSearchTerm: string;

  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {
    this.anlage = this.serviceAuftrag.AnlageObject;
    if (GlobalHelper.isNullOrUndefined(this.anlage)) throw new Error('Anlage fehlt in Serviceauftragsdaten');
    this.orderNumber = this.serviceAuftrag.getOrderNumber();
    this.subjectLine = this.serviceAuftrag.getBetreff();
    this.statusText = this.serviceAuftrag.getStatusText();
    this.customerSearchTerm = this.serviceAuftrag.getKunde().SUCH;
  }

  async goToServiceauftragDetail(): Promise<void> {
    await this.routingService.navigateTo('wartungsauftrag/' + this.serviceAuftrag.UUID + '/edit');
  }

  toggleContactBar(): void {
    this.showContactBar = !this.showContactBar;
  }
}

<div class="content-block">
  <div class="form-block">
    <h2>Technische Daten 2</h2>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <div *ngFor="let item of technischeDatenZusaetze; let i = index">
            <app-bss-plain-text-box
              [labelText]="technischeDatenBezeichnung[i]"
              [value]="technischeDatenZusaetze[i]"
            ></app-bss-plain-text-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card anlagen-card">
  <div class="flexContainer" *ngIf="anlage" style="flex-direction: column">
    <div class="flexContainerItem">
      <div class="flexContainer">
        <div class="flexContainerItem" (click)="goToSystemDetail()">
          <b>Anlage: {{ anlage.ANLAGE }}</b
          ><br />
          <b>{{ anlage.Anlagendaten?.SUCH }}</b
          ><br />
          <p>Typ: {{ anlage.Anlagendaten.Anlagentyp }}</p>
          <p>Kunde: {{ anlage.Anlagendaten.Kundenobjekt?.SUCH }}</p>
        </div>
        <div *ngIf="anlage.Anlagenstandort" class="flexContainerItem standort" (click)="goToSystemDetail()">
          <b>Standort:</b><br />
          <p>{{ anlage.Anlagenstandort.NAME }}</p>
          <p>{{ anlage.Anlagenstandort.STRASSE }}</p>
          <p>{{ anlage.Anlagenstandort.PLZ }} {{ anlage.Anlagenstandort.ORT }}</p>
        </div>
        <div (click)="toggleContactBar()" class="boxWithToggle" style="padding: 0 15px">
          <i class="dx-icon dx-icon-chevrondown"></i>
        </div>
      </div>
    </div>
    <div *ngIf="showContactBar" class="flexContainerItem">
      <app-bss-contact-bar
        [address]="anlage.Anlagenstandort.StandortAnschrift"
        [telephoneNumber]="anlage.Anlagenstandort.TEL"
        [telephoneDescription]="true"
      >
      </app-bss-contact-bar>
    </div>
  </div>
</div>

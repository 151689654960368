import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { IndexedDBTypes } from './dbType';

export class HWDatenblatt extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('HWDatenblatt') KlassenName: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: number;
  @IndexedDBTypes.DataField('string') ABESCHR: string = null;
  @IndexedDBTypes.DataField('string') BEZ: string = null;
  @IndexedDBTypes.DataField('string[]') DEFAULTS: string[] = [];
  @IndexedDBTypes.DataField('string[]') EINHEITEN: string[] = [];
  @IndexedDBTypes.DataField('string[]') FELDER: string[] = [];
  @IndexedDBTypes.DataField('string') NR: string = null;
  @IndexedDBTypes.DataField('string') RCIDATE: string = null;
  @IndexedDBTypes.DataField('number') RCIUSER: number = null;
  @IndexedDBTypes.DataField('string') REPORT: string = null;
  @IndexedDBTypes.DataField('string') RTFDATA: string = null;
  @IndexedDBTypes.DataField('string') TXDATA: string = null;
  @IndexedDBTypes.DataField('string') TXFIELDS: string = null;
  @IndexedDBTypes.DataField('string') UUID: string = null;
  @IndexedDBTypes.DataField('string[]') VORGABEN: string[] = [];
  @IndexedDBTypes.DataField('string[]') ZUSAETZE: string[] = [];

  constructor(data: HWDatenblatt) {
    super();
    GlobalHelper.assignIfPropertyExists(this, data);
  }

  static toString(): string {
    return 'HWDatenblatt';
  }
}

<div class="content-block bottom-padding" *ngIf="appointment">
  <div class="form-block">
    <h2>Termindetails</h2>

    <div *ngIf="!allowEdit" class="bss-alert bss-alert-warning">Editierrecht nicht vorhanden</div>

    <div class="dx-card responsive-paddings">
      <fieldset [disabled]="!allowEdit || hasReference">
        <div class="dx-fieldset">
          <div class="fieldbox">
            <app-bss-plain-text-box [labelText]="'Art'" [value]="appointmentText"></app-bss-plain-text-box>
            <app-bss-plain-text-box
              *ngIf="appointment.mitarbeiterName"
              [labelText]="'Monteur'"
              [value]="appointment.mitarbeiterName"
            ></app-bss-plain-text-box>
            <app-bss-textbox [labelText]="'Betreff'" [(value)]="appointment.caption"></app-bss-textbox>
            <app-bss-textbox [labelText]="'Ort'" [(value)]="appointment.location"></app-bss-textbox>
          </div>

          <app-bss-textarea [labelText]="'Beschreibung'" [(value)]="appointment.message"></app-bss-textarea>

          <div style="width: 150px; margin-bottom: 10px">
            <app-bss-search-popup
              [buttonText]="'Kunde wählen'"
              [buttonStyle]="'bss-button block-button primary-button'"
              [headline]="'Wählen Sie einen Kunden aus'"
              [inputDataSource]="appointmentPerson"
              [disabled]="!allowEdit || hasReference"
              [customDisplayExpressions]="['NAME', 'STRASSE', 'PLZ', 'ORT']"
              [additionalSearchExpression]="['KU_NR']"
              (selectedValue)="selectCustomer($event)"
            >
            </app-bss-search-popup>
          </div>

          <div *ngIf="selectedPerson" class="dx-field">
            <div class="dx-field-label">Kunde</div>
            <div class="dx-field-value">
              <div class="card-detail">
                <app-address-card [address]="selectedPerson"></app-address-card>
              </div>
            </div>
          </div>

          <div *ngIf="!task" class="fieldbox">
            <app-bss-datetime-box
              [min]="mindate"
              [labelText]="'Beginn'"
              [disabled]="!allowEdit || hasReference"
              [value]="appointment.startDate"
              [type]="editType"
              (outValue)="dateChanged($event, true)"
            ></app-bss-datetime-box>
            <app-bss-datetime-box
              [min]="appointment.startDate"
              [labelText]="'Ende'"
              [disabled]="!allowEdit || hasReference"
              [value]="appointment.endDate"
              [type]="editType"
              (outValue)="dateChanged($event, false)"
            ></app-bss-datetime-box>
          </div>

          <div *ngIf="task" class="fieldbox">
            <app-bss-datetime-box
              [labelText]="'Fälligkeit'"
              [disabled]="!allowEdit || hasReference"
              [value]="appointment.startDate"
              (outValue)="dateChanged($event, true)"
            ></app-bss-datetime-box>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</div>

<app-bss-speed-dial
  *ngIf="(dataChanged | async) && !hasReference && allowEdit"
  id="saveButton"
  icon="check"
  class="add-button"
  label="speichern"
  [index]="1"
  (Click)="save()"
></app-bss-speed-dial>

<app-bss-speed-dial
  *ngIf="hasReference && referenceActive"
  icon="arrowright"
  class="add-button"
  [label]="navigationButtonTitle"
  [index]="1"
  (Click)="navigateToReference()"
></app-bss-speed-dial>

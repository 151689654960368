import { Injectable } from '@angular/core';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { TimeHelper } from 'libs/shared/src/lib/helper/timeHelper';
import { RestService } from 'libs/shared/src/lib/services/rest.service';
import { tap } from 'rxjs';
import { GlobalSettings } from '../../config/Konstanten';
import { ActivityTracker } from '../../entities';
import { ActivityTrackingEntryDTO } from '../../entities/models/ActivityTrackingEntryDTO';
import { BaseInformationTracker } from '../../entities/repository/BaseInformationTracker';
import { ControllerService } from '../globalServices/controller.service';
import { HWGlobalSettingService } from '../globalServices/hwGlobalSetting.service';
import { StateService } from '../globalServices/state.service';
import { BaseService } from './base.service';
import { BaseInformationTrackerService } from './baseInformationTracker.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityTrackerService {
  constructor(
    private baseService: BaseService,
    private globalSettingsService: HWGlobalSettingService,
    private controllerService: ControllerService,
    private restService: RestService,
    private baseInformationService: BaseInformationTrackerService,
    private stateService: StateService
  ) {}

  async getCurrentActivityFromIDB(): Promise<ActivityTracker> {
    const allActivities = await this.getAllActivities();
    const activity = allActivities.find(e => e.Date.toLocaleDateString() === new Date().toLocaleDateString());

    let userInfo: UserInfo;
    if (!activity) {
      userInfo = await this.globalSettingsService.getUserInfo();
      const baseInfo = await this.baseInformationService.createBaseInformationEntity();
      await this.baseInformationService.saveBaseInformationToIDB(baseInfo);
    }
    return new ActivityTracker(activity, userInfo?.Device.Id);
  }

  async saveActivityToIDB(activity: ActivityTracker): Promise<void> {
    await this.deleteActivity(activity);
    await this.controllerService.setData(ActivityTracker.toString(), [activity]);
  }

  async pushUnpushedData(): Promise<void> {
    const savingTime = await this.globalSettingsService.getEntity<Date>(GlobalSettings.ActivityTrackerSavingTime);
    if (!savingTime || new Date() < savingTime) return;
    const newSavingTime = this.getSavingTime();
    await this.globalSettingsService.setEntity(newSavingTime, GlobalSettings.ActivityTrackerSavingTime);
    const loggedIn = this.stateService.loggedIn;
    if (!loggedIn) return;
    const activities = (await this.getAllActivities()).filter(
      e => e.Date.toLocaleDateString() !== new Date().toLocaleDateString()
    );

    const allBaseInformation = await this.baseInformationService.getAll();

    const isProd = this.globalSettingsService.isInProdEnvironment();
    const url = isProd
      ? 'https://activity.bssservices.de/api/ActivityTracker'
      : 'https://activitydev.bssservices.de/api/ActivityTracker';

    const activityDTOs: ActivityTrackingEntryDTO[] = [];
    for (const activity of activities) {
      const baseInformation = allBaseInformation.find(
        info => info.dateTime.toLocaleDateString() === activity.Date.toLocaleDateString()
      );
      const activityDTO = this.createActivityTrackingEntryDTO(activity, baseInformation);
      activityDTOs.push(activityDTO);
    }

    this.restService
      .newHttpPost(url, activityDTOs)
      .pipe(
        tap({
          next: () => {
            for (const activity of activities) {
              void this.deleteActivity(activity);
              const baseInformation = allBaseInformation.find(
                info => info.dateTime.toLocaleDateString() === activity.Date.toLocaleDateString()
              );
              void this.baseInformationService.deleteBaseInformation(baseInformation);
            }
          },
        })
      )
      .subscribe();
  }

  async countUpAutoSyncs(): Promise<void> {
    const loggedIn = this.stateService.loggedIn;
    if (!loggedIn) return;
    const activity = await this.getCurrentActivityFromIDB();
    activity.CallsAutomaticSync++;
    await this.saveActivityToIDB(activity);
  }

  createActivityTrackingEntryDTO(
    activity: ActivityTracker,
    baseInformationTracker: BaseInformationTracker
  ): ActivityTrackingEntryDTO {
    const moduleCalls = activity.getModuleCalls();

    const activityTrackingEntry = new ActivityTrackingEntryDTO(
      baseInformationTracker,
      moduleCalls,
      activity.CallsAutomaticSync,
      activity.CallsManualSync
    );

    return activityTrackingEntry;
  }

  /** @description creates a date on for the next day with a random Time between the startHour and endHour */
  getSavingTime(): Date {
    let currentDate = new Date();
    currentDate = TimeHelper.addDays(currentDate, 1);
    const startHour = 6;
    const endHour = 17;

    const randomHour = Math.floor(Math.random() * (endHour - startHour)) + startHour;
    const randomMinutes = Math.floor(Math.random() * 60);

    // Set the random time on the current date
    currentDate.setHours(randomHour, randomMinutes, 0, 0);

    return currentDate;
  }

  private async deleteActivity(activity: ActivityTracker): Promise<void> {
    await this.controllerService.deleteData(ActivityTracker.toString(), 'AutoKey', activity.AutoKey);
  }

  private async getAllActivities(): Promise<ActivityTracker[]> {
    return await this.baseService.getAll(ActivityTracker);
  }
}

<div class="content-block bottom-padding" *ngIf="measurement">
  <div class="form-block">
    <h2>Aufmaßdetails</h2>

    <div class="dx-card responsive-paddings">
      <div class="dx-fieldset">
        <div class="fieldbox">
          <button (click)="editAufmass()" class="bss-button button-block primary-button" style="width: 100%">
            Aufmaßdaten editieren
          </button>

          <app-bss-plain-text-box
            *ngIf="measurement.AufName"
            [labelText]="'Aufmaß'"
            [value]="measurement.AufName + ' ' + measurement?.AufmId"
          >
          </app-bss-plain-text-box>

          <app-bss-plain-text-box *ngIf="measurement.Such" [labelText]="'Suchbegriff'" [value]="measurement.Such">
          </app-bss-plain-text-box>

          <app-bss-plain-text-box *ngIf="measurement.Betreff" [labelText]="'Betreff'" [value]="measurement.Betreff">
          </app-bss-plain-text-box>
        </div>

        <div class="fieldbox-mobile">
          <app-bss-plain-text-box
            [labelText]="'Kunde'"
            [valueArray]="[customer.NAME, customer.STRASSE, customer.PLZ + ' ' + customer.ORT]"
            [buttonIcon]="'map'"
            [buttonEnabled]="!!(customer.STRASSE && customer.PLZ && customer.ORT)"
            (buttClick)="openMaps(customer.STRASSE, customer.PLZ, customer.ORT)"
          >
          </app-bss-plain-text-box>

          <app-bss-plain-text-box
            *ngIf="measurement.Datum"
            [labelText]="'Datum'"
            [value]="measurement.Datum?.substring(0, 10)"
          ></app-bss-plain-text-box>
        </div>

        <app-bss-toggle-textarea [buttonText]="'Bemerkung'" [(value)]="measurement.Beschr" [disabled]="true">
        </app-bss-toggle-textarea>

        <app-bss-button-link
          [title]="'Medien'"
          [path]="'medien/Aufmass/' + measurement.Uuid"
          [displayCount]="currentFilesAmount"
        ></app-bss-button-link>

        <div *ngIf="roomBook">
          <p
            id="rootNode"
            style="font-size: 16px"
            (click)="selectRoom(roomBook)"
            [ngStyle]="{ 'background-color': selectedRoomBookAsRoot ? 'rgba(0,0,0,.12)' : 'transparent' }"
          >
            <b>{{ roomBook.Bezeich }}</b>
          </p>
          <dx-tree-view
            *ngIf="roomBookAsTreeNodes"
            selectionMode="single"
            [focusStateEnabled]="false"
            [selectByClick]="true"
            [expandEvent]="'click'"
            [items]="roomBookAsTreeNodes"
            (onItemClick)="selectRoom($event.itemData)"
          >
          </dx-tree-view>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="roomBookAsTreeNodes && roomTemplateExists">
  <app-bss-speed-dial
    *ngIf="selectedRoomBookAsRoot === false"
    icon="trash"
    class="add-button"
    label="Raum entfernen"
    [index]="4"
    (Click)="deleteRoom(measurement, selectedRoom)"
  >
  </app-bss-speed-dial>
  <app-bss-speed-dial
    *ngIf="selectedRoom && selectedRoom.level !== roomBookPositionLevels.room"
    icon="add"
    class="add-button"
    label="Raum hinzufügen"
    [index]="3"
    (Click)="addRoom()"
  ></app-bss-speed-dial>
  <app-bss-speed-dial
    *ngIf="selectedRoomBookAsRoot === false && selectedRoom?.hasDimensionChain"
    icon="photo"
    class="add-button"
    label="Medien zum Raum"
    [index]="1"
    (Click)="newImages()"
  >
  </app-bss-speed-dial>
  <app-bss-speed-dial
    *ngIf="selectedRoomBookAsRoot === false && selectedRoom?.hasDimensionChain"
    icon="edit"
    class="add-button"
    label="Raumaufmaß bearbeiten"
    [index]="2"
    (Click)="gotoRoomMeasurement()"
  >
  </app-bss-speed-dial>
  <app-bss-speed-dial
    *ngIf="selectedRoomBookAsRoot === false && !selectedRoom?.hasDimensionChain"
    icon="add"
    class="add-button"
    label="Einzelaufmaß hinzufügen"
    [index]="1"
    (Click)="gotoDimensionChain()"
  >
  </app-bss-speed-dial>
  <app-bss-speed-dial
    *ngIf="selectedRoomBookAsRoot === false && !selectedRoom?.hasDimensionChain"
    icon="add"
    class="add-button"
    label="Grundriss-Skizze erstellen"
    [index]="0"
    (Click)="drawGroundPlan()"
  >
  </app-bss-speed-dial>
</div>

<div *ngIf="!roomTemplateExists">
  <app-bss-speed-dial icon="add" class="add-button" label="Raumvorlage wählen" (Click)="selectRoomTemplate()">
  </app-bss-speed-dial>
</div>

<dx-popup
  *ngIf="possibleRoomNames"
  [width]="'90%'"
  [maxWidth]="'500px'"
  [title]="'Raum hinzufügen'"
  [dragEnabled]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="showPopup"
>
  <app-raumvorlage
    *ngIf="showPopup"
    [parentRoom]="selectedRoom"
    [roomBookPositions]="roomBookPositions"
    [roomNames]="possibleRoomNames"
    (selectedRoomBookPosition)="addPositionToMeasurement($event)"
    [parentIsRoomBook]="selectedRoomBookAsRoot"
    [measurement]="measurement"
  >
  </app-raumvorlage>
</dx-popup>

<dx-popup
  *ngIf="finished"
  [width]="'90%'"
  [maxWidth]="'500px'"
  [height]="320"
  [title]="'Raumvorlage wählen'"
  [dragEnabled]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="showRoomTemplatePopup"
>
  <app-choose-raumvorlage [aufmass]="measurement"></app-choose-raumvorlage>
</dx-popup>

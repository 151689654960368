<div *ngIf="formulas" class="card">
  <app-bss-search-popup
    [buttonText]="'Formel aus Vorlage'"
    (click)="formulaString = ''"
    [buttonStyle]="'bss-button primary-button'"
    [headline]="'Wählen Sie eine Formel aus'"
    [inputDataSource]="formulas"
    [customDisplayExpressions]="['shortName']"
    (selectedValue)="selectedFormula = $event"
  >
  </app-bss-search-popup>

  <div *ngIf="selectedFormula">
    <br />
    <button (click)="clearFormulaSelection()" class="bss-button primary-button" style="width: 100%">
      Formel abwählen
    </button>
  </div>

  <div *ngIf="selectedFormula">
    <br />
    <app-bss-plain-text-box [labelText]="'Bezeichnung'" [value]="selectedFormula.shortName"> </app-bss-plain-text-box>
    <br />

    <div style="display: grid">
      <img style="margin: auto" *ngIf="selectedFormula.ImageBase64" [src]="getImage(selectedFormula.ImageBase64)" />
    </div>

    <br />
    <app-bss-plain-text-box
      *ngIf="selectedFormula"
      [labelText]="'Formel'"
      [value]="selectedFormula.formula.replaceAll('[', '').replaceAll(']', '')"
    >
    </app-bss-plain-text-box>
    <br />
    <div *ngFor="let pair of selectedFormula.ValueNamePairs">
      <app-bss-number-popup
        [labelText]="pair.displayName"
        [title]="'Eingabe: ' + pair.displayName"
        [id]="'messwert'"
        (outputValue)="measurementChanged($event, pair.displayName)"
        [mengenTyp]="'m'"
        [placeholder]="pair.displayName"
        [rtlEnabled]="true"
        [inputValue]="pair.value"
        [minusAllowed]="false"
        [showArrows]="false"
        [DecimalPlaces]="getDecimalPlaces(pair)"
      >
      </app-bss-number-popup>
      <br />
    </div>
  </div>

  <br />

  <div *ngIf="!selectedFormula" class="or-box">oder</div>

  <br *ngIf="!selectedFormula" />

  <app-bss-textbox
    *ngIf="!selectedFormula"
    [labelText]="'Maßkette eingeben :'"
    [(value)]="formulaString"
    (focusOut)="validateFormula($event)"
  >
  </app-bss-textbox>

  <app-bss-textbox
    *ngIf="selectedFormula && allMeasured"
    [labelText]="'Werteformel :'"
    [(value)]="formulaString"
    (focusOut)="validateFormula($event)"
  >
  </app-bss-textbox>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Belegpositionen } from 'apps/handwerkPWA/src/app/entities';

@Component({
  selector: 'app-supporting-document-position-card',
  templateUrl: './supporting-document-position-card.component.html',
  styleUrls: ['./supporting-document-position-card.component.scss'],
})
export class SupportingDocumentPositionCardComponent implements OnInit {
  @Input() belegposition: Belegpositionen;
  @Input() priceRight: boolean;
  menge: string;

  ngOnInit(): void {
    // Auswertung für Löhne
    if (this.belegposition.XFlags === '00000001' || this.belegposition.XFlags === '00080001')
      this.menge = (this.belegposition.Zeit / 60).toString();
    else this.menge = this.belegposition.Menge.toString();
  }
}

<div class="dx-field">
  <div *ngIf="labelText" class="dx-field-label">{{ labelText }}</div>
  <div class="dx-field-value">
    <dx-select-box
      #selectbox
      [dataSource]="selectBoxData"
      [value]="value"
      [showClearButton]="showClearButton"
      [searchEnabled]="!!searchMode"
      [displayExpr]="displayExpressionValue"
      [disabled]="disabled"
      [acceptCustomValue]="acceptCustomValue"
      [searchExpr]="searchExpr?.toString()"
      [showDataBeforeSearch]="true"
      [minSearchLength]="minSearchLength"
      [showDataBeforeSearch]="showDataBeforeSearch"
      [placeholder]="placeholder"
      (onValueChanged)="valueChanged($event)"
    ></dx-select-box>
  </div>
</div>

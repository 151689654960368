<div *ngIf="finishedLoading">
  <div class="content-block bottom-padding">
    <app-bss-multiView *ngIf="multiViewSites" [multiViewItems]="multiViewSites"></app-bss-multiView>
  </div>

  <app-bss-speed-dial
    *ngIf="isPremium && rights.measurementRights.enableModule"
    icon="home"
    label="Neues Aufmaß"
    [index]="5"
    (Click)="navigateTo('aufmass/neu/type/R')"
  ></app-bss-speed-dial>
  <app-bss-speed-dial
    *ngIf="isPremium && rights.repairOrderRights.createRepairOrder"
    icon="columnproperties"
    label="Neuer Reparaturauftrag"
    [index]="4"
    (Click)="navigateTo('reparaturauftrag/neu')"
  ></app-bss-speed-dial>
  <app-bss-speed-dial
    *ngIf="rights.addressRights.editCustomers || rights.addressRights.editFreeAddresses"
    icon="user"
    label="Neue Adresse"
    [index]="3"
    (Click)="navigateTo('adresse/neu')"
  ></app-bss-speed-dial>
  <app-bss-speed-dial
    *ngIf="rights.appointmentRights.allowCreation"
    icon="clock"
    label="Neuer Termin"
    [index]="2"
    (Click)="navigateTo('termin/neu')"
  ></app-bss-speed-dial>
  <app-bss-speed-dial
    *ngIf="isPremium && rights.repairOrderRights.enableModule"
    icon="message"
    label="Neue Nachricht"
    [index]="1"
    (Click)="navigateTo('nachricht/neu')"
  ></app-bss-speed-dial>
</div>

import { ModuleCalls } from '../models/ModuleCallsDTO';
import { IndexedDBTypes } from './dbType';

export class ActivityTracker extends IndexedDBTypes.DbType {
  @IndexedDBTypes.KlassenName('ActivityTracker') KlassenName: string;
  @IndexedDBTypes.KeyDBField('number') AutoKey: string;
  @IndexedDBTypes.DataField('Date') Date: Date;
  @IndexedDBTypes.DataField('number') DeviceId: number;
  //**Calls from Login, BackgroundSync */
  @IndexedDBTypes.DataField('number') CallsAutomaticSync = 0;
  //**Calls from sync button in the Header or the side-menu */
  @IndexedDBTypes.DataField('number') CallsManualSync = 0;
  @IndexedDBTypes.DataField('number') CallsAddresses = 0;
  @IndexedDBTypes.DataField('number') CallsSupportingDocuments = 0;
  @IndexedDBTypes.DataField('number') CallsCalendar = 0;
  @IndexedDBTypes.DataField('number') CallsRepairOrder = 0;
  @IndexedDBTypes.DataField('number') CallsMessages = 0;
  @IndexedDBTypes.DataField('number') CallsMaintenanceSystems = 0;
  @IndexedDBTypes.DataField('number') CallsMaintenanceOrders = 0;
  @IndexedDBTypes.DataField('number') CallsRoomMeasurement = 0;
  @IndexedDBTypes.DataField('number') CallsDiscover = 0;

  constructor(data: ActivityTracker, deviceId?: number) {
    super();
    this.Date = new Date();
    this.Date.setHours(0, 0, 0, 0);
    Object.assign(this, data);
    if (deviceId) this.DeviceId = deviceId;
  }
  static toString(): string {
    return 'ActivityTracker';
  }

  getModuleCalls(): ModuleCalls[] {
    const moduleCalls: ModuleCalls[] = [];
    if (this.CallsAddresses > 0) moduleCalls.push(new ModuleCalls('Addresses', this.CallsAddresses));

    if (this.CallsSupportingDocuments > 0)
      moduleCalls.push(new ModuleCalls('SupportingDocuments', this.CallsSupportingDocuments));

    if (this.CallsCalendar > 0) moduleCalls.push(new ModuleCalls('Calendar', this.CallsCalendar));

    if (this.CallsRepairOrder > 0) moduleCalls.push(new ModuleCalls('RepairOrder', this.CallsRepairOrder));

    if (this.CallsMessages > 0) moduleCalls.push(new ModuleCalls('Messages', this.CallsMessages));

    if (this.CallsMaintenanceSystems > 0)
      moduleCalls.push(new ModuleCalls('MaintenanceSystems', this.CallsMaintenanceSystems));

    if (this.CallsMaintenanceOrders > 0)
      moduleCalls.push(new ModuleCalls('MaintenanceOrders', this.CallsMaintenanceOrders));

    if (this.CallsRoomMeasurement > 0) moduleCalls.push(new ModuleCalls('RoomMeasurement', this.CallsRoomMeasurement));

    if (this.CallsDiscover > 0) moduleCalls.push(new ModuleCalls('Discover', this.CallsDiscover));

    return moduleCalls;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-bss-toggle-textarea',
  templateUrl: './bss-toggle-textarea.component.html',
  styleUrls: ['./bss-toggle-textarea.component.scss'],
})
export class BssToggleTextareaComponent {
  @Input() buttonText: string;
  @Input() labelText: string;
  @Input() value: string;
  @Input() disabled = false;
  @Input() placeholder: string;
  @Output() valueChange = new EventEmitter<string>();
  showTextArea = false;

  toggleTextArea(): void {
    this.showTextArea = !this.showTextArea;
  }

  valueChanged(value: string): void {
    this.valueChange.emit(value);
  }
}

import { Component, OnInit } from '@angular/core';
import { HWAnlage } from 'apps/handwerkPWA/src/app/entities';
import { MaintenanceSystemService } from 'apps/handwerkPWA/src/app/services/dataServices/maintenanceSystem.service';
import { MediaService } from 'apps/handwerkPWA/src/app/services/globalServices/media.service';
import { ContactHelper } from 'libs/shared/src/lib/helper/contactHelper';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';

@Component({
  selector: 'app-system-edit',
  templateUrl: './system-edit.component.html',
  styleUrls: ['./system-edit.component.scss'],
})
export class SystemEditComponent implements OnInit {
  anlage: HWAnlage;
  showBeschreibung = false;
  scrollOffsetTop: number;
  currentFilesAmount: string;
  callPhone = ContactHelper.callPhone;
  mailTo = ContactHelper.mailTo;
  inputSystemMeasurementRight: boolean;

  constructor(
    private maintenanceSystemService: MaintenanceSystemService,
    private routingService: RoutingService,
    private mediaService: MediaService,
    private rightsService: RightsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.inputSystemMeasurementRight =
      this.rightsService.getCurrentRight().employeeRights.maintenanceRights.inputMeasuredValues;
    const anlageUUID = this.routingService.getRouteParam('uuid');
    this.anlage = await this.maintenanceSystemService.findOneAnlageBy('UUID', anlageUUID);
    await this.getFileAmount();
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  private async getFileAmount(): Promise<void> {
    const files = await this.mediaService.getAllBy('Baseuuid', this.anlage.UUID);
    this.currentFilesAmount = GlobalHelper.calculateDataAmount(files);
  }
}

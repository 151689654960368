<div class="bss-toggle-textarea">
  <div class="flexContainer" (click)="toggleTextArea()">
    <div class="flexContainerItem button-text">
      {{ buttonText }}
    </div>
    <div class="arrow" *ngIf="value?.length > 1">
      <img class="dreieck" [src]="'assets/icons/info.png'" style="max-width: 25px" />
    </div>
    <div class="arrow">
      <img
        class="dreieck"
        [src]="'assets/icons/dreieck.svg'"
        style="max-width: 25px"
        [class.rotated180]="showTextArea"
      />
    </div>
  </div>
  <app-bss-textarea
    *ngIf="showTextArea"
    [labelText]="labelText"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(value)]="value"
    (valueChange)="valueChanged($event)"
  ></app-bss-textarea>
</div>

import { HWAddress } from 'apps/handwerkPWA/src/app/entities';
import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';

export class SortHelper {
  static sortAddresses(addresses: HWAddress[]): HWAddress[] {
    const allAddresses = this.sortAddressByKundennummer(addresses);
    const allAddressesSorted = this.changeOrderOfAddressTypes(allAddresses);

    return allAddressesSorted;
  }

  /**@description Sortiert die Adressen nach Kundennummer mit der größten Kunden Nummer zuerst
   * @param reverse Umgekehrte Reihenfolge
   */
  private static sortAddressByKundennummer(addresses: HWAddress[], reverse?: boolean): HWAddress[] {
    addresses = addresses.sort((a, b) => GlobalHelper.compareFunction(a.KU_NR, b.KU_NR, reverse));
    return addresses;
  }

  /**@description Sortiert die Adressen in Blöcke um sie neu zusammenzusetzen */
  private static changeOrderOfAddressTypes(allAddresses: HWAddress[]): HWAddress[] {
    const customers: HWAddress[] = [];
    const lieferanten: HWAddress[] = [];
    const freie: HWAddress[] = [];
    const mitarbeiter: HWAddress[] = [];
    for (const address of allAddresses) {
      const type = address.ADRTYP;
      switch (type) {
        case 'K': {
          customers.push(address);
          break;
        }
        case 'L': {
          lieferanten.push(address);
          break;
        }
        case 'F': {
          freie.push(address);
          break;
        }
        case 'M': {
          mitarbeiter.push(address);
          break;
        }
      }
    }
    const firstBlock = customers.concat(lieferanten);
    const secondBlock = freie.concat(mitarbeiter);
    const allAddressesReordered = firstBlock.concat(secondBlock);
    return allAddressesReordered;
  }
}

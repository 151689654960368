import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { addressSalesSearch } from '../../../config/search-constants';
import { AddressService } from '../../../services/dataServices/address.service';
import { UmsatzService } from '../../../services/dataServices/umsatz.service';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';

@Component({
  selector: 'app-address-sales',
  templateUrl: './address-sales.component.html',
  styleUrls: ['./address-sales.component.scss'],
})
export class AddressSalesComponent implements OnInit {
  searchExpressions = addressSalesSearch;
  sales = [];

  constructor(
    private globalSettingService: HWGlobalSettingService,
    private umsatzService: UmsatzService,
    private routingService: RoutingService,
    private addressService: AddressService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAddressSales();
  }

  private async loadAddressSales(): Promise<void> {
    const userInfo = await this.globalSettingService.getUserInfo();
    const addressGuid = this.routingService.getRouteParam('guid');
    const address = await this.addressService.findOneBy('Guid', addressGuid);
    this.sales = await this.umsatzService.getUmsatzByKundennummerByChoice(userInfo, address.KU_NR);
  }
}

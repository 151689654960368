import { BrowserQRCodeReader } from '@zxing/browser';
export class QRCodeHelper {
  static async readQRCodeFromFile(file: File): Promise<string> {
    const img = document.createElement('img');
    const reader = new FileReader();

    // Read file data
    reader.readAsDataURL(file);

    // Wait for reader to load
    await new Promise((resolve, reject) => {
      reader.onload = (): void => {
        img.src = reader.result as string;
        img.onload = resolve;
        img.onerror = reject;
      };
    });

    const qrCodeReader = new BrowserQRCodeReader();

    // Decode QR code from image data
    const result = await qrCodeReader.decodeFromImageElement(img);

    return result.getText();
  }
}

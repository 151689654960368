import { GlobalHelper } from 'libs/shared/src/lib/helper/globalHelper';
import { HWAnlage } from '../../repository/HWAnlage';

export enum NotificationWay {
  Telephone = 0,
  Written = 1,
  Fax = 2,
  EMail = 3,
  NoNotification = 4,
  NotPlanned = 5,
}

export class Anlagentermin {
  INTERVALL: number = null; // Wartungszyklus in Tagen
  ANLAGEBEG: string = null; // Beginn Wartung
  ISTART: string = null; // IntervallBeginntAb
  ANLAGEENDE: string = null; // EndeWartung
  LWARTUNG: string = null; // LetzteWartung
  NWARTUNG: string = null; // NaechsteWartung
  USELWTONW: boolean = null; // Letzte Wartung für Intervall verwenden
  USERTTONW: boolean = null; // LetztenTatsaechlichenTermin
  NOWARTUNG: boolean = null; // KeineWartung:
  Benachrichtigung: string = null; // ?
  INBETRIEB: string = null; // Inbetriebnahme
  VGARANTIE: string = null; // GarantieVon
  BGARANTIE: string = null; // GarantieBis
  KUENDAM: string = null; // Gekündigt am
  IntervallReadable: string = null; // Gekündigt Am
  NACHRICHT: NotificationWay = null;

  constructor(data: Anlagentermin | HWAnlage) {
    GlobalHelper.assignIfPropertyExists(this, data);
    this.cleanTimeEntries();
    this.IntervallReadable = this.getReadableIntervall();
  }

  cleanTimeEntries(): void {
    const propertyArray = Object.getOwnPropertyNames(this);
    for (const property of propertyArray) {
      let value = this[property];
      if (typeof value === 'string') {
        value = value.replace(' 00:00:00', '');
        this[property] = value;
      }
    }
  }

  private getReadableIntervall(): string {
    const interval = this.INTERVALL;
    switch (interval) {
      case 0: {
        return 'Kein Zyklus';
      }
      case 7: {
        return 'Wöchentlich';
      }
      case 30: {
        return 'Monatlich';
      }
      case 365: {
        return 'Jährlich';
      }
    }
    if (interval < 30) return 'Alle ' + interval + ' Tage';
    if (interval < 365) return 'Alle ' + (interval / 30).toFixed(0) + ' Monate';
    return 'Alle ' + (interval / 365).toFixed(0) + ' Jahre';
  }
}

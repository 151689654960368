import { DeviceInfo } from 'ngx-device-detector';
import { GlobalHelper } from '../../../helper/globalHelper';
import { UserInfo } from './UserInfo';

export class Mobiledevices {
  Id = -1;
  Monteurnr: string = null;
  Devtype: string = null;
  Devos: string = null;
  Devosversion: string = null;
  /**Ist die ID des Gerätes für Push - wird nur gesetzt falls Push aktiv und zugestimmt wurde */
  Devtoken = 'NoPush';
  /**Scheinbar der letzte Zeitpunkt der Kommunikation */
  LContact: Date = null;
  Unregistered = false;
  LicenceUuid: string = null;

  constructor(data: object) {
    GlobalHelper.assignIfPropertyExists(this, data);
    this.Unregistered = false;
  }

  assignPropertiesFromUserInfo(userInfo: UserInfo): void {
    this.Monteurnr = userInfo.monteur;
  }

  assignDeviceInfo(deviceInfo: DeviceInfo): void {
    this.Devtype = deviceInfo.device;
    this.Devos = deviceInfo.os;
    this.Devosversion = deviceInfo.os_version;
  }

  assignPushtoken(pushToken: string): void {
    this.Devtoken = pushToken;
  }

  assignId(Id: number): void {
    this.Id = Id;
  }

  unregister(): void {
    this.Unregistered = true;
  }
}

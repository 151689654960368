import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewComponent, DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { RecursiveHelper } from 'libs/shared/src/lib/helper/recursiveHelper';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { ScreenService } from 'libs/shared/src/lib/services/screen.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { datenschutzPath, hilfePath } from '../../../config/Konstanten';
import { NavigationItem } from '../../../entities';
import { LoginService } from '../../../pages/login/login.service';
import { HWGlobalSettingService } from '../../../services/globalServices/hwGlobalSetting.service';
import { NavigationItemService } from '../../../services/globalServices/navigationItem.service';
import { SyncService } from '../../../services/globalServices/sync.service';
import { HeaderModule } from '../header/header.component';
import {
  SideNavigationMenuComponent,
  SideNavigationMenuModule,
} from '../side-navigation-menu/side-navigation-menu.component';

@Component({
  selector: 'app-header-and-side-navigation',
  templateUrl: './header-and-side-navigation.component.html',
  styleUrls: ['./header-and-side-navigation.component.scss'],
})
export class HeaderAndSideNavigationComponent implements OnInit, OnDestroy {
  @ViewChild(DxScrollViewComponent, { static: true }) scrollView: DxScrollViewComponent;
  @ViewChild('sidenav') sidenav: SideNavigationMenuComponent;
  @Input() title: string;
  selectedRoute: Subject<string>;
  menuOpened: BehaviorSubject<boolean>;
  flatNavigationItems: BehaviorSubject<NavigationItem[]> = new BehaviorSubject<NavigationItem[]>(null);
  navigationItemSubscription: Subscription;
  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;

  constructor(
    private screen: ScreenService,
    private routingService: RoutingService,
    private loginService: LoginService,
    private syncService: SyncService,
    private globalSettingService: HWGlobalSettingService,
    private navigationItemService: NavigationItemService,
    private recursiveHelper: RecursiveHelper
  ) {}

  ngOnInit(): void {
    this.menuOpened = this.routingService.menuOpen;
    this.screen.changed.subscribe(() => {
      this.updateDrawer();
    });
    this.selectedRoute = this.routingService.currentRoute;
    this.updateDrawer();
    this.navigationItemSubscription = this.navigationItemService.currentNavigationItems.subscribe(navigationItems => {
      this.flatNavigationItems.next(this.recursiveHelper.recursiveFlatMap(navigationItems, 'items'));
    });
  }

  ngOnDestroy(): void {
    this.navigationItemSubscription.unsubscribe();
  }

  updateDrawer(): void {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];

    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    this.minMenuSize = isXSmall ? 0 : 60;
    this.shaderEnabled = !isLarge;
  }

  /**
   * @description Pfad(Klick auf Item) kommt an - nun Menü öffnen falls nicht offen, oder beim geöffnetem Menü den Pfad gehen und Menü schließen
   * - außer es ist ein großer bildschirm,dann kann das Menü immer offen bleiben
   */
  async navigationChanged(path: string): Promise<void> {
    const navigationItem = this.flatNavigationItems.value.find(e => e.items.length >= 0 && e.path === path);
    if (navigationItem.items.length !== 0) return;

    if (path === '/abmelden') {
      this.sidenav?.menu?.instance?.unselectAll();
      void this.loginService.logOut();
      return;
    }
    if (path === '/Synchronisation') {
      this.routingService.nextMenuState(false);
      const userInfo = await this.globalSettingService.getUserInfo();
      await this.syncService.getAllDataFromWebService(userInfo, false);
      this.routingService.reload();
      return;
    }
    if (path === datenschutzPath || path === hilfePath) {
      window.open(path);
      this.routingService.nextMenuState(false);
      return;
    }

    if (this.menuOpened.value) {
      void this.routingService.navigateTo(path);
      return;
    }
  }

  OnShaderClick = (): void => {
    if (this.shaderEnabled) this.menuOpened.next(!this.menuOpened);
  };
}

@NgModule({
  imports: [SideNavigationMenuModule, DxDrawerModule, HeaderModule, DxScrollViewModule, CommonModule],
  exports: [HeaderAndSideNavigationComponent],
  declarations: [HeaderAndSideNavigationComponent],
})
export class HeaderAndSideNavigationModule {}

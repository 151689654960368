export class Druckinformationen {
  Ort: string;
  Kundenbezug: string;
  Datum: string;
  constructor(Ort: string, Kundenbezug: string, Datum: string) {
    this.Ort = Ort;
    this.Kundenbezug = Kundenbezug;
    this.Datum = Datum;
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogService } from '@handwerk-pwa/shared';
import { BaseAuftrag, BaseDocumentPosition } from 'apps/handwerkPWA/src/app/interfaces';
import { HWGlobalSettingService } from 'apps/handwerkPWA/src/app/services/globalServices/hwGlobalSetting.service';
import { DxListComponent } from 'devextreme-angular';
import { UserInfo } from 'libs/shared/src/lib/entities';
import { RightsService } from 'libs/shared/src/lib/services/rights.service';
import { RoutingService } from 'libs/shared/src/lib/services/routing.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SpeedDialCssClasses } from '../../../config/Konstanten';
import { positionsSearch } from '../../../config/search-constants';
import { HWRepairOrder, ServiceAuftrag } from '../../../entities';
import { DragEvent } from '../../../interfaces/DragEvent';
import { RepairOrderService } from '../../../services/dataServices/repairOrder.service';
import { ServiceOrderService } from '../../../services/dataServices/serviceOrder.service';
import { ConnectionDialogues, ConnectionService } from '../../../services/globalServices/connection.service';

@Component({
  selector: 'app-order-items-index',
  templateUrl: './order-items-index.component.html',
  styleUrls: ['./order-items-index.component.scss'],
})
export class OrderItemsIndexComponent implements OnInit, OnDestroy {
  @ViewChild(DxListComponent, { static: true }) itemsList: DxListComponent;
  searchExpressions = positionsSearch;
  finalizeAble = false;
  sortedItems: BaseDocumentPosition[];
  order: BaseAuftrag;
  userInfo: UserInfo;
  showPrices: boolean;
  orderType: string;
  orderId: string;
  subscription: Subscription;
  hasShownOfflineMessage = false;
  canEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  speedDialCssClasses = SpeedDialCssClasses;

  constructor(
    private globalSettingsService: HWGlobalSettingService,
    private routingService: RoutingService,
    private repairOrderService: RepairOrderService,
    private serviceOrderService: ServiceOrderService,
    private rightsService: RightsService,
    private dialogService: DialogService,
    private connectionService: ConnectionService
  ) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.subscription = this.routingService.save.subscribe((nextRoute: string) => {
      void this.saveAndExit(nextRoute);
    });
    this.userInfo = await this.globalSettingsService.getUserInfo();
    await this.loadOrder();
    const showPricesRight = this.rightsService.getCurrentRight().employeeSettings.showPrices;
    this.showPrices = this.order.getShowPrices(showPricesRight);
    this.sortedItems = this.order.getDisplayPositions();

    this.canEdit.next(this.hasEditRights());
  }

  async loadOrder(): Promise<void> {
    this.orderType = this.routingService.getRouteParam('type');
    this.orderId = this.routingService.getRouteParam('id');

    if (this.orderType === 'reparaturauftrag') {
      this.order = await this.repairOrderService.findOneBy('Guid', this.orderId);
    } else if (this.orderType === 'wartungsauftrag') {
      this.order = await this.serviceOrderService.findOneBy('UUID', this.orderId);
    }
  }

  async navigateTo(path: string): Promise<void> {
    await this.routingService.navigateTo(path);
  }

  async listChanged(): Promise<void> {
    if (!this.hasShownOfflineMessage) {
      await this.connectionService.checkOnline(ConnectionDialogues.PushData);
      this.hasShownOfflineMessage = true;
    }
    this.routingService.dataChanged.next(true);
  }

  reorderList(dragEvent: DragEvent): void {
    this.order.reorderPositions(dragEvent);
    this.sortedItems = this.order.getDisplayPositions();
    this.routingService.dataChanged.next(true);
  }

  async saveAndExit(nextRoute: string): Promise<void> {
    if (this.order instanceof HWRepairOrder)
      await this.repairOrderService.sendRepairOrderToWebService(this.order, false);
    if (this.order instanceof ServiceAuftrag)
      await this.serviceOrderService.sendServiceOrderToWebService(this.order, false);
    await this.routingService.navigateTo(nextRoute);
    this.dialogService.closeLoadingDialog();
  }

  hasEditRights(): boolean {
    if (this.order instanceof ServiceAuftrag) return this.order.isUserMainEmployee(this.userInfo);
    return true;
  }
}

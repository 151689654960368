export class DeviceHelper {
  /**@description Gibt true zurück, wenn der userAgent auf Android schließen lässt, ansonsten false */
  static isAndroid(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    const isDeviceAndroid = userAgent.indexOf('android') > -1;
    return isDeviceAndroid;
  }

  /**@description Gibt true zurück, wenn der userAgent auf iOs schließen lässt, ansonsten false */
  static isUserAgentIOS(): boolean {
    const userAgent = navigator.userAgent;
    if (userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('Macintosh')) {
      return true;
    }
    return false;
  }

  static isIosStandalone(): boolean {
    const ios = this.isUserAgentIOS();
    const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;

    return ios && isInStandaloneMode;
  }

  /**@description Gibt true zurück wenn das Gerät des Users ein iPad oder Mac ist */
  static isIpad(): boolean {
    const userAgent = navigator.userAgent;
    if (userAgent.includes('iPad')) {
      return true;
    }
    if (userAgent.indexOf('Macintosh') > -1) {
      return true;
    }

    return false;
  }

  static b64toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    b64Data = b64Data.replace('data:application/pdf;base64,', '');
    b64Data = b64Data.replace('==', '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
